import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Space, message } from 'antd';
import { useCreateLinesheetMutation } from 'api/linesheetApi';
import SearchBar from 'components/Product/ProductListing/components/SearchBar';
import LinesheetsName from 'components/Linesheets/LinesheetName';
import Loader from 'components/Common/Loader';
import ImageWithTooltip from '../Tooltip';
import { faCopy, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  MainLayout,
  ProductHeading,
  SytledTitle,
  RightContainer,
  StyledLineSheetsButton,
} from './styles';

const Toolbar = ({
  title,
  selectedRows,
  searchFilter,
  handleSearchChange,
  handleDelete,
  handleDuplicate,
  setIsCreateModal,
  setEditCategory,
  setIsShowCatListing,
  isSelectAll,
}) => {
  const [isLinesheet, setIsLinesheet] = useState(false);
  const [selectedName, setSelectedName] = useState('');

  const [createLinesheet, { isLoading: linesheetIsLoading }] = useCreateLinesheetMutation();

  const navigate = useNavigate();

  const handleClickCreate = () => {
    setIsLinesheet(true);
    setIsShowCatListing(false);
    // navigate('/linesheets/new');
  };

  const backClickHandler = () => {
    setIsLinesheet(false);
  };

  const nameChangeHandler = (event) => {
    setSelectedName(event.target.value);
  };

  const handleCreate = async () => {
    setIsLinesheet(true);
    // create payload to create linesheet
    let payload = {
      name: selectedName,
      categoryIds: selectedRows,
      isSelectAllProducts: false,
      isSelectAllCategories: isSelectAll,
    };

    const res = await createLinesheet(payload);
    if (!res?.data?.id) {
      message.error(res?.error?.data?.message);
      return;
    }
    navigate(`/linesheet-editor/${res?.data?.id}`);
  };
  return (
    <MainLayout>
      {isLinesheet ? (
        <LinesheetsName
          handleCreate={handleCreate}
          backClickHandler={backClickHandler}
          nameChangeHandler={nameChangeHandler}
          selectedName={selectedName}
        />
      ) : (
        <Row justify="space-between">
          <Col>
            <ProductHeading>
              <Space>
                <SytledTitle>{title}</SytledTitle>
                <ImageWithTooltip
                  icon={faPlus}
                  onClick={() => {
                    setIsCreateModal(true);
                    setEditCategory(false);
                  }}
                  alt="icon"
                  title="Add new category"
                  placement="bottom"
                />
                <ImageWithTooltip
                  icon={faCopy}
                  onClick={() => handleDuplicate()}
                  alt="icon"
                  title="Duplicate Categories"
                  placement="bottom"
                />
                <ImageWithTooltip
                  icon={faTrash}
                  onClick={() => handleDelete()}
                  alt="icon"
                  title="Delete Categories"
                  placement="bottom"
                />
              </Space>
            </ProductHeading>
          </Col>
          <Col>
            <RightContainer>
              <SearchBar
                placeholder="Search"
                handleSearchChange={handleSearchChange}
                searchFilter={searchFilter}
              />
              <StyledLineSheetsButton
                disabled={selectedRows && selectedRows.length > 0 ? false : true}
                onClick={handleClickCreate}
              >
                Create Linesheet
              </StyledLineSheetsButton>
            </RightContainer>
          </Col>
        </Row>
      )}
      <Loader isLoading={linesheetIsLoading} />
    </MainLayout>
  );
};

export default Toolbar;
