import { useDispatch } from 'react-redux';
import { addPageAt, addPageAfterLast, updateZoomLevel, deletePages } from 'slices/editorSlice';

const PlaygroundMenuOptions = () => {
  const dispatch = useDispatch();

  const options = [
    {
      label: 'Linesheets',
      disabled: true,
    },
    {
      label: 'Add Page Before First',
      onClick: () => dispatch(addPageAt(0)),
    },
    {
      label: 'Add Page After Last',
      onClick: () => dispatch(addPageAfterLast()),
    },
    {
      type: 'divider',
    },
    {
      label: 'Zoom In',
      onClick: () => dispatch(updateZoomLevel({ zoomType: 'zoom-in' })),
    },
    {
      label: 'Zoom Out',
      onClick: () => dispatch(updateZoomLevel({ zoomType: 'zoom-out' })),
    },
    {
      label: 'Reset Zoom',
      onClick: () => dispatch(updateZoomLevel({ zoomType: 'zoom-reset' })),
    },
    {
      type: 'divider',
    },
    {
      label: 'Delete All Pages',
      onClick: () => dispatch(deletePages()),
    },
  ];

  return options;
};

export default PlaygroundMenuOptions;
