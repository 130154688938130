import React from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import Loader from '../Common/Loader';
import { useResetPasswordReqMutation } from 'api/authApi';
import {
  BackgroundLayout,
  ContentWrapper,
  InnerLayout,
  OuterLayout,
  StyledBody,
  StyledText,
  StyledForm,
  StyledInput,
  StyledButton,
  ButtonWrapper,
  StyledLink,
  LinkWrapper,
} from './styles';

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [passwordReset, { isLoading }] = useResetPasswordReqMutation();

  const onFinish = async (values) => {
    const res = await passwordReset(values);
    if (res.data) {
      message.success('An email has been successfully sent to the provided email address.');
      navigate('/login');
    } else if (res.error) {
      message.error(res.error.data.message);
    }
  };
  return (
    <BackgroundLayout>
      <OuterLayout>
        <InnerLayout>
          <StyledBody>
            <StyledText>Reset Password</StyledText>
            <ContentWrapper>
              <StyledForm
                name="forgetPassword"
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
              >
                <StyledForm.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: 'Please input your email!' },
                    { type: 'email', message: 'Please enter a valid email address!' },
                  ]}
                >
                  <StyledInput placeholder="Enter email address" />
                </StyledForm.Item>
                <ButtonWrapper>
                  <StyledButton htmlType="submit" size="large">
                    Reset
                  </StyledButton>
                </ButtonWrapper>
                <LinkWrapper>
                  <StyledLink to="/login">Login</StyledLink>
                </LinkWrapper>
              </StyledForm>
            </ContentWrapper>
          </StyledBody>
        </InnerLayout>
      </OuterLayout>
      <Loader isLoading={isLoading} />
    </BackgroundLayout>
  );
};

export default ForgetPassword;
