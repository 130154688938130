import { useDispatch } from 'react-redux';
import {
  updateFontSettings,
  updateLinesheetFontSettings,
  updateHeaderSettings,
  updateFooterSettings,
} from 'slices/editorSlice';

import { faAlignLeft, faAlignCenter, faAlignRight } from '@fortawesome/free-solid-svg-icons';

const EditorTextDirectionToolsComponent = () => {
  const dispatch = useDispatch();

  const textDecorationClickHandler = (val, isGlobal, isHeader = false, isFooter = false) => {
    const payload = { key: 'align', value: val };
    const action = isGlobal
      ? updateLinesheetFontSettings
      : isHeader
      ? updateHeaderSettings
      : isFooter
      ? updateFooterSettings
      : updateFontSettings;
    dispatch(action(payload));
  };

  const TextDirectionTools = [
    {
      name: 'left',
      tooltip: 'Left Justify',
      icon: faAlignLeft,
      disabled: false,
      action: textDecorationClickHandler,
    },
    {
      name: 'center',
      tooltip: 'Middle Justify',
      icon: faAlignCenter,
      disabled: false,
      action: textDecorationClickHandler,
    },
    {
      name: 'right',
      tooltip: 'Right Justify',
      icon: faAlignRight,
      disabled: false,
      action: textDecorationClickHandler,
    },
  ];

  return TextDirectionTools;
};

export default EditorTextDirectionToolsComponent;
