import { Col, Row, Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import globals from 'websiteGlobals';

const Container = styled.div`
  padding: 48px 40px;
`;

const StyledCol = styled(Col)`
  margin-bottom: 1rem;
`;

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 10px;
`;

const CardBody = styled.div`
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
`;

const CardTitle = styled.h5`
  margin-bottom: 0.75rem;
  padding: 25px 0;
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
`;

const StyledLink = styled(Link)`
  color: #02b183;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  font-family: Muli;
`;

const VideoDivHolder = styled.div`
  h2 {
    font-family: Muli;
    font-size: 30px;
    font-weight: 600;
    line-height: 1;
    color: #000000;
    margin: 0 0 35px;
  }
`;

const Videos = () => {
  const videosSections = globals.videos.map((videoSection) => (
    <StyledCol md={8}>
      <Card>
        <CardBody>
          <CardTitle>{videoSection.name}</CardTitle>
          <p>{videoSection.description}</p>
          <Tooltip placement="bottom" title="Get started">
            <StyledLink to={`/videos/${videoSection.videoCategory}`}>Watch Videos</StyledLink>
          </Tooltip>
        </CardBody>
      </Card>
    </StyledCol>
  ));

  return (
    <Container>
      <VideoDivHolder>
        <h2>Linesheets Video Tutorials</h2>
      </VideoDivHolder>
      <Row gutter={24}>{videosSections}</Row>
    </Container>
  );
};

export default Videos;
