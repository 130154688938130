import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MainContainer, StyledActionButtons, StyledSaveButton, StyledCancelButton } from './styles';

const PopoverContent = ({ html, onSave, onCancel }) => {
  const [editorData, setEditorData] = useState(html);

  const handleSave = () => {
    onSave(editorData);
  };

  const handleCancel = () => {
    onCancel();
    setEditorData(html);
  };

  return (
    <MainContainer>
      <CKEditor
        editor={ClassicEditor}
        data={editorData}
        onChange={(event, editor) => {
          const data = editor.getData();
          setEditorData(data);
        }}
        config={{
          toolbar: ['numberedList', 'bulletedList'],
        }}
      />
      <StyledActionButtons>
        <StyledSaveButton type="primary" onClick={handleSave}>
          Save
        </StyledSaveButton>
        <StyledCancelButton type="default" onClick={handleCancel}>
          Cancel
        </StyledCancelButton>
      </StyledActionButtons>
    </MainContainer>
  );
};

export default PopoverContent;
