import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sources: [],
};

const externalSourceApiSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setSources: (state, action) => {
      state.sources = action.payload;
    },
    clearSources: (state) => {
      state.sources = [];
    },
  },
});

export const { setSource, clearSource } = externalSourceApiSlice.actions;
export default externalSourceApiSlice.reducer;
