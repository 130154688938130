import React, { useEffect, useState, useMemo } from 'react';
import globals, { ObjectToArray } from 'websiteGlobals';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  addPage,
  addTemplateId,
  changeTemplate,
  setShowConfirmTemplateModal,
  updateLinesheetTemplate,
  setTemplateModalVisibility,
  setIsEditorLoading,
} from 'slices/editorSlice';
import {
  StyledModal,
  StyledTitle,
  FooterWrapper,
  StyledButton,
  ButtonsWrapper,
  CustomTemplateContainer,
  TemplateGridCell,
  TemplateGridCellInfo,
  TemplateGridCellName,
  TemplateGridCellThumbnail,
  ThumbnailContainer,
  StyledFontAwesomeIcon,
  ThumbnailRow,
  ThumbnailCol,
  ThumbnailBg,
} from './styles';

const TemplateModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const templatesArray = useMemo(() => ObjectToArray(globals.pageTemplates), []);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const pages = useSelector((state) => state.editor.linesheetData.pages);
  const { isChangeTemplate, selectedPageIndex, changeLinesheetTemplate } = useSelector(
    (state) => state.editor,
  );

  useEffect(() => {
    const currentPage = pages[selectedPageIndex];
    if (currentPage) {
      const currentTemplate = currentPage.settings?.template;
      if (currentTemplate) {
        setSelectedTemplateId(currentTemplate.templateId);
      }
    }
  }, [selectedPageIndex]);

  const handleClose = () => {
    dispatch(setTemplateModalVisibility({ show: false }));
  };

  const templateClickHandler = (template) => {
    dispatch(setTemplateModalVisibility({ show: false }));
    dispatch(addTemplateId(template.templateId));
    dispatch(setIsEditorLoading({ isLoading: true }));
    onClose();

    setTimeout(() => {
      if (isChangeTemplate) {
        const existingTemplate = pages[selectedPageIndex].settings.template;
        const totalContentSize = existingTemplate.gridSize[0] * existingTemplate.gridSize[1];
        const newTemplateTotalSize = template.gridSize[0] * template.gridSize[1];

        if (
          newTemplateTotalSize < totalContentSize &&
          pages[selectedPageIndex].content.length > newTemplateTotalSize
        ) {
          dispatch(setShowConfirmTemplateModal({ templateId: template.templateId }));
        } else {
          dispatch(changeTemplate({ template }));
        }
      } else if (changeLinesheetTemplate) {
        dispatch(updateLinesheetTemplate({ template }));
      } else if (template.templateId === 'blanktemplate') {
        dispatch(addPage());
      } else {
        dispatch(addPage({ template }));
      }
      dispatch(setIsEditorLoading({ isLoading: false }));
    }, 1000);
  };

  const renderTemplateThumbnailBlank = (template) => (
    <TemplateGridCellThumbnail
      className={template.templateId === selectedTemplateId ? 'template-active' : ''}
    >
      <ThumbnailContainer>
        <StyledFontAwesomeIcon size="lg" icon={faPlus} />
      </ThumbnailContainer>
    </TemplateGridCellThumbnail>
  );

  const renderTemplateThumbnail = (template) => {
    const [gridX, gridY] = template.gridSize;
    const activeClass = template.templateId === selectedTemplateId ? 'template-active' : '';
    const colWidth = 100 / gridX;

    return (
      <TemplateGridCellThumbnail className={activeClass}>
        <ThumbnailContainer>
          {Array.from({ length: gridY }).map((_, i) => (
            <ThumbnailRow key={i} height={100 / gridY}>
              {Array.from({ length: gridX }).map((_, y) => (
                <ThumbnailCol key={y} width={colWidth}>
                  <ThumbnailBg />
                </ThumbnailCol>
              ))}
            </ThumbnailRow>
          ))}
        </ThumbnailContainer>
      </TemplateGridCellThumbnail>
    );
  };

  return (
    <StyledModal
      title={<StyledTitle>Template Select</StyledTitle>}
      open={true}
      footer={
        <FooterWrapper>
          <ButtonsWrapper>
            <StyledButton size="large" type="text" onClick={handleClose}>
              Cancel
            </StyledButton>
            <StyledButton size="large">Continue</StyledButton>
          </ButtonsWrapper>
        </FooterWrapper>
      }
      onCancel={handleClose}
      width="70%"
    >
      {templatesArray.map((template, index) => (
        <CustomTemplateContainer key={template.templateId}>
          <TemplateGridCell onClick={() => templateClickHandler(template)}>
            {index === 0
              ? renderTemplateThumbnailBlank(template)
              : renderTemplateThumbnail(template)}
            <TemplateGridCellInfo>
              <TemplateGridCellName>{template.name}</TemplateGridCellName>
            </TemplateGridCellInfo>
          </TemplateGridCell>
        </CustomTemplateContainer>
      ))}
    </StyledModal>
  );
};

export default React.memo(TemplateModal);
