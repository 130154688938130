import styled from 'styled-components';
import AntForm from 'uiKits/Form';
import { AntInput, AntTextArea } from 'uiKits/Input';
import AntButton from 'uiKits/Button';
import AntSelect from 'uiKits/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FormContainer = styled.div`
  width: 100%;
  padding: ${(props) => (props.removepadding ? '0' : '48px 32px;')};
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1400px) {
    max-width: 1340px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 1.5rem;
`;

export const StyledHeading = styled.h3`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  margin-bottom: 0;
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StyledCancelButton = styled(AntButton)`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 16px;
  background: none;
  border-color: unset;
  box-shadow: none;
  border: 0;
  color: #6c757d;
  border-radius: 10000px;
  font-weight: 600;
  &:hover {
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
    border-color: #017f5e !important;
  }
`;

export const StyledSubmitButton = styled(AntButton)`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 16px;
  border-width: 2px;
  font-weight: 600;
  border-radius: 10000px;
  &:hover {
    color: #fff !important;
    background-color: #028b67 !important;
    border-color: #017f5e !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 189, 150, 0.5) !important;
    color: #fff !important;
    background-color: #028b67 !important;
    border-color: #017f5e !important;
  }
  &.ml-2 {
    margin-left: 5px;
  }
`;

export const StyledViewButton = styled(AntButton)`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 16px;
  border-width: 2px;
  font-weight: 600;
  border-radius: 10000px;
  color: #02b183;
  border-color: #02b183;
  background: none;
  &:hover {
    color: #fff !important;
    background-color: #02b183 !important;
    border-color: #02b183 !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(2, 177, 131, 0.5) !important;
  }
`;

export const HorizontalLine = styled.hr`
  margin-top: 1rem;
  margin-bottom: 1rem;
  opacity: unset;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const StyledForm = styled(AntForm)`
  .ant-form-item {
    margin-bottom: 1.5rem;
  }
  .ant-form-item-label {
    display: flex;
    align-items: center;
    padding: 0;
    label {
      margin-bottom: 0.625vw;
      font-family: Muli;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: 0.28px;
      &:before {
        display: none !important;
      }
    }
  }
`;

export const StyledInput = styled(AntInput)`
  max-width: 256px;
  height: calc(1.5em + 1.3rem + 4px);
  border-width: 2px;
  padding: 0.65rem 0.75rem;
  font-weight: 400;
`;

export const StyledButton = styled(AntButton)`
  width: 100%;
  font-size: 0.8333333333333334vw;
  margin-top: 1.5rem;
  border-radius: 20px;
  line-height: 16px;
  letter-spacing: 0.96px;

  @media all and (max-width: 1023px) {
    font-size: 12px;
  }
  @media all and (max-width: 600px) {
    width: 100%;
  }
`;

export const StyledTextArea = styled(AntTextArea)`
  height: unset;
`;

export const StyledSelect = styled(AntSelect)`
  border-radius: 10px;
  background-color: #ffffff;
  width: 100%;
  max-width: 150px;
  min-height: 45px;
  border: 2px solid #ced4da;
  align-items: flex-start;
  max-width: 100%;
  .ant-select-arrow {
    display: none;
  }
  .ant-select-selector {
    border: none !important;
    cursor: pointer !important;
    min-height: 40px;
  }
`;

export const BarcodeContainer = styled.div`
  position: relative;
  width: 271px;
  height: 130px;
  border-radius: 15px;
  background-color: #dbdbdb;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const BarcodeContent = styled.div`
  display: flex;
  align-items: center;
  padding: 35px 15px;
`;

export const BarcodeImageIcon = styled.img`
  width: 61px;
  height: 57px;
`;

export const BarcodeText = styled.p`
  flex: 1;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: 0.32px;
  text-align: left;
  color: #495057;
  margin: 0px;
  margin-left: 10px;
  span {
    display: block;
    width: 100%;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: 0.28px;
    text-align: left;
    color: #495057;
    margin-top: 10px;
    .image-underline-text {
      font-weight: bold;
      text-decoration: underline;
    }
  }
`;

export const AddVariantContainer = styled.div`
  position: relative;
  margin-top: 4.5rem;
  .question-icon {
    margin-left: 10px;
    vertical-align: middle;
  }
`;

export const VariantHeading = styled(StyledHeading)`
  margin-bottom: 0;
`;

export const CustomError = styled.p`
  font-size: 16px;
  color: red;
`;

export const EditProductFooter = styled.div`
  visibility: hidden;
`;

export const EditProductHeading = styled.h3`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 1.44px;
  text-align: left;
  color: #000000;
  padding-bottom: 15px;
  margin-top: 10px;
`;

export const BarcodeImage = styled.img``;

export const ProductFormContainer = styled.div`
  @media (min-width: 992px) and (max-width: 1400px) {
    margin-left: 10px;
  }
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

export const AddIcon = styled(StyledFontAwesomeIcon)``;