import { configureStore } from '@reduxjs/toolkit';
import { authApi } from '../api/authApi';
import { profileApi } from '../api/profileApi';
import { productApi } from '../api/productApi';
import { contactApi } from 'api/contactsApi';
import { categoriesApi } from '../api/categoriesApi';
import { outboxApi } from 'api/outboxApi';
import { externalSourceApi } from 'api/externalSourceApi';
import authReducer from '../slices/authSlice';
import profileReducer from '../slices/profileSlice';
import productsReducer from '../slices/productsSlice';
import categoriesReducer from '../slices/categoriesSlice';
import externalSourceApiReducer from 'slices/externalSourceApiSlice';
import editorReducer from 'slices/editorSlice';
import alertMessageReducer from 'slices/alertMessageSlice';
import { rtkQueryErrorLogger } from './rtkQueryErrorMiddleware';
import { linesheetApi } from '../api/linesheetApi';
import { undoRedoMiddleware } from './undoRedoMiddleware';

const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [outboxApi.reducerPath]: outboxApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [externalSourceApi.reducerPath]: externalSourceApi.reducer,
    [linesheetApi.reducerPath]: linesheetApi.reducer,
    auth: authReducer,
    profile: profileReducer,
    products: productsReducer,
    categories: categoriesReducer,
    externalSources: externalSourceApiReducer,
    editor: editorReducer,
    alertMessage: alertMessageReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      profileApi.middleware,
      productApi.middleware,
      contactApi.middleware,
      outboxApi.middleware,
      categoriesApi.middleware,
      externalSourceApi.middleware,
      linesheetApi.middleware,
      rtkQueryErrorLogger,
      undoRedoMiddleware,
    ),
});

export default store;
