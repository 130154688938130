import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { useSaveAsNewMutation } from 'api/linesheetApi';
import { message } from 'antd';
import Loader from 'components/Common/Loader';
import {
  StyledCancelButton,
  StyledLineSheetsButton,
  Wrapper,
  AntdModal,
  TitleWrapper,
} from '../BackModal/styles';
import { BodyWrapper, StyledInput } from './styles';

const NewNameModal = ({ showNewNameModal, setShowNewNameModal }) => {
  const navigate = useNavigate();
  const { id: linesheetId, settings, pages } = useSelector((state) => state.editor.linesheetData);
  const [name, setName] = useState('');
  const [updatedPages, setUpdatedPages] = useState([]);
  const [saveAsNew, { isLoading }] = useSaveAsNewMutation();

  useEffect(() => {
    if (pages.length > 0) {
      const updatedPages = pages.map((page) => {
        const newPageId = generateId();

        // Update content IDs
        const updatedContent = page.content?.map((content) => {
          return {
            ...content,
            unique_content_id: generateId(),
          };
        });

        // Return updated page with new ID and updated content
        return {
          ...page,
          unique_page_id: newPageId,
          content: updatedContent,
        };
      });

      setUpdatedPages(updatedPages); // Call setUpdatedPages here
    }
  }, [pages]);

  const generateId = () => {
    return uuidv4();
  };

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const handleSaveAsNew = async () => {
    const payload = { name, settings, pages: updatedPages };
    if (pages.length <= 0) return message.error('Please add some pages and content');

    try {
      await saveAsNew({ linesheetId: linesheetId, payload }).unwrap();
      message.success(`Success added a new linesheet with name ${name}`);
      navigate('/linesheets');
    } catch (error) {
      message.error(error.data?.message || 'Failed to save new linesheet');
    }
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      {!isLoading && (
        <AntdModal
          open={showNewNameModal}
          closable={false}
          centered={true}
          onClose={() => setShowNewNameModal(false)}
          title={<TitleWrapper>New Name for Linesheet</TitleWrapper>}
          footer={
            <Wrapper>
              <StyledCancelButton onClick={() => setShowNewNameModal(false)}>
                Cancel
              </StyledCancelButton>
              <StyledLineSheetsButton onClick={() => handleSaveAsNew()}>Ok</StyledLineSheetsButton>
            </Wrapper>
          }
          maxWidth="575"
        >
          <BodyWrapper>
            <StyledInput placeholder="Enter new name" value={name} onChange={handleChange} />
          </BodyWrapper>
        </AntdModal>
      )}
    </>
  );
};

export default React.memo(NewNameModal);
