import styled from 'styled-components';
import { Button, Form, Row } from 'antd';
import { AntInput } from 'uiKits/Input';

export const ModalHeader = styled.div`
  border: 0;
  font-family: Muli;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 1.44px;
  text-align: left;
  color: #000;
  padding-bottom: 20px;
`;

export const StyledForm = styled(Form)`
  .ant-form-item-label > label {
    font-family: Muli;
    line-height: 20px;
    height: auto;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.28px;
    color: #000;
  }
  .ant-form-item {
    margin-bottom: 0.4166666666666667vw;
  }
`;

export const StyledRow = styled(Row)`
  margin-bottom: 20px;
`;

export const StyledButton = styled(Button)`
  width: 337px;
  height: 50px;
  border-radius: 38px;
  border: 2px solid #02b183;
  background-color: #02b183;
  font-family: Muli;
  font-size: 24px;
  font-weight: 700;
  line-height: 0.96;
  letter-spacing: 1.44px;
  color: #fff;
  &:hover {
    background-color: #02b183 !important;
    border-color: #02b183 !important;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledInput = styled(AntInput)`
  padding: 12px;
`;

export const StyledStripeInput = styled.div`
  border: ${(props) => (props.error ? '1px solid #ff4d4f' : '2px solid #d7d7d7')};
  border-radius: 10px;
  padding: 15px 10px;
`;

export const StyledError = styled.div`
  color: #ff4d4f !important;
  text-align: ${(props) => (props?.align ? props.align : 'left')};
`;
