import styled from 'styled-components';
import { Col, Row, Tooltip, Radio } from 'antd';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const MainContainer = styled.div``;

export const PageHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTitle = styled.h4`
  font-family: Muli;
  font-size: 18px;
  line-height: 2.33;
  color: #000;
`;

export const BackButton = styled.button`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 16px;
  background: none;
  border-color: unset;
  box-shadow: none;
  border: 0;
  color: #6c757d;
  border-radius: 10000px;
  font-weight: 600;
  padding: 0.3125vw 0.8333333333333334vw;
  &:hover {
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
    border-color: #017f5e !important;
  }
`;

export const StyledRow = styled(Row)``;

export const StyledCol = styled(Col)``;

export const StyledTooltip = styled(Tooltip)``;

export const StyledCSVLink = styled(CSVLink)`
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.96px;
  padding: 15px 30px;
  border-radius: 10000px;
  border-width: 2px;
  color: #02b183;
  border-color: #02b183;
  width: 100%;
  max-width: 300px;
  margin-top: 1rem;
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-weight: 600;
  background: none;
  margin-bottom: 10px;
  &:hover {
    color: #fff !important;
    background-color: #02b183 !important;
    border-color: #02b183 !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(2, 177, 131, 0.5) !important;
  }
  &.disable-sample-csv {
    pointer-events: none;
    cursor: default;
    opacity: 0.3;
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  svg {
    font-size: 20px;
  }
`;

export const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  label {
    display: block;
    height: auto;
    margin-bottom: 5px;
    border-radius: 20px !important;
    padding: 0 10px;
    min-width: 200px;
    max-width: 300px;
    border: 2px solid #d7d7d7;
    flex-basis: 90%;
    &.ant-radio-button-wrapper-checked {
      border-color: #02b183 !important;
    }
  }
  &:not(.ant-radio-button-wrapper-disabled),
  &:not(.ant-radio-button-wrapper-disabled):active {
    border-color: #02b183 !important;
  }
  @media all and (max-width: 1200px) {
    margin-bottom: 10px;
  }
`;

export const StyledRadioButton = styled(Radio.Button)`
  &::before {
    content: '' !important;
    height: 0 !important;
    width: 0 !important;
  }
  span {
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledImage = styled.img`
  cursor: pointer;
  height: 23px;
`;

export const StyleLogoCon = styled.div`
  text-align: center;
`;

export const StyledLogoImage = styled.img`
  cursor: pointer;
  width: 124px;
  height: 129px;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;
