import styled, { keyframes } from 'styled-components';
import BackgroundImage from 'assets/images/signin-bg-croped.png';
import SmallBackground from 'assets/images/loginbackground.jpg';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const LogoutContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${BackgroundImage}) !important;
  background-size: auto;
  background-position: right bottom;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;

  @media screen and (min-width: 751px) {
    background-image: url(${SmallBackground});
  }

  @media screen and (min-width: 1501px) {
    background-image: url(${SmallBackground});
  }
`;

export const StyledInnerBody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  outline: 0;
  animation: ${fadeIn} 750ms ease-in-out both;
  height: fit-content;
  width: 50%;
`;

export const Header = styled.h2`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-align: center;
  margin: 0;
`;

export const Message = styled.div`
  font-family: Muli, Helvetica, Arial, sans-serif;
  text-align: center;
`;

export const Divider = styled.hr`
  width: 25%;
  border: 0;
  border-top: 1px solid #ddd;
  margin: 1rem 0;
`;

export const SpinnerContainer = styled.div`
  margin-top: 1rem;
  text-align: center;
  color: #02b183;
`;

const spinnerAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  display: inline-block;
  width: 3rem;
  height: 3rem;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: ${spinnerAnimation} 0.75s linear infinite;
`;

export const SrOnly = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;
