import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import LinesheetEditor from 'components/LinesheetEditor';
import { useGetLinesheetByIdQuery } from 'api/linesheetApi';
import { setLinesheet } from 'slices/editorSlice';
import Loader from 'components/Common/Loader';

const LinesheetEditorPage = () => {
  const dispatch = useDispatch();
  const { linesheetId } = useParams();
  const { data, isLoading } = useGetLinesheetByIdQuery(linesheetId, {
    skip: !linesheetId,
    refetchOnMountOrArgChange: true
  });

  useEffect(() => {
    if (data) {
      dispatch(setLinesheet(data));
    }
  }, [data, dispatch]);

  return isLoading ? <Loader isLoading={isLoading} /> : <LinesheetEditor />;
};

export default LinesheetEditorPage;
