import styled from 'styled-components';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const MainContainer = styled.div`
  padding-top: 36px;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.05);
`;

export const StyledHeader = styled.div`
  display: flex;
  background: #000;
  justify-content: space-between;
  align-items: center;
  min-height: 36px;
  gap: 10px;
`;

export const StyledTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  color: #ffffff;
  margin-left: 10px;
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 0.5;
  letter-spacing: normal;
  cursor: default;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: ${(props) => (props.cursor ? 'default' : 'pointer')};
`;

export const ActionButton = styled.div`
  display: flex;
  gap: 4px;
  margin-right: 10px;
`;

export const StyledButton = styled.button`
  border-radius: 18px;
  border: 0;
  background: #fff;
  width: 40px;
  height: 25px;
  color: #000;
  cursor: pointer;
`;

export const StyledCollapsedButton = styled.button`
  background: none;
  border: none;
  color: #ffffff;
`;

export const StyledTooltip = styled(Tooltip)``;

export const StyledBody = styled.div`
  height: ${(props) => props.height};
  background-color: #fff;
  overflow: hidden;
  transition: height 250ms ease-in-out;
`;

export const StyledEditorToolBody = styled.div`
  height: calc(100% - 15px);
  padding: 8px 13px;
`;
