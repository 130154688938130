import styled from 'styled-components';
import { Modal } from 'antd';
import AntButton from 'uiKits/Button';

export const StyledCancelButton = styled(AntButton)`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 16px;
  background: none;
  box-shadow: none;
  border: 0;
  color: #6c757d;
  border-radius: 10000px;
  font-weight: 600;
  &:hover {
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
    border-color: #017f5e !important;
  }
`;

export const StyledLineSheetsButton = styled.button`
  font-family: Muli;
  border-radius: 17.5px;
  border: 2px solid #07af7c;
  background-color: #07af7c;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  width: 120px;
  border-radius: 10000px;
  &:hover {
    background-color: #028b67;
    border: 2px solid #017f5e;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const AntdModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
    padding: 0;
    width: 570px;
  }

  .ant-modal-body {
    // padding: 32px 40px;
  }

  .ant-modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 25px;
    border-bottom-right-radius: 11px;
    border-bottom-left-radius: 11px;
    background-color: #f0f0f0; /* Grey background color */
  }
`;

export const TitleWrapper = styled.p`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 1.44px;
  margin: 0;
  padding: 30px;
`;
