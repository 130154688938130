import React from 'react';
import {
  FooterWrapper,
  StyledCancelButton,
  StyledModal,
  StyledDivider,
  HeadingText,
  SubjectText,
  MessageText,
  DateText,
} from './styles';

const EmailDetailsModal = ({ isOpen, setShowDetailsModal, selectedDetails }) => {
  const { email, subject, notes, time } = selectedDetails;
  return (
    <StyledModal
      open={isOpen}
      width={850}
      centered
      footer={
        <FooterWrapper>
          <StyledCancelButton onClick={() => setShowDetailsModal(false)} type="text">
            Cancel
          </StyledCancelButton>
        </FooterWrapper>
      }
    >
      <HeadingText>{email}</HeadingText>
      <StyledDivider />
      <SubjectText>
        Subject: <span>{subject}</span>
      </SubjectText>
      <MessageText>
        Message: <span>{notes}</span>
      </MessageText>
      <DateText>{time}</DateText>
    </StyledModal>
  );
};

export default EmailDetailsModal;
