import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setHeaderFooterPosition } from 'slices/editorSlice';
import FontSettings from 'components/LinesheetEditor/RightPanel/FontSettings';
import { StyledModal } from './styles';

const Text = ({ data, pageWidth, isHeader }) => {
  const dispatch = useDispatch();
  const textDivRef = useRef(null);
  const [left, setLeft] = useState(data.settings?.left || 0);
  const [right, setRight] = useState(data.settings?.right || 0);
  const [top, setTop] = useState(data.settings?.top || 0);
  const [descriptionSelected, setDescriptionSelected] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (data && data.settings) {
      setRight(data.settings.right);
      setTop(data.settings.top);
      setLeft(data.settings.left);
    }
  }, [data]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (textDivRef.current && !textDivRef.current.contains(event.target)) {
        setDescriptionSelected(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const clickHandler = (e) => {
    e.preventDefault();
    if (e.target.closest('.ant-modal-wrap')) {
      setShowSettings(false);
      return;
    }

    const { clientY } = e;
    const contentRect = e.target.getBoundingClientRect();
    const isBottomHalf = clientY > window.innerHeight / 2;
    const left = contentRect.right + 10;
    const transform = isBottomHalf ? 'translateY(-100%)' : 'translateY(0)';
    const top = isBottomHalf ? contentRect.bottom : contentRect.top;
    setDescriptionSelected(true);
    setMenuPosition({ y: top, x: left, transform });
    setShowSettings(true);
  };

  const onDragStart = (e) => {
    e.preventDefault();
    setDescriptionSelected(true);
    const { clientHeight: height, clientWidth: width } = e.target;
    const startX = e.clientX;
    const startY = e.clientY;
    const startTop = top;
    const startLeft = left;
    let newTop = top;
    let newLeft = left;

    const onMouseMove = (moveEvent) => {
      const deltaX = moveEvent.clientX - startX;
      const deltaY = moveEvent.clientY - startY;

      newTop = startTop + deltaY;
      newLeft = startLeft + deltaX;

      const maxTop = 40 - height;
      const maxLeft = pageWidth - width;

      if (newTop < 0) newTop = 0;
      if (newTop > maxTop) newTop = maxTop;
      if (newLeft < 0) newLeft = 0;
      if (newLeft > maxLeft) newLeft = maxLeft;

      setTop(newTop);
      setLeft(newLeft);
      dispatch(setHeaderFooterPosition({ left: newLeft, top: newTop, isHeader, isImage: false }));
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const { font, size, color, bold, italic, underline, align } = data.settings;
  const style = {
    top: `${top}px`,
    left: left === 'unset' ? left : `${left}px`,
    right: right === 'unset' ? right : `${right}px`,
    overflow: 'hidden',
    fontFamily: font || 'Arial',
    fontSize: size || '10px',
    textDecoration: underline ? 'underline' : '',
    color: color || '#000',
    fontWeight: bold ? 'bold' : '',
    fontStyle: italic ? 'italic' : 'normal',
    textAlign: align || 'left',
    border: `${descriptionSelected ? '1px solid #02b183' : 'unset'}`,
    margin: 0,
  };

  return (
    <>
      <p ref={textDivRef} style={style} onMouseDown={onDragStart} onDoubleClick={clickHandler}>
        {data.value}
      </p>
      {showSettings && (
        <StyledModal
          open={showSettings}
          onCancel={() => setShowSettings(false)}
          footer={null}
          closable={false}
          mask={false}
          style={{
            top: menuPosition.y,
            left: menuPosition.x,
            transform: menuPosition.transform,
            position: 'absolute',
            padding: 0,
            margin: 0,
          }}
          width={200}
        >
          <FontSettings
            isGlobal={false}
            fontSettings={{
              font: data.settings.font,
              size: data.settings.size,
              color: data.settings.color,
            }}
            isHeader={isHeader}
            isFooter={!isHeader}
          />
        </StyledModal>
      )}
    </>
  );
};

export default Text;
