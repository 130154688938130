import styled from 'styled-components';
import { Row, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledPreviewContainter = styled(Row)`
  position: fixed;
  width: 100%;
  padding-top: 30px;
`;

export const StyledHeader = styled.div`
  background-color: #fff;
  text-align: center;
  padding: 10px 0 8px;
  width: 100%;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

export const StyledButton = styled.button`
  border-color: #343a40;
  border-radius: 18px;
  background: #ffffff;
  width: 32px;
  height: 32px;
  margin: 3px;
  &:hover {
    background-color: #343a40;
    border-color: #343a40;
    color: #ffffff;
  }
`;

export const StyledTooltip = styled(Tooltip)``;
