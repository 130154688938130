import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import ProductListTable from '../../Product/ProductListing/components/ProductListTable';
import SearchBar from 'components/Product/ProductListing/components/SearchBar';
import Loader from 'components/Common/Loader';
import { useCreateLinesheetMutation } from 'api/linesheetApi';
import LinesheetsName from '../LinesheetName';

import {
  useDuplicateProductsMutation,
  useGetProductsQuery,
  useDeleteProductMutation,
} from 'api/productApi';
import {
  MainLayout,
  HeaderWraper,
  ButtonWrapper,
  StyledCancelButton,
  StyledNextButton,
  SearchWrapper,
  SytledTitle,
} from './styles';

const NewLinesheet = () => {
  const navigate = useNavigate();
  const [searchFilter, setSearchFilter] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [isSelectAll, setSelectAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [selectedName, setSelectedName] = useState('');
  const [createLinesheet, { isLoading: linesheetIsLoading }] = useCreateLinesheetMutation();

  // Get Products Query
  const {
    data: productsData,
    isLoading: productsLoading,
    refetch: refetchProducts,
  } = useGetProductsQuery({
    searchFilter,
    sortBy,
    sortOrder,
    currentPage,
    pageSize,
    categoryFilter,
  });

  const resetSelectedRows = () => {
    setSelectedRows([]);
    setSelectAll(false);
    setIsModalOpen(false);
    setIsDuplicate(false);
  };

  const handleSelectAll = () => {
    setSelectAll(true);
    const ids = Object.values(productsData.products).map((product) => product.id);
    setSelectedRows(ids);
  };

  const handleUnselectAll = () => {
    setSelectAll(false);
    setSelectedRows([]);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    if (newSelectedRowKeys.length === 0) {
      resetSelectedRows();
      return;
    }
    if (newSelectedRowKeys.length < pageSize) {
      setSelectAll(false);
    }
    setSelectedRows(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRows,
    preserveSelectedRowKeys: false,
    onChange: onSelectChange,
  };
  // Delete Product Mutation
  const [deleteProduct] = useDeleteProductMutation();

  // Duplicate Products Mutation
  const [duplicateProducts] = useDuplicateProductsMutation();

  // Delete Products
  const handleDeleteProducts = async () => {
    if (selectedRows && selectedRows.length === 0) {
      message.error('Please select some products to delete');
      return;
    }

    const res = await deleteProduct({ selectedProducts: selectedRows, isSelectAll: isSelectAll });
    if (res.data) {
      resetSelectedRows();
    }
  };

  // Handle Duplicate Products
  const handleDuplicateProducts = (productIds) => {
    if (productIds && productIds.length === 0) {
      message.error('Please select some products to duplicate');
      return;
    }
    if (isSelectAll && productsData?.total > 50) {
      setIsModalOpen(true);
      setIsDuplicate(true);
      return;
    }
    duplicateProductsApi(productIds);
  };

  const duplicateProductsApi = async (productIds) => {
    setIsModalOpen(false);
    setIsDuplicate(false);

    const res = await duplicateProducts({
      selectedProducts: productIds,
      isSelectAll: isSelectAll,
      totalProducts: productsData?.total,
    });
    if (res.data) {
      resetSelectedRows();
    }
  };
  const handleSortChange = (column, order) => {
    setSortBy(column);
    setSortOrder(order);
  };

  const handleCreate = async () => {
    setIsNext(true);
    // create payload to create linesheet
    let payload = { name: selectedName, productIds: selectedRows };
    const res = await createLinesheet(payload);
    if (!res?.data?.id) {
      message.error(res?.error?.data?.message);
      return;
    }
    navigate(`/linesheet-editor/${res?.data?.id}`);
  };

  const nextClickHandler = () => {
    setIsNext(true);
  };
  const handleSearchChange = (e) => {
    setSearchFilter(e.target.value);
  };

  const closeClickHandler = () => {
    navigate('/linesheets');
  };

  const backClickHandler = () => {
    setIsNext(false);
  };

  const nameChangeHandler = (event) => {
    setSelectedName(event.target.value);
  };
  return (
    <MainLayout className="container">
      {isNext ? (
        <LinesheetsName
          backClickHandler={backClickHandler}
          handleCreate={handleCreate}
          nameChangeHandler={nameChangeHandler}
          selectedName={selectedName}
        />
      ) : (
        <>
          <HeaderWraper>
            <SytledTitle>Select Products</SytledTitle>
            <ButtonWrapper>
              <StyledCancelButton onClick={closeClickHandler}>Cancel</StyledCancelButton>
              <StyledNextButton
                onClick={nextClickHandler}
                disabled={selectedRows && selectedRows.length > 0 ? false : true}
              >
                Next
              </StyledNextButton>
            </ButtonWrapper>
          </HeaderWraper>
          <SearchWrapper>
            <SearchBar
              placeholder="Search"
              handleSearchChange={handleSearchChange}
              searchFilter={searchFilter}
            />
          </SearchWrapper>
          <ProductListTable
            products={productsData?.products}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            handleDuplicateProducts={handleDuplicateProducts}
            deleteProduct={deleteProduct}
            refetchProducts={refetchProducts}
            handleSortChange={handleSortChange}
            rowSelection={rowSelection}
            preserveSelectedRowKeys={false}
            totalProducts={productsData?.total}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            searchFilter={searchFilter}
            sortBy={sortBy}
            sortOrder={sortOrder}
            pageSize={pageSize}
            setPageSize={setPageSize}
            isSelectAll={isSelectAll}
            handleSelectAll={handleSelectAll}
            resetSelectedRows={resetSelectedRows}
            handleUnselectAll={handleUnselectAll}
          />
        </>
      )}
      <Loader isLoading={productsLoading || linesheetIsLoading} />
    </MainLayout>
  );
};

export default NewLinesheet;
