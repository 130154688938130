import styled from 'styled-components';
import { Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledContainer = styled.div`
  padding: 48px 32px;
`;

export const StyledRow = styled(Row)``;

export const StyledCol = styled(Col)``;

export const StyledApiCol = styled(Col)`
  padding: 0 15px;
`;

export const StyledApiContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 160px;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding: 24px 0;
  border-radius: 10px;
  border: 2px solid transparent;
  text-align: center;
  margin-bottom: 1rem !important;
  border-color: #d4d4d4;
  &.connected {
    background-color: #fff;
    border-color: #02b183;
  }
`;

export const PageHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTitle = styled.h4`
  font-family: Muli;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.03;
  letter-spacing: .6px;
  margin-bottom: 20px;
}
`;

export const CancelButton = styled.button`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 16px;
  background: none;
  border-color: unset;
  box-shadow: none;
  border: 0;
  color: #6c757d;
  border-radius: 10000px;
  font-weight: 600;
  padding: 0.3125vw 0.8333333333333334vw;
  &:hover {
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
    border-color: #017f5e !important;
  }
`;

export const Subtitle = styled.p`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.07;
  letter-spacing: 0.28px;
  margin-bottom: 3rem;
`;

export const StyledHeading = styled.h4`
  font-family: Muli, Helvetica, Arial, sans-serif;
  margin: 1rem 0;
`;

export const StyledButton = styled.button`
  font-family: Muli, Helvetica, Arial, sans-serif;
  border: none;
  cursor: pointer;
  border-radius: 10000px;
  padding: 0.3125vw 0.8333333333333334vw;
  font-weight: 600;
  color: #02b183;
  &:hover {
    color: #fff;
    background-color: #02b183;
    border-color: #02b183;
  }
`;

export const StyledImportButton = styled(StyledButton)`
  color: #fff;
  background-color: #02b183;
  border-color: #02b183;
`;

export const StyledConnectButton = styled(StyledButton)`
  background: #fff;
  border: 2px solid #02b183;
`;

export const StyledCheckCircle = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 5px;
`;

export const StyledLogo = styled.img`
  width: ${(props) => `${props.width}%`};
  margin: 15px auto;
`;

export const StyledApiTitle = styled.div`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 18.5px;
  font-weight: 900;
  line-height: 0.95;
  letter-spacing: 0.37px;
  margin-bottom: 10px;
`;

export const ConnectedContainer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  color: #02b183;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.92;
  display: flex;
  gap: 4px;
  align-items: center;
`;
