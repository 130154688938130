import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_BASE_URL;

export const handle401Error = async (api, action, { getState }) => {
  console.log('handle401Error called!');
  // Rest of your middleware logic
};

export const contactApi = createApi({
  reducerPath: 'contactApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    credentials: 'include',
  }),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(handle401Error),
  endpoints: (builder) => ({
    createContact: builder.mutation({
      query: (contactData) => ({
        url: '/contacts/create',
        method: 'POST',
        body: contactData,
        formData: true,
      }),
      invalidatesTags: ['Contacts'],
    }),
    getContacts: builder.query({
      query: ({ searchFilter, sortBy, sortOrder, currentPage, pageSize, fetchAll }) => {
        let url = `/contacts?fetchAll=${fetchAll}`;
        if (pageSize) {
          url += `&limit=${pageSize}`;
        }
        if (searchFilter) {
          url += `&searchFilter=${encodeURIComponent(searchFilter)}`;
        }
        if (sortBy && sortOrder) {
          url += `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }
        if (currentPage) {
          url += `&page=${currentPage}`;
        }
        return { url };
      },
      providesTags: ['Contacts'],
    }),
    deleteContact: builder.mutation({
      query: (deleteContactData) => ({
        url: '/contacts',
        method: 'DELETE',
        body: deleteContactData,
      }),
      invalidatesTags: ['Contacts'],
    }),
    duplicateContacts: builder.mutation({
      query: (duplicateContactData) => ({
        url: '/contacts/duplicate',
        method: 'POST',
        body: duplicateContactData,
      }),
      invalidatesTags: ['Contacts'],
    }),
    updateContact: builder.mutation({
      query: ({ contactId, contactData }) => ({
        url: `/contacts/update/${contactId}`,
        method: 'PUT',
        body: contactData,
        formData: true,
      }),
      invalidatesTags: ['Contacts'],
    }),
    fetchGoogleContact: builder.mutation({
      query: (fetchGoogleData) => ({
        url: `/contacts/googleContacts`,
        method: 'POST',
        body: fetchGoogleData,
      }),
      invalidatesTags: ['Contacts'],
    }),
  }),
});

export const {
  useCreateContactMutation,
  useGetContactsQuery,
  useDeleteContactMutation,
  useDuplicateContactsMutation,
  useUpdateContactMutation,
  useFetchGoogleContactMutation,
} = contactApi;
