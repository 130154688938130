import styled from 'styled-components';
import { Row, Col } from 'antd';
import AntButton from 'uiKits/Button';

export const MainLayout = styled.div`
  width: 100%;
  .ant-row {
    gap: 10px;
  }
`;

export const StyledRow = styled(Row)`
  justify-content: space-between !important;
`;

export const StyledCol = styled(Col)`
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
`;

export const ProductHeading = styled.div`
  display: inline-flex;
  align-items: center;
  .ant-space-item {
    cursor: pointer;
  }
`;

export const SytledTitle = styled.h4`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin: 0px;
  cursor: default;
`;

export const RightContainer = styled(Col)`
  display: flex;
  align-content: center;
  gap: 10px;
`;

export const StyledLineSheetsButton = styled(AntButton)`
  font-family: Muli;
  font-weight: 450;
  border-radius: 17.5px;
  border: 2px solid #07af7c;
  background-color: #f7f7f7;
  color: #07af7c;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.disabled ? '#ffffff' : '#07af7c')};
    color: ${(props) => (props.disabled ? 'inherit' : '#ffffff')};
  }

  &:disabled {
    background-color: #f5f5f5; /* Optional: Set a different background for disabled state */
    color: #02b183; /* Optional: Set a different color for disabled state */
    cursor: not-allowed;
    opacity: 0.35;
  }
`;
