import { message } from 'antd';
import { setUser } from '../slices/authSlice';

// Helper function for handling successful login
export const handleSuccessfulLogin = async (
  codeResponse,
  dispatch,
  cache,
  navigate,
  loginWithGoogle,
) => {
  try {
    const res = await loginWithGoogle({ token: codeResponse.access_token });
    if (res.data) {
      dispatch(setUser(res.data));
      cache.setItem('user', res.data);
      navigate('/');
    } else {
      showError(res.error, res.error.data.message);
    }
  } catch (error) {
    // Handle login error, if any
    showError(error, 'Failed to login with google. Please try again.');
  }
};

// Helper function for handling login error
export const handleLoginError = error => {
  showError(error, 'Failed to login with google. Please try again.');
};

// Helper function to show error message
export const showError = (error, err_message) => {
  console.error('Login error:', error);
  message.error(err_message);
};
