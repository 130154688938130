import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import SideBar from 'components/SideBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/images/LinesheetsLogo.png';
import {
  StyledLayout,
  StyledHeader,
  StyledSider,
  StyledContent,
  BarIconWrapper,
  LogoWrapper,
  HeaderContainer,
  StyledHeading,
  StyledImage,
  StyledDropdown,
  DropdownWrapper,
  StyledSpace,
} from './styles';
import cache from 'cache';

const PrivateLayout = ({ children }) => {
  const { pathname } = useLocation();
  const { firstName, lastName, email } = JSON.parse(cache.getItem('user'));
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState('1');
  const [title, setTitle] = useState('Dashboard');

  const navigate = useNavigate();
  const items = [
    {
      label: (
        <div>
          <div className="text-truncate">{firstName + ' ' + lastName}</div>
          <div className="text-truncate">{email}</div>
        </div>
      ),
      key: '0',
      type: 'button',
      className: 'wrapper-header-user-menu-dropdown-top',
    },
    {
      label: 'My Profile',
      key: '1',
      onClick: () => navigate('/profile'),
    },
    {
      label: 'Plans & Pricing',
      key: '2',
      onClick: () =>
        navigate('/profile', {
          state: { key: 'subscription' },
          replace: true,
        }),
    },
    {
      label: 'Log Out',
      key: '3',
      onClick: () => navigate('/logout'),
    },
  ];

  const handleResize = () => {
    if (window.innerWidth < 1200) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  };

  useEffect(() => {
    handleResize(); // Check the initial window size
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    switch (pathname) {
      case '/':
        setTitle('Dashboard');
        setSelectedKey('1');
        break;
      case '/products':
        setTitle('Products');
        setSelectedKey('2');
        break;
      case '/linesheets':
        setTitle('Linesheets');
        setSelectedKey('3');
        break;

      case '/linesheets/new':
        setTitle('New Linesheet');
        setSelectedKey('3');
        break;
      case '/categories':
        setTitle('Categories');
        setSelectedKey('10');
        break;
      case '/products/new':
        setTitle('New Products');
        setSelectedKey('2');
        break;
      case '/contacts':
        setTitle('Contacts');
        setSelectedKey('4');
        break;
      case '/email-outbox':
        setTitle('Emails');
        setSelectedKey('5');
        break;
      case '/sync':
        setTitle('Sync');
        setSelectedKey('6');
        break;
      case '/profile':
        setTitle('My Profile');
        setSelectedKey('7');
        break;
      case '/help':
        setTitle('Help');
        setSelectedKey('8');
        break;
      default:
        break;
    }
  }, [pathname]);
  return (
    <StyledLayout>
      <StyledHeader>
        <HeaderContainer>
          <LogoWrapper>
            <BarIconWrapper>
              <FontAwesomeIcon onClick={() => setCollapsed(!collapsed)} icon={faBars} />
            </BarIconWrapper>
            <Link to="/">
              <StyledImage src={logo} />
            </Link>
          </LogoWrapper>
          <StyledHeading>{title}</StyledHeading>
          <DropdownWrapper>
            <StyledDropdown
              menu={{
                items,
              }}
              trigger={['click']}
            >
              <StyledSpace>
                {firstName}
                <DownOutlined />
              </StyledSpace>
            </StyledDropdown>
          </DropdownWrapper>
        </HeaderContainer>
      </StyledHeader>
      <StyledLayout>
        <StyledSider collapsed={collapsed}>
          <SideBar selectedKey={selectedKey} setSelectedKey={setSelectedKey} />
        </StyledSider>
        <StyledContent collapsed={collapsed}>{children}</StyledContent>
      </StyledLayout>
    </StyledLayout>
  );
};
export default PrivateLayout;
