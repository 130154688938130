import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Tooltip, Slider, InputNumber, Switch } from 'antd';

export const ProductEditorToolsContainer = styled.div`
  padding: 16px;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

export const StyledRow = styled(Row)`
  align-items: center;
  color: #fff !important;
  margin-bottom: ${(props) => (props.nomargin === 'true' ? '0' : '14px')};
  text-align: ${(props) => (props.textalign ? props.textalign : 'left')};
  gap: ${(props) => (props.gap ? `${props.gap}px` : '0')};
`;

export const StyledCol = styled(Col)``;

export const StyledGroupTitle = styled.p`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #ccc;
  margin: ${(props) => (props.notopmargin ? '0 0 0.5rem' : '0.5rem 0')};
`;

export const StyledLabel = styled.p`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.08;
  text-align: ${(props) => (props.textalign ? props.textalign : 'center')};
  color: #fff;
  margin: 0 !important;
`;

export const StyledTooltip = styled(Tooltip)``;

export const StyleSlider = styled(Slider)`
  width: 150px;
  .ant-slider-track,
  .ant-slider-step {
    background: #d3d3d3 !important;
    height: 1px !important;
  }
  .ant-slider-handle {
    inset-block-start: 0px !important;
    border-radius: 100% !important;
    &:focus,
    &::after {
      background-color: #cccccc;
      box-shadow: unset !important;
    }
    &:hover {
      &::after {
        background-color: rgb(2, 177, 131);
        box-shadow: unset !important;
      }
    }
  }
`;

export const StyledInputNumber = styled(InputNumber)`
  height: fit-content;
  width: 65px;
  border-radius: 3px;
  border: 2px solid #6b757c !important;
  background-color: #242424 !important;
  .ant-input-number-handler-wrap {
    border-radius: 3px;
    border: 2px solid #6b757c !important;
    background-color: #242424 !important;
    svg {
      color: #ffffff;
    }
  }
  .ant-input-number-input-wrap {
    color: #ffffff;
  }
`;

export const StyledAddButton = styled.button`
  border: 2px solid #fff;
  background-color: #242424;
  font-weight: 600;
  padding: 0 8px;
  line-height: 30px;
  border-radius: 30px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
`;

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48%;
  height: 34px;
  border-radius: 3px;
  border: solid 2px #ffffff !important;
  background-color: #242424 !important;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  padding: 0 8px;
  line-height: 1.08;
  color: #ffffff !important;
  cursor: pointer;
`;

export const StyledSwitch = styled(Switch)`
  width: 20%;
  .ant-switch-handle {
    &::before {
      background-color: #adb5bd;
    }
  }
  .ant-switch-inner {
    background-color: #fff;
  }
  &.ant-switch-checked {
    .ant-switch-handle {
      &::before {
        background-color: #fff; !important;
      }
    }
    .ant-switch-inner {
      background-color: #02b183 !important;
    }
  }
`;

export const StyledImage = styled.img`
  cursor: pointer;
`;
