import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/Common/Loader';
import GlobalEditorTools from '../RightPanel/GlobalEditorTools';
import {
  setIsEditorLoading,
  applyGlobalSettings,
  setInGlobalSettingsMode,
} from 'slices/editorSlice';
import { setAlert } from 'slices/alertMessageSlice';
import {
  AntModal,
  MainContainer,
  ProductListingContainer,
  StyledRow,
  StyledCol,
  ProductBox,
  ProductImageContainer,
  ProductImage,
  ButtonsWrapper,
  StyledTitle,
  BackButton,
  SaveButton,
  ProductsContainer,
  SettingsContainer,
} from './styles';
import { message } from 'antd';

const GlobalSettingsPanel = () => {
  const dispatch = useDispatch();
  const { pages } = useSelector((state) => state.editor.linesheetData);
  const inGlobalSettingsMode = useSelector((state) => state.editor.inGlobalSettingsMode);
  const isEditorLoading = useSelector((state) => state.editor.isEditorLoading);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const settings = {
    value: '',
    font: null,
    size: null,
    color: '#000',
    bold: false,
    italic: false,
    underline: false,
    align: 'center',
    show: 'none',
  };
  const [stylingState, setStylingState] = useState({
    titleStyling: settings,
    skuStyling: settings,
    descriptionStyling: settings,
    wholesalePriceStyling: settings,
    retailPriceStyling: settings,
  });
  const [showSettings, setShowSettings] = useState({
    title: false,
    sku: false,
    description: false,
    wholesalePrice: false,
    retailPrice: false,
  });

  useEffect(() => {
    if (inGlobalSettingsMode) {
      dispatch(setIsEditorLoading({ isLoading: true }));
      const productsData = {};

      pages.forEach((page, pageIndex) => {
        page.content
          .filter((content) => content.product_id !== '' && content.product_id !== null)
          .forEach((content, contentIndex) => {
            productsData[content.unique_content_id] = {
              id: content.unique_content_id,
              pageIndex: pageIndex,
              contentIndex: contentIndex,
              product_image: content.image_content?.url,
              title: content.text_content?.title,
              description: content.text_content?.description,
              sku: content.text_content?.sku,
              wholesalePrice: content.text_content?.wholesalePrice,
              retailPrice: content.text_content?.retailPrice,
            };
          });
      });
      setProducts(Object.values(productsData));
      dispatch(setIsEditorLoading({ isLoading: false }));
      // eslint-disable-next-line
    }
  }, [inGlobalSettingsMode]);

  useEffect(() => {
    if (selectedProducts.length === 0) {
      setStylingState({
        titleStyling: settings,
        skuStyling: settings,
        descriptionStyling: settings,
        wholesalePriceStyling: settings,
        retailPriceStyling: settings,
      });
      setShowSettings({
        title: false,
        sku: false,
        description: false,
        wholesalePrice: false,
        retailPrice: false,
      });
    }
  }, [selectedProducts]);

  const isProductSelected = (productId) => {
    return selectedProducts.some((selectedProduct) => selectedProduct.id === productId);
  };

  const handleProductSelections = (product) => {
    setSelectedProducts((prevSelectedProducts) => {
      return isProductSelected(product.id)
        ? prevSelectedProducts.filter((selectedProduct) => selectedProduct.id !== product.id)
        : [...prevSelectedProducts, product];
    });
  };

  const resetStates = () => {
    setSelectedProducts([]);
    setIsSelectAll(false);
    setStylingState({
      titleStyling: settings,
      skuStyling: settings,
      descriptionStyling: settings,
      wholesalePriceStyling: settings,
      retailPriceStyling: settings,
    });
    setShowSettings({
      title: false,
      sku: false,
      description: false,
      wholesalePrice: false,
      retailPrice: false,
    });
  };

  const handleSaveButton = () => {
    dispatch(applyGlobalSettings({ products }));
    resetStates();
    dispatch(
      setAlert({
        message: 'Global settings have been implemented for the selected products.',
        type: 'success',
      }),
    );
  };

  const handleSelectAll = (val) => {
    setIsSelectAll(val);
    setSelectedProducts(val ? products : []);
  };

  const updateSelectedProducts = (category, key, value) => {
    const updatedProducts = products.map((product) => {
      if (isProductSelected(product.id)) {
        const updatedProduct = { ...product }; // Shallow clone of product

        if (key === 'value') {
          updatedProduct[category] = { ...product[category], [key]: value };
        } else {
          updatedProduct[category] = {
            ...product[category],
            settings: { ...product[category].settings, [key]: value },
          };
        }
        return updatedProduct;
      }
      return product;
    });

    setProducts(updatedProducts);
  };

  const updateProductsSettings = (category, key, value) => {
    if (selectedProducts.length === 0) {
      message.error('Please select some products first');
      return;
    }

    const type = `${category}Styling`;
    setStylingState((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        [key]: value,
      },
    }));

    updateSelectedProducts(category, key, value);
  };

  const handleSettingsVisibility = (key) => {
    if (selectedProducts.length === 0 && !showSettings[key]) {
      message.error('Please select some products first');
      return;
    }

    setShowSettings((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleClose = () => {
    resetStates();
    dispatch(setInGlobalSettingsMode(false));
  };

  const getTextStyles = (property) => {
    return {
      fontFamily: property.font || 'Arial',
      fontSize: property.size || '10px',
      textDecoration: property.underline ? 'underline' : 'none',
      color: property.color || '#000',
      fontWeight: property.bold ? 'bold' : 'normal',
      fontStyle: property.italic ? 'italic' : 'normal',
      textAlign: property.align || 'left',
    };
  };

  const showContent = (product, key) => {
    return !(product[key].settings.show === false || product[key].settings.show === 'hide');
  };

  return (
    <AntModal
      centered
      width={'90%'}
      open={inGlobalSettingsMode}
      closeIcon={false}
      footer={null}
      onCancel={handleClose}
    >
      <MainContainer>
        <ProductsContainer>
          <ButtonsWrapper>
            <SaveButton
              disabled={products.length === 0}
              onClick={() => handleSelectAll(!isSelectAll)}
            >
              {isSelectAll ? 'Unselect' : 'Select All'}
            </SaveButton>
            <StyledTitle>Quick Edit</StyledTitle>
            <div>
              <BackButton onClick={handleClose}>Discard Changes</BackButton>
              <SaveButton onClick={() => handleSaveButton()}>Save Changes</SaveButton>
            </div>
          </ButtonsWrapper>
          <ProductListingContainer>
            {products.length > 0 ? (
              <StyledRow>
                {products.map((product, index) => (
                  <StyledCol key={index} onClick={() => handleProductSelections(product)}>
                    <ProductBox
                      className={isSelectAll || isProductSelected(product.id) ? 'checked' : ''}
                    >
                      <ProductImageContainer>
                        <ProductImage
                          src={product.product_image}
                          alt={product.title.value}
                          loading="lazy"
                        />
                      </ProductImageContainer>
                      {showContent(product, 'title') && (
                        <div
                          style={getTextStyles(product.title.settings)}
                          dangerouslySetInnerHTML={{ __html: product.title.value }}
                        />
                      )}
                      {showContent(product, 'sku') && (
                        <div
                          style={getTextStyles(product.sku.settings)}
                          dangerouslySetInnerHTML={{ __html: product.sku.value }}
                        />
                      )}
                      {showContent(product, 'description') && (
                        <div
                          style={getTextStyles(product.description.settings)}
                          dangerouslySetInnerHTML={{ __html: product.description.value }}
                        />
                      )}
                      {showContent(product, 'wholesalePrice') && (
                        <div
                          style={getTextStyles(product.wholesalePrice.settings)}
                          dangerouslySetInnerHTML={{ __html: product.wholesalePrice.value }}
                        />
                      )}
                      {showContent(product, 'retailPrice') && (
                        <div
                          style={getTextStyles(product.retailPrice.settings)}
                          dangerouslySetInnerHTML={{ __html: product.retailPrice.value }}
                        />
                      )}
                    </ProductBox>
                  </StyledCol>
                ))}
              </StyledRow>
            ) : (
              <p>No Products available for Quick edits</p>
            )}
          </ProductListingContainer>
        </ProductsContainer>
        <SettingsContainer>
          <GlobalEditorTools
            stylingState={stylingState}
            updateProductsSettings={updateProductsSettings}
            showSettings={showSettings}
            handleSettingsVisibility={handleSettingsVisibility}
          />
        </SettingsContainer>
      </MainContainer>
      <Loader isLoading={isEditorLoading} />
    </AntModal>
  );
};

export default GlobalSettingsPanel;
