import styled from 'styled-components';
import { Tooltip, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cropper from 'react-cropper';

export const StyledTooltip = styled(Tooltip)``;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

export const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

export const ImageActions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 25px;
  color: #02b183;
`;

export const StyledSpin = styled(Spin)`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-spin-dot-holder {
    color: #02b183 !important;
  }
`;

export const StyledCropper = styled(Cropper)`
  .cropper-view-box,
  .cropper-line,
  .cropper-point {
    outline: 1px solid #02b183 !important;
    outline-color: #02b183 !important;
    background-color: #02b183 !important;
  }
`;
