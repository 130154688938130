import React, { useState } from 'react';
import styled from 'styled-components';
import LogoImg from 'assets/images/logo.svg';
import LinesheetEditorHelp from './LinesheetEditorHelp';
import GettingStarted from './GettingStarted';
import LandingPage from './LandingHelpPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import background from 'assets/images/bg-srch.png';

const Help = () => {
  const [state, setState] = useState('');

  const handleState = (value) => {
    setState(value);
  };

  const renderPage = () => {
    switch (state) {
      case 'editor':
        return <LinesheetEditorHelp onClick={handleState} />;
      case 'gettingStarted':
        return <GettingStarted onClick={handleState} />;
      default:
        return <LandingPage onClick={handleState} />;
    }
  };

  return (
    <HelpPage>
      <CustomContainer>
        <SearchHolder>
          <HelpFrame className="container">
            <LogoHolder>
              <Logo src={LogoImg} alt="" onClick={() => handleState('')} />
            </LogoHolder>
            <Slogan>We are here to help</Slogan>
            <SearchBox>
              <SearchInput type="text" name="email" placeholder="Search" />
              <SearchButton>
                <FontAwesomeIcon icon={faSearch} />
              </SearchButton>
            </SearchBox>
          </HelpFrame>
        </SearchHolder>
        {renderPage()}
      </CustomContainer>
    </HelpPage>
  );
};

export default Help;

const HelpPage = styled.div`
  position: relative;
  overflow: hidden;
  background: white;
`;

const CustomContainer = styled.div`
  /* max-width: 1440px; */
  margin: 0 auto;
  &:after {
    display: block;
    clear: both;
    content: '';
  }
`;

const SearchHolder = styled.div`
  background: url(${background});
  width: 100%;
  height: 421px;
  padding: 100px 30px 0;
  background-size: cover;
`;

const HelpFrame = styled.div`
  // position: relative;
  // max-width: 1086px;
  // margin: 0 auto;
  // &:after {
  //   display: block;
  //   clear: both;
  //   content: '';
  // }
`;

const LogoHolder = styled.div`
  max-width: 381px;
  margin: 0 0 20px;
`;

const Logo = styled.img`
  cursor: pointer;
  display: inline-block;
  width: 100%;
  height: auto;
`;

const Slogan = styled.div`
  font-family: Muli;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #666666;
  margin: 0 0 30px;
`;

const SearchBox = styled.div`
  position: relative;
  overflow: hidden;
  margin: 0 0 30px;
`;

const SearchInput = styled.input`
  border-radius: 10px;
  border: solid 1px #d4d4d4;
  background: rgba(255, 255, 255, 0.5);
  font-family: Muli;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  width: 100%;
  padding: 15px 20px;
  &::placeholder {
    color: #000000;
  }
`;

const SearchButton = styled.span`
  position: absolute;
  right: 25px;
  top: 10px;
  font-size: 30px;
`;
