import styled from 'styled-components';
import { Row } from 'antd';

export const MainContainer = styled.div`
  background: #dee2e6;
  height: 100vh;
  font-family: Muli, Helvetica, Arial, sans-serif;
`;

export const LinesheetEditorBody = styled(Row)`
  position: fixed;
  width: 100%;
`;

export const StyledLeftPanel = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  z-index: 1;
`;
export const StyledEditorPanel = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
export const StyledRightPanel = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 256px;
  z-index: 1;
`;
