import React from 'react';
import { Link } from 'react-router-dom';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import globals from 'websiteGlobals';
import Help from 'components/Common/NeedHelp';
import { useGetSourcesQuery } from 'api/externalSourceApi';
import {
  StyledContainer,
  PageHeaderContainer,
  StyledTitle,
  CancelButton,
  StyledRow,
  StyledCol,
  Subtitle,
  StyledHeading,
  StyledButton,
  StyledImportButton,
  StyledCheckCircle,
  StyledLogo,
  StyledApiCol,
  StyledApiContainer,
  ConnectedContainer,
  StyledConnectButton,
} from './styles';

const ChooseAddProductType = () => {
  const { data: userExternalAPIs } = useGetSourcesQuery();

  const renderAPIGrid = () => {
    return globals.externalAPIs
      .filter(() => true)
      .sort((a, b) => {
        const aConnected = userExternalAPIs?.some((e) => e.type === a.id);
        const bConnected = userExternalAPIs?.some((e) => e.type === b.id);

        if (aConnected && !bConnected) return -1;
        else if (!aConnected && bConnected) return 1;
        else return 0;
      })
      .map((api, idx) => {
        const isConnected = userExternalAPIs?.some((e) => e.type === api.id);
        const fullWidthlogos = [
          'bigcartle',
          'pinnaclecart',
          'squarespace',
          'magento',
          '3dcart',
          'woocommerce',
        ];
        const logoWidth = fullWidthlogos.includes(api.id) ? 90 : 50;
        return (
          <StyledApiCol key={idx} xs={24} sm={12} md={12} lg={6} align="center">
            <StyledApiContainer className={isConnected ? 'connected' : ''}>
              <StyledLogo src={api.logo} width={logoWidth} />
              {/* <StyledApiTitle>{api.name}</StyledApiTitle> */}
              {isConnected ? (
                <>
                  <Link to={`/products/new?method=${api.id}`}>
                    <StyledImportButton>Import</StyledImportButton>
                  </Link>
                  <ConnectedContainer>
                    Connected <StyledCheckCircle icon={faCheckCircle} />
                  </ConnectedContainer>
                </>
              ) : (
                <Link to={`/sync?api=${api.id}`}>
                  <StyledConnectButton>Connect</StyledConnectButton>
                </Link>
              )}
            </StyledApiContainer>
          </StyledApiCol>
        );
      });
  };

  return (
    <StyledContainer>
      <PageHeaderContainer>
        <StyledTitle>Choose which method you like to use on linesheet.</StyledTitle>
        <Link to="/products">
          <CancelButton>Cancel</CancelButton>
        </Link>
      </PageHeaderContainer>
      <StyledRow>
        <StyledCol xs={{ flex: '100%' }} lg={{ flex: '60%' }}>
          <Subtitle>
            Please select the method you'd like to use to import products to use in your linesheet
            creation.
          </Subtitle>
          <StyledRow>
            <StyledCol xs={24} sm={12} md={12} lg={12}>
              <StyledHeading>Manual Upload</StyledHeading>
              <Link to="/products/new?method=manual">
                <StyledButton className="btn btn-no-outline-primary">+ Add Product</StyledButton>
              </Link>
            </StyledCol>
            <StyledCol xs={24} sm={12} md={12} lg={12}>
              <StyledHeading>CSV Import</StyledHeading>
              <Link to="/products/new?method=csv">
                <StyledButton className="btn btn-no-outline-primary">Upload CSV</StyledButton>
              </Link>
            </StyledCol>
          </StyledRow>
          <StyledHeading>External Shop API</StyledHeading>
          <StyledRow>{renderAPIGrid()}</StyledRow>
        </StyledCol>
        <StyledCol xs={{ flex: '100%' }} lg={{ flex: '40%' }}>
          <Help />
        </StyledCol>
      </StyledRow>
    </StyledContainer>
  );
};

export default ChooseAddProductType;
