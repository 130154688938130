import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_BASE_URL;

export const handle401Error = async (api, action, { getState }) => {
  console.log('handle401Error called!');
  // Rest of your middleware logic
};

export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    credentials: 'include',
  }),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(handle401Error),
  endpoints: (builder) => ({
    createProduct: builder.mutation({
      query: (productData) => ({
        url: '/products/create',
        method: 'POST',
        body: productData,
        formData: true,
      }),
      invalidatesTags: ['Products'],
    }),
    getProducts: builder.query({
      query: ({
        searchFilter,
        sortBy,
        sortOrder,
        currentPage,
        pageSize,
        categoryFilter,
        productFilter,
      }) => {
        let url = `/products?limit=${pageSize}`;
        if (searchFilter) {
          url += `&searchFilter=${encodeURIComponent(searchFilter)}`;
        }
        if (sortBy && sortOrder) {
          url += `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }
        if (currentPage) {
          url += `&page=${currentPage}`;
        }
        if (categoryFilter) {
          url += `&categoryFilter=${categoryFilter}`;
        }
        if (productFilter) {
          url += `&productFilter=${productFilter}`;
        }
        return { url };
      },
      providesTags: ['Products'],
    }),
    deleteProduct: builder.mutation({
      query: (deleteProductData) => ({
        url: '/products',
        method: 'DELETE',
        body: deleteProductData,
      }),
      invalidatesTags: ['Products'],
    }),
    duplicateProducts: builder.mutation({
      query: (duplicateProductData) => ({
        url: '/products/duplicate',
        method: 'POST',
        body: duplicateProductData,
      }),
      invalidatesTags: ['Products'],
    }),
    getProduct: builder.mutation({
      query: (productId) => ({
        url: `/products/${productId}`,
        method: 'GET',
      }),
    }),
    updateProduct: builder.mutation({
      query: (productDetails) => {
        const { productId, formData: productData } = productDetails;
        return {
          url: `/products/update/${productId}`,
          method: 'PUT',
          body: productData,
          formData: true,
        };
      },
      invalidatesTags: ['Products'],
    }),
    generateProductsCsv: builder.mutation({
      query: (generateCsvProductData) => ({
        url: '/products/generateCsv',
        method: 'POST',
        body: generateCsvProductData,
      }),
      // invalidatesTags: ['Products'],
    }),
    importFromCSV: builder.mutation({
      query: (csvFormData) => ({
        url: `/products/importfromCSV`,
        method: 'POST',
        body: csvFormData,
        formData: true,
      }),
      invalidatesTags: ['Products'],
    }),
    assignCategoriesToProducts: builder.mutation({
      query: (payload) => ({
        url: '/products/assignCategories',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Products'],
    }),
    getNonLinesheetProducts: builder.mutation({
      query: ({
        searchFilter,
        sortBy,
        sortOrder,
        currentPage,
        pageSize,
        linesheetFilter = null,
      }) => {
        let url = `/products?limit=${pageSize}`;
        if (searchFilter) {
          url += `&searchFilter=${encodeURIComponent(searchFilter)}`;
        }
        if (sortBy && sortOrder) {
          url += `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }
        if (currentPage) {
          url += `&page=${currentPage}`;
        }
        if (linesheetFilter !== null) {
          url += `&linesheetFilter=${linesheetFilter}`;
        }
        return {
          url,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useCreateProductMutation,
  useGetProductsQuery,
  useDeleteProductMutation,
  useDuplicateProductsMutation,
  useGetProductMutation,
  useUpdateProductMutation,
  useGenerateProductsCsvMutation,
  useImportFromCSVMutation,
  useAssignCategoriesToProductsMutation,
  useGetNonLinesheetProductsMutation,
} = productApi;
