import React from 'react';
import Input from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import {
  StyledSpan,
  MainContainer,
  InputLabelWrapper,
  StyledDivider,
  StyledInput,
  StyledTextArea,
  StyledCarousel,
  StyledLinesheetContainer,
  StyledImage,
  StyledLinesheetInfo,
  StyledLinesheetName,
  StyledLinesheetDetail,
} from './styles';

const ComposeEmail = ({
  setSubject,
  setMessageInput,
  selectedEmails,
  setSelectedEmails,
  selectedLinesheets,
}) => {
  const handleTagsChange = (tags) => {
    setSelectedEmails(tags);
  };

  const timeSince = (date) => {
    const now = new Date();
    const previous = new Date(date);

    const secondsAgo = Math.floor((now - previous) / 1000);
    const minutesAgo = Math.floor(secondsAgo / 60);
    const hoursAgo = Math.floor(minutesAgo / 60);
    const daysAgo = Math.floor(hoursAgo / 24);

    if (daysAgo > 1) {
      return `Last updated ${daysAgo} days ago`;
    } else if (daysAgo === 1) {
      return 'Last updated a day ago';
    } else if (hoursAgo > 1) {
      return `Last updated ${hoursAgo} hours ago`;
    } else if (hoursAgo === 1) {
      return 'Last updated an hour ago';
    } else if (minutesAgo > 1) {
      return `Last updated ${minutesAgo} minutes ago`;
    } else if (minutesAgo === 1) {
      return 'Last updated a minute ago';
    } else {
      return `Last updated just now`;
    }
  };

  return (
    <MainContainer>
      <StyledCarousel arrows infinite={false} slidesToShow={2}>
        {selectedLinesheets.map((linesheet, index) => (
          <StyledLinesheetContainer key={`linesheet-${index}`}>
            <StyledImage>
              <img src={linesheet.thumbnail_url} alt="" />
            </StyledImage>
            <StyledLinesheetInfo>
              <StyledLinesheetName>{linesheet.name}</StyledLinesheetName>
              <StyledLinesheetDetail>
                {`${linesheet.number_of_pages} Page(s)`}
                &nbsp;&nbsp;
                {`${linesheet.number_of_products} Product(s)`}
              </StyledLinesheetDetail>
              <StyledLinesheetDetail>{timeSince(linesheet.updated_at)}</StyledLinesheetDetail>
            </StyledLinesheetInfo>
          </StyledLinesheetContainer>
        ))}
      </StyledCarousel>
      <StyledDivider />
      <InputLabelWrapper>
        <StyledSpan>To:</StyledSpan>
        <Input
          value={selectedEmails}
          onChange={handleTagsChange}
          validationRegex={
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          }
          inputProps={{
            className: 'react-tagsinput-input',
            placeholder: '',
          }}
          addOnBlur
          // onValidationReject={onRejectEmail}
        />
      </InputLabelWrapper>
      <StyledDivider />
      <InputLabelWrapper>
        <StyledSpan>Subject:</StyledSpan>
        <StyledInput placeholder="Subject" onChange={(e) => setSubject(e.target.value)} />
      </InputLabelWrapper>
      <StyledDivider />
      <StyledTextArea
        rows={3}
        placeholder="Add a message: (optional)"
        onChange={(e) => setMessageInput(e.target.value)}
      />
    </MainContainer>
  );
};

export default ComposeEmail;
