import styled from 'styled-components';
import AntForm from 'uiKits/Form';
import { AntInput } from 'uiKits/Input';

export const StyledHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 32px 0;
`;

export const StyledTitle = styled.h5`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 1.44px;
  margin: 0;
`;
export const StyledFormItem = styled(AntForm.Item)`
  margin-bottom: 1rem;
  .ant-row {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledInput = styled(AntInput)`
  width: 256px;
  border-width: 2px;
  font-weight: 400;
`;

export const StyledSaveButton = styled.button`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  background-color: #02b183;
  border: none;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 10000px;
  padding: 0.3125vw 0.8333333333333334vw;
  color: #ffffff;
  margin-left: 5px;
  &:hover {
    background-color: #028b67;
    border-color: #017f5e;
  }
`;
