import styled from 'styled-components';
import { Alert } from 'antd';

export const AlertContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledAlert = styled(Alert)`
  width: 50%;
  z-index: 2;
  margin-top: 5px;
  a {
    text-decoration: underline;
    font-size: 14px;
    font-weight: bold;
    color: red;
  }
`;
