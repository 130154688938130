import React from 'react';
import { Col, Flex } from 'antd';
import { Link } from 'react-router-dom';
import { Row } from 'antd';
import { useSelector } from 'react-redux';
import apiIcon from '../../assets/images/ls-api-icon-static.png';
import csvIcon from '../../assets/images/ls-csv-icon-static.png';
import oneByOneIcon from '../../assets/images/ls-one-by-one-icon-static.png';
import questionsIcon from 'assets/images/question-icon.svg';
import rightApiIcon from '../../assets/images/api-icon.png';
import rightCsvIcon from '../../assets/images/csv-icon.png';
import rightManualIcon from '../../assets/images/manua-icon.png';
import {
  FooterBox,
  ModalHeading,
  ModalDescriptionText,
  StyledCloseButton,
  ModalOptionsRow,
  ModalApiBox,
  DashboardModalRightCol,
  ModalRightHeading,
  ModalRightContent,
  ModalRightContentHeading,
  ModalRightContentText,
  StyledModal,
} from './styles';

const DashboardIntroModal = ({ showModal, setShowModal, refetch }) => {
  const user = useSelector((state) => state?.auth.user);

  const handleCancel = () => {
    setShowModal(false);
    refetch();
  };

  return (
    <StyledModal
      open={showModal}
      onCancel={handleCancel}
      closable={false}
      footer={[
        <FooterBox>
          <Flex justify="center" vertical align="center">
            <p>
              Not ready to add products? Click close to go explore.Thanks for registering with
              Linesheets.
            </p>
            <StyledCloseButton size="large" onClick={handleCancel}>
              Close
            </StyledCloseButton>
          </Flex>
        </FooterBox>,
      ]}
      width={'720px'}
      centered
    >
      {/* <div className="container"> */}
      <Row gutter={24}>
        <Col md={16}>
          <Row>
            <ModalHeading>Hi {user.firstName}!</ModalHeading>
          </Row>
          <Row>
            <ModalDescriptionText>
              Welcome to the Linesheets family — thanks for signing up!
            </ModalDescriptionText>
          </Row>
          <Row>
            <ModalDescriptionText>
              Let’s start by adding some products. Choose one of the options below:{' '}
              <img src={questionsIcon} alt="" />
            </ModalDescriptionText>
          </Row>
          <ModalOptionsRow gutter={24}>
            <Col md={8} onClick={handleCancel}>
              <Link to="/syncApi">
                <ModalApiBox>
                  <img src={apiIcon} alt="" />
                </ModalApiBox>
                <p>API</p>
              </Link>
            </Col>
            <Col md={8} onClick={handleCancel}>
              <Link to={'/products/new?method=csv'}>
                <ModalApiBox>
                  <img src={csvIcon} alt="" />
                </ModalApiBox>
                <p>CSV</p>
              </Link>
            </Col>
            <Col md={8} onClick={handleCancel}>
              <Link to={'/products/new?method=manual'}>
                <ModalApiBox>
                  <img src={oneByOneIcon} alt="" />
                </ModalApiBox>
                <p>Manual</p>
              </Link>
            </Col>
          </ModalOptionsRow>
        </Col>
        <Col md={8}>
          <DashboardModalRightCol>
            <div>
              <ModalRightHeading>Help</ModalRightHeading>
            </div>
            <ModalRightContent>
              <ModalRightContentHeading>
                <img src={rightApiIcon} alt="" />
                API
              </ModalRightContentHeading>
              <ModalRightContentText>
                Connect directly to your existing store and import products with a few quick clicks.
              </ModalRightContentText>
            </ModalRightContent>
            <ModalRightContent>
              <ModalRightContentHeading>
                <img src={rightCsvIcon} alt="" />
                CSV
              </ModalRightContentHeading>
              <ModalRightContentText>
                Use our spreadsheet template or upload your own exported file from an existing store
                to bulk import your entire product catalog or bring a new collection on board.
              </ModalRightContentText>
            </ModalRightContent>
            <ModalRightContent>
              <ModalRightContentHeading>
                <img src={rightManualIcon} alt="" />
                Manual
              </ModalRightContentHeading>
              <ModalRightContentText>
                Just need to upload a single superstar product or add one to an existing collection?
                Use our manual upload and put in the details by hand.
              </ModalRightContentText>
            </ModalRightContent>
          </DashboardModalRightCol>
        </Col>
      </Row>
      {/* </div> */}
    </StyledModal>
  );
};

export default DashboardIntroModal;
