import styled from 'styled-components';
import AntTable from 'uiKits/Table';
import AntButton from 'uiKits/Button';

export const StyledLayout = styled.div`
  marging-top: 25px;
`;

export const StyledTableRow = styled.tr`
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: normal;
  text-align: left;
`;

export const StyledTable = styled(AntTable)``;

export const ActionImage = styled.img`
  width: 15px;
  cursor: pointer;
`;

export const SelectAllContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SelectAllButton = styled(AntButton)`
  background-color: #fff;
  padding: 8px 29px;
  font-size: 12px;
  font-weight: 700;
  font-family: Muli;
  border: 2px solid #02b183;
  color: #02b183;
  border-radius: 100px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SelectedRowsText = styled.h6`
  font-family: Muli;
  font-weight: 600;
  height: auto;
  width: auto;
  margin: 0;
  font-size: 1rem;
`;

export const EllipsisTooltip = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 90px;
`;

export const DropdownWrapper = styled.div`
  cursor: pointer;
`;
