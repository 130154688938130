import { Input } from 'antd';
import styled from 'styled-components';

const { TextArea } = Input;

export const AntInput = styled(Input)`
  border: 1px solid #ced4da;
  border-width: 2px;
  border-radius: 10px;
  &::placeholder {
    color: $gray-500;
    font-style: Muli;
  }
  &:hover {
    border-color: #ced4da;
  }
  &:focus {
    border-color: #36fdc8;
    box-shadow: 0 0 0 0.2rem rgba(2, 177, 131, 0.25);
  }
  &:focus-within {
    border-color: #36fdc8;
    box-shadow: 0 0 0 0.2rem rgba(2, 177, 131, 0.25);
  }
`;

export const AntTextArea = styled(TextArea)`
  border: 1px solid #ced4da;
  border-width: 2px;
  border-radius: 10px;
  &::placeholder {
    color: $gray-500;
    font-style: Muli;
  }
  &:hover {
    border-color: #ced4da;
  }
  &:focus {
    border-color: #36fdc8;
    box-shadow: 0 0 0 0.2rem rgba(2, 177, 131, 0.25);
  }
`;
