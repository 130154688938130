import styled from 'styled-components';
import { Button, Modal } from 'antd';

export const StyledModal = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  .ant-modal-header {
    padding: 32px 48px 0;
    margin: 0;
  }
  .ant-modal-content {
    padding: 0;
    top: -70px;
  }
  .ant-modal-body {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 48px;
    align-items: center;
    overflow-y: auto;
  }
`;

export const FooterWrapper = styled.div`
  padding: 38px;
  background-color: #f3f3f3;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

export const StyledButton = styled(Button)`
  border: none;
  color: #6c757d;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.96px;
  border-radius: 10000px;
  background: none;
  &:hover {
    color: #ffffff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
  }
`;

export const StyledTitle = styled.h5`
  font-family: Muli;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 1.44px;
  margin: 0;
`;

export const ModalBody = styled.div`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight 400
`;

export const StyledActionsButton = styled.button`
  font-family: Muli;
  border-radius: 17.5px;
  border: 2px solid #07af7c;
  background-color: #07af7c;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10000px;
  padding: 0.3125vw 0.8333333333333334vw;
  &:hover {
    background-color: #028b67;
    border: 2px solid #017f5e;
  }
`;
