import styled from 'styled-components';
import { Row, Typography, Col } from 'antd';

const { Title, Text } = Typography;

export const SyncContainer = styled.div`
  margin: 32px;
  @media all and (max-width: 1300px) {
    margin: 16px;
  }
`;

export const SyncTitle = styled(Title)`
  font-size: 30px;
  font-family: Muli;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.03;
  letter-spacing: 0.6px;
  text-align: left;
  color: #000000;
  margin-bottom: 8px !important;
`;

export const SyncSubTitle = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.07;
  font-family: Muli;
  letter-spacing: 0.28px;
`;

export const StyledRow = styled(Row)``;

export const StyledCol = styled(Col)``;

export const StyledProductApiRow = styled(Row)`
  margin-top: 3rem !important;
  gap: 10px;
`;
