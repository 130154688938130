import AntForm from 'uiKits/Form';
import styled from 'styled-components';
import { AntInput } from 'uiKits/Input';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-number-input/input';
import { Divider } from 'antd';

export const StyledForm = styled(AntForm)``;

export const StyledInput = styled(AntInput)`
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.28px;
  max-height: 38px;
  padding: 0.5rem 0.75rem !important;
  max-width: 256px;
  height: calc(1.5em + 1.3rem + 4px);
`;

export const StyledDivider = styled(Divider)`
  margin: 12px 0px;
`;

export const StyledRegionDropdown = styled(RegionDropdown)`
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.28px;
  max-height: 38px;
  padding: 0.5rem 0.75rem !important;
  max-width: 256px;
  width: 100%;
  height: calc(1.5em + 1.3rem + 4px);
  border: 1px solid #ced4da;
  border-radius: 10px;
  border-width: 2px;
`;

export const StyledCountryDropdown = styled(CountryDropdown)`
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.28px;
  max-height: 38px;
  padding: 0.5rem 0.75rem !important;
  max-width: 256px;
  width: 100%;
  height: calc(1.5em + 1.3rem + 4px);
  border: 1px solid #ced4da;
  border-radius: 10px;
  border-width: 2px;
`;

export const StyledPhoneInput = styled(PhoneInput)`
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.28px;
  max-height: 38px;
  padding: 0.5rem 0.75rem !important;
  max-width: 256px;
  width: 100%;
  height: calc(1.5em + 1.3rem + 4px);
  border: 1px solid #ced4da;
  border-radius: 10px;
  border-width: 2px;
`;
