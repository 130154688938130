import { createSlice } from '@reduxjs/toolkit';
import globals from 'websiteGlobals';
import { v4 as uuidv4 } from 'uuid';

const fontSettings = (align = 'center', size = '9px', bold = false, font = 'Arial') => {
  return {
    font: font,
    size,
    color: '#000',
    bold,
    italic: false,
    underline: false,
    align,
    isEditing: false,
    show: true,
  };
};

const linesheetDataInitSettings = {
  font: null,
  size: null,
  color: '#000',
  productsBackgroundColor: 'rgb(255, 255, 255)',
  productsOpacity: 100,
  pageBackground: 'rgb(255, 255, 255)',
  pageOpacity: 100,
  orientation: 'portrait',
  zoomLevel: 0.8,
  allPageLocked: false,
};

const linesheetDataInitState = {
  id: null,
  name: null,
  pdf_url: null,
  settings: linesheetDataInitSettings,
  products: [],
  categories: {},
  pages: [],
  header_content: null,
  footer_content: null,
};

const initialState = {
  previousLinesheetData: [],
  linesheetData: linesheetDataInitState,
  futureLinesheetData: [],
  inPreview: false,
  inGlobalSettingsMode: false,
  activeAction: 'pointer',
  selectedContentId: '',
  selectedPageId: '',
  selectedPageIndex: null,
  selectedTemplateId: '',
  showTemplateModal: false,
  isChangeTemplate: false,
  changeLinesheetTemplate: false,
  showConfirmTemplateModal: false,
  showDownloadConfirmationModal: false,
  isEditing: false,
  selectedProductIds: [],
  changeImage: false,
  isEditorLoading: false,
  addingHeaderFooterMode: false,
  isAddingHeader: false,
  isAddingFooter: false,
};

const generateId = () => {
  return uuidv4();
};

const extractContentHeight = (height, gridY) => {
  let newHeight;
  switch (gridY) {
    case 1:
      newHeight = height - 100;
      break;
    case 2:
      newHeight = height - 120;
      break;
    case 3:
      newHeight = height - 140;
      break;
    default:
      newHeight = height - 100;
      break;
  }
  return newHeight / gridY;
};

const pageSettings = (state, template = null) => {
  const { pageBackground = 'rgb(255, 255, 255)', pageOpacity = 100 } =
    state.linesheetData.settings || {};

  return {
    background: backgroundWithOpacity(pageBackground, pageOpacity),
    orientation: state.linesheetData.settings.orientation,
    template: template,
    opacity: pageOpacity,
    isNotFormatted: false,
  };
};

const contentSettings = (width, height, left, top) => {
  return {
    width: width - 22,
    height,
    left,
    top,
    backgroundColor: 'rgb(255, 255, 255)',
    opacity: 100,
    widthPercentage: 100,
    heightPercentage: 100,
    spaceBetween: 10,
    zIndex: 1,
    productArrangment: 'top',
    isOverflow: false,
  };
};

const imageSettings = (imageHeight, imageContentSizePercentage) => {
  return {
    height: imageHeight,
    imageSizePercentage: 100,
    imageContentSizePercentage: imageContentSizePercentage,
    size: 'contain',
    flipVertical: false,
    flipHorizontal: false,
    transform: 'scale(1,1)',
    WebkitTransform: 'scale(1,1)',
    MozTransform: 'scale(1,1)',
    cropImage: false,
  };
};

const getPageDimensions = (orientation, gridX, gridY) => {
  const pageSize = globals.constants.pageSize;
  const isPortrait = orientation === 'portrait';
  const pageWidth = pageSize[isPortrait ? 1 : 0];
  const pageHeight = pageSize[isPortrait ? 0 : 1];
  const contentWidth = pageWidth / gridX;
  const contentHeight = extractContentHeight(pageHeight, gridY);
  const imageHeight = 225 / gridY;
  return [contentHeight, imageHeight, contentWidth, pageWidth, pageHeight];
};

const addPagesWithContent = (state, products, template) => {
  const [gridX, gridY] = template.gridSize;
  const [contentHeight, imageHeight, contentWidth] = getPageDimensions(
    state.linesheetData.settings.orientation,
    gridX,
    gridY,
  );
  const imageContentSizePercentage = imageHeight / contentHeight;
  products.forEach((productLists) => {
    let contentData = [];
    const productListX = createBatches(productLists, gridX);
    productListX.forEach((productList, index) => {
      let top = 50 + index * (contentHeight + 20);
      productList.forEach((product, index) => {
        let left = 10 + index * contentWidth;
        contentData.push({
          unique_content_id: generateId(),
          type: 'product',
          product_id: product.id,
          settings: contentSettings(contentWidth, contentHeight, left, top),
          image_content: {
            url: product.product_image || '',
            image_index: 1,
            number_of_images: product.images?.length,
            product_images: product.images || [],
            settings: imageSettings(imageHeight, imageContentSizePercentage),
          },
          text_content: {
            title: {
              value: product.name,
              settings: fontSettings('center', '12px', true),
            },
            sku: {
              value: product.productNumber,
              settings: fontSettings(),
            },
            description: {
              value: product.description,
              settings: fontSettings('center', '12px'),
            },
            wholesalePrice: {
              value: `WS: $${product.wholesalePrice}`,
              settings: fontSettings(),
            },
            retailPrice: {
              value: `MSRP:$ ${product.retailPrice}`,
              settings: fontSettings(),
            },
          },
        });
      });
    });
    state.linesheetData.pages.push({
      unique_page_id: generateId(),
      type: 'template',
      settings: pageSettings(state, template),
      content: contentData,
      isFormatted: true,
      locked: false,
    });
  });
};

const createBatches = (products, batchSize) => {
  const batches = [];
  for (let i = 0; i < products.length; i += batchSize) {
    const batch = products.slice(i, i + batchSize);
    batches.push(batch);
  }
  return batches;
};

const changePageDimension = (orientation) => {
  if (orientation === 'portrait') {
    return 'landscape';
  } else {
    return 'portrait';
  }
};

const addEmptyPage = (state) => {
  state.linesheetData.pages.push({
    type: 'custom',
    unique_page_id: generateId(),
    settings: pageSettings(state),
    isFormatted: true,
    locked: false,
  });
};

const percentageToPixels = (percentage, referenceDimension) => {
  return (percentage / 100) * referenceDimension;
};

const setPageAlignment = (page, isRotate = false) => {
  const { template, orientation } = page.settings;

  if (page.type === 'custom' && isRotate) {
    adjustCustomPageContent(page);
  }

  if (template) {
    const [gridX, gridY] = template.gridSize;
    const [contentHeight, imageHeight, contentWidth, pageWidth, pageHeight] = getPageDimensions(
      orientation,
      gridX,
      gridY,
    );
    const processedProductIds = new Set();

    let row = 0,
      col = 0;
    let updatedContent = [];

    page.content?.forEach((content) => {
      if (processedProductIds.has(content.product_id)) return;

      if (['text', 'image'].includes(content.type)) {
        updatedContent.push(adjustContentPosition(content, pageWidth, pageHeight));
      } else if (isIncompleteProduct(content) && isRotate) {
        const newContentItems = processProductContent(
          page,
          content,
          processedProductIds,
          contentWidth,
          contentHeight,
          imageHeight,
          row,
          col,
        );
        updatedContent.push(...newContentItems);
      } else {
        updatedContent.push(
          adjustGridContent(content, contentWidth, contentHeight, imageHeight, row, col),
        );
      }

      col = (col + 1) % gridX;
      if (col === 0) row += 1;
    });

    page.content = updatedContent;
  }
};

const adjustCustomPageContent = (page) => {
  page.content = page.content?.map((content) => {
    if (['text', 'image'].includes(content.type)) {
      const updatedContent = {
        ...content,
        settings: {
          ...content.settings,
          left: content.settings.top,
          top: content.settings.left,
          // height: content.settings.width,
          // width: content.settings.height,
        },
      };
      // if (content.type === 'image') {
      //   updatedContent.image_content.settings.height = content.settings.width;
      // }
      return updatedContent;
    }
    return content;
  });
};

const adjustContentPosition = (content, pageWidth, pageHeight) => ({
  ...content,
  settings: {
    ...content.settings,
    left: (content.settings.left * pageHeight) / pageWidth,
    top: (content.settings.top * pageWidth) / pageHeight,
  },
});

const isIncompleteProduct = (content) =>
  content.type === 'product' && (!content.image_content || !content.text_content);

const processProductContent = (
  page,
  content,
  processedProductIds,
  contentWidth,
  contentHeight,
  imageHeight,
  row,
  col,
) => {
  const relatedContents = page.content.filter((c) => c.product_id === content.product_id);
  let textProductContent, imageProductContent;

  relatedContents.forEach((relatedContent) => {
    if (relatedContent.image_content) imageProductContent = relatedContent;
    if (relatedContent.text_content) textProductContent = relatedContent;
  });

  processedProductIds.add(content.product_id);

  const left = 10 + col * (contentWidth - 2);
  const top = 50 + row * (contentHeight + 20);
  const isImageOnTop = content.settings.productArrangment === 'top';

  const newContentItems = [];
  if (textProductContent && imageProductContent) {
    newContentItems.push(
      adjustImageContent(
        imageProductContent,
        contentWidth,
        imageHeight,
        left,
        top,
        isImageOnTop,
        contentHeight,
      ),
    );
    newContentItems.push(
      adjustTextContent(
        textProductContent,
        contentWidth,
        contentHeight,
        imageHeight,
        left,
        top,
        isImageOnTop,
      ),
    );
  } else {
    newContentItems.push({
      ...content,
      settings: {
        ...content.settings,
        left,
        top,
        width: contentWidth - 22,
        height: contentHeight,
      },
    });
  }

  return newContentItems;
};

const adjustImageContent = (
  content,
  contentWidth,
  imageHeight,
  left,
  top,
  isImageOnTop,
  contentHeight,
) => ({
  ...content,
  settings: {
    ...content.settings,
    width: contentWidth - 22,
    height: imageHeight,
    top: isImageOnTop ? top : top + contentHeight - imageHeight,
    left,
    image_content: {
      ...content.image_content,
      settings: {
        ...content.image_content.settings,
        height: imageHeight - 10,
      },
    },
  },
});

const adjustTextContent = (
  content,
  contentWidth,
  contentHeight,
  imageHeight,
  left,
  top,
  isImageOnTop,
) => ({
  ...content,
  settings: {
    ...content.settings,
    width: contentWidth - 22,
    height: contentHeight - imageHeight - content.settings.spaceBetween,
    top: isImageOnTop ? top + imageHeight + content.settings.spaceBetween : top,
    left,
  },
});

const adjustGridContent = (content, contentWidth, contentHeight, imageHeight, row, col) => ({
  ...content,
  settings: {
    ...content.settings,
    left: 10 + col * (contentWidth - 2),
    top: 50 + row * (contentHeight + 20),
    width: contentWidth - 22,
    height: contentHeight,
  },
  image_content: content.image_content
    ? {
        ...content.image_content,
        settings: {
          ...content.image_content.settings,
          height: imageHeight,
          imageSizePercentage: 100,
          imageContentSizePercentage: imageHeight / contentHeight,
        },
      }
    : null,
  text_content: content.text_content || null,
});

const updateSettings = (content, settingKey, settingValue) => {
  const productFields = ['title', 'sku', 'description', 'wholesalePrice', 'retailPrice'];
  const oppositeSettingKeys = ['underline', 'italic', 'bold'];

  const updateField = (field, key, value) => {
    content.text_content[field].settings[key] = value;
  };

  const toggleField = (field, key) => {
    content.text_content[field].settings[key] = !content.text_content[field].settings[key];
  };

  if (content.type === 'product') {
    productFields.forEach((field) => {
      if (oppositeSettingKeys.includes(settingKey)) {
        toggleField(field, settingKey);
      } else {
        updateField(field, settingKey, settingValue);
      }
    });
  } else {
    if (oppositeSettingKeys.includes(settingKey)) {
      content.text_content.html.settings[settingKey] =
        !content.text_content.html.settings[settingKey];
    } else {
      content.text_content.html.settings[settingKey] = settingValue;
    }
  }
};

const backgroundWithOpacity = (background, opacity) => {
  const rgbValues = background.match(/\d+/g).map(Number);

  return `rgba(${rgbValues[0]}, ${rgbValues[1]}, ${rgbValues[2]}, ${opacity}%)`;
};

const updateLinesheetContentTemplate = (state, contents, template) => {
  const [gridX, gridY] = template.gridSize;
  const [contentHeight, imageHeight, contentWidth] = getPageDimensions(
    state.linesheetData.settings.orientation,
    gridX,
    gridY,
  );
  contents.forEach((contentLists) => {
    let contentData = [];
    const contentListX = createBatches(contentLists, gridX);

    contentListX.forEach((contentList, rowIndex) => {
      const top = 50 + rowIndex * (contentHeight + 20);

      contentList.forEach((content, colIndex) => {
        const left = 10 + colIndex * (contentWidth - 2);

        contentData.push({
          ...content,
          image_content: {
            ...content?.image_content,
            settings: {
              ...content?.image_content.settings,
              height: imageHeight,
              imageSizePercentage: 100,
            },
          },
          settings: {
            ...content.settings,
            width: contentWidth - 22,
            height: contentHeight,
            left: left,
            top: top,
          },
        });
      });
    });

    state.linesheetData.pages.push({
      unique_page_id: generateId(),
      type: 'template',
      settings: pageSettings(state, template),
      content: contentData,
      isFormatted: true,
      locked: false,
    });
  });
};

const moveContent = (state, direction) => {
  const { selectedPageIndex, selectedContentId } = state;
  const page = state.linesheetData.pages[selectedPageIndex];
  if (!page) return state;

  const content = page.content.find((c) => c.unique_content_id === selectedContentId);
  if (!content) return state;

  if (direction === 'forward' || direction === 'front') {
    content.settings.zIndex = page.content.length;
  } else {
    content.settings.zIndex = 0;
  }
};

const getSelectedContent = (state) => {
  const { selectedPageIndex, selectedContentId } = state;
  const page = state.linesheetData.pages[selectedPageIndex];
  if (!page) return null;

  const content = page.content?.find((c) => c.unique_content_id === selectedContentId);
  if (!content) return null;

  return content;
};

const unGroupProduct = (state, content) => {
  const page = state.linesheetData.pages[state.selectedPageIndex];
  if (!page) return null;

  const { image_content, text_content } = content;

  const imageHeight = image_content?.settings?.height || 0;

  let newContentItems = [];

  const isImageOnTop = content.settings.productArrangment === 'top';
  const spaceBetween = content.settings.spaceBetween || 0;
  if (image_content) {
    newContentItems.push({
      unique_content_id: generateId(),
      type: 'product',
      product_id: content.product_id,
      settings: {
        ...content.settings,
        height: imageHeight,
        top: isImageOnTop
          ? content.settings.top
          : text_content
          ? 50 + content.settings.height - imageHeight + spaceBetween
          : content.settings.top,
      },
      image_content: {
        ...image_content,
        settings: {
          ...image_content.settings,
          imageContentSizePercentage: 1,
        },
      },
    });
  }
  if (text_content) {
    newContentItems.push({
      unique_content_id: generateId(),
      type: 'product',
      product_id: content.product_id,
      settings: {
        ...content.settings,
        height: image_content
          ? content.settings.height - imageHeight - content.settings.spaceBetween
          : content.settings.height,
        top:
          isImageOnTop && image_content
            ? content.settings.top + imageHeight + spaceBetween
            : content.settings.top,
      },
      text_content,
    });
  }

  const contentIndex = page.content.findIndex(
    (item) => item.unique_content_id === content.unique_content_id,
  );
  if (contentIndex === -1) return;

  page.content.splice(contentIndex, 1, ...newContentItems);
};

const updatePagesPageNos = (state) => {
  for (let i = 0; i < state.linesheetData.pages.length; i++) {
    state.linesheetData.pages[i].page_number = i;
  }
};

const updateHeaderFooterPosition = (content, orientation) => {
  const pageSize = globals.constants.pageSize;
  const [landscapePageWidth, portraitPageWidth] = pageSize;
  const oldWidth = orientation === 'portrait' ? landscapePageWidth : portraitPageWidth;
  const newWidth = orientation === 'portrait' ? portraitPageWidth : landscapePageWidth;

  const updatePosition = (settings, type) => {
    const positionMap = {
      image: [0.45, 0.48],
      description: [0.51, 0.48],
    };

    const typePositions = positionMap[type];

    if (typePositions) {
      typePositions.forEach((position) => {
        if (settings.left === position * oldWidth) {
          settings.left = position * newWidth;
        }
      });
    } else if (settings.left === 'unset' || settings.right === 'unset') {
      return;
    } else {
      settings.left = (settings.left / oldWidth) * newWidth;
    }
  };

  if (content.image) {
    updatePosition(content.image.settings, 'image');
  }

  if (content.description) {
    updatePosition(content.description.settings, 'description');
  }
};

const getImageSettings = (imagePosition, pageWidth, description, descriptionPosition) => {
  let left = 10;
  let right = 'unset';
  switch (imagePosition) {
    case 'center':
      left = description && descriptionPosition === 'center' ? 0.45 * pageWidth : 0.48 * pageWidth;
      break;
    case 'right':
      left = 'unset';
      right = 10;
      break;
    default:
      break;
  }
  return { left, right, top: 10 };
};

const getDescriptionSettings = (
  imagePosition,
  descriptionPosition,
  pageWidth,
  imageUrl,
  description,
) => {
  let left = 10;
  let right = 'unset';
  if (descriptionPosition === 'center') {
    left = imageUrl && imagePosition === 'center' ? 0.51 * pageWidth : 0.48 * pageWidth;
  } else if (descriptionPosition === 'right') {
    left = 'unset';
    right = imageUrl && description && imagePosition === descriptionPosition ? 50 : 10;
  } else {
    left = imageUrl && description && imagePosition === descriptionPosition ? 50 : 10;
  }
  return { left, right, top: 10 };
};

const setContent = (content, field, value, settings) => {
  if (value) {
    content[field] = { value, settings };
  } else {
    delete content[field];
  }
};

const addOrUpdateContent = (state, action, isUpdate = false) => {
  const { imageUrl, description, imagePosition, descriptionPosition } = action.payload;
  const pageSize = globals.constants.pageSize;
  const isPortrait = state.linesheetData.settings.orientation === 'portrait';
  const pageWidth = pageSize[isPortrait ? 1 : 0];

  let content = isUpdate
    ? state.isAddingHeader
      ? state.linesheetData.header_content
      : state.linesheetData.footer_content
    : {};

  setContent(
    content,
    'image',
    imageUrl,
    getImageSettings(imagePosition, pageWidth, description, descriptionPosition),
  );
  const descriptionSettings = isUpdate
    ? {
        ...content.description?.settings,
        ...getDescriptionSettings(
          imagePosition,
          descriptionPosition,
          pageWidth,
          imageUrl,
          description,
        ),
      }
    : {
        ...getDescriptionSettings(
          imagePosition,
          descriptionPosition,
          pageWidth,
          imageUrl,
          description,
        ),
        ...fontSettings('center', '12px'),
      };
  setContent(content, 'description', description, descriptionSettings);

  if (state.isAddingHeader) {
    state.linesheetData.header_content = content;
  } else {
    state.linesheetData.footer_content = content;
  }
};

const updateHeaderFooterContentSettings = (state, action, section) => {
  const { key, value } = action.payload;
  if (['bold', 'italic', 'underline'].includes(key)) {
    state.linesheetData[section].description.settings[key] =
      !state.linesheetData[section].description.settings[key];
  } else {
    state.linesheetData[section].description.settings[key] = value;
  }
};

export const editorSlice = createSlice({
  name: 'linesheet',
  initialState,
  reducers: {
    saveState: (state, action) => {
      state.previousLinesheetData = action.payload; // Set the payload (previous state) to the past array
    },
    resetEditorStates: () => initialState,
    setLinesheet: (state, action) => {
      const { id, name, settings, categories, pages, pdf_url, header_content, footer_content } =
        action.payload;
      state.linesheetData.id = id;
      state.linesheetData.name = name;
      state.linesheetData.settings =
        settings && Object.keys(settings).length > 0 ? settings : linesheetDataInitSettings;
      state.linesheetData.pages = [...pages]?.sort((a, b) => a.page_number - b.page_number) || [];
      state.linesheetData.products = Object.values(categories).flat();
      state.linesheetData.categories = categories;
      state.linesheetData.pdf_url = pdf_url;
      state.linesheetData.header_content = header_content;
      state.linesheetData.footer_content = footer_content;
    },
    setLinesheetProducts: (state, action) => {
      state.linesheetData.categories = action.payload;
      state.linesheetData.products = Object.values(action.payload).flat();
    },
    addPage: (state, action) => {
      if (action.payload) {
        const template = action.payload.template;
        const gridSize = template.gridSize;
        let batchSize = gridSize[0] * gridSize[1];
        const selectedProducts = state.linesheetData.products.filter((product) =>
          state.selectedProductIds.includes(product.id),
        );
        const products = createBatches(selectedProducts, batchSize);
        addPagesWithContent(state, products, template);
        state.selectedProductIds = [];
      } else {
        addEmptyPage(state);
      }
      // Update the pageno of all pages
      updatePagesPageNos(state);
    },
    rotatePage: (state) => {
      state.isEditorLoading = true;
      const linesheetOrientation = state.linesheetData.settings.orientation;
      const newLinesheetOrientation = changePageDimension(linesheetOrientation);
      state.linesheetData.pages.forEach((page) => {
        page.settings.orientation = newLinesheetOrientation;
        setPageAlignment(page, true);
      });

      if (state.linesheetData.header_content) {
        updateHeaderFooterPosition(state.linesheetData.header_content, newLinesheetOrientation);
      }
      if (state.linesheetData.footer_content) {
        updateHeaderFooterPosition(state.linesheetData.footer_content, newLinesheetOrientation);
      }
      state.linesheetData.settings.orientation = newLinesheetOrientation;
      state.isEditorLoading = false;
    },
    updateContent: (state, action) => {
      const { value, type } = action.payload;
      const content = getSelectedContent(state);
      if (content) {
        content.text_content[type].value = value;
      }
      state.isEditing = false;
    },
    setActiveAction: (state, action) => {
      state.activeAction = action.payload;
    },
    addTextDiv: (state, action) => {
      const { x, y, text, pageId } = action.payload;
      state.activeAction = 'pointer';
      const page = state.linesheetData.pages.find((p) => p.unique_page_id === pageId);
      if (page) {
        if (!page.content) {
          page.content = [];
        }
        const unique_content_id = generateId();
        page.content.push({
          unique_content_id,
          type: 'text',
          text_content: {
            html: {
              value: text,
              settings: { ...fontSettings('left'), isEditing: true },
            },
          },
          settings: contentSettings(172, 60, x, y),
        });
        state.selectedContentId = unique_content_id;
      }
      state.isEditing = true;
    },
    setContentIsOverflow: (state, action) => {
      const { pageIdx, contentId, isOverflow } = action.payload;
      const page = state.linesheetData.pages[pageIdx];
      if (page && page.content) {
        const contentIndex = page.content.findIndex((c) => c.unique_content_id === contentId);
        if (contentIndex !== -1) {
          page.content[contentIndex].settings = {
            ...page.content[contentIndex].settings,
            isOverflow,
          };
          const isNotFormatted = page.content.some((item) => item.settings.isOverflow);
          page.settings.isNotFormatted = isNotFormatted;
        }
      }
    },
    updateZoomLevel: (state, action) => {
      const { zoomType } = action.payload;
      switch (zoomType) {
        case 'zoom-in':
          if (state.linesheetData.settings.zoomLevel < 2)
            state.linesheetData.settings.zoomLevel += 0.2;
          return;
        case 'zoom-out':
          if (state.linesheetData.settings.zoomLevel > 0.5)
            state.linesheetData.settings.zoomLevel -= 0.2;
          return;
        case 'zoom-reset':
          state.linesheetData.settings.zoomLevel = 0.8;
          return;
        default:
          state.linesheetData.settings.zoomLevel = 0.8;
          return;
      }
    },
    addImage: (state, action) => {
      const { imageUrl, x, y, pageId, width, height } = action.payload;
      const page = state.linesheetData.pages.find((p) => p.unique_page_id === pageId);
      if (page) {
        if (!page.content) {
          page.content = [];
        }
        page.content.push({
          unique_content_id: generateId(),
          type: 'image',
          image_content: {
            url: imageUrl,
            image_index: 1,
            number_of_images: 1,
            product_images: [],
            settings: imageSettings(200, 100),
          },
          settings: contentSettings(width, height, x, y),
        });
      }
    },
    setInPreview: (state, action) => {
      state.inPreview = action.payload;
    },
    setInGlobalSettingsMode: (state, action) => {
      state.inGlobalSettingsMode = action.payload;
    },
    setSelectedPage: (state, action) => {
      state.selectedPageId = action.payload.pageId;
      state.selectedPageIndex = action.payload.pageIdx;
    },
    addPageAt: (state, action) => {
      const index = action.payload;
      const page = {
        unique_page_id: generateId(),
        settings: pageSettings(state),
        isFormatted: true,
        type: 'custom',
      };
      state.linesheetData.pages.splice(index, 0, page);
      // Update the pageno of all pages
      updatePagesPageNos(state);
    },
    addPageAfterLast: (state) => {
      addEmptyPage(state);
      // Update the pageno of all pages
      updatePagesPageNos(state);
    },
    deletePages: (state) => {
      state.isEditorLoading = true;
      state.linesheetData.pages = [];
      state.isEditorLoading = false;
    },
    updateFontSettings: (state, action) => {
      const content = getSelectedContent(state);
      const { key, value } = action.payload;

      if (content) {
        updateSettings(content, key, value);
      }
    },
    updateLinesheetFontSettings: (state, action) => {
      const { key, value } = action.payload;
      state.isEditorLoading = true;
      const updatePageContent = (updateFn) => {
        state.linesheetData.pages.forEach((page) => {
          if (page.settings.locked) return;

          page.content?.forEach((content) => {
            updateFn(content);
          });
        });
      };

      const updates = {
        font: (content) => {
          updateSettings(content, 'font', value);
        },
        size: (content) => {
          updateSettings(content, 'size', value);
        },
        underline: (content) => {
          updateSettings(content, 'underline', null);
        },
        italic: (content) => {
          updateSettings(content, 'italic', null);
        },
        color: (content) => {
          updateSettings(content, 'color', value);
        },
        align: (content) => {
          updateSettings(content, 'align', value);
        },
        bold: (content) => {
          updateSettings(content, 'bold', null);
        },
        productBackground: (content) => {
          content.settings.backgroundColor = backgroundWithOpacity(value, content.settings.opacity);
          state.linesheetData.settings.productsBackgroundColor = content.settings.backgroundColor;
        },
        productOpacity: (content) => {
          content.settings.opacity = value;
          content.settings.backgroundColor = backgroundWithOpacity(
            content.settings.backgroundColor,
            content.settings.opacity,
          );
          state.linesheetData.settings.productsOpacity = value;
        },
      };

      const updatePageSettings = (updateFn) => {
        state.linesheetData.pages.forEach((page) => {
          if (page.settings.locked) return;

          updateFn(page);
        });
      };

      const pageUpdates = {
        pageBackground: (page) => {
          page.settings.background = backgroundWithOpacity(value, page.settings.opacity);
        },
        pageOpacity: (page) => {
          page.settings.opacity = value;
          page.settings.background = backgroundWithOpacity(
            page.settings.background,
            page.settings.opacity,
          );
        },
      };

      if (updates[key]) {
        updatePageContent(updates[key]);
        if (['font', 'size', 'color'].includes(key)) {
          state.linesheetData.settings[key] = value;
        }
      } else if (pageUpdates[key]) {
        updatePageSettings(pageUpdates[key]);
        state.linesheetData.settings[key] = value;
      }

      state.isEditorLoading = false;
    },
    setSelectedContentId: (state, action) => {
      state.selectedContentId = action.payload;
    },
    deleteContent: (state) => {
      const selectedPageIndex = state.selectedPageIndex;
      state.linesheetData.pages[selectedPageIndex].content = [];
    },
    addPageBefore: (state) => {
      const selectedPageIndex = state.selectedPageIndex;
      const page = {
        unique_page_id: generateId(),
        settings: pageSettings(state),
        isFormatted: true,
        type: 'custom',
      };
      // const index = selectedPageIndex > 0 ? selectedPageIndex - 1 : 0;
      state.linesheetData.pages.splice(selectedPageIndex, 0, page);
      // Update the pageno of all pages
      updatePagesPageNos(state);
    },
    addPageAfter: (state) => {
      const selectedPageIndex = state.selectedPageIndex;
      const page = {
        unique_page_id: generateId(),
        settings: pageSettings(state),
        isFormatted: true,
        type: 'custom',
      };
      state.linesheetData.pages.splice(selectedPageIndex + 1, 0, page);
      // Update the pageno of all pages
      updatePagesPageNos(state);
    },
    duplicatePage: (state) => {
      const selectedPageIndex = state.selectedPageIndex;
      const originalPage = state.linesheetData.pages[selectedPageIndex];
      const page = {
        ...originalPage,
        unique_page_id: generateId(),
        content: originalPage.content.map((content) => ({
          ...content,
          unique_content_id: generateId(),
        })),
      };
      state.linesheetData.pages.splice(selectedPageIndex + 1, 0, page);
      // Update the pageno of all pages
      updatePagesPageNos(state);
    },
    lockPage: (state) => {
      const selectedPageIndex = state.selectedPageIndex;
      state.linesheetData.pages[selectedPageIndex].settings.locked = true;
      const allPagesLocked = state.linesheetData.pages.every((page) => page.settings.locked);
      state.linesheetData.settings.allPageLocked = allPagesLocked;
    },
    unlockPage: (state) => {
      const selectedPageIndex = state.selectedPageIndex;
      state.linesheetData.pages[selectedPageIndex].settings.locked = false;
      state.linesheetData.settings.allPageLocked = false;
    },
    deletePage: (state) => {
      const selectedPageIndex = state.selectedPageIndex;
      state.linesheetData.pages.splice(selectedPageIndex, 1);
      // Update the pageno of all pages
      updatePagesPageNos(state);
    },
    updateImageSettings: (state, action) => {
      if (['pageColor', 'pageOpacity'].includes(action.payload.key)) {
        const { linesheetData, selectedPageIndex } = state;
        const page = linesheetData.pages[selectedPageIndex];
        if (page) {
          switch (action.payload.key) {
            case 'pageColor':
              page.settings.background = backgroundWithOpacity(
                action.payload.value,
                page.settings.opacity,
              );
              break;
            case 'pageOpacity':
              page.settings.opacity = action.payload.value;
              page.settings.background = backgroundWithOpacity(
                page.settings.background,
                page.settings.opacity,
              );
              break;
            default:
              break;
          }
        }
        return;
      }
      const content = getSelectedContent(state);
      if (content) {
        switch (action.payload.key) {
          case 'color':
            content.settings.backgroundColor = backgroundWithOpacity(
              action.payload.value,
              content.settings.opacity,
            );
            break;
          case 'opacity':
            content.settings.opacity = action.payload.value;
            content.settings.backgroundColor = backgroundWithOpacity(
              content.settings.backgroundColor,
              content.settings.opacity,
            );
            break;
          case 'size':
            const val = action.payload.value;
            if (val === 'fill') {
              content.image_content.settings.size = 'cover';
            }
            if (val === 'fit') {
              content.image_content.settings.size = 'contain';
            }
            if (val === 'stretch') {
              content.image_content.settings.size = '';
            }
            if (val === 'enlarge') {
              console.log('enlarge:custom');
            }
            if (val === 'ungroup-product') {
              unGroupProduct(state, content);
            }
            break;

          case 'position':
            const pval = action.payload.value;

            // Toggle the vertical flip state
            if (pval === 'flip-vertical') {
              content.image_content.settings.flipVertical =
                !content.image_content.settings.flipVertical;
              const verticalScale = content.image_content.settings.flipVertical ? -1 : 1;
              content.image_content.settings.transform = `scale(${
                content.image_content.settings.flipHorizontal ? -1 : 1
              }, ${verticalScale})`;
              content.image_content.settings.WebkitTransform =
                content.image_content.settings.transform;
              content.image_content.settings.MozTransform =
                content.image_content.settings.transform;
            }

            // Toggle the horizontal flip state
            if (pval === 'flip-horizontal') {
              content.image_content.settings.flipHorizontal =
                !content.image_content.settings.flipHorizontal;
              const horizontalScale = content.image_content.settings.flipHorizontal ? -1 : 1;
              content.image_content.settings.transform = `scale(${horizontalScale}, ${
                content.image_content.settings.flipVertical ? -1 : 1
              })`;
              content.image_content.settings.WebkitTransform =
                content.image_content.settings.transform;
              content.image_content.settings.MozTransform =
                content.image_content.settings.transform;
            }
            break;

          default:
            break;
        }
      }
    },
    addTemplateId: (state, action) => {
      state.selectedTemplateId = action.payload;
    },
    addProductImageIndex: (state, action) => {
      const content = getSelectedContent(state);

      if (content) {
        content.image_content.image_index = action.payload;
      }
    },
    setContentBodyStyle: (state, action) => {
      const { width, height, top, left } = action.payload;
      const content = getSelectedContent(state);
      if (content) {
        if (
          content.type === 'image' ||
          (content.hasOwnProperty('image_content') && !content.hasOwnProperty('text_content'))
        ) {
          content.settings.width = width;
          content.settings.height = height;
          content.settings.left = left;
          content.settings.top = top;
          if (content.image_content && content.image_content.settings) {
            content.image_content.settings.height = height;
          }
        } else {
          content.settings = {
            ...content.settings,
            width,
            height,
            left,
            top,
          };
          if (content.image_content) {
            const newImageHeght =
              content.image_content.settings.imageContentSizePercentage * height;
            content.image_content.settings.height = newImageHeght;
          }
        }
      }
    },
    setProductArrangement: (state, action) => {
      const content = getSelectedContent(state);
      if (content) {
        content.settings.productArrangment = action.payload;
      }
    },
    setProductSizing: (state, action) => {
      const { type, value } = action.payload;
      const content = getSelectedContent(state);
      if (content) {
        const template = globals?.pageTemplates[state.selectedTemplateId];
        const [gridX, gridY] = template.gridSize;
        const [contentHeight, imageHeight, contentWidth] = getPageDimensions(
          state.linesheetData.settings.orientation,
          gridX,
          gridY,
        );
        const updatedContentWidth = contentWidth - 22;
        switch (type) {
          case 'productSizeWidth':
            content.settings.width = percentageToPixels(value, updatedContentWidth);
            content.settings.widthPercentage = value;
            break;
          case 'productSizeHeight':
            content.settings.height = percentageToPixels(value, contentHeight);
            content.settings.heightPercentage = value;
            break;
          case 'productSizeImage':
            content.image_content.settings.height = percentageToPixels(value, imageHeight);
            content.image_content.settings.imageSizePercentage = value;
            break;
          case 'productSizeSpace':
            content.settings.spaceBetween = value;
            break;
          default:
            break;
        }
      }
    },
    realign: (state) => {
      state.isEditorLoading = true;
      const selectedPageIndex = state.selectedPageIndex;
      if (selectedPageIndex !== null) {
        const page = state.linesheetData.pages[selectedPageIndex];
        setPageAlignment(page);
      }
      state.isEditorLoading = false;
    },
    setTemplateModalVisibility: (state, action) => {
      state.showTemplateModal = action.payload.show;
      if (action.payload?.isChangeTemplate) {
        const { isChangeTemplate } = action.payload;
        state.isChangeTemplate = isChangeTemplate;
      }
      if (action.payload?.changeLinesheetTemplate) {
        const { changeLinesheetTemplate } = action.payload;
        state.changeLinesheetTemplate = changeLinesheetTemplate;
      }
    },
    changeTemplate: (state, action) => {
      const { template } = action.payload;
      const selectedPageIndex = state.selectedPageIndex;
      if (selectedPageIndex !== null) {
        const page = state.linesheetData.pages[selectedPageIndex];
        if (page) {
          page.settings.template = template;
          setPageAlignment(page);
        }
      }
      state.isChangeTemplate = false;
    },
    setShowConfirmTemplateModal: (state, action) => {
      state.showConfirmTemplateModal = !state.showConfirmTemplateModal;
      if (action.payload) {
        state.selectedTemplateId = action.payload.templateId;
      }
    },
    setShowDownloadConfirmationModal: (state) => {
      state.showDownloadConfirmationModal = !state.showDownloadConfirmationModal;
    },
    changeTemplateWithAction: (state, action) => {
      state.isEditorLoading = true;
      const { tempAction } = action.payload;
      const { selectedPageIndex, selectedTemplateId } = state;
      state.isChangeTemplate = false;

      if (selectedPageIndex >= 0) {
        const page = state.linesheetData.pages[selectedPageIndex];
        if (page) {
          const template = selectedTemplateId
            ? globals.pageTemplates[selectedTemplateId]
            : page.settings.template;
          if (template) {
            page.settings.template = template;
            const [gridX, gridY] = template.gridSize;
            const batchSize = gridX * gridY;

            let contents;

            switch (tempAction) {
              case 'add-pages':
                contents = createBatches(page.content, batchSize);
                contents.forEach((contentBatch, index) => {
                  let newPage;
                  if (index === 0) {
                    page.content = contentBatch;
                  } else {
                    newPage = {
                      ...page,
                      unique_page_id: generateId(),
                      content: contentBatch,
                    };
                    state.linesheetData.pages.splice(selectedPageIndex + index, 0, newPage);
                  }
                  setPageAlignment(index === 0 ? page : newPage);
                });
                // Update the pageno of all pages
                updatePagesPageNos(state);
                break;

              case 'remove-elements':
                page.content = page.content.slice(0, batchSize);
                setPageAlignment(page);
                break;

              case 'change-template':
                state.showTemplateModal = true;
                state.isChangeTemplate = true;
                break;

              default:
                break;
            }
          }
        }
      }

      state.showConfirmTemplateModal = false;
      state.isEditorLoading = false;
    },
    updateAllPageLocked: (state) => {
      state.isEditorLoading = true;
      state.linesheetData.pages.forEach((page) => {
        page.settings.locked = !state.linesheetData.settings.allPageLocked;
      });
      state.linesheetData.settings.allPageLocked = !state.linesheetData.settings.allPageLocked;
      state.isEditorLoading = false;
    },
    updateLinesheetTemplate: (state, action) => {
      const { template } = action.payload;

      const [rows, columns] = template.gridSize;
      const batchSize = rows * columns;

      // Flatten all product contents from each page and remove undefined values
      const allContents = state.linesheetData.pages
        .flatMap((page) => page.content)
        .filter((content) => content !== undefined);

      // Create batches of products based on the batch size
      const batchedContents = createBatches(allContents, batchSize);

      // Clear existing pages
      state.linesheetData.pages = [];

      updateLinesheetContentTemplate(state, batchedContents, template);

      // Update the pageno of all pages
      updatePagesPageNos(state);
      state.changeLinesheetTemplate = false;
    },
    moveContentForward: (state) => {
      moveContent(state, 'forward');
    },
    moveContentBackward: (state) => {
      moveContent(state, 'backward');
    },
    moveContentFront: (state) => {
      moveContent(state, 'front');
    },
    moveContentBack: (state) => {
      moveContent(state, 'back');
    },
    contentMenuActionHandler: (state, action) => {
      const { selectedPageIndex, selectedContentId } = state;
      const page = state.linesheetData.pages[selectedPageIndex];
      if (page) {
        const content = page.content.find(
          (content) => content.unique_content_id === selectedContentId,
        );
        if (content) {
          switch (action.payload) {
            case 'duplicate':
              const duplicateContent = {
                ...content,
                unique_content_id: generateId(),
              };
              page.content.push(duplicateContent);
              break;
            case 'ungroup':
              unGroupProduct(state, content);
              break;
            case 'delete':
              page.content = page.content.filter(
                (content) => content.unique_content_id !== selectedContentId,
              );
              break;
            case 'crop':
              content.image_content.settings.cropImage = !content.image_content.settings.cropImage;
              break;
            case 'changeImage':
              state.changeImage = !state.changeImage;
              break;
            case 'editText':
              if (content.type === 'product') {
                content.text_content.description.settings.isEditing =
                  !content.text_content.description.settings.isEditing;
              } else {
                content.text_content.html.settings.isEditing =
                  !content.text_content.html.settings.isEditing;
              }
              state.isEditing = true;
              break;
            default:
              break;
          }
        }
      }
    },
    setIsEditing: (state, action) => {
      const { isEditing, type } = action.payload;
      const content = getSelectedContent(state);
      if (content) {
        content.text_content[type].settings.isEditing = isEditing;
      }
      state.isEditing = isEditing;
    },
    setImageUrl: (state, action) => {
      const content = getSelectedContent(state);
      if (content) {
        content.image_content.url = action.payload;
      }
    },
    setSelectedProductIds: (state, action) => {
      state.selectedProductIds = action.payload;
    },
    addContentToPage: (state, action) => {
      state.isEditorLoading = true;
      const { left, top } = action.payload;
      const { selectedProductIds, selectedPageIndex, linesheetData } = state;
      const page = linesheetData.pages[selectedPageIndex];

      if (!page) return;

      const selectedProducts = linesheetData.products.filter((product) =>
        selectedProductIds.includes(product.id),
      );
      const [gridX, gridY] = page.settings.template.gridSize;
      const [contentHeight, imageHeight, contentWidth] = getPageDimensions(
        linesheetData.settings.orientation,
        gridX,
        gridY,
      );
      const imageContentSizePercentage = imageHeight / contentHeight;
      const contentData = selectedProducts.map((product) => ({
        unique_content_id: generateId(),
        type: 'product',
        product_id: product.id,
        settings: contentSettings(contentWidth, contentHeight, left, top),
        image_content: {
          url: product.product_image || '',
          image_index: 1,
          number_of_images: product.images?.length,
          product_images: product.images || [],
          settings: imageSettings(imageHeight, imageContentSizePercentage),
        },
        text_content: {
          title: {
            value: product.name,
            settings: fontSettings('center', '12px', true),
          },
          sku: {
            value: product.productNumber,
            settings: fontSettings(),
          },
          description: {
            value: product.description,
            settings: fontSettings('center', '12px'),
          },
          wholesalePrice: {
            value: `WS: $${product.wholesalePrice}`,
            settings: fontSettings(),
          },
          retailPrice: {
            value: `MSRP:$ ${product.retailPrice}`,
            settings: fontSettings(),
          },
        },
      }));

      page.content = [...(page.content || []), ...contentData];

      const totalContentAllowed = gridX * gridY;
      const currentContent = page.content.length;

      if (currentContent > totalContentAllowed) {
        state.showConfirmTemplateModal = true;
      } else {
        setPageAlignment(page);
      }
      state.isEditorLoading = false;
    },
    redo: (state) => {
      state.isEditorLoading = true;
      if (state.futureLinesheetData.length > 0) {
        const nextState = state.futureLinesheetData.pop();
        state.previousLinesheetData.push({
          settings: state.linesheetData.settings,
          pages: state.linesheetData.pages,
        });
        state.linesheetData.settings = nextState.settings || {};
        state.linesheetData.pages = nextState.pages || [];
      }
      state.isEditorLoading = false;
    },
    undo: (state) => {
      state.isEditorLoading = true;
      if (state.previousLinesheetData.length > 0) {
        const previousState = state.previousLinesheetData.pop();
        state.futureLinesheetData.push({
          settings: state.linesheetData.settings,
          pages: state.linesheetData.pages,
        });
        state.linesheetData.settings = previousState.settings || {};
        state.linesheetData.pages = previousState.pages || [];
      }
      state.isEditorLoading = false;
    },
    setIsEditorLoading: (state, action) => {
      state.isEditorLoading = action.payload.isLoading;
    },
    unsetSelection: (state) => {
      state.selectedContentId = '';
      state.selectedPageId = '';
      state.selectedPageIndex = null;
    },
    setOrderedPages: (state, action) => {
      state.linesheetData.pages = action.payload;
    },
    applyGlobalSettings: (state, action) => {
      const { products } = action.payload;
      state.inGlobalSettingsMode = false;
      const pages = state.linesheetData.pages;
      state.isEditorLoading = true;

      const applyStyling = (content, field, styling) => {
        if (styling.settings.show !== null && styling.settings.show === 'hide') {
          content.text_content[field].settings.show = false;
          return;
        }
        if (styling.settings.show !== null && styling.settings.show === 'show') {
          content.text_content[field].settings.show = true;
        }
        if (styling.value !== '' && styling.value !== null) {
          content.text_content[field].value = styling.value;
        }
        content.text_content[field].settings = {
          ...content.text_content[field].settings,
          font: styling.settings.font
            ? styling.settings.font
            : content.text_content[field].settings.font,
          size: styling.settings.size
            ? styling.settings.size
            : content.text_content[field].settings.size,
          color: styling.settings.color,
          underline: styling.settings.underline,
          italic: styling.settings.italic,
          align: styling.settings.align,
          bold: styling.settings.bold,
        };
      };

      products.forEach((product) => {
        let page = pages[product.pageIndex];
        let content = page.content[product.contentIndex];

        applyStyling(content, 'title', product.title);
        applyStyling(content, 'sku', product.sku);
        applyStyling(content, 'description', product.description);
        applyStyling(content, 'wholesalePrice', product.wholesalePrice);
        applyStyling(content, 'retailPrice', product.retailPrice);
      });

      state.isEditorLoading = false;
    },
    setAddingHeaderFooterMode: (state, action) => {
      const { show, isHeader, isFooter } = action.payload;
      state.addingHeaderFooterMode = show;
      state.isAddingHeader = isHeader;
      state.isAddingFooter = isFooter;
    },
    addHeaderFooterContent: (state, action) => {
      addOrUpdateContent(state, action);
    },
    updateHeaderFooterContent: (state, action) => {
      addOrUpdateContent(state, action, true);
    },
    deleteHeaderFooterContent: (state, action) => {
      const { isHeader } = action.payload;
      const contentType = isHeader ? 'header_content' : 'footer_content';
      state.linesheetData[contentType] = null;
    },
    updateHeaderSettings: (state, action) => {
      updateHeaderFooterContentSettings(state, action, 'header_content');
    },
    updateFooterSettings: (state, action) => {
      updateHeaderFooterContentSettings(state, action, 'footer_content');
    },
    setHeaderFooterPosition: (state, action) => {
      const { left, top, isHeader, isImage } = action.payload;

      const contentType = isHeader ? 'header_content' : 'footer_content';
      const target = isImage ? 'image' : 'description';

      state.linesheetData[contentType][target].settings.top = top;
      state.linesheetData[contentType][target].settings.left = left;
    },
  },
});

export const {
  saveState,
  setLinesheet,
  resetEditorStates,
  setLinesheetProducts,
  addPage,
  rotatePage,
  setActiveAction,
  addTextDiv,
  updateContent,
  setContentIsOverflow,
  updateZoomLevel,
  addImage,
  setInPreview,
  setInGlobalSettingsMode,
  setSelectedPage,
  addPageAt,
  addPageAfterLast,
  deletePages,
  updateFontSettings,
  setSelectedContentId,
  deleteContent,
  addPageBefore,
  addPageAfter,
  duplicatePage,
  lockPage,
  unlockPage,
  deletePage,
  updateImageSettings,
  addTemplateId,
  addProductImageIndex,
  setContentBodyStyle,
  setProductArrangement,
  setProductSizing,
  realign,
  setTemplateModalVisibility,
  changeTemplate,
  setShowConfirmTemplateModal,
  setShowDownloadConfirmationModal,
  changeTemplateWithAction,
  updateLinesheetFontSettings,
  updateAllPageLocked,
  updateLinesheetTemplate,
  moveContentForward,
  moveContentBackward,
  contentMenuActionHandler,
  setIsEditing,
  moveContentFront,
  moveContentBack,
  setImageUrl,
  setSelectedProductIds,
  addContentToPage,
  redo,
  undo,
  setIsEditorLoading,
  unsetSelection,
  setOrderedPages,
  applyGlobalSettings,
  setAddingHeaderFooterMode,
  addHeaderFooterContent,
  updateHeaderFooterContent,
  updateHeaderSettings,
  updateFooterSettings,
  setHeaderFooterPosition,
  deleteHeaderFooterContent,
} = editorSlice.actions;

export default editorSlice.reducer;
