import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';

import {
  faExpandArrowsAlt,
  faCompressArrowsAlt,
  faArrowsAltH,
  faUnlink,
} from '@fortawesome/free-solid-svg-icons';

import { updateImageSettings } from 'slices/editorSlice';
import enlargeIcon from 'assets/images/enlarge-icon.svg';

const EditorImageSizingToolsComponent = () => {
  const selectedTempId = useSelector((state) => state.editor.selectedTemplateId);

  const dispatch = useDispatch();
  const imageObjectEditorClickHandler = (val) => {
    if (val === 'enlarge' && selectedTempId !== 'blanktemplate') {
      message.info('This functionality will only work on custom page');
    } else {
      dispatch(updateImageSettings({ key: 'size', value: val }));
    }
  };
  const ImageSizingTools = [
    {
      name: 'fill',
      tooltip: 'Image Fill',
      icon: faExpandArrowsAlt,
      disabled: false,
      action: imageObjectEditorClickHandler,
    },
    {
      name: 'fit',
      tooltip: 'Image Fit',
      icon: faCompressArrowsAlt,
      disabled: false,
      action: imageObjectEditorClickHandler,
    },
    {
      name: 'stretch',
      tooltip: 'Image Stretch',
      icon: faArrowsAltH,
      disabled: false,
      action: imageObjectEditorClickHandler,
    },
    {
      name: 'enlarge',
      tooltip: 'Enlarge',
      icon: '',
      image: enlargeIcon,
      disabled: false,
      action: imageObjectEditorClickHandler,
    },
    {
      name: 'ungroup-product',
      tooltip: 'Ungroup Product',
      icon: faUnlink,
      disabled: false,
      action: imageObjectEditorClickHandler,
    },
  ];
  return ImageSizingTools;
};

export default EditorImageSizingToolsComponent;
