import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { rotatePage, setActiveAction, updateZoomLevel, redo, undo } from 'slices/editorSlice';
import {
  faArrowPointer,
  faICursor,
  faImage,
  faSync,
  faSearchMinus,
  faSearchPlus,
  faUndo,
  faRedo,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { StyledButton, StyledDropdown, StyledFontAwesomeIcon } from './styles';

const ListEditorToolsComponent = () => {
  const dispatch = useDispatch();
  const { previousLinesheetData, futureLinesheetData } = useSelector((state) => state.editor);

  const actionBtnClickHandler = (action) => {
    switch (action) {
      case 'text':
      case 'pointer':
      case 'image':
        dispatch(setActiveAction(action));
        break;
      // Add other cases if necessary
      default:
        console.log('Unhandled action:', action);
    }
  };

  const rotateBtnClickHandler = () => {
    dispatch(rotatePage());
  };

  const zoomBtnClickHandler = (action) => {
    dispatch(updateZoomLevel({ zoomType: action }));
  };

  const items = [
    {
      label: 'Reset zoom',
      key: '0',
      onClick: () => zoomBtnClickHandler('zoom-reset'),
    },
    {
      label: 'Zoom in',
      key: '1',
      onClick: () => zoomBtnClickHandler('zoom-in'),
    },
    {
      label: 'Zoom out',
      key: '2',
      onClick: () => zoomBtnClickHandler('zoom-out'),
    },
  ];

  const tools = [
    {
      name: 'pointer',
      tooltip: 'Pointer',
      icon: faArrowPointer,
      disabled: false,
      action: actionBtnClickHandler,
    },
    {
      name: 'text',
      tooltip: 'Add Text',
      icon: faICursor,
      disabled: false,
      action: (val) => actionBtnClickHandler(val),
    },
    {
      name: 'image',
      tooltip: 'Add Image',
      icon: faImage,
      disabled: false,
      action: (val) => actionBtnClickHandler(val),
    },
    {
      name: 'rotate',
      tooltip: 'Rotate',
      icon: faSync,
      disabled: false,
      action: rotateBtnClickHandler,
    },
    {
      name: 'zoom-out',
      tooltip: 'Zoom Out',
      icon: faSearchMinus,
      disabled: false,
      action: zoomBtnClickHandler,
    },
    {
      name: 'zoom',
      tooltip: '',
      icon: null,
      disabled: false,
      action: null,
      dropdown: (
        <StyledDropdown
          menu={{
            items,
          }}
          trigger={['click']}
        >
          <StyledButton>
            Zoom <StyledFontAwesomeIcon icon={faChevronDown} />
          </StyledButton>
        </StyledDropdown>
      ),
    },
    {
      name: 'zoom-in',
      tooltip: 'Zoom In',
      icon: faSearchPlus,
      disabled: false,
      action: zoomBtnClickHandler,
    },
    {
      name: 'undo',
      tooltip: 'Undo',
      icon: faUndo,
      disabled: previousLinesheetData.length === 0,
      action: () => dispatch(undo()),
    },
    {
      name: 'redo',
      tooltip: 'Redo',
      icon: faRedo,
      disabled: futureLinesheetData.length === 0,
      action: () => dispatch(redo()),
    },
  ];

  return tools;
};

export default ListEditorToolsComponent;
