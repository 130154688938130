import React from 'react';
import Image from './Image';
import Text from './Text';
import { MainContainer } from './styles';

const HeaderFooter = ({ content, pageWidth, isHeader = false }) => {
  return (
    <MainContainer className={`${isHeader ? 'header' : 'footer'}`}>
      {content.image && <Image data={content.image} pageWidth={pageWidth} isHeader={isHeader} />}
      {content.description && (
        <Text data={content.description} pageWidth={pageWidth} isHeader={isHeader} />
      )}
    </MainContainer>
  );
};

export default HeaderFooter;
