import { Modal } from 'antd';
import styled from 'styled-components';

export const MainLayout = styled.div`
  position: relative;
  padding: 48px 55px;
  margin-top: 10px;
`;

export const StyledModal = styled(Modal)`
  font-family: Muli;
  .ant-btn{
    &:hover {
      color: #fff !important;
      background-color: #028b67 !important;
      border-color: #017f5e !important;
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 189, 150, 0.5) !important;
      color: #fff !important;
      background-color: #028b67 !important;
      border-color: #017f5e !important;
    }
  }
  .ant-btn-primary {
    background: #07af7c !important;
  }
`;
