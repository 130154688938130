import styled, { keyframes } from 'styled-components';
import SignUpBackground from '../../assets/images/register-bg.png';
import { Link } from 'react-router-dom';
import AntForm from 'uiKits/Form';
import { AntInput } from 'uiKits/Input';
import AntButton from 'uiKits/Button';

export const BackgroundLayout = styled.div`
  background-image: url(${SignUpBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 100%;
  height: 100vh;
`;

export const MainLayout = styled.div`
  position: fixed;
  display: block;
  top: 0;
  z-index: 1050;
  right: 0;
  margin: 0;
  width: auto;
  left: unset;

  @media all and (max-width: 767px) {
    width: 100%;
  }
`;

export const OuterLayout = styled.div`
  margin: 0;
  width: 21.145833333333332vw;

  @media all and (max-width: 1023px) {
    width: 30vw;
  }

  @media all and (max-width: 767px) {
    width: 100%;
  }
`;

const slideAndFadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(60px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const InnerLayout = styled.div`
  animation: ${slideAndFadeIn} 750ms ease-in-out both;
  min-height: 100vh;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;

  @media all and (max-width: 767px) {
    padding: 0 15px;
  }
`;

export const BodyContainer = styled.div`
  padding: 1rem 2.083333333333333vw;
`;

export const StyledHeading = styled.h2`
  margin-bottom: 25px;
  padding-left: 19px;
  line-height: 0.83;
  font-family: Muli;
  font-size: 1.875vw;
  font-weight: 600;
  color: #000;

  @media all and (max-width: 1023px) {
    font-size: 22px;
    padding-left: 0;
  }
`;

export const GoogleButtonContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border: 2px solid #d7d7d7;
  border-radius: 20px;
  background-color: #fff;
  margin: 0 0 8px;
  padding: 4px 0.8333333333333334vw;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;

  @media all and (max-width: 1023px) {
    font-size: 12px;
  }
`;

export const GoogleButtonIcon = styled.img`
  max-width: 1.3541666666666667vw;

  @media all and (max-width: 1023px) {
    max-width: 18px;
  }
`;

export const GoogleButtonText = styled.span`
  color: #000;
  font-family: Muli;
  font-size: 0.7291666666666666vw;
  font-weight: 600;
  line-height: 1.5;

  @media all and (max-width: 1023px) {
    font-size: 12px;
  }
`;

export const SignUpLabel = styled.p`
  text-align: center;
  font-family: Muli;
  font-size: 1vw;
  font-weight: 600;
  letter-spacing: 0.32px;
  color: #c3c3c3;
  margin: 0 auto;
  padding: 5px 0px;

  @media all and (max-width: 1023px) {
    font-size: 12px;
  }

  @media all and (max-width: 767px) {
    line-height: 10px;
  }
`;

export const StyledForm = styled(AntForm)`
  max-height: calc(100vh - 250px);
  overflow: auto;
  .ant-form-item {
    margin-bottom: 0.5rem;
  }
`;

export const StyledInput = styled(AntInput)``;

export const BottomButtonsContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  padding: 0 2.083333333333333vw;
  @media all and (max-width: 767px) {
    padding: 0 35px;
  }
`;

export const BottomButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  @media all and (max-width: 1400px) {
    margin-bottom: 0;
  }
`;

export const SignUpButton = styled(AntButton)`
  max-height: 100%;
  border-radius: 1.0416666666666665vw;
  background-color: #07af7c !important;
  font-family: Muli;
  font-size: 0.9375vw;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.96px;

  @media all and (max-width: 1023px) {
    font-size: 12px;
  }
`;

export const LoginLink = styled(Link)`
  color: #000;
  max-width: 40%;
  font-family: Muli;
  font-size: 0.8072916666666667vw;
  font-weight: 400;
  &:hover {
    color: #000;
  }

  @media all and (max-width: 1023px) {
    font-size: 12px;
  }

  media all and (max-width: 767px) {
    font-size: 16px;
  }
`;

export const StyledImage = styled.img`
  margin-left: 5px;
`;
