import styled from 'styled-components';
import { Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledCol = styled(Col)`
  position: relative;
  width: 100%;
  padding: 28px 32px;
  border-radius: 10px;
  border: 2px solid transparent;
  margin-bottom: 30px;
  &.connected {
    background-color: #fff;
    border-color: #02b183;
  }
  @media all and (max-width: 1300px) {
    padding: 15px 10px;
  }
`;

export const StyledApiImageContainer = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const StyledLogo = styled.img`
  max-height: 100%;
  max-width: 80%;
  height: auto;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  padding-left: 10px;
  cursor: pointer;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const StyledButton = styled.button`
  font-family: Muli;
  font-weight: 900;
  font-size: 10px;
  border: none;
  cursor: pointer;
  border-radius: 10000px;
  padding: 0.3125vw 0.8333333333333334vw;
  color: #ffffff;
  &:hover {
    color: #ffffff;
    background-color: #028b67;
    border-color: #017f5e;
  }
`;

export const StyledConnectButton = styled(StyledButton)`
  color: #02b183;
  background: #ffffff;
  border: 2px solid #02b183;
  &:hover {
    background-color: #02b183;
    border-color: #02b183;
  }
`;

export const StyledDisconnectButton = styled(StyledConnectButton)``;

export const StyledImportButton = styled(StyledButton)`
  color: #fff;
  background-color: #02b183;
  border-color: #02b183;
  margin-left: 0.5rem !important;
`;

export const StyledWatchButton = styled(StyledButton)`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-color: #02b183;
  border-color: #02b183;
  margin-right: 5px;
  &:hover {
    background-color: #028b67;
    border-color: #017f5e;
  }
`;
export const StyledEditButton = styled.button`
  font-size: 12px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: normal;
  font-family: Muli;
  text-align: left;
  color: #000000;
  text-decoration: underline;
  position: absolute;
  bottom: 4px;
  right: 8px;
  background: none;
  border: none;
  cursor: pointer;
`;
