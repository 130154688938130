import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Layout from '../../layout';
import cache from 'cache';
import { useSelector } from 'react-redux';

const PrivateRoutes = () => {
  const location = useLocation();
  const state = useSelector((state) => state.auth);
  const user = JSON.parse(cache.getItem('user'));

  const wrappedRoute = location.pathname.includes('/linesheet-editor') ? false : true;

  return state.user || user ? (
    wrappedRoute ? (
      <Layout isPrivate={true}>
        <Outlet />
      </Layout>
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate to="/login" />
  );
};
export default PrivateRoutes;
