import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { InfiniteContainer, StyledSpin } from './styles';

const CustomInfiniteScroll = ({children, hasMore, loadMore, limit, showSpin }) => {
  return (
    <InfiniteContainer>
      <InfiniteScroll
        initialLoad={false}
        loadMore={loadMore}
        hasMore={hasMore}
        threshold={limit}
        useWindow={false}
        loader={showSpin && <StyledSpin />}
      >
        {children}
      </InfiniteScroll>
    </InfiniteContainer>
  );
};

export default CustomInfiniteScroll;
