import { useDispatch } from 'react-redux';
import {
  contentMenuActionHandler,
  moveContentForward,
  moveContentBackward,
  moveContentFront,
  moveContentBack,
  updateImageSettings,
} from 'slices/editorSlice';

const ImageMenuOptions = () => {
  const dispatch = useDispatch();

  const options = [
    {
      label: 'Image',
      disabled: true,
    },
    {
      label: 'Choose Image',
      onClick: () => dispatch(contentMenuActionHandler('changeImage')),
      disabled: false,
    },
    {
      label: 'Duplicate',
      onClick: () => dispatch(contentMenuActionHandler('duplicate')),
      disabled: false,
    },
    {
      label: 'Fill',
      onClick: () => dispatch(updateImageSettings({ key: 'size', value: 'fill' })),
      disabled: false,
    },
    {
      label: 'Fit',
      onClick: () => dispatch(updateImageSettings({ key: 'size', value: 'fit' })),
      disabled: false,
    },
    {
      label: 'Stretch',
      onClick: () => dispatch(updateImageSettings({ key: 'size', value: 'stretch' })),
      disabled: false,
    },
    {
      type: 'divider',
    },
    {
      label: 'Bring Forward',
      onClick: () => dispatch(moveContentForward()),
      disabled: false,
    },
    {
      label: 'Bring to Front',
      onClick: () => dispatch(moveContentFront()),
    },
    {
      label: 'Send Backward',
      onClick: () => dispatch(moveContentBackward()),
    },
    {
      label: 'Send to Back',
      onClick: () => dispatch(moveContentBack()),
    },
    {
      type: 'divider',
    },
    {
      label: 'Delete Image',
      onClick: () => dispatch(contentMenuActionHandler('delete')),
    },
    {
      label: 'Crop Image',
      onClick: () => dispatch(contentMenuActionHandler('crop')),
    },
  ];

  return options;
};

export default ImageMenuOptions;
