import React, { useEffect, useState } from 'react';
import { Flex, Row, Col } from 'antd';
import Help from '../Common/NeedHelp';
import DashboardIntroModal from './DashboardIntro';
import { useShowIntroModalQuery } from 'api/authApi';
import { useGetLinesheetsQuery } from 'api/linesheetApi';
import FirstLinesheet from '../Dashboard/FirstLinesheet';
import AddProducts from '../Dashboard/AddProducts';
import RecentLinesheets from '../Dashboard/RecentLinesheets';
import EmailLinesheets from './EmailLinesheet';
import Loader from '../Common/Loader';
import { DashboardContainer, StyledRow, CreateLinesheetWrapper } from './styles';

const Dashboard = () => {
  const { data: popup, refetch, isLoading } = useShowIntroModalQuery();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(popup);
  }, [popup]);

  // Get Linesheets Query
  let queryObj = {
    searchFilter: '',
    sortBy: 'last_modified',
    currentPage: 1,
    pageSize: 4,
  };
  const { data: linesheetsData, isLoading: linesheetsLoading } = useGetLinesheetsQuery({
    ...queryObj,
  });

  return (
    <>
      <DashboardContainer className="container">
        {linesheetsData && linesheetsData.length > 0 ? (
          <>
            <Row>
              <RecentLinesheets
                linesheetsData={linesheetsData}
                linesheetsLoading={linesheetsLoading}
              />
            </Row>

            <StyledRow gutter={24}>
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <AddProducts />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Help />
              </Col>
            </StyledRow>

            <EmailLinesheets />
          </>
        ) : (
          <Row gutter={16}>
            <Flex justify="center" gap="middle" wrap="nowrap">
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <CreateLinesheetWrapper>
                  <Row>
                    <FirstLinesheet />
                  </Row>
                </CreateLinesheetWrapper>

                <AddProducts />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Help />
              </Col>
            </Flex>
            <Loader isLoading={isLoading} />
          </Row>
        )}
      </DashboardContainer>
      {!!showModal && (
        <DashboardIntroModal showModal={showModal} setShowModal={setShowModal} refetch={refetch} />
      )}
    </>
  );
};

export default Dashboard;
