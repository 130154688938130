import styled from 'styled-components';

export const PreviewContainer = styled.div`
  margin: 0 auto;
  padding: 0 25px;
`;

export const StyledRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const StyledCol = styled.div`
  height: ${(props) => props.height || 'auto'};
  position: relative;
  width: ${(props) => props.width || 'auto'};
`;
