import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import { updateImageSettings, updateLinesheetFontSettings } from 'slices/editorSlice';
import { StyledRow, StyledCol, StyledColorPicker, StyledLabel, StyledTooltip } from './styles';

const BackgroundColor = ({ title, initialColor }) => {
  const [open, setOpen] = useState(false);
  const [color, setColor] = useState(initialColor);
  const dispatch = useDispatch();

  useEffect(() => {
    setColor(initialColor);
  }, [initialColor]);

  const handleColorChange = (value) => {
    const colorValue = value.toRgbString();
    setColor(colorValue);
    switch (title) {
      case 'Page Background':
        dispatch(updateLinesheetFontSettings({ key: 'pageBackground', value: colorValue }));
        break;
      case "Product's Background":
        dispatch(updateLinesheetFontSettings({ key: 'productBackground', value: colorValue }));
        break;
      case 'Page Background Color':
        dispatch(updateImageSettings({ key: 'pageColor', value: colorValue }));
        break;
      default:
        dispatch(updateImageSettings({ key: 'color', value: colorValue }));
        break;
    }
  };

  return (
    <StyledRow justify="space-between" gap={10}>
      <StyledCol xs={24} xl={14}>
        <StyledLabel>{title}</StyledLabel>
      </StyledCol>
      <StyledCol xs={24} xl={8}>
        <StyledTooltip title="Background Color" placement="left">
          <StyledColorPicker
            value={color}
            open={open}
            onChange={handleColorChange}
            onOpenChange={setOpen}
            showText={() => (
              <DownOutlined
                rotate={open ? 180 : 0}
                style={{
                  color: `${color}`,
                  filter: 'invert(1)',
                }}
              />
            )}
          />
        </StyledTooltip>
      </StyledCol>
    </StyledRow>
  );
};

export default BackgroundColor;
