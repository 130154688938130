import React from 'react';
import styled from 'styled-components';

const HelpSub = styled.div`
  position: relative;
`;

const HelpFrame = styled.div`
  position: relative;
  max-width: 1086px;
  margin: 0 auto;

  &:after {
    display: block;
    clear: both;
    content: '';
  }
`;

const PageNavi = styled.ul`
  padding: 0 20px;
  margin: 0 0 35px;
  list-style: none;
  overflow: hidden;

  li {
    display: inline-block;
    vertical-align: top;
    position: relative;
    color: #52c78d;
    margin: 0 10px 0 0;

    a {
      font-family: Muli;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.32px;
      text-align: left;
      color: #52c78d;
    }
  }
`;

const Cursor = styled.span`
  cursor: pointer;
`;

const HelpPage = styled.div`
  position: relative;
  overflow: hidden;
  font-family: Muli;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.4px;
  color: #000;
  padding-left: 2rem;

  h2 {
    font-family: Muli;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    text-align: left;
    margin: 36px 0 18px 0;
  }

  p {
    margin: 5px 0;
  }

  h3 {
    font-family: Muli;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.4px;
    margin: 7px 0 15px;
    text-decoration: underline;
  }
`;

const UnOrderedList = styled.ul`
  font-weight: 600;
  margin: 10px 0;
`;

const OrderedList = styled.ul`
  text-decoration: none;
  list-style: none;
  margin: 0 0 18px 0;

  span {
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px 0 0;
  }

  a {
    text-decoration: underline;
  }

  p {
    margin: 0;
  }

  h3 {
    margin: 9px 0 6px 0;
    font-family: Muli;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.4px;
    text-decoration: underline;
  }
`;

const Second = styled.div`
  font-family: Muli;
  margin: 0 0 15px 70px;

  p {
    margin: 5px 0;
  }
`;

const Centered = styled.div`
  h3 {
    text-align: center;
    font-size: 21px;
    font-family: Muli;
    font-weight: 600;
    letter-spacing: 0.4px;
    text-decoration: underline;
  }
`;

const UnorderedLists2 = styled.div`
  font-weight: normal;
`;

const GettingStarted = ({ onClick }) => {
  return (
    <div>
      <HelpSub>
        <HelpFrame>
          <PageNavi>
            <li>
              <Cursor onClick={() => onClick('')}>Help</Cursor>
            </li>
            <li>/</li>
            <li>
              <Cursor>Getting Started</Cursor>
            </li>
          </PageNavi>
          <HelpPage>
            <h2>1 How to add Products?</h2>
            <p>You can go to the Sync and add products by 3 ways</p>
            <UnOrderedList>
              <li>
                Go to Dashboard from the left menu panel and you can see Add Your Product section
                there.
              </li>
              <li>
                Go to Products from the left menu panel and click on the add/plus icon it’ll
                redirect you to the product sync page.
              </li>
              <li>
                Go to Sync from the left menu panel and it&#39;ll redirect you to the product sync
                page.
              </li>
            </UnOrderedList>
            <p>On the sync page there are three ways you can add products.</p>
            <h3>1.1 Add Products through API Sync:</h3>
            <OrderedList>
              <li>
                <span>1. </span> Click on the Connect button in the API section and it&#39;ll take
                you to the stores list.
              </li>
              <li>
                <span>2. </span> Click on the Connect button of the store which you want to connect.
              </li>
              <li>
                <span>3. </span> A pop up will appear where you give the required information and
                press Save.
              </li>
              <li>
                <span>4. </span> After saving the information Import button will appear in the box
                click that button it’ll take some time and show you the list of your store’s
                products.
              </li>
              <li>
                <span>5. </span> Select products you want to import and click the Import button on
                the top right.
              </li>
            </OrderedList>
            <Second>
              <h3>1.1.1 Shopify:</h3>
              <OrderedList>
                <li>
                  <span>1. </span>Go to your Shopify admin page and click Apps.
                </li>
                <li>
                  <span>2. </span>Click Manage Private Apps near the very bottom of the page.
                </li>
                <li>
                  <span>3. </span>Click Create a New Private App.
                </li>
                <li>
                  <span>4. </span>Fill out the form, entering “Linesheets” in the private app
                  section and adding a contact email address (the email address is so Shopify can
                  contact the developer if there’s an issue with the connection or performance of
                  the Linesheets app or in case of an API change)
                </li>
                <li>
                  <span>5. </span>In the Admin API section, select the areas of your store that you
                  want Linesheets to be able to access (If you want to use the Storefront API, then
                  you’ll need to put a checkmark where it says Allow this app to access your
                  storefront data using the Storefront API”)
                </li>
                <li>
                  <span>6. </span>If you click to allow Storefront API permissions, you’ll have the
                  option to select which types of data you want to share with the Linesheets app
                </li>
                <li>
                  <span>7. </span>Click Save
                </li>
                <li>
                  <span>8. </span>API Password will appear right after you save the app then copy
                  the password from the admin api.{' '}
                </li>
                <li>
                  <span>9. </span>
                  <b>Note:</b> select 2020-10 api versions of the app.
                </li>
              </OrderedList>
              <h3>1.1.2 PinnacleCart:</h3>
              <OrderedList>
                <li>
                  <span>1. </span> Type API in the search tab of your account.
                </li>
                <li>
                  <span>2. </span> Click API access in the search results.
                </li>
                <li>
                  <span>3. </span> Check the box to enable API.
                </li>
                <li>
                  <span>4. </span> You will be prompted to create a Username and Password.
                </li>
                <li>
                  <span>5. </span> To get a security token, click Generate.
                </li>
              </OrderedList>
              <h3>1.1.3 Etsy:</h3>
              <p>Endpoint: Enter the name of your store</p>
              <p>Api Key:</p>
              <OrderedList>
                <li>
                  <span>1. </span>Create a New App so that you can get an API key. Go to{' '}
                  <a href="https://www.etsy.com/developers/register">
                    https://www.etsy.com/developers/register
                  </a>
                  . Fill in the details as in the example below:
                </li>
                <OrderedList>
                  <li>
                    <span>a. </span> Application Name: Any Name
                  </li>
                  <li>
                    <span>b. </span> Describe your Application: Automatically imports content from
                    Etsy to my website.
                  </li>
                  <li>
                    <span>c. </span> Application Website: Your website’s URL, for example,
                    https://abc.com
                  </li>
                  <li>
                    <span>d. </span> What type of application are you building? Buyer Tools
                  </li>
                  <li>
                    <span>e. </span> Who will be the users of this application? The general public
                  </li>
                  <li>
                    <span>f. </span> Is your application commercial? No
                  </li>
                  <li>
                    <span>g. </span> Will your app do any of the following? No check/Check
                    none/Blank/Empty
                  </li>
                  <li>
                    <span>h. </span> Inventory Management compatibility: Tick Compatible with the
                    new Inventory Management API
                  </li>
                  <li>
                    <span>i. </span> Solve the Captcha
                  </li>
                  <li>
                    <span>j. </span> Click on Read Terms and Create App
                  </li>
                </OrderedList>
                <li>
                  <span>2. </span> Tick I have read and agree to the Etsy API Terms of Use and then
                  click on Create App
                </li>
                <li>
                  <span>3. </span> Once the App creation is successful, you will be sent to a page
                  containing the App Name as well as your Keystring and Shared Secret. Copy these
                  keys
                </li>
                <li>
                  <span>4. </span> Paste them into the appropriate fields in your WP Auto Content
                  settings. Click on Save All Changes once done
                </li>
                <li>
                  <span>5. </span> Your keys are saved to your developer account for future
                  reference. While logged in, go to{' '}
                  <a href=" https://www.etsy.com/developers/your-apps">
                    {' '}
                    https://www.etsy.com/developers/your-apps
                  </a>
                  . to revisit your keys.
                </li>
              </OrderedList>
              <h3>1.1.4 Bigcartel:</h3>
              <OrderedList>
                <li>
                  <span>1. </span> Endpoint: Account id.
                </li>
                <OrderedList>
                  <li> e.g. (winterclothing.bigcartel.com) winterclothing is account id.</li>
                </OrderedList>
              </OrderedList>
              <h3>1.1.5 Squarespace:</h3>
              <p>Location for the API key</p>
              <OrderedList>
                <li>
                  <span>1. </span> Go to the Home Menu, click Settings, then click Advanced
                </li>
                <li>
                  <span>2. </span> Click Developer API Keys
                </li>
                <li>
                  <span>3. </span> Click Generate Key
                </li>
                <li>
                  <span>4. </span> Under Permissions, check Orders, Forms, Inventory, or
                  Transactions.Forms are available for Business-level plans or higher. Orders,
                  Inventory, and Transactions are only available if you have the Commerce Advanced
                  plan.
                </li>
                <li>
                  <span>5. </span> Select the permissions for the API key
                </li>
                <li>
                  <span>6. </span> Click Next
                </li>
                <li>
                  <span>7. </span> Copy your unique API key
                </li>
              </OrderedList>
              <h3>1.1.6 Magento:</h3>

              <OrderedList>
                <li>
                  <span>1. </span> Go to the system>>integrations>>click Add new integrations Button
                </li>
                <li>
                  <span>2. </span> Fill the required fields,Name and password(of your store)
                </li>
                <li>
                  <span>3. </span> Go to Api section from left menu
                </li>
                <li>
                  <span>4. </span> Give Resource access (Custom/All)
                </li>
                <li>
                  <span>5. </span> click on save button
                </li>
                <li>
                  <span>6. </span> From the listing click Activate link >>Allow
                </li>
              </OrderedList>

              <h3>1.1.6 Magento:</h3>
              <OrderedList>
                <li>
                  <span>1. </span> Log in/sign-up to 3d-cart's dev portal
                </li>
                <li>
                  <span>2. </span> Click on Add New button
                </li>
                <li>
                  <span>3. </span> EnterApplication name Press create app button
                </li>
                <li>
                  <span>4. </span> Check the permissions for required modules to read and write
                </li>
                <li>
                  <span>5. </span> Copy Public key
                </li>
                <li>
                  <span>6. </span> Go to your store's admin panel Then go to module section Search
                  for Rest API
                </li>
                <li>
                  <span>7. </span> Click setting button click Add button from top right corner of
                  the page
                </li>
                <li>
                  <span>8. </span> Paste public key here (copied from the dev portal)
                </li>
                <li>
                  <span>9. </span> Click authorize button from the bottom of pop-up
                </li>
                <li>
                  <span>10. </span> Go to dev portal where you can see the token in the bottom Copy
                  Token and Private key
                </li>
              </OrderedList>

              <h3>1.1.8 Woo Commerce:</h3>
              <OrderedList>
                <li>
                  <span>1. </span> Go toWooCommerce > Settings > Advanced > REST API
                </li>
                <li>
                  <span>2. </span> Select Add Key You are taken to the Key Details screen.
                </li>
                <li>
                  <span>3. </span> Add a Description
                </li>
                <li>
                  <span>4. </span> Select the User you would like to generate a key for in the drop
                  down.
                </li>
                <li>
                  <span>5. </span> Select a level of access for this API key — Read access,
                  Writeaccess or Read/Write access.
                </li>
                <li>
                  <span>6. </span> Select Generate API Key, and WooCommerce creates API keys for
                  that user.
                </li>
              </OrderedList>
            </Second>

            <h3>1.2 Add Products through CSV upload:</h3>
            <OrderedList>
              <li>
                <span>1. </span> Click on the Upload button in the CSV section and it'll take you to
                the upload section.{' '}
              </li>
              <li>
                <span>2. </span> You can see store names there, select a store and browse its CSV
                file in the box or you can just drag and drop your file in the box.{' '}
              </li>
              <li>
                <span>3. </span> It’ll take a while depending on the number of the products, after
                uploading it’ll redirect you to the products table where you can see your uploaded
                products.{' '}
              </li>
            </OrderedList>
            <h3>1.3 Manually Add Products:</h3>
            <OrderedList>
              <li>
                <span>1. </span> Click on the Create button in the Manual section and it'll take you
                to the Add product page.{' '}
              </li>
              <li>
                <span>2. </span> Provide required information of the product.{' '}
              </li>
              <li>
                <span>3. </span> If you want to add variants, in the bottom you can see the add/plus
                icon click on it and provide variant information (you can add multiple variants by
                just clicking on add icon).{' '}
              </li>
              <li>
                <span>4. </span> Click on the Save button and if you want to add another product
                click Save and Create a new button on the top right.{' '}
              </li>
            </OrderedList>

            <h2>2 How to add contacts?</h2>
            <h3>2.1 Manually add contacts:</h3>
            <OrderedList>
              <li>
                <span>1. </span> Go to the contacts section from the left menu panel.{' '}
              </li>
              <li>
                <span>2. </span> Click on the add/plus icon which is the very first icon in the head
                section.{' '}
              </li>
              <li>
                <span>3. </span> A pop up will appear, you need to fill the fields and press the
                save button.{' '}
              </li>
            </OrderedList>

            <h3>2.1 Import contacts from Google:</h3>
            <OrderedList>
              <li>
                <span>1. </span> 1. Go to the contacts section from the left menu panel.{' '}
              </li>
              <li>
                <span>2. </span> 2. Click on the google icon in the head section.{' '}
              </li>
              <li>
                <span>3. </span> 3. A pop up will appear where you need to sign in google account.{' '}
              </li>
            </OrderedList>

            <h2>3 How to create a linesheet?</h2>

            <h3>3.1 Create Linesheets from Dashboard:</h3>
            <OrderedList>
              <li>
                <span>1. </span> Go to Dashboard from the left menu panel.{' '}
              </li>
              <li>
                <span>2. </span> Click the Create Linesheet button in the top section and it'll take
                you to the products table{' '}
              </li>
              <li>
                <span>3. </span> Select products you want to add in the linesheet and click Next
                button.
              </li>
              <li>
                <span>4. </span> Enter your linesheet name and press the Create button.{' '}
              </li>
              <li>
                <span>5. </span> Editor will appear on your screen.{' '}
              </li>
            </OrderedList>

            <h3>3.2 Create Linesheets from Products:</h3>
            <OrderedList>
              <li>
                <span>1. </span> Go to Products from the left menu panel.{' '}
              </li>
              <li>
                <span>2. </span> Click the Create Linesheet button in the top section and it'll take
                you to the products table.{' '}
              </li>
              <li>
                <span>3. </span> Select products you want to add in the linesheet and click the Next
                button.{' '}
              </li>
              <li>
                <span>4. </span> Enter your linesheet name and press the Create button.{' '}
              </li>
              <li>
                <span>5. </span> Editor will appear on your screen.{' '}
              </li>
            </OrderedList>
            <Centered>
              <h3> OR </h3>
            </Centered>

            <OrderedList>
              <li>
                <span>1. </span> Go to Products from the left menu panel.{' '}
              </li>
              <li>
                <span>2. </span> Select products and press the Create Linesheet button.{' '}
              </li>
              <li>
                <span>3. </span> Enter your linesheet name and press the Create button.{' '}
              </li>
              <li>
                <span>4. </span> Editor will appear on your screen.{' '}
              </li>
            </OrderedList>
            <h3>3.3 Create Linesheets from Categories:</h3>
            <OrderedList>
              <li>
                <span>1. </span> Go to Categories from the left menu panel.{' '}
              </li>
              <li>
                <span>2. </span> Click the Create Linesheet button in the top section and it'll take
                you to the products table.{' '}
              </li>
              <li>
                <span>3. </span> Select products you want to add in the linesheet and click Next
                button.{' '}
              </li>
              <li>
                <span>4. </span> Enter your linesheet name and press the Create button.{' '}
              </li>
              <li>
                <span>5. </span> Editor will appear on your screen.{' '}
              </li>
            </OrderedList>
            <Centered>
              <h3> OR </h3>
            </Centered>
            <OrderedList>
              <li>
                <span>1. </span> Go to Categories from the left menu panel.{' '}
              </li>
              <li>
                <span>2. </span> Select Categories and press Create Linesheet button all products
                will be selected which belong to these categories.{' '}
              </li>
              <li>
                <span>3. </span> Enter your linesheet name and press the Create button.{' '}
              </li>
              <li>
                <span>4. </span> Editor will appear on your screen.{' '}
              </li>
            </OrderedList>
            <h2>4 How to edit an existing linesheet?</h2>
            <h3>4.1 Edit from linesheets:</h3>
            <OrderedList>
              <li>
                <span>1. </span> Go to the Linesheets section from the left menu panel.{' '}
              </li>
              <li>
                <span>2. </span> Click on the edit icon from the listing.{' '}
              </li>
              <li>
                <span>3. </span> It will open a linesheet editor on your screen.{' '}
              </li>
              <li>
                <span>4. </span> You can also edit the linesheet by double click on it.{' '}
              </li>
            </OrderedList>
            <h3>4.2 Edit from dashboard:</h3>
            <OrderedList>
              <li>
                <span>1. </span> Go to Dashboard from the left menu panel.{' '}
              </li>
              <li>
                <span>2. </span> On the top, there is a section for recent linesheets.{' '}
              </li>
              <li>
                <span>3. </span> Click on the edit button of a specific linesheet.{' '}
              </li>
              <li>
                <span>4. </span> It will open a linesheet editor on your screen{' '}
              </li>
            </OrderedList>

            <h2>5 How to email linesheets?</h2>
            <h3>5.1 Send email from linesheets:</h3>
            <OrderedList>
              <li>
                <span>1. </span> Go to Dashboard from the left menu panel.{' '}
              </li>
              <li>
                <span>2. </span> Select linesheets you want to email.{' '}
              </li>
              <li>
                <span>3. </span> Click on email icon a pop up will appear where you can add
                recipients email{' '}
              </li>
              <li>
                <span>4. </span> addresses, subject of email and description{' '}
              </li>
              <li>
                <span>5. </span> Press the send button.{' '}
              </li>
            </OrderedList>
            <h3>5.2 Send email from contacts:</h3>
            <OrderedList>
              <li>
                <span>1. </span> Go to Contacts from the left menu panel.{' '}
              </li>
              <li>
                <span>2. </span> Select contacts you want to send email.{' '}
              </li>
              <li>
                <span>3. </span> Click on the email icon, a pop up will appear where you can add
                more contacts from the right section.{' '}
              </li>
              <li>
                <span>4. </span> There is a listing of linesheets you need to select the linesheets
                you want to press the continue button.{' '}
              </li>
              <li>
                <span>5. </span> Another pop up will appear where you can add more recipients by
                typing their{' '}
              </li>
              <li>
                <span>6. </span> email addresses.{' '}
              </li>
              <li>
                <span>7. </span> Type subject and message and press send button.{' '}
              </li>
            </OrderedList>
            <h3>5.3 Send email from Emails:</h3>
            <OrderedList>
              <li>
                <span>1. </span> Go to Emails from the left menu panel.{' '}
              </li>
              <li>
                <span>2. </span> Select contacts you want to send email.{' '}
              </li>
              <li>
                <span>3. </span> Click on the email icon and a pop up will appear where you can add
                more contacts from the right section.{' '}
              </li>
              <li>
                <span>4. </span> There is a listing of linesheets you need to select the linesheets
                you want to send and press the continue button.{' '}
              </li>
              <li>
                <span>5. </span>Another pop up will appear where you can add more recipients by
                typing their email addresses.{' '}
              </li>
              <li>
                <span>6. </span> Type subject and message and press send button.{' '}
              </li>
            </OrderedList>

            <h2>6 Linesheet Editor:</h2>
            <h3>6.1 Header:</h3>
            <OrderedList>
              <h3>6.1.1 Back Button:</h3>
              <p>
                When you click the back button it shows you a pop up where you can either save
                linesheet or don’t save and it redirects to the dashboard.
              </p>
              <h3>6.1.2 Save:</h3>
              <p>You can save your recent changes here.</p>
              <h3>6.1.3 Save as:</h3>
              <p>
                You can also save this linesheet as a new linesheet. When you click on this button a
                pop up appears where you provide the name of the new linesheet. If you want to edit
                newly saved linesheet goto the Linesheets or Dashboard and edit that linesheet.
              </p>
              <h3>6.1.4 Download:</h3>
              <OrderedList>
                <li>
                  <span>1. </span> Click on Download in the right corner.{' '}
                </li>
                <li>
                  <span>2. </span> A pop up will appear where you can see your linesheet name in the
                  pop up click on it, it’ll redirect to the Linesheets.{' '}
                </li>
                <li>
                  <span>3. </span> Click on Download Icon.{' '}
                </li>
              </OrderedList>
            </OrderedList>
            <h3>6.2 Products/Left Panel:</h3>
            <p>
              On the left side of the editor you can see an area populated with the products you
              selected at the time of linesheet creation. There is a list of actions you can perform
              here.
            </p>
            <OrderedList>
              <h3>6.2.1 Select:</h3>

              <UnorderedLists2>
                <li>
                  You can select products you want to import in the linesheet by clicking on every
                  single product.{' '}
                </li>
                <li>You can also select products by selecting categories </li>
                <li>
                  You can also select all products by clicking on the Select all icon which is the
                  first icon in the top black bar.{' '}
                </li>
              </UnorderedLists2>
              <h3>6.2.2 Add new Products:</h3>
              <OrderedList>
                <li>
                  <span>1. </span> Click on the second icon in the top black bar.{' '}
                </li>
                <li>
                  <span>2. </span> A pop up will appear which will have the product listing.{' '}
                </li>
                <li>
                  <span>3. </span> Select desired products here and press Add button products will
                  be added in the panel.{' '}
                </li>
              </OrderedList>
              <h3>6.2.3 Add products in Linesheet:</h3>
              <OrderedList>
                <li>
                  <span>1. </span> Click on Plus icon and it'll show you a pop up.{' '}
                </li>
                <li>
                  <span>2. </span> Select a template you want to add and click on continue. You can
                  also add products to the page by drag and drop.{' '}
                </li>
              </OrderedList>
            </OrderedList>
            <h3>6.3 Editing Tools/Right Panel:</h3>
            <OrderedList>
              <h3>6.3.1 Text:</h3>
              <p>
                This tool will affect selected products or text. You can change the following
                properties of the text
              </p>
              <UnorderedLists2>
                <li>Font Family</li>
                <li>Font Size</li>
                <li>Font color</li>
                <li> Font Style (Bold, Italic, Underline)</li>
                <li> Font Justification (Left, Right, Center)</li>
              </UnorderedLists2>
              <h3>6.3.2 Image/Object:</h3>
              <p>
                This tool will affect selected Page, products, Images and text. You can change the
                following properties of selected object
              </p>
              <UnorderedLists2>
                <li> Background Color</li>
                <li> Opacity of the background color</li>
                <li>
                  {' '}
                  Bring selected object forward (you can also use this property by right clicking on
                  the object){' '}
                </li>
                <li>
                  Send selected object backward (you can also use this property by right clicking on
                  the object)
                </li>
                <li> Image Fill</li>
                <li> Image Fit</li>
                <li> Image Stretch</li>
                <li>Flip the object Vertical</li>
                <li> Flip the object Horizontal</li>
                <li> Enlarge Image to its original dimensions (height and width)</li>
                <li>
                  <b>Ungroup Product</b> It’ll separate image and text of the product (you can also
                  use this property by right clicking on the product)
                </li>
              </UnorderedLists2>
              <h3>6.3.3 Product Image:</h3>
              <p>
                If a product has more than one image then you can select here which image will be
                displayed.
              </p>
              <h3>6.3.4 Product Arrangement:</h3>
              <p>This will apply on selected product and page.</p>
              <UnorderedLists2>
                <h3>6.3.4.1 Image on the top of text:</h3>
                <p>This arrangement will show the image on the top and text in bottom.</p>
                <h3>6.3.4.2 Image on the bottom of text:</h3>
                <p>This arrangement will show the text on the top and image in bottom.</p>
              </UnorderedLists2>

              <h3>6.3.5 Product Size:</h3>
              <p>You can change the following properties of selected page and product</p>
              <UnorderedLists2>
                <li> Change width of the product </li>
                <li> Change height of the product </li>
                <li> Space acquired by the product image </li>
                <li> Space between image and text</li>
              </UnorderedLists2>
              <h3>6.3.6 Add Page:</h3>
              <OrderedList>
                <li>
                  <span>1. </span> You have to select a page first.{' '}
                </li>
                <li>
                  <span>2. </span> Click on the add button and it will add a page under the selected
                  page.{' '}
                </li>
                <li>
                  <span>3. </span> If you don’t select any page it’ll add a page on the top.{' '}
                </li>
              </OrderedList>
              <h3>6.3.7 Page Template:</h3>
              <OrderedList>
                <h3>6.3.7.1 Realign:</h3>
                <OrderedList>
                  <li>
                    <span>1. </span> Select a page and click the Realign button.{' '}
                  </li>
                  <li>
                    <span>2. </span> It will reset the alignment of the products to the default
                    position.{' '}
                  </li>
                </OrderedList>
                <h3>6.3.7.2 Change Template:</h3>
                <p>Select a page and click the Change Template button.</p>
                <p>It’ll show you a pop up where you can select a template.</p>
                <p>
                  If products are exceeding it’ll show you another pop up where you can select what
                  will happen to the exceeding products.
                </p>
                <OrderedList>
                  <UnorderedLists2>
                    <li>
                      <b> Add pages:</b> This will add new pages in the linesheet.
                    </li>
                    <li>
                      <b> Remove Element:</b> This’ll remove the exceeding elements
                    </li>
                    <li>
                      <b> Change Template:</b> It’ll redirect you to the change template pop up.
                    </li>
                  </UnorderedLists2>
                </OrderedList>
              </OrderedList>

              <h3>6.3.8 Lock Page:</h3>
              <p>If you lock a page no global changes will apply on that page.</p>
              <h3>6.3.9 Linesheet:</h3>
              <p>
                Any changes made in this section will be applied to your linesheet globally, every
                single page will be affected.
              </p>
              <p>You can change the following properties here</p>
              <UnorderedLists2>
                <li> Font Family</li>
                <li> Font Size</li>
                <li> Font color</li>
                <li> Font Style (Bold, Italic, Underline)</li>
                <li> Font Justification (Left, Right, Center)</li>
                <li>
                  {' '}
                  <b>Lock all Pages:</b> You can lock all the pages here.
                </li>
                <li>
                  {' '}
                  <b>Linesheet Template:</b> You can change the template of the whole linesheet.
                </li>
              </UnorderedLists2>
            </OrderedList>

            <h3>6.4 Top Bar:</h3>
            <OrderedList>
              <h3>6.4.1 Default Cursor: </h3>
              <p>
                This will take the cursor to the default state. e.g. You select the add text option
                and then click on the default cursor it will unselect the add text.
              </p>

              <h3>6.4.2 Add Text:</h3>
              <p>
                Click on Add text icon and then click where you want to add text a box will appear
                you can add your text in that box.
              </p>

              <h3>6.4.3 Add Image:</h3>
              <OrderedList>
                <li>
                  <span>1. </span> Click on the Add image icon and then click where you want to add
                  the image.{' '}
                </li>
                <li>
                  <span>2. </span> A pop up will appear where you can give the URL of the image,
                  browse your image from your computer or drag and drop the image in the box.{' '}
                </li>
              </OrderedList>
              <h3>6.4.4 Rotate:</h3>
              <p>
                You can change the orientation of the whole linesheet from landscape to portrait or
                from portrait to landscape. It will apply throughout the linesheet.
              </p>
              <h3>6.4.5 Zoom:</h3>
              <p>You can zoom in and out the whole linesheet.</p>
              <h3>6.4.6 Undo & Redo:</h3>
              <p>You can undo and redo your recent changes</p>
              <h3>6.4.7 Preview Linesheet:</h3>
              <p>Click on preview button it will take you to the preview page where you can</p>
              <UnorderedLists2>
                <li>
                  {' '}
                  Rearrange pages by dragging (click and hold the page and drag it to the desired
                  index).
                </li>
                <li> Rotate your linesheet.</li>
                <li> Redo and Undo recent Changes.</li>
                <li> Save and Download your linesheet.</li>
              </UnorderedLists2>
            </OrderedList>
          </HelpPage>
        </HelpFrame>
      </HelpSub>
    </div>
  );
};

export default GettingStarted;
