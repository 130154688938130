import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FontSettings from '../FontSettings';
import BackgroundColor from '../BackgroundColor';
import SliderLayout from '../SliderLayout';
import {
  updateLinesheetFontSettings,
  updateAllPageLocked,
  setTemplateModalVisibility,
} from 'slices/editorSlice';
import {
  StyledTitle,
  ToolsContainer,
  StyledSubTitle,
  StyledRow,
  StyledGroupTitle,
  StyledTooltip,
  StyledSwitch,
  StyledChooseButton,
} from './styles';

const LinesheetsEditorTools = () => {
  const dispatch = useDispatch();
  const linesheetSettings = useSelector((state) => state.editor.linesheetData.settings);
  const [allPageLocked, setAllPageLocked] = useState(false);
  const [opacityLinesheetProducts, setOpacityLinesheetProducts] = useState(100);
  const [opacityLinesheet, setOpacityLinesheet] = useState(100);
  const [productBackground, setProductBackground] = useState('rgb(255,255,255)');
  const [pageBackground, setPageBackground] = useState('rgb(255,255,255)');
  const [fontSettings, setFontSettings] = useState({
    font: 'Arial',
    size: '12px',
    color: '#000',
  });

  useEffect(() => {
    if (linesheetSettings) {
      setPageBackground(linesheetSettings.pageBackground || 'rgb(255,255,255)');
      setOpacityLinesheet(linesheetSettings.pageOpacity || 100);
      setProductBackground(linesheetSettings.productsBackgroundColor || 'rgb(255,255,255)');
      setOpacityLinesheetProducts(linesheetSettings.productsOpacity || 100);
      setFontSettings({
        font: linesheetSettings.font || 'Arial',
        size: linesheetSettings.size || '12px',
        color: linesheetSettings.color || '#000',
      });
      setAllPageLocked(linesheetSettings.allPageLocked || false);
    }
  }, [linesheetSettings]);

  const opacityLinesheetChangeHandler = (value) => {
    if (isNaN(value)) {
      return;
    }
    setOpacityLinesheet(value);
    dispatch(updateLinesheetFontSettings({ key: 'pageOpacity', value }));
  };

  const opacityLinesheetProductsChangeHandler = (value) => {
    if (isNaN(value)) {
      return;
    }
    setOpacityLinesheetProducts(value);
    dispatch(updateLinesheetFontSettings({ key: 'productOpacity', value }));
  };

  const allPageLockedChangeHandler = () => {
    dispatch(updateAllPageLocked());
  };

  const chooseTemplateLinesheetClickHandler = () => {
    dispatch(setTemplateModalVisibility({ show: true, changeLinesheetTemplate: true }));
  };

  return (
    <>
      <StyledTitle>Linesheet</StyledTitle>
      <ToolsContainer>
        <StyledSubTitle>
          Any changes made in this section will be applied to your linesheet globally
        </StyledSubTitle>
        <FontSettings isGlobal={true} fontSettings={fontSettings} />
        <BackgroundColor title="Page Background" initialColor={pageBackground} />
        <SliderLayout
          tooltipTitle="Background Opacity"
          label="Opacity"
          value={opacityLinesheet}
          onChange={opacityLinesheetChangeHandler}
        />
        <BackgroundColor title="Product's Background" initialColor={productBackground} />
        <SliderLayout
          tooltipTitle="Background Opacity"
          label="Opacity"
          value={opacityLinesheetProducts}
          onChange={opacityLinesheetProductsChangeHandler}
        />
        <StyledRow justify="space-between" wrap={false}>
          <StyledGroupTitle>Lock All Pages</StyledGroupTitle>
          <StyledTooltip title="Lock all pages to ignore global changes" placement="left">
            <StyledSwitch value={allPageLocked} onChange={allPageLockedChangeHandler} />
          </StyledTooltip>
        </StyledRow>
        <StyledRow justify="space-between" wrap={false}>
          <StyledGroupTitle>Linesheet Template</StyledGroupTitle>
          <StyledTooltip title="Choose Template for All Pages" placement="left">
            <StyledChooseButton onClick={chooseTemplateLinesheetClickHandler}>
              Choose
            </StyledChooseButton>
          </StyledTooltip>
        </StyledRow>
      </ToolsContainer>
    </>
  );
};

export default LinesheetsEditorTools;
