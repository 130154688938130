import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { message } from 'antd';
import cache from 'cache';
import { useUnSubscribeMonthlyMutation } from 'api/authApi';
import Loader from 'components/Common/Loader';
import {
  CancelButton,
  CancelLayout,
  UnsubscribeButton,
  StyledModal,
  MainLayout,
  StyledText,
  StyledDivider,
} from './styles';

const UnsubscribeModal = ({ isOpen, closeModal }) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [unsubscribeUser, { isLoading }] = useUnSubscribeMonthlyMutation();
  const subscription = JSON.parse(cache.getItem('user'))?.subscription;

  let monthDiffVerify = 0;
  let current_nextBilling = moment(subscription.nextBilling);

  if (
    subscription.yearEnding &&
    subscription.nextBilling &&
    moment(subscription.yearEnding).isAfter(subscription.nextBilling, 'day')
  ) {
    monthDiffVerify = moment(subscription.yearEnding).diff(current_nextBilling, 'months');
  }
  let amount = 29.99 * monthDiffVerify;

  const handleUnsubscibeMonthly = async () => {
    try {
      await unsubscribeUser({ amount });
      if (!isLoading) {
        message.success('User unsubscibed');
        closeModal(false);
      }
    } catch (err) {
      closeModal(false);
      message.error('Some thing went wrong');
    }
  };

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    closeModal(false);
    setIsModalOpen(false);
  };
  return (
    <>
      <StyledModal
        centered
        open={isModalOpen}
        onOk={handleOk}
        width={580}
        onCancel={handleCancel}
        footer={null}
      >
        <MainLayout>
          <StyledText>
            You are paying monthly for an annual subscription, so if unsubscribe you will be
            charged" <strong>$0+Tax </strong> "for the remaining full year. But you can still use
            the application untill year ending."
          </StyledText>
          <UnsubscribeButton onClick={handleUnsubscibeMonthly}>Unsubscribe</UnsubscribeButton>
        </MainLayout>
        <StyledDivider />
        <CancelLayout>
          <CancelButton size="large" onClick={handleCancel}>
            Cancel
          </CancelButton>
        </CancelLayout>
        {!!isLoading && <Loader isLoading={isLoading} />}
      </StyledModal>
    </>
  );
};
export default UnsubscribeModal;
