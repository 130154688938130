import styled from 'styled-components';
import { Modal, Divider, Button } from 'antd';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 10px;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 5px 0;
`;

export const StyledButton = styled(Button)`
  justify-content: flex-start;
  padding: 8px 16px;
`;
