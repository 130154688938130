import React, { useState } from 'react';
import { Tooltip, Dropdown } from 'antd';
import moment from 'moment';
import Pagination from 'components/Common/Pagination';
import ManualForm from '../../../ManualForm';
import dotImage from 'assets/images/3dots.png';
import {
  ActionImage,
  StyledTable,
  SelectAllContainer,
  SelectAllButton,
  SelectedRowsText,
  EllipsisTooltip,
  DropdownWrapper,
} from './styles';

const ProductListTable = ({
  products,
  deleteProduct,
  handleDuplicateProducts,
  rowSelection,
  refetchProducts,
  handleSortChange,
  totalProducts,
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  selectedRows,
  isSelectAll,
  handleSelectAll,
  resetSelectedRows,
  handleUnselectAll,
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedEditProductId, setSelectedEditProductId] = useState(null);

  const onEditProductSubmit = () => {
    refetchProducts();
    setShowEditModal(false);
  };

  const handlePaginationChange = (page) => {
    resetSelectedRows();
    setCurrentPage(page);
  };

  const handleDelete = async (id) => {
    await deleteProduct({ selectedProducts: [id] });
  };

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };

  const items = [
    {
      label: 'Duplicate',
      key: 'duplicate',
    },
    {
      label: 'Delete',
      key: 'delete',
    },
    {
      label: 'Edit',
      key: 'edit',
    },
  ];

  const columns = [
    {
      title: 'Image',
      dataIndex: 'product_image',
      class: 'product-image',
      width: '15%',
      key: 'product_image',
      render: (product_image) => {
        return (
          <div className="table-image-wrapper">
            {product_image ? <img src={product_image} alt="" loading="lazy" /> : null}
          </div>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <span>{text}</span>,
      width: '15%',
      sorter: true,
    },
    {
      title: 'Product Number',
      key: 'productNumber',
      dataIndex: 'productNumber',
      render: (text) => <span>{text}</span>,
      width: '10%',
      sorter: true,
    },
    {
      title: 'Variants',
      dataIndex: 'numberOfVariants',
      key: 'numberOfVariants',
      width: '5%',
      sorter: true,
    },
    {
      title: 'Categories',
      dataIndex: 'categories',
      width: '10%',
      render: (categories) => (
        <Tooltip title={<span>{categories.map((category) => category.name).join(', ')}</span>}>
          <EllipsisTooltip>
            {categories.map((category) => category.name).join(', ')}
          </EllipsisTooltip>
        </Tooltip>
      ),
    },
    {
      title: 'Wholesale Price',
      dataIndex: 'wholesalePrice',
      key: 'wholesalePrice',
      width: '15%',
      render: (text) => (text ? <span>{`$ ${text}`}</span> : `$ ${0}`),
      sorter: true,
    },
    {
      title: 'Retail Price',
      dataIndex: 'retailPrice',
      key: 'retailPrice',
      width: '15%',
      render: (text) => (text ? <span>$ {text}</span> : `$ ${0}`),
      sorter: true,
    },
    {
      title: 'Last Modified',
      dataIndex: 'lastModified',
      key: 'lastModified',
      width: '10%',
      render: (text) => <span>{moment(text).fromNow()}</span>,
      sorter: true,
    },
    {
      title: '',
      key: 'action',
      width: '5%',
      render: (record) => (
        <Dropdown
          menu={{
            items,
            onClick: ({ key }) => {
              switch (key) {
                case 'delete':
                  handleDelete(record?.id);
                  break;
                case 'duplicate':
                  handleDuplicateProducts([record?.id]);
                  break;
                case 'edit':
                  setSelectedEditProductId(record?.id);
                  setShowEditModal(true);
                  break;
                default:
                  break;
              }
            },
          }}
          trigger={['click']}
        >
          <DropdownWrapper>
            <ActionImage src={dotImage} alt="icon" />
          </DropdownWrapper>
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <SelectAllContainer>
        {selectedRows?.length > 0 && (
          <>
            {isSelectAll || selectedRows?.length === totalProducts ? (
              <SelectAllButton onClick={() => handleUnselectAll()}>Unselect all</SelectAllButton>
            ) : (
              <SelectAllButton onClick={() => handleSelectAll()}>
                Select all {totalProducts}
              </SelectAllButton>
            )}
            <SelectedRowsText>
              Selected {isSelectAll ? totalProducts : selectedRows?.length} products
            </SelectedRowsText>
          </>
        )}
      </SelectAllContainer>
      <StyledTable
        rowKey="id"
        rowSelection={{
          ...rowSelection,
          selectedRowKeys: selectedRows,
        }}
        columns={columns}
        // expandable={{ rowExpand }}
        locale={{ emptyText: 'No Data Found' }}
        dataSource={products}
        onChange={(pagination, filters, sorter) => {
          // Handle column sorting change
          if (sorter && sorter.column && sorter.order) {
            handleSortChange(sorter.column.key, sorter.order === 'ascend' ? 'ASC' : 'DESC');
          }
        }}
        pagination={{
          ...Pagination({
            currentPage,
            pageSize,
            total: totalProducts,
            onShowSizeChange,
            handlePaginationChange,
          }),
        }}
      />
      {showEditModal && (
        <ManualForm
          editProduct={true}
          productId={selectedEditProductId}
          hideEditModal={() => setShowEditModal(false)}
          onSubmit={onEditProductSubmit}
        />
      )}
    </>
  );
};

export default ProductListTable;
