import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { message } from 'antd';
import Loader from 'components/Common/Loader';
import SubscriptionModal from '../SubscriptionPlanModal';
import UnsubscribeModal from '../UnsubscribeModal';
import visaImg from '../../../assets/images/visa.png';
import masterCardImg from '../../../assets/images/mastercard.png';
import discoverImg from '../../../assets/images/discover.png';
import amexImg from '../../../assets/images/amex.png';
import paypalImg from '../../../assets/images/paypal_logo.png';
import { useUnSubscribeMutation, useReSubscribeMutation } from 'api/authApi';
import {
  MainLayout,
  PlansContainer,
  StyledButton,
  StyledTitle,
  CurrentPlanWrapper,
  StyledSubTitle,
  CurrentPlanPriceWrapper,
  StyledDivider,
  DownloadsWrapper,
  NextBillingWrapper,
  FlexWrapper,
  AccessLevelWrapper,
  PaymentTypeLabel,
  StyledImage,
  PaymentTypeWrapper,
  ImagesWrapper,
  SubscriptionStatus,
} from './styles';

const SubscriptionSection = ({ billingInfo }) => {
  const userData = useSelector((state) => state.auth.user);
  const [unSubscribeUser, { isLoading: unSubscribeLoading }] = useUnSubscribeMutation();
  const [reSubscribeUser, { isLoading: reSubscribeLoading }] = useReSubscribeMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [unSubModalShowing, setUnSubModalShowing] = useState(false);

  const nextBilling =
    userData.subscription && userData.subscription.nextBilling
      ? userData.subscription.nextBilling
      : null;
  const yearEnding =
    userData.subscription && userData.subscription.yearEnding
      ? userData.subscription.yearEnding
      : null;
  const endOfPeriodBilling =
    userData.subscription && userData.subscription.nextBilling
      ? moment(userData.subscription.nextBilling).format('MMMM D, YYYY')
      : '';
  const endOfPeriodActive =
    userData.subscription && userData.subscription.yearEnding
      ? moment(userData.subscription.yearEnding).format('MMMM D, YYYY')
      : '';

  const reSubscribeHandler = async () => {
    try {
      setIsLoading(true);
      await reSubscribeUser({ activePlanType: userData.activePlanType });
      if (!reSubscribeLoading) {
        setIsLoading(false);
        message.success('Successfully resubscribed');
      }
    } catch (err) {
      message.error('Oops! Some thing went wrong');
    }
  };

  const unsubscribeHandler = async () => {
    try {
      setIsLoading(true);
      await unSubscribeUser();
      if (!unSubscribeLoading) {
        message.success('Successfully unsubscribed');
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      message.error('Oops! Some thing went wrong');
    }
  };

  const viewPlansClickHandler = () => {
    setShowSubscriptionModal(true);
  };

  const unSubscribeClickHandler = () => {
    if (userData && userData.subscription && userData.subscription.planType === 'monthly') {
      if (nextBilling) {
        if (yearEnding && moment(yearEnding).diff(nextBilling, 'days') < 2) {
          unsubscribeHandler();
          return;
        }
        setUnSubModalShowing(true);
      } else {
        message.info('Please wait, payment is not verified yet');
      }
    } else {
      unsubscribeHandler();
    }
  };

  return (
    <>
      {userData.subscription &&
      userData.subscription.status === 'active' &&
      (!userData.subscription.cancelAtPeriodEnd ||
        moment(userData.subscription.yearEnding).isAfter()) ? (
        <MainLayout>
          <FlexWrapper>
            <div style={{ overflowX: 'auto' }}>
              <PlansContainer>
                <CurrentPlanWrapper>
                  <StyledTitle>Current Plan</StyledTitle>
                  <StyledSubTitle>Annual Subscription</StyledSubTitle>
                  {userData.activePlanType === 'monthly' ? (
                    <StyledSubTitle>Charged Monthly</StyledSubTitle>
                  ) : null}
                  {userData.subscription.cancelAtPeriodEnd ? (
                    <h5>Canceled</h5>
                  ) : userData.activePlanType === 'monthly' ? (
                    <CurrentPlanPriceWrapper>
                      $29
                      <sup>.99</sup>
                    </CurrentPlanPriceWrapper>
                  ) : (
                    <CurrentPlanPriceWrapper>
                      $309
                      <sup>.88</sup>
                    </CurrentPlanPriceWrapper>
                  )}
                  {/* <StyledButton>Unsubscribe</StyledButton> */}
                  {userData.subscription.cancelAtPeriodEnd ? (
                    userData.activePlanType !== 'monthly' ||
                    moment(yearEnding).diff(nextBilling, 'days') < 2 ? (
                      <StyledButton onClick={reSubscribeHandler}>Resubscribe</StyledButton>
                    ) : (
                      <StyledButton onClick={viewPlansClickHandler}>
                        View Plans & Prices
                      </StyledButton>
                    )
                  ) : (
                    <StyledButton onClick={unSubscribeClickHandler}>Unsubscribe</StyledButton>
                  )}
                </CurrentPlanWrapper>
                <StyledDivider type="vertical" style={{ height: 'auto' }} />
                <DownloadsWrapper>
                  <StyledTitle>Downloads</StyledTitle>
                  <StyledSubTitle>Unlimited</StyledSubTitle>
                </DownloadsWrapper>
                <StyledDivider type="vertical" style={{ height: 'auto' }} />
                <NextBillingWrapper>
                  <StyledTitle>
                    {userData.subscription.cancelAtPeriodEnd ? 'Active Until' : 'Next Billing'}
                  </StyledTitle>
                  {userData.subscription.cancelAtPeriodEnd ? (
                    <StyledSubTitle>{endOfPeriodActive}</StyledSubTitle>
                  ) : (
                    <StyledSubTitle>{endOfPeriodBilling}</StyledSubTitle>
                  )}
                </NextBillingWrapper>
              </PlansContainer>
            </div>
            <AccessLevelWrapper>
              <StyledTitle>Linesheet Creator Access Level</StyledTitle>
              <p>Your current access level allows:</p>
              <ul>
                <li>Create, edit, and download Linesheets</li>
                <li>To download Linesheets, you'd need to pay for each</li>
                <li>Edit product information</li>
                <li>Manage your personal profile</li>
              </ul>
            </AccessLevelWrapper>
          </FlexWrapper>
          <StyledDivider style={{ margin: '10px 0px' }} />
          {userData.subscription.cancelAtPeriodEnd ? (
            <div>
              <small>
                <em>
                  You can still use the system to its fullest until{' '}
                  {userData.subscription.subCancelAtPeriodEnd ? (
                    <small>{endOfPeriodActive}</small>
                  ) : (
                    <small>{endOfPeriodBilling}</small>
                  )}
                </em>
              </small>
            </div>
          ) : null}
          <PaymentTypeLabel>Payment Type</PaymentTypeLabel>
          <PaymentTypeWrapper>
            <ImagesWrapper>
              {`Using`}
              {userData.activePaymentMethod === 'card' ? (
                <>
                  <StyledImage src={visaImg} />
                  <StyledImage src={masterCardImg} />
                  <StyledImage src={discoverImg} />
                  <StyledImage src={amexImg} />
                </>
              ) : (
                <StyledImage src={paypalImg} />
              )}
            </ImagesWrapper>
            {nextBilling ? (
              !userData.subscription.cancelAtPeriodEnd ? (
                <SubscriptionStatus>
                  This method will be charged on next billing day {endOfPeriodBilling}
                </SubscriptionStatus>
              ) : (
                <SubscriptionStatus>
                  This method is active until {endOfPeriodActive}
                </SubscriptionStatus>
              )
            ) : (
              <SubscriptionStatus>You are not subscribed</SubscriptionStatus>
            )}
          </PaymentTypeWrapper>
        </MainLayout>
      ) : (
        <MainLayout>
          <FlexWrapper>
            <div style={{ overflowX: 'auto' }}>
              <PlansContainer>
                <CurrentPlanWrapper>
                  <StyledTitle>Current Plan</StyledTitle>
                  <StyledSubTitle>
                    {userData.activePlanType ? userData.activePlanType : 'Not Subscribed'}
                  </StyledSubTitle>
                  <StyledButton onClick={viewPlansClickHandler}>View Plans & Prices</StyledButton>
                </CurrentPlanWrapper>
                <StyledDivider type="vertical" style={{ height: 'auto' }} />
                <DownloadsWrapper>
                  <StyledTitle>Downloads</StyledTitle>
                  <StyledSubTitle>
                    $99
                    <sup>.99</sup> each
                  </StyledSubTitle>
                </DownloadsWrapper>
              </PlansContainer>
            </div>
            <AccessLevelWrapper>
              <StyledTitle>Linesheet Creator Access Level</StyledTitle>
              <p>Your current access level allows:</p>
              <ul>
                <li>Create, edit, and download Linesheets</li>
                <li>To download Linesheets, you'd need to pay for each</li>
                <li>Edit product information</li>
                <li>Manage your personal profile</li>
              </ul>
            </AccessLevelWrapper>
          </FlexWrapper>
          <StyledDivider style={{ margin: '10px 0px' }} />
          <PaymentTypeLabel>Payment Type</PaymentTypeLabel>
          <PaymentTypeWrapper>
            <ImagesWrapper>
              {`Using`}
              {userData.activePaymentMethod === 'card' ? (
                <>
                  <StyledImage src={visaImg} />
                  <StyledImage src={masterCardImg} />
                  <StyledImage src={discoverImg} />
                  <StyledImage src={amexImg} />
                </>
              ) : (
                <StyledImage src={paypalImg} />
              )}
            </ImagesWrapper>
            {nextBilling ? (
              !userData.subscription.cancelAtPeriodEnd ? (
                <SubscriptionStatus>
                  This method will be charged on next billing day {endOfPeriodBilling}
                </SubscriptionStatus>
              ) : (
                <SubscriptionStatus>
                  This method is active until {endOfPeriodActive}
                </SubscriptionStatus>
              )
            ) : (
              <SubscriptionStatus>You are not subscribed</SubscriptionStatus>
            )}
          </PaymentTypeWrapper>
        </MainLayout>
      )}
      <Loader isLoading={isLoading} />
      <SubscriptionModal
        isOpen={showSubscriptionModal}
        setShowSubscriptionModal={setShowSubscriptionModal}
        billingInfo={billingInfo}
      />
      <UnsubscribeModal isOpen={unSubModalShowing} closeModal={setUnSubModalShowing} />
    </>
  );
};

export default SubscriptionSection;
