import React from 'react';
import { useDispatch } from 'react-redux';
import { setProductSizing } from 'slices/editorSlice';
import SliderLayout from '../SliderLayout';

const ProductSizing = ({
  productSizeWidth,
  productSizeHeight,
  productSizeImage,
  productSizeSpace,
}) => {
  const dispatch = useDispatch();
  const productSizeChangeHandler = (type, value) => {
    switch (type) {
      case 'productSizeWidth':
        dispatch(setProductSizing({ type, value }));
        break;
      case 'productSizeHeight':
        dispatch(setProductSizing({ type, value }));
        break;
      case 'productSizeImage':
        dispatch(setProductSizing({ type, value }));
        break;
      case 'productSizeSpace':
        dispatch(setProductSizing({ type, value }));
        break;
      default:
        return;
    }
  };

  return (
    <>
      <SliderLayout
        tooltipTitle="Product Width"
        label="Width"
        value={productSizeWidth}
        onChange={(val) => productSizeChangeHandler('productSizeWidth', val)}
      />
      <SliderLayout
        tooltipTitle="Product Height"
        label="Height"
        value={productSizeHeight}
        onChange={(val) => productSizeChangeHandler('productSizeHeight', val)}
      />
      <SliderLayout
        tooltipTitle="Image Height"
        label="Image"
        value={productSizeImage}
        onChange={(val) => productSizeChangeHandler('productSizeImage', val)}
      />
      <SliderLayout
        tooltipTitle="Space Between Image and Text"
        label="Space"
        value={productSizeSpace}
        onChange={(val) => productSizeChangeHandler('productSizeSpace', val)}
      />
    </>
  );
};

export default ProductSizing;
