import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import monthlyIcon from 'assets/images/ls-pricing-monthly-ico.png';
import oneTimeIcon from 'assets/images/ls-one-time-icon.png';
import upFrontIcon from 'assets/images/upfront-icon.png';
import CheckoutForm from '../CheckoutForm';
import SubscriptionCard from './SubscriptionCard';
import {
  CancelButton,
  CardsWrapper,
  Footer,
  PolicyLayout,
  PolicyDescription,
  PolicyTitle,
  Policy,
} from './styles';

const SubscriptionModal = ({ isOpen, setShowSubscriptionModal, billingInfo = null }) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [showCheckout, setShowCheckout] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);

  const handleCancel = () => {
    setShowSubscriptionModal(false);
    setIsModalOpen(false);
    setShowCheckout(false);
    setSubscriptionPlan(null);
  };

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      <Modal
        centered
        width={showCheckout ? 750 : 1250}
        open={isModalOpen}
        style={{
          top: '20px',
        }}
        closeIcon={showCheckout}
        footer={null}
        onCancel={handleCancel}
      >
        {showCheckout ? (
          <CheckoutForm
            handleCancel={handleCancel}
            subscriptionPlan={subscriptionPlan}
            billingInfo={billingInfo}
          />
        ) : (
          <>
            <CardsWrapper>
              <SubscriptionCard
                icon={monthlyIcon}
                type="monthly"
                title="Monthly Annual Subscription"
                price="$29.99"
                offer="Mo."
                offerDesc="Pay monthly and enjoy all the key Linesheets perks at a rate that wonâ€™t tax your budget."
                showCheckout={setShowCheckout}
                setSubscriptionPlan={setSubscriptionPlan}
              />
              <SubscriptionCard
                icon={upFrontIcon}
                type="annual"
                title="Upfront Annual Subscription"
                price="$309.88"
                offer="(a $50 annual savings)"
                offerDesc="Ditch the monthly payments and save some dough ($50 per year!) with an annual subscription. You get everything that comes with the monthly plan, including unlimited access and downloads."
                showCheckout={setShowCheckout}
                setSubscriptionPlan={setSubscriptionPlan}
              />
              <SubscriptionCard
                icon={oneTimeIcon}
                type="oneTime"
                title="One-Time Download"
                price="$99.99"
                offerDesc="Not a fan of commitment?
            Create your masterpiece and keep it forever.
            This is an ideal option for brands that only need to download one linesheet; highlight your star product or detail a collection and sell."
                showCheckout={setShowCheckout}
                setSubscriptionPlan={setSubscriptionPlan}
              />
            </CardsWrapper>
            <PolicyLayout>
              <Policy>
                <PolicyTitle>Renewal and Cancellation Policies</PolicyTitle>
              </Policy>
              <Policy>
                <PolicyDescription>
                  Both annual subscriptions (paid upfront or monthly) are set to autorenew, so
                  you’ll never accidentally lose access to your Linesheets account. Monthly
                  subscriptions cancelled before the end of the full 12-month term will be charged
                  the full balance for the remaining contract if you have already downloaded a line
                  sheet. (For instance, if the subscription is cancelled at the 6-month mark, the
                  remaining $179.94 will be charged at the time of cancellation.) You will still
                  have full access to linesheets’ tools and downloads until your 12 month term
                  expires.
                </PolicyDescription>
              </Policy>
            </PolicyLayout>
            <Footer>
              <CancelButton onClick={handleCancel}>Cancel</CancelButton>
            </Footer>
          </>
        )}
      </Modal>
    </>
  );
};
export default SubscriptionModal;
