import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListEditorToolsComponent from './ListEditorTools';
import Playground from '../Playground';
import {
  setInPreview,
  setInGlobalSettingsMode,
  setAddingHeaderFooterMode,
} from 'slices/editorSlice';
import { faEye, faGear } from '@fortawesome/free-solid-svg-icons';
import { faWindowMaximize } from '@fortawesome/free-regular-svg-icons';
import {
  MainContainer,
  StyledHeader,
  StyledTooltip,
  StyledButton,
  StyledFontAwesomeIcon,
  PlaygroundSection,
} from './styles';

const EditorPanel = () => {
  const dispatch = useDispatch();
  const ListEditorTools = ListEditorToolsComponent();
  const activeAction = useSelector((state) => state.editor.activeAction);

  const handlePreview = () => {
    dispatch(setInPreview(true));
  };

  const handleGlobalSettings = () => {
    dispatch(setInGlobalSettingsMode(true));
  };

  return (
    <MainContainer>
      <StyledHeader>
        {ListEditorTools.map((tool, index) => (
          <StyledTooltip key={index} title={tool.tooltip} placement="bottom">
            {tool.dropdown ? (
              tool.dropdown
            ) : (
              <StyledButton
                isactive={(activeAction === tool.name).toString()}
                onClick={() => tool.action(tool.name)}
                disabled={tool.disabled}
              >
                <StyledFontAwesomeIcon icon={tool.icon} />
              </StyledButton>
            )}
          </StyledTooltip>
        ))}
        <StyledTooltip title={'Add Header'} placement="bottom">
          <StyledButton
            onClick={() =>
              dispatch(setAddingHeaderFooterMode({ show: true, isHeader: true, isFooter: false }))
            }
          >
            <StyledFontAwesomeIcon icon={faWindowMaximize} />
          </StyledButton>
        </StyledTooltip>
        <StyledTooltip title={'Add Footer'} placement="bottom">
          <StyledButton
            onClick={() =>
              dispatch(setAddingHeaderFooterMode({ show: true, isHeader: false, isFooter: true }))
            }
          >
            <StyledFontAwesomeIcon icon={faWindowMaximize} flip="vertical" />
          </StyledButton>
        </StyledTooltip>
        <StyledTooltip title={'Preview Linesheet'} placement="bottom">
          <StyledButton onClick={() => handlePreview()}>
            <StyledFontAwesomeIcon icon={faEye} /> Preview Linesheet
          </StyledButton>
        </StyledTooltip>
        <StyledTooltip title={'Quick Edit'} placement="bottom">
          <StyledButton onClick={() => handleGlobalSettings()}>
            <StyledFontAwesomeIcon icon={faGear} /> Quick Edit
          </StyledButton>
        </StyledTooltip>
      </StyledHeader>
      <PlaygroundSection>
        <Playground />
      </PlaygroundSection>
    </MainContainer>
  );
};

export default EditorPanel;
