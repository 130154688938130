import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: null,
  type: null,
  pages: [],
};

const alertMessageSlice = createSlice({
  name: 'alertMessage',
  initialState,
  reducers: {
    setAlert: (state, action) => {
      const { message, type, pages = [] } = action.payload;
      state.message = message;
      state.type = type;
      state.pages = pages;
    },
    clearAlert: () => initialState,
  },
});

export const { setAlert, clearAlert } = alertMessageSlice.actions;
export default alertMessageSlice.reducer;
