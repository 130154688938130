import styled from 'styled-components';
import { Modal, Upload, Tooltip } from 'antd';
import { AntTextArea } from 'uiKits/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AntButton from 'uiKits/Button';

const StyledButton = styled(AntButton)`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 16px;
  background: none;
  box-shadow: none;
  border: 0;
  color: #fff;
  border-radius: 10000px;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  border-radius: 10000px;
`;

export const StyledCancelButton = styled(StyledButton)`
  background: none;
  color: #6c757d;
  &:hover {
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
    border-color: #017f5e !important;
  }
`;

export const StyledLineSheetsButton = styled(StyledButton)`
  border: 2px solid #07af7c;
  background-color: #07af7c;
  &:hover {
    background-color: #028b67 !important;
    border-color: #017f5e !important;
  }
`;

export const StyledDeleteButton = styled(StyledButton)`
  border: 2px solid red;
  background: red;
  &:hover {
    background-color: red !important;
    border-color: red !important;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
`;

export const AntdModal = styled(Modal)`
  .ant-modal-title {
    display: flex;
    padding: 30px;
  }
  .ant-modal-content {
    border-radius: 10px;
    padding: 0;
    width: 570px;
  }

  .ant-modal-body {
    padding: 30px;
  }

  .ant-modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 25px;
    border-bottom-right-radius: 11px;
    border-bottom-left-radius: 11px;
    background-color: #f0f0f0; /* Grey background color */
  }
`;

export const TitleWrapper = styled.p`
  margin: 0;
  margin-bottom: 30px;
  color: rgba(0, 0, 0, 0.88);
  font-family: Muli;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.5;
  word-wrap: break-word;
  text-align: center;
`;

export const StyledErrorMessage = styled.p`
  color: red;
`;

export const AntInputStyled = styled(AntTextArea)`
  width: 100%;
  min-height: 100px !important;
  padding: 12px;
  border-radius: 4px;
`;

export const StyledLabel = styled.h3`
  color: #595959;
  margin-bottom: 10px;
`;

export const StyledDescriptionCon = styled.div`
  flex-basis: 100%;
  .position {
    margin-top: 16px;
  }
`;

export const StyledUpload = styled(Upload)`
  .ant-upload-picture-card-wrapper {
    .ant-upload-list-item-container {
      height: 116px !important;
      border-radius: 4px !important;
    }
  }
`;

export const StyledTooltip = styled(Tooltip)``;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: #000;
  padding: 5px;
  &.active {
    background: #242424;
    color: #fff;
  }
`;
