import styled from 'styled-components';
import { Button, Divider } from 'antd';

export const MainLayout = styled.div`
  width: 100%;
`;

export const PlansContainer = styled.div`
  display: flex;
  width: 560px;
  border-radius: 10px;
  background-color: #f1f3f5;
  padding: 16px 32px;
  margin-bottom: 25px;
`;

export const StyledButton = styled(Button)`
  margin-top: 1rem;
  border-radius: 10px;
  border: 2px solid #07af7c;
  font-family: Muli;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.72px;
  margin-left: 5px;
  color: #07af7c;
  line-height: 1.7;
  &:hover {
    color: #ffffff !important;
    background-color: #07af7c !important;
    border-color: #07af7c !important;
  }
`;

export const StyledTitle = styled.h5`
  font-family: Muli;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.36px;
  line-height: 18px;
  margin: 0 0 4px;
`;

export const CurrentPlanWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 0 0.5rem;
`;

export const StyledSubTitle = styled.small`
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.24px;
  margin-bottom: 4px;
`;

export const CurrentPlanPriceWrapper = styled.h4`
  font-family: Muli;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.6px;
  margin: 0 0 8px;
`;

export const StyledDivider = styled(Divider)``;

export const DownloadsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 0 0.5rem;
`;

export const NextBillingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 0 0.5rem;
`;

export const FlexWrapper = styled.div`
  display: flex;
  gap: 2.5rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const AccessLevelWrapper = styled.div``;

export const PaymentTypeWrapper = styled.div`
  display: flex;
  gap: 8rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
  }

  @media (max-width: 1023px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

export const PaymentTypeLabel = styled.h5`
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.36px;
  color: #000;
  display: inline-block;
  margin-bottom: 25px;
`;

export const StyledImage = styled.img``;

export const ImagesWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const SubscriptionStatus = styled.p`
  margin: 0;
`;
