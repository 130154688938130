import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cache from 'cache';
import { clearUser } from 'slices/authSlice';
import { useLogOutMutation } from 'api/authApi';
import { profileApi } from 'api/profileApi';
import { productApi } from 'api/productApi';
import { contactApi } from 'api/contactsApi';
import { categoriesApi } from 'api/categoriesApi';
import { outboxApi } from 'api/outboxApi';
import { externalSourceApi } from 'api/externalSourceApi';
import { linesheetApi } from 'api/linesheetApi';

import {
  LogoutContainer,
  Header,
  Message,
  SpinnerContainer,
  Spinner,
  Divider,
  SrOnly,
  StyledInnerBody,
} from './styles';

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logoutUser] = useLogOutMutation();

  useEffect(() => {
    setTimeout(() => {
      logoutUser();
      dispatch(clearUser());
      // Clear RTK Query caches
      dispatch(profileApi.util.resetApiState());
      dispatch(productApi.util.resetApiState());
      dispatch(contactApi.util.resetApiState());
      dispatch(categoriesApi.util.resetApiState());
      dispatch(outboxApi.util.resetApiState());
      dispatch(externalSourceApi.util.resetApiState());
      dispatch(linesheetApi.util.resetApiState());
      cache.removeItem('user');
      navigate('/login');
    }, 3000);
  }, []);

  return (
    <LogoutContainer>
      <StyledInnerBody>
        <Header>LOGGING OUT</Header>
        <Divider />
        <Message>
          Thank you for using Linesheets
          <br />
          Hope to see you back soon.
        </Message>
        <SpinnerContainer>
          <Spinner>
            <SrOnly>Loading...</SrOnly>
          </Spinner>
        </SpinnerContainer>
      </StyledInnerBody>
    </LogoutContainer>
  );
};

export default Logout;
