import { styled } from 'styled-components';
import { EditFilled } from '@ant-design/icons';

export const MainLayout = styled.div`
  padding: 0px 30px;
`;

// Container for the grid
export const GridContainer = styled.div`
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  padding: 20px;
`;

// Individual grid items
export const GridItem = styled.div`
  position: relative;
  background-color: white;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 350px; /* Adjust this value to set the desired height */
  width: 250px;
  cursor: pointer;
`;

export const GridItemContent = styled.div`
  padding: 16px;
`;

export const GridItemTitle = styled.div`
  font-size: 1.25em;
  font-weight: bold;
  margin-bottom: 8px;
`;

export const GridItemDetails = styled.div`
  color: #757575;
  margin-bottom: 16px;
  display: flex;
  gap: 8px;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledEdit = styled(EditFilled)``;
