import React, { useState } from 'react';
import { Upload } from 'antd';
import { AntInput } from 'uiKits/Input';
import { useDispatch } from 'react-redux';
import { addImage, setImageUrl, contentMenuActionHandler } from 'slices/editorSlice';
import { useUploadFileMutation, useUploadImageUsingUrlMutation } from 'api/linesheetApi';
import {
  AntdModal,
  Wrapper,
  StyledCancelButton,
  StyledLineSheetsButton,
  TitleWrapper,
  Wrap,
  StyledErrorMessage,
} from './styles';

const { Dragger } = Upload;

const ImageUploadModal = ({ visible, onClose, imgDiv, pageId, changeImage }) => {
  const dispatch = useDispatch();
  const [imageUrl, setIsImageUrl] = useState('');
  const [isFile, setIsFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [uploadFile] = useUploadFileMutation();
  const [uploadImageUsingUrl] = useUploadImageUsingUrlMutation();
  const [imageError, setImageError] = useState('');

  const handleImageSave = () => {
    onClose();
    if (changeImage) {
      dispatch(setImageUrl(isFile ? fileUrl : imageUrl));
      dispatch(contentMenuActionHandler('changeImage'));
    } else {
      const imageDiv = {
        x: imgDiv.x,
        y: imgDiv.y,
        width: 300,
        height: 300,
        imageUrl: isFile ? fileUrl : imageUrl,
        pageId,
      };

      dispatch(addImage(imageDiv));
    }
  };

  const handleUpload = async ({ file, onSuccess, onError }) => {
    try {
      try {
        const response = await uploadFile(file).unwrap();
        if (response.imageUrl) {
          setFileUrl(response.imageUrl);
          onSuccess('ok');
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    } catch (err) {
      console.log('err', err);
      onError(err);
    }
  };

  const beforeUpload = (file) => {
    const isImage = ['image/jpeg', 'image/png'].includes(file.type);
    if (!isImage) {
      setImageError('You can only upload JPG/PNG files!');
    }
    const isLt5MB = file.size / 1024 / 1024 < 5;
    if (!isLt5MB) {
      setImageError('Image must be smaller than 5MB!');
    }
    return isImage && isLt5MB;
  };

  const uploadProps = {
    name: 'file',
    multiple: false,
    action: '',
    customRequest: handleUpload,
    beforeUpload,
    onChange(info) {
      setIsFile(info.file);
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const handleChange = async (e) => {
    try {
      const response = await uploadImageUsingUrl(e.target.value).unwrap();
      if (response.imageUrl) {
        setIsImageUrl(response.imageUrl);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setImageError('Error uploading file');
    }
  };

  return (
    <AntdModal
      open={visible}
      closable={false}
      centered={true}
      footer={
        <Wrapper>
          <StyledCancelButton onClick={onClose}>Cancel</StyledCancelButton>
          <StyledLineSheetsButton
            onClick={handleImageSave}
            disabled={imageError || (imageUrl === '' && fileUrl === null)}
          >
            Save
          </StyledLineSheetsButton>
        </Wrapper>
      }
      width={790}
    >
      <TitleWrapper>Choose Image</TitleWrapper>
      <div>
        <p>{'Paste Image URL'}</p>
        <AntInput disabled={fileUrl} onBlur={handleChange} placeholder="Image Url" />
        <Wrap>or</Wrap>
        <Dragger disabled={imageUrl} {...uploadProps}>
          <p>Drag and Drop</p>
          <p>or</p>
          <p>Choose Image from Your Computer</p>
        </Dragger>
        <StyledErrorMessage>{imageError}</StyledErrorMessage>
      </div>
    </AntdModal>
  );
};

export default ImageUploadModal;
