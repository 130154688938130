import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { faPlusCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { setTemplateModalVisibility, setSelectedProductIds } from 'slices/editorSlice';
import TemplateModal from '../TemplateModal';
import { Tooltip } from 'antd';
import {
  StyledListHeader,
  SelectedCount,
  StyleListBody,
  CategoryHeader,
  StyledCheckbox,
  StyledRow,
  StyledCol,
  ProductImageContainer,
  ProductImage,
  ProductBox,
  StyledProductCheckbox,
  StyledFontAwesomeIcon,
  EllipsisTooltip,
  StyledActionButtons,
} from './styles';
import Loader from 'components/Common/Loader';

const ListCategoryProducts = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.editor.linesheetData.categories);
  const [categoriesClone, setCategoriesClone] = useState({});
  const { showTemplateModal } = useSelector((state) => state.editor);
  const [isLoading, setIsLoading] = useState(true);
  const [selectProductIds, setSelectProductIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const createCategoriesClone = () => {
    setSelectAll(false);
    const deepClone = JSON.parse(JSON.stringify(categories));
    setCategoriesClone(deepClone);
    setIsLoading(false);
  };

  useEffect(() => {
    createCategoriesClone();
  }, [categories]);

  useEffect(() => {
    const checkedIds = Object.values(categoriesClone)
      .flatMap((products) => products)
      .filter((product) => product.checked)
      .map((product) => product.id);
    setSelectProductIds(checkedIds);
  }, [categoriesClone]);

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      createCategoriesClone();
    } else {
      setIsLoading(true);
      setSelectAll(true);
      setCategoriesClone((prevCategories) => {
        const updatedCategories = Object.keys(prevCategories).reduce((acc, category) => {
          acc[category] = prevCategories[category].map((product) => ({
            ...product,
            checked: true,
          }));
          return acc;
        }, {});

        return updatedCategories;
      });
      setIsLoading(false);
    }
  };

  const handleCheckAllChange = (category, e) => {
    const checked = e.target.checked;
    setCategoriesClone((prevCategories) => {
      const updatedCategories = { ...prevCategories };
      updatedCategories[category] = updatedCategories[category].map((product) => ({
        ...product,
        checked,
      }));
      return updatedCategories;
    });
  };

  const handleProductClick = (category, productIdx) => {
    setCategoriesClone((prevCategories) => {
      const updatedProducts = prevCategories[category].map((product, idx) => {
        if (idx === productIdx) {
          return {
            ...product,
            checked: !product.checked,
          };
        }
        return product;
      });

      return {
        ...prevCategories,
        [category]: updatedProducts,
      };
    });
  };

  const handleDragStart = (productId) => {
    if (selectProductIds.length === 0 && productId !== undefined) {
      dispatch(setSelectedProductIds([productId]));
    } else if (selectProductIds.length > 0) {
      dispatch(setSelectedProductIds(selectProductIds));
    }
  };

  const handleTemplateModalClose = () => {
    createCategoriesClone();
  };

  const handleTemplateModalOpen = () => {
    dispatch(setTemplateModalVisibility({ show: true }));
    dispatch(setSelectedProductIds(selectProductIds));
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <StyledListHeader>
        <SelectedCount>Selected Products: {selectProductIds.length}</SelectedCount>
        <StyledActionButtons>
          <Tooltip title={selectAll ? 'Unselect All' : 'Select All'} placement="bottom">
            <StyledFontAwesomeIcon
              onClick={() => handleSelectAll(selectAll)}
              icon={faCircleCheck}
            />
          </Tooltip>
          <Tooltip title={'Select Template'} placement="right">
            <StyledFontAwesomeIcon onClick={handleTemplateModalOpen} icon={faPlusCircle} />
          </Tooltip>
        </StyledActionButtons>
      </StyledListHeader>
      <StyleListBody>
        {Object.entries(categoriesClone).map(([category, products]) => {
          const isCheckedAll = products.every((item) => item.checked);
          const isIndeterminate = products.some((item) => item.checked);

          return (
            <div key={category}>
              <CategoryHeader>
                <StyledCheckbox
                  indeterminate={isIndeterminate && !isCheckedAll}
                  onChange={(e) => handleCheckAllChange(category, e)}
                  checked={isCheckedAll}
                >
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </StyledCheckbox>
              </CategoryHeader>
              <StyledRow>
                {products.map((product, index) => (
                  <StyledCol
                    xs={11}
                    sm={11}
                    xl={7}
                    key={product.id}
                    onClick={() => handleProductClick(category, index)}
                    draggable
                    onDragStart={() => handleDragStart(product.id)}
                    onDragEnd={(event) => event.preventDefault()}
                  >
                    <ProductBox className={product.checked ? 'checked' : ''}>
                      {/* <StyledProductCheckbox checked={product.checked} /> */}
                      <ProductImageContainer>
                        <ProductImage
                          src={product.product_image}
                          alt={product.name}
                          loading="lazy"
                        />
                      </ProductImageContainer>
                    </ProductBox>
                    <EllipsisTooltip>{product.name}</EllipsisTooltip>
                  </StyledCol>
                ))}
              </StyledRow>
            </div>
          );
        })}
      </StyleListBody>
      {!!showTemplateModal && <TemplateModal onClose={handleTemplateModalClose} />}
    </>
  );
};

export default React.memo(ListCategoryProducts);
