import React, { useState } from 'react';
import Loader from '../Loader';
import { useCreateOutboxMutation } from 'api/outboxApi';
import { useGetLinesheetsQuery } from 'api/linesheetApi';
import ComposeEmail from './ComposeEmail';
import { message } from 'antd';
import {
  FooterWrapper,
  ButtonsWrapper,
  StyledCancelButton,
  StyledModal,
  StyledSaveButton,
} from './styles';
import SelectionSection from './SelectionSection';

const SendEmailModal = ({ isOpenModal, toggleModal, selectedLinesheets = [] }) => {
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [selectedLinesheetIds, setSelectedLinesheetIds] = useState(selectedLinesheets);
  const [showComposeModal, setShowComposeModal] = useState(false);
  const [subject, setSubject] = useState('');
  const [messageInput, setMessageInput] = useState('');
  const [createOutbox, { isLoading: createOutboxLoading }] = useCreateOutboxMutation();
  const searchFilter = '';
  const sortBy = 'last_modified';
  const currentPage = 1;
  const pageSize = 25;
  // Get Linesheets Query
  const { data: linesheetsData, isLoading: linesheetsLoading } = useGetLinesheetsQuery({
    searchFilter,
    sortBy,
    currentPage,
    pageSize,
  });

  const handleContinue = () => {
    if (selectedLinesheetIds.length === 0) {
      message.error('Please select linesheets first');
      return;
    }

    const selected = linesheetsData.filter((linesheet) =>
      selectedLinesheetIds.includes(linesheet.id),
    );
    setSelectedRecords(selected);
    setShowComposeModal(true);
  };

  const handleSendEmails = async () => {
    const res = await createOutbox({
      emails: selectedEmails,
      subject,
      message: messageInput,
      linesheetIds: selectedLinesheetIds,
    });
    if (res.data) {
      toggleModal();
      setShowComposeModal(false);
      setSelectedEmails([]);
      message.success(res.data.message);
    } else if (res.error) {
      message.error(res.error.data.message);
    }
  };

  return (
    <>
      <StyledModal
        open={isOpenModal}
        onCancel={toggleModal}
        width={showComposeModal ? 700 : 1200}
        centered
        footer={
          <FooterWrapper>
            <ButtonsWrapper>
              <StyledCancelButton onClick={toggleModal} type="text">
                Cancel
              </StyledCancelButton>
              {showComposeModal && (
                <StyledSaveButton
                  disabled={!selectedEmails.length || subject === ''}
                  onClick={handleSendEmails}
                >
                  Send
                </StyledSaveButton>
              )}
            </ButtonsWrapper>
          </FooterWrapper>
        }
      >
        {showComposeModal ? (
          <ComposeEmail
            setSubject={setSubject}
            setMessageInput={setMessageInput}
            selectedEmails={selectedEmails}
            setSelectedEmails={setSelectedEmails}
            selectedLinesheets={selectedRecords}
          />
        ) : (
          <SelectionSection
            selectedEmails={selectedEmails}
            setSelectedEmails={setSelectedEmails}
            selectedLinesheetIds={selectedLinesheetIds}
            setSelectedLinesheetIds={setSelectedLinesheetIds}
            handleContinue={handleContinue}
            linesheetsData={linesheetsData}
          />
        )}
        <Loader isLoading={createOutboxLoading || linesheetsLoading} />
      </StyledModal>
    </>
  );
};

export default SendEmailModal;
