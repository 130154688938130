import { Menu } from 'antd';
import styled from 'styled-components';

export const MenuLayout = styled.div`
  width: 100%;
  padding: 16px 0;
  .ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span,
  .ant-menu-inline-collapsed
    > .ant-menu-submenu
    .ant-menu-submenu-title
    .ant-menu-item-icon
    + span {
    opacity: 1;
    margin-inline-start: 0px;
    margin-inline-start: 0px !important;
  }
  .ant-menu-inline-collapsed > .ant-menu-item,
  .ant-menu-inline-collapsed > .ant-menu-submenu .ant-menu-submenu-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 2;
    font-size: 12px;
    height: 55px;
  }
`;

export const StyledMenu = styled(Menu)`
  background: #0000;

  .ant-menu-item,
  .ant-menu-submenu-title {
    font-size: 18px;
    font-weight: 400;
    font-family: Muli;
    color: #495057 !important;
    padding: 5px 28px;
    cursor: pointer;
    background: transparent;
    line-height: 50px;
    height: 50px;
    margin-block: 0px;
  }
  .ant-menu-item:hover,
  .ant-menu-submenu-title:hover {
    background: #f8f9fa;
  }
  .ant-menu-submenu-title span a {
    color: #495057;
  }
  .ant-menu-item svg,
  .ant-menu-submenu-title svg {
    width: 28px;
    font-size: 22px !important;
  }
  .ant-menu-item .ant-menu-item-icon + span,
  .ant-menu-submenu-title .ant-menu-item-icon + span {
    margin-inline-start: 20px !important;
  }
  .ant-menu-item-selected {
    color: #07af7c !important;
  }

  .ant-menu-submenu-selected {
    .ant-menu-submenu-title,
    .ant-menu-submenu-title span a {
      color: #07af7c !important;
    }
  }

  .ant-menu-submenu {
    .ant-menu-submenu-title {
      .ant-menu-title-content {
      }
      a {
        display: block !important;
      }
    }
  }
`;

export const StyledLink = styled.a`
  &:active {
    color: #07af7c !important;
  }
`;
