import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import Loader from 'components/Common/Loader';
import googleIcon from 'assets/images/google-hangouts.png';
import rightArrow from 'assets/images/right-arrow.png';
import { useRegisterMutation, useLoginWithGoogleMutation } from 'api/authApi';
import { useGoogleLogin } from '@react-oauth/google';
import { handleSuccessfulLogin, handleLoginError } from 'utils/helper';
import cache from 'cache';
import {
  BackgroundLayout,
  MainLayout,
  OuterLayout,
  InnerLayout,
  BodyContainer,
  StyledHeading,
  GoogleButtonContainer,
  GoogleButtonIcon,
  GoogleButtonText,
  SignUpLabel,
  StyledForm,
  StyledInput,
  BottomButtonsContainer,
  BottomButtonsWrapper,
  SignUpButton,
  LoginLink,
  StyledImage,
} from './styles';

const SignUp = () => {
  const [registerUser, { isLoading }] = useRegisterMutation();
  const [loginWithGoogle] = useLoginWithGoogleMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = StyledForm.useForm();

  const onFinish = async (values) => {
    const res = await registerUser(values);
    if (res.data) {
      message.success('Please verify your email');
    } else if (res.error) {
      if (res.error.data) {
        message.error(res.error.data);
      } else {
        message.error('An error occurred while registering. Please try again.');
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('errorInfo: ', errorInfo);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) =>
      handleSuccessfulLogin(codeResponse, dispatch, cache, navigate, loginWithGoogle),
    onError: handleLoginError,
  });

  return (
    <BackgroundLayout>
      <MainLayout>
        <OuterLayout>
          <InnerLayout>
            <BodyContainer>
              <StyledHeading>Sign Up</StyledHeading>
              <GoogleButtonContainer>
                <GoogleButtonIcon src={googleIcon} alt="Google Icon" />
                <GoogleButtonText onClick={googleLogin}>Continue with Google</GoogleButtonText>
              </GoogleButtonContainer>
              <SignUpLabel>Sign Up with your email address</SignUpLabel>
              <StyledForm
                name="login"
                layout="vertical"
                autoComplete="off"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <StyledForm.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your first name!',
                    },
                  ]}
                >
                  <StyledInput placeholder="First Name" />
                </StyledForm.Item>
                <StyledForm.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your last name!',
                    },
                  ]}
                >
                  <StyledInput placeholder="Last Name" />
                </StyledForm.Item>
                <StyledForm.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    { required: true, message: 'Please input your email!' },
                    { type: 'email', message: 'Please enter a valid email address!' },
                  ]}
                >
                  <StyledInput placeholder="Email address" />
                </StyledForm.Item>
                <StyledForm.Item
                  label="Password"
                  name="password"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please provide password!',
                    },
                    { min: 8, message: 'Password must have a minimum length of 8' },
                    {
                      pattern: new RegExp(
                        '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$',
                      ),
                      message: 'Password must be strong!',
                    },
                  ]}
                >
                  <StyledInput placeholder="Password" type="password" />
                </StyledForm.Item>
                <StyledForm.Item
                  label="Verify Password"
                  name="confirmPassword"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: ' Please provide confirm password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Passwords do not match!'));
                      },
                    }),
                  ]}
                >
                  <StyledInput placeholder="Confirm Password" type="password" />
                </StyledForm.Item>
                <StyledForm.Item
                  label="Industry"
                  name="industry"
                  rules={[
                    {
                      required: true,
                      message: 'Please provide your industry!',
                    },
                  ]}
                >
                  <StyledInput placeholder="Industry" />
                </StyledForm.Item>
                <BottomButtonsContainer>
                  <BottomButtonsWrapper>
                    <SignUpButton htmlType="submit" size="large">
                      Sign Up
                    </SignUpButton>
                    <LoginLink to="/login">
                      Log In
                      <StyledImage src={rightArrow} alt="img" />
                    </LoginLink>
                  </BottomButtonsWrapper>
                </BottomButtonsContainer>
              </StyledForm>
            </BodyContainer>
          </InnerLayout>
        </OuterLayout>
      </MainLayout>
      <Loader isLoading={isLoading} />
    </BackgroundLayout>
  );
};

export default SignUp;
