import styled from 'styled-components';
import { Button, Divider, Modal } from 'antd';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-body {
    padding: 25px;
    overflow-y: auto;
    height: 30rem;
  }

  .ant-modal-footer {
    margin: 0;
  }
`;

export const FooterWrapper = styled.div`
  background-color: #f3f3f3;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
`;

export const StyledCancelButton = styled(Button)`
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  height: 50px;
  width: 116px;
  letter-spacing: 0.96px;
  border-radius: 10000px;
  color: #6c757d;
  &:hover {
    color: #ffffff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
  }
`;

export const HeadingText = styled.h2`
  font-family: Muli;
  font-size: 24px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 1.44px;
  text-align: left;
  color: #000;
  margin: 0;
  margin-bottom: 32px;
`;

export const StyledDivider = styled(Divider)``;

export const SubjectText = styled.h4`
  font-weight: 600;
  font-size: 1.5rem;
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-family: Muli;
  margin-bottom: 0.5rem;
  span {
    font-size: 80%;
    font-weight: 400;
    margin-left: 10px;
    line-height: 1.2;
  }
`;

export const MessageText = styled.h5`
  font-weight: 600;
  font-size: 1.25rem;
  font-family: Muli;
  margin: 0;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  span {
    font-size: 80%;
    font-weight: 400;
    margin-left: 10px;
    line-height: 1.2;
  }
`;

export const DateText = styled.span`
  ont-family: Muli;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
`;
