import styled from 'styled-components';

export const CardsWrapper = styled.div`
  position: relative;
  max-width: 1258px;
  margin: 0 auto;
  padding: 25px 15px;
  background: #fff;
  display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-column-gap: 2%;
`;

export const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  border-top: 1px solid #d7d7d7;
  border-bottom-right-radius: 11px;
  border-bottom-left-radius: 11px;
`;

export const CancelButton = styled.button`
  border: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.96px;
  padding: 15px 30px;
  border-radius: 10000px;
  margin-right: 0.5rem !important;
  color: #6c757d;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: initial;
  margin: 0.25rem;

  &:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
`;

export const PolicyLayout = styled.div`
  width: 95%;
  margin-bottom: 20px;
  font-family: Muli;
  color: #000;
`;

export const Policy = styled.div`
  display: flex;
  flex-wrap: wrap;
  //   margin-right: -15px;
  //   margin-left: -15px;
  justify-content: center !important;
  padding-right: 15px;
  padding-left: 15px;
`;

export const PolicyTitle = styled.h5`
  font-size: 1.25rem;
`;

export const PolicyDescription = styled.div`
  font-size: 16px;
  padding: 0;
  margin: 0;
`;
