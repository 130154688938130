import styled from 'styled-components';

export const MainContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 40px;
  overflow: hidden;
  &.header {
    top: 0;
  }
  &.footer {
    bottom: 0;
  }
  .image-con {
    display: flex;
    position: absolute;
    width: 30px;
    height: 30px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  p {
    position: absolute;
  }
`;
