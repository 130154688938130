import React from 'react';
import styled from 'styled-components';

const VideoResponsive = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
`;

const ResponsiveIframe = styled.iframe`
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: 10px;
  background: transparent;
`;

const YoutubeVideoModel = ({ embedId }) => {
  return (
    <VideoResponsive>
      <ResponsiveIframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </VideoResponsive>
  );
};

export default YoutubeVideoModel;
