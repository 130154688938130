import { Button } from 'antd';
import styled from 'styled-components';

export const MainLayout = styled.div`
  position: relative;
  border-radius: 15px;
  box-shadow: 0 0 59px 0 rgba(83, 78, 78, 0.24);
  background-color: #fff;
`;

export const MostPopularText = styled.div`
  display: block;
  color: #fff;
  background: #02b183;
  text-align: center;
  font-size: 12px;
  line-height: 25px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 15px 15px 0 0;
`;

export const BodyWrapper = styled.div`
  padding: 0 20px;
  min-height: 595px;
`;

export const IconHolder = styled.div`
  max-width: 36px;
  margin: 40px auto 25px;
`;

export const StyledIcon = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

export const Styledlabel = styled.h2`
  font-family: Muli;
  font-size: 24px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  margin: 0 0 12px;
`;

export const PriceText = styled.div`
  font-family: Muli;
  font-size: 24px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #000;
`;

export const StyledSpan = styled.span`
  font-family: Muli;
  font-size: 16px;
  color: #ccc;
`;

export const StyledUl = styled.ul`
  font-family: Muli;
  font-size: 12px;
  line-height: 2.58;
  color: #000;
  padding: 0 25px;
  margin: 0 0 25px;
  list-style: none;
  overflow: hidden;
`;

export const StyledLi = styled.li`
  margin: 0 0 5px;
  position: relative;
`;

export const CheckIconWrapper = styled.span`
  left: -30px;
  position: absolute;
  text-align: center;
  width: 2em;
  top: 0;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  font-family: Muli;
  font-size: 22.5px;
  font-weight: 800;
  text-align: center;
  color: #02b183;
  border-radius: 25.9px;
  border: 2px solid #02b183;
  padding: 0;
  height: unset;
  background-color: #fff;
  margin: 0 0 20px;
  &:hover {
    color: #fff !important;
    background-color: #02b183 !important;
    border-color: #02b183 !important;
  }
`;

export const SubPlanFooter = styled.div`
  font-family: Muli;
  font-size: 12px;
  line-height: 1.25;
  text-align: center;
  background-color: #000;
  color: #fff;
  padding: 30px 20px;
  border-radius: 0 0 15px 15px;
  min-height: 135px;
  box-sizing: border-box;
`;

export const RestrictText = styled.span`
  text-decoration: line-through;
`;
