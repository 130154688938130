import { useDispatch } from 'react-redux';
import { updateImageSettings, moveContentForward, moveContentBackward } from 'slices/editorSlice';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import { faObjectUngroup } from '@fortawesome/free-regular-svg-icons';
import flipVerticalIcon from 'assets/images/flip-vertical-icon.svg';
import flipHorizontalIcon from 'assets/images/flip-horizontal-icon.svg';

const EditorImagePositioningToolsComponent = () => {
  const dispatch = useDispatch();
  const imageObjectEditorClickHandler = (val) => {
    dispatch(updateImageSettings({ key: 'position', value: val }));
  };

  const bringForwardHandler = () => {
    dispatch(moveContentForward());
  };

  const sendBackwardHandler = () => {
    dispatch(moveContentBackward());
  };

  const ImagePositioningTool = [
    {
      name: 'bring-forward',
      tooltip: 'Bring Forward',
      icon: faClone,
      disabled: false,
      action: bringForwardHandler,
    },
    {
      name: 'send-backward',
      tooltip: 'Send Backward',
      icon: faObjectUngroup,
      disabled: false,
      action: sendBackwardHandler,
    },
    {
      name: 'flip-vertical',
      tooltip: 'Flip Image Vertical',
      icon: '',
      image: flipVerticalIcon,
      disabled: false,
      action: imageObjectEditorClickHandler,
    },
    {
      name: 'flip-horizontal',
      tooltip: 'Flip Image Horizontal',
      icon: '',
      image: flipHorizontalIcon,
      disabled: false,
      action: imageObjectEditorClickHandler,
    },
  ];
  return ImagePositioningTool;
};

export default EditorImagePositioningToolsComponent;
