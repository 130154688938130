export const columns = [
  {
    title: 'Image',
    dataIndex: 'images',
    class: 'product-image',
    key: 'images',
    render: (images) => {
      return (
        <div className="table-image-wrapper">
          {images[0] ? (
            <img className="mx-auto img-fluid d-block w-100 mh-100" src={images[0]} alt="" />
          ) : null}
        </div>
      );
    },
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <span>{text}</span>,
    sorter: {
      compare: (a, b) => a.name.localeCompare(b.name),
    }
  },
  {
    title: 'Product Number',
    key: 'productNumber',
    dataIndex: 'productNumber',
    render: (text) => <span>{text}</span>,
    sorter: {
      compare: (a, b) => a.productNumber - b.productNumber,
    },
  },
  {
    title: 'Variants',
    dataIndex: 'numberOfVariants',
    key: 'numberOfVariants',
    sorter: {
      compare: (a, b) => a.numberOfVariants - b.numberOfVariants,
    },
  },
  {
    title: 'Wholesale Price',
    dataIndex: 'wholesalePrice',
    key: 'wholesalePrice',
    render: (text) => (text ? <span>{`$ ${text}`}</span> : `$ ${0}`),
    sorter: {
      compare: (a, b) => parseFloat(a.wholesalePrice) - parseFloat(b.wholesalePrice),
    },
  },
  {
    title: 'Retail Price',
    dataIndex: 'retailPrice',
    key: 'retailPrice',
    render: (text) => (text ? <span>$ {text}</span> : `$ ${0}`),
    sorter: {
      compare: (a, b) => parseFloat(a.wholesalePrice) - parseFloat(b.wholesalePrice),
    },
  },
];
