import styled from 'styled-components';
import AntButton from 'uiKits/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AntTootltip from 'uiKits/Tooltip';

export const StyledHeader = styled.div`
  position: fixed;
  width: 100%;
  height: 36px;
  z-index: 2;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  background: #f8f9fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BackButton = styled(AntButton)`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: initial;
  border-radius: 10000px;
  color: #02b183;
  border-color: #02b183;
  background: none;
  width: 40px;
  height: 25px;
  margin-left: 8px;
  cursor: pointer;
  &:hover {
    color: #fff !important;
    background-color: #02b183 !important;
    border-color: #02b183 !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(2, 177, 131, 0.5) !important;
  }
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

export const HeaderTitle = styled.p`
  font-size: 18px;
  margin: 0px;
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 5px;
  margin-right: 8px;
`;

export const StyledTooltip = styled(AntTootltip)``;

export const StyledButton = styled.button`
  border-radius: 16px;
  width: 40px;
  height: 25px;
  border: 2px solid #000;
  padding: 0;
  background: #fff;
  cursor: pointer;
`;
