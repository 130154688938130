import styled from 'styled-components';
import { AntInput } from 'uiKits/Input';

export const StyledManualProductVariant = styled.div`
  position: relative;
  padding-top: 20px;
`;

export const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledColumn = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  @media (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
`;

export const InputBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    line-height: 1.5;
    flex-basis: 100px;
    max-width: 100px;
    height: auto;
    margin: 0px;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: 0.28px;
  }
  input {
    flex: 1;
    max-width: 256px;
    height: calc(1.5em + 1.3rem + 4px);
    border-width: 2px;
    padding: 0.65rem 0.75rem;
    font-weight: 400;
  }
`;

export const StyledInput = styled(AntInput)``;

export const CustomError = styled.div`
  font-size: 16px;
  text-align: center;
  color: red;
  width: 100%;
`;
