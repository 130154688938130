import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAddingHeaderFooterMode,
  addHeaderFooterContent,
  updateHeaderFooterContent,
  deleteHeaderFooterContent,
} from 'slices/editorSlice';
import { useUploadFileMutation } from 'api/linesheetApi';
import { faAlignLeft, faAlignCenter, faAlignRight } from '@fortawesome/free-solid-svg-icons';
import {
  StyledCancelButton,
  StyledLineSheetsButton,
  Wrapper,
  AntdModal,
  TitleWrapper,
  StyledErrorMessage,
  AntInputStyled,
  StyledLabel,
  StyledDescriptionCon,
  StyledUpload,
  StyledDeleteButton,
  StyledTooltip,
  StyledFontAwesomeIcon,
} from './styles';
import { Flex } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const HeaderFooterModal = () => {
  const dispatch = useDispatch();
  const { addingHeaderFooterMode, isAddingHeader, isAddingFooter } = useSelector(
    (state) => state.editor,
  );
  const { header_content, footer_content } = useSelector((state) => state.editor.linesheetData);
  const [fileUrl, setFileUrl] = useState(null);
  const [uploadFile] = useUploadFileMutation();
  const [imageError, setImageError] = useState('');
  const [description, setDescription] = useState('');
  const [fileList, setFileList] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [imagePosition, setImagePosition] = useState('left');
  const [descriptionPosition, setDescriptionPosition] = useState('left');

  useEffect(() => {
    const updateContent = (content, isAdding) => {
      if (!content || !isAdding) return;

      const description = content?.description?.value;
      if (description) {
        setDescription(description);
      }

      const imageUrl = content?.image?.value;
      if (imageUrl) {
        setFileUrl(imageUrl);
        setFileList([
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: imageUrl,
          },
        ]);
      } else {
        setFileUrl(null);
        setFileList([]);
      }
      setIsUpdating(true);
    };

    updateContent(header_content, isAddingHeader);
    updateContent(footer_content, isAddingFooter);
  }, [header_content, footer_content, isAddingHeader, isAddingFooter]);

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value.trim());
  };

  const handleSave = () => {
    if (fileUrl || description) {
      const action = isUpdating ? updateHeaderFooterContent : addHeaderFooterContent;
      dispatch(action({ imageUrl: fileUrl, description, imagePosition, descriptionPosition }));
    }
    handleClose();
  };

  const handleDelete = () => {
    handleClose();
    dispatch(deleteHeaderFooterContent({ isHeader: isAddingHeader }));
  };

  const handleClose = () => {
    dispatch(setAddingHeaderFooterMode({ show: false, isHeader: false, isFooter: false }));
    setFileUrl(null);
    setImageError('');
    setDescription('');
    setFileList([]);
    setIsUpdating(false);
  };

  const handleUpload = async ({ file, onSuccess, onError }) => {
    setIsUploading(true);
    try {
      const response = await uploadFile(file).unwrap();
      if (response.imageUrl) {
        setFileUrl(response.imageUrl);
        onSuccess('ok');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      onError(error);
    } finally {
      setIsUploading(false);
    }
  };

  const beforeUpload = (file) => {
    const isImage = ['image/jpeg', 'image/png'].includes(file.type);
    const isLt5MB = file.size / 1024 / 1024 < 5;

    if (!isImage) {
      setImageError('You can only upload JPG/PNG files!');
      return false;
    }
    if (!isLt5MB) {
      setImageError('Image must be smaller than 5MB!');
      return false;
    }
    setImageError('');
    return true;
  };

  const uploadProps = {
    name: 'file',
    listType: 'picture-card',
    fileList: fileList,
    customRequest: handleUpload,
    beforeUpload,
    showUploadList: { showPreviewIcon: false },
    onChange(info) {
      setFileList(info.fileList);
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    onRemove(file) {
      setFileList([]);
      setFileUrl(null);
    },
  };

  const TextAlignmentTools = [
    {
      name: 'left',
      tooltip: 'Left Justify',
      icon: faAlignLeft,
    },
    {
      name: 'center',
      tooltip: 'Middle Justify',
      icon: faAlignCenter,
    },
    {
      name: 'right',
      tooltip: 'Right Justify',
      icon: faAlignRight,
    },
  ];

  return (
    <AntdModal
      centered={true}
      open={addingHeaderFooterMode}
      closeIcon={false}
      onCancel={handleClose}
      footer={null}
    >
      <TitleWrapper>Add {isAddingHeader ? 'Header' : 'Footer'}</TitleWrapper>
      <Flex gap="large">
        <div>
          <StyledLabel>Add logo</StyledLabel>
          <div>
            <StyledUpload {...uploadProps}>
              {fileList.length < 1 && (
                <div>
                  <PlusOutlined />
                  <div>Upload</div>
                </div>
              )}
            </StyledUpload>
            <StyledErrorMessage>{imageError}</StyledErrorMessage>
          </div>
          <StyledLabel>Set Position</StyledLabel>
          <Flex gap="large">
            {TextAlignmentTools.map((tool, index) => (
              <div key={index}>
                <StyledTooltip title={tool.tooltip} placement="left">
                  <StyledFontAwesomeIcon
                    className={imagePosition === tool.name ? 'active' : ''}
                    icon={tool.icon}
                    onClick={() => setImagePosition(tool.name)}
                  />
                </StyledTooltip>
              </div>
            ))}
          </Flex>
        </div>
        <StyledDescriptionCon>
          <StyledLabel>{`Add ${isAddingHeader ? 'Header' : 'Footer'} Text`}</StyledLabel>
          <AntInputStyled
            placeholder={`Enter ${isAddingHeader ? 'header' : 'footer'} text here...`}
            rows={3}
            onBlur={handleDescriptionChange}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <StyledLabel className="position">Set Position</StyledLabel>
          <Flex gap="large">
            {TextAlignmentTools.map((tool, index) => (
              <div key={index}>
                <StyledTooltip title={tool.tooltip} placement="left">
                  <StyledFontAwesomeIcon
                    className={descriptionPosition === tool.name ? 'active' : ''}
                    icon={tool.icon}
                    onClick={() => setDescriptionPosition(tool.name)}
                  />
                </StyledTooltip>
              </div>
            ))}
          </Flex>
        </StyledDescriptionCon>
      </Flex>

      <Wrapper>
        <StyledCancelButton onClick={handleClose}>Cancel</StyledCancelButton>
        {isUpdating && <StyledDeleteButton onClick={handleDelete}>Delete</StyledDeleteButton>}
        <StyledLineSheetsButton onClick={handleSave} disabled={isUploading}>
          {isUpdating ? 'Update' : 'Save'}
        </StyledLineSheetsButton>
      </Wrapper>
    </AntdModal>
  );
};

export default HeaderFooterModal;
