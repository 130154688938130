import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import LinesheetThumbnail from 'components/Linesheets/LinesheetThumbnail';
import Loader from 'components/Common/Loader';
import {
  HeaderWrapper,
  Wrappr,
  Container,
  RecentItem,
  AddNewItem,
  ItemWrapper,
  Name,
  Text,
  StyledButton,
  ThumbnailWrappar,
} from './styles';

const RecentLinesheets = ({ linesheetsData, linesheetsLoading }) => {
  const navigate = useNavigate();
  const handleEdit = (id) => {
    navigate(`/linesheet-editor/${id}`);
  };
  return (
    <Container>
      <HeaderWrapper>
        <h4>Recent</h4>
        <Link to={'linesheets'}>
          <Text>See All</Text>
        </Link>
      </HeaderWrapper>
      <Wrappr>
        {linesheetsData.map((linesheet, index) => (
          <ItemWrapper key={index}>
            <StyledButton onClick={() => handleEdit(linesheet.id)}>Edit</StyledButton>
            <ThumbnailWrappar>
              <LinesheetThumbnail linesheet={linesheet} showCheckbox={false} />
            </ThumbnailWrappar>
            <Name>{linesheet.name}</Name>
          </ItemWrapper>
        ))}
        <ItemWrapper>
          <Link to={'linesheets/new'}>
            <AddNewItem>
              <FontAwesomeIcon icon={faPlus} />
            </AddNewItem>
          </Link>
          <Name>{'Create New Linesheet'}</Name>
        </ItemWrapper>
      </Wrappr>
      <Loader isLoading={linesheetsLoading} />
    </Container>
  );
};

export default RecentLinesheets;
