import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LinesheetThumbnail from '../LinesheetThumbnail';
import ImageWithTooltip from 'components/Common/Tooltip';
import downloadIcon from 'assets/images/download-icon.png';
import editIcon from 'assets/images/edit-icon.png';
import { useDownloadMutation } from 'api/linesheetApi';
import Loader from 'components/Common/Loader';
import { inActiveSubscription } from 'slices/authSlice';
import { message } from 'antd';
import {
  MainLayout,
  GridContainer,
  GridItem,
  GridItemTitle,
  GridItemDetails,
  GridItemContent,
  IconWrapper,
} from './styles';

const LinesheetsGrid = ({
  linesheetsData,
  selectedLinesheets,
  setIsSelectedLinesheets,
  showActionButtons,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { planType } = useSelector((state) => state.auth.user.subscription);
  const [download, { isLoading }] = useDownloadMutation();

  const handleEdit = (id) => {
    navigate(`/linesheet-editor/${id}`);
  };

  const handleDownload = async (id) => {
    try {
      const result = await download(id).unwrap();
      message.success(result?.message);
      if (planType === 'oneTime') dispatch(inActiveSubscription());
    } catch (error) {
      message.error('Failed to download linesheet');
    }
  };

  return (
    <MainLayout>
      <GridContainer>
        {linesheetsData.map((linesheet) => (
          <GridItem key={linesheet.id}>
            <LinesheetThumbnail
              linesheet={linesheet}
              selectedLinesheets={selectedLinesheets}
              setIsSelectedLinesheets={setIsSelectedLinesheets}
              showCheckbox={true}
            />
            <GridItemContent>
              <GridItemTitle>{linesheet?.name}</GridItemTitle>
              <GridItemDetails>
                {`${linesheet.number_of_pages} Page(s)`}
                &nbsp;&nbsp;
                {`${linesheet.number_of_products} Product(s)`}
              </GridItemDetails>
              {showActionButtons && (
                <IconWrapper>
                  <ImageWithTooltip
                    src={editIcon}
                    alt="icon"
                    title="Edit Linesheets"
                    placement="bottom"
                    onClick={() => handleEdit(linesheet.id)}
                  />
                  <ImageWithTooltip
                    src={downloadIcon}
                    alt="icon"
                    title="Download Linesheets"
                    placement="bottom"
                    onClick={() => handleDownload(linesheet.id)}
                  />
                </IconWrapper>
              )}
            </GridItemContent>
          </GridItem>
        ))}
      </GridContainer>
      <Loader isLoading={isLoading} />
    </MainLayout>
  );
};

export default LinesheetsGrid;
