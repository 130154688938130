import styled from 'styled-components';

export const StyledPlaygroundPage = styled.div`
  position: relative;
  box-shadow: 4px 4px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 75px;
  &:hover {
    2px solid #02b183;
  }
`;

export const StyledPageContent = styled.div`
  padding: 25px 10px;
  pointer-events: none;
`;

export const StyledPageNumber = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #343a40;
  font-size: 14px;
  margin-top: 8px;
  pointer-events: none;
`;

export const ActionsIcons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 50%;
  right: 22px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
`;
