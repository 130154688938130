import { styled } from 'styled-components';
import RecentBackground from 'assets/images/dashboard-recent-background.png';

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  h4 {
    font-family: Muli;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: 1.44px;
    margin: 0;
    margin-top: 10px;
  }
`;

export const Wrappr = styled.div`
  display: flex;
  gap: 30px;
  padding: 30px;
  overflow: scroll;
`;

export const Container = styled.div`
  border-radius: 10px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  background-color: #fff;
  margin-bottom: 30px;
  position: relative;
  background-image: url(${RecentBackground});
  background-size: cover;
  background-position: 100% 100%;
`;
// Styled component for the recent items
export const RecentItem = styled.div`
  height: 144px;
  width: 200px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5rem;
  border: 2px solid #c0ece0;
  border-radius: 10px;
  overflow: hidden;
`;
export const AddNewItem = styled.div`
  height: 144px;
  width: 200px;
  background-color: #c0ece0;
  color: #fff;
  text-align: center;
  font-size: 50px;
  line-height: 144px;
  transition: background-color 0.15s ease-in-out;
  margin-bottom: 0.5rem;
  border: 2px solid #c0ece0;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    color: #ffffff !important;
    background-color: #02b183 !important;
    border-color: #07af7c !important;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
export const Name = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: Muli;
`;
export const Text = styled.p`
  color: #02b183;
  font-size: 16px;
  font-family: Muli;
  margin: 0;
  margin-top: 10px;
`;

export const StyledButton = styled.button`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 17.5px;
  border: 1px solid #5bccae;
  width: 72px;
  height: 30px;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  padding: 0;
  cursor: pointer;
  color: #07af7c;
  &:hover {
    background-color: ${(props) => (props.disabled ? '#ffffff' : '#07af7c')};
    color: ${(props) => (props.disabled ? 'inherit' : '#ffffff')};
  }
`;

export const ThumbnailWrappar = styled.div`
  width: 200px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
  border: 2px solid #c0ece0;
  border-radius: 10px;
  padding: 10px;
`;
