import React, { useState, useRef, useEffect } from 'react';
import CustomModal from 'components/Common/CustomModal';
import { StyledCancelButton } from 'components/Product/ManualForm/styles';
import { StyledHeader, StyledTitle, StyledFormItem, StyledInput, StyledSaveButton } from './styles';

const ExternalApiForm = ({ showing, title, inputs, onClose, onSubmit }) => {
  const [formValues, setFormValues] = useState(
    inputs.reduce((acc, input) => {
      acc[input.key] = input.value;
      return acc;
    }, {}),
  );
  const [invalidFields, setInvalidFields] = useState({});
  const formRef = useRef(null);

  useEffect(() => {
    setFormValues(
      inputs.reduce((acc, input) => {
        acc[input.key] = input.value || '';
        return acc;
      }, {}),
    );
  }, [inputs]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setInvalidFields({
      ...invalidFields,
      [name]: !value,
    });
  };

  const renderInputs = () => {
    if (!inputs || inputs.length === 0) return null;

    return inputs.map((input, index) => {
      const isInvalid = invalidFields[input.key];
      return (
        <StyledFormItem
          key={index}
          label={input.name}
          name={input.name}
          required
          rules={[{ required: true, message: `Please provide a valid ${input.name}!` }]}
        >
          <StyledInput
            type={input.type}
            name={input.key}
            defaultValue={input.value}
            onChange={handleInputChange}
            style={isInvalid ? { border: '1px solid red' } : {}}
          />
        </StyledFormItem>
      );
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    // Check for empty fields
    const newInvalidFields = {};
    inputs.forEach((input) => {
      if (!formValues[input.key]) {
        newInvalidFields[input.key] = true;
      }
    });

    setInvalidFields(newInvalidFields);

    if (Object.keys(newInvalidFields).length > 0) {
      return;
    }

    onSubmit(formValues);
  };

  return (
    <CustomModal
      showing={showing}
      footer={
        <>
          <StyledCancelButton onClick={() => onClose()}>Cancel</StyledCancelButton>
          <StyledSaveButton onClick={handleFormSubmit}>Save</StyledSaveButton>
        </>
      }
      maxWidth="500"
    >
      <StyledHeader>
        <StyledTitle>{title + ' API Info'}</StyledTitle>
      </StyledHeader>
      <form ref={formRef} onSubmit={handleFormSubmit}>
        {renderInputs()}
      </form>
    </CustomModal>
  );
};

export default ExternalApiForm;
