import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Space, message } from 'antd';
import { useCreateLinesheetMutation } from 'api/linesheetApi';
import ImageWithTooltip from 'components/Common/Tooltip';
import Loader from 'components/Common/Loader';
import { faCopy, faPlus, faTrash, faUpload, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import {
  MainLayout,
  ProductHeading,
  SytledTitle,
  StyledSelectCategories,
  RightContainer,
  StyledLineSheetsButton,
} from './styles';
import SearchBar from '../SearchBar';
import LinesheetsName from 'components/Linesheets/LinesheetName';

const Toolbar = ({
  handleDeleteProducts,
  handleDuplicateProducts,
  selectedRows,
  handleDownloadCsv,
  searchFilter,
  setSearchFilter,
  handleSearchChange,
  categories,
  setCategoryFilter,
  setIsAssignModal,
  categoryFilter,
  setIsShowProdListing,
  isSelectAll,
}) => {
  const [isLinesheet, setIsLinesheet] = useState(false);
  const [selectedName, setSelectedName] = useState('');
  const [createLinesheet, { isLoading: linesheetIsLoading }] = useCreateLinesheetMutation();

  const navigate = useNavigate();

  const categoryOptions = categories?.map((category) => ({
    label: category.name,
    value: category.id,
  }));
  const handleClickCreate = () => {
    setIsLinesheet(true);
    setIsShowProdListing(false);
  };

  const backClickHandler = () => {
    setIsLinesheet(false);
  };

  const nameChangeHandler = (event) => {
    setSelectedName(event.target.value);
  };

  const handleCreate = async () => {
    setIsLinesheet(true);
    // create payload to create linesheet
    let payload = {
      name: selectedName,
      productIds: selectedRows,
      isSelectAllProducts: isSelectAll,
      isSelectAllCategories: false,
    };
    const res = await createLinesheet(payload);
    if (!res?.data?.id) {
      message.error(res?.error?.data?.message);
      return;
    }
    navigate(`/linesheet-editor/${res?.data?.id}`);
  };

  return (
    <MainLayout>
      {isLinesheet ? (
        <LinesheetsName
          handleCreate={handleCreate}
          backClickHandler={backClickHandler}
          nameChangeHandler={nameChangeHandler}
          selectedName={selectedName}
        />
      ) : (
        <Row justify="space-between">
          <Col>
            <ProductHeading>
              <Space>
                <SytledTitle>Products</SytledTitle>
                <Link to="/sync">
                  <ImageWithTooltip icon={faPlus} title="Add new product" placement="bottom" />
                </Link>
                <ImageWithTooltip
                  icon={faCopy}
                  onClick={() => handleDuplicateProducts(selectedRows)}
                  title="Duplicate selected products"
                  placement="bottom"
                />
                <ImageWithTooltip
                  icon={faTrash}
                  onClick={handleDeleteProducts}
                  title="Delete selected products"
                  placement="bottom"
                />
                <ImageWithTooltip
                  icon={faLayerGroup}
                  onClick={() => setIsAssignModal(true)}
                  title="Assign Categories"
                  placement="bottom"
                  className="product-toolbar-icon"
                />
                <ImageWithTooltip
                  onClick={handleDownloadCsv}
                  icon={faUpload}
                  title="Export products to Csv"
                  placement="bottom"
                  className="product-toolbar-icon"
                />
              </Space>
            </ProductHeading>
          </Col>
          <Col>
            <RightContainer>
              {categoryFilter ? (
                <StyledLineSheetsButton onClick={() => setCategoryFilter('')}>
                  Clear Filter
                </StyledLineSheetsButton>
              ) : null}
              <StyledSelectCategories
                placeholder="Category"
                onChange={(value) => setCategoryFilter(value)}
                options={categoryOptions}
                value={categoryFilter || 'Category'}
              />

              <SearchBar
                placeholder="Search"
                handleSearchChange={handleSearchChange}
                setSearchFilter={setSearchFilter}
                searchFilter={searchFilter}
              />
              <StyledLineSheetsButton
                disabled={selectedRows && selectedRows.length > 0 ? false : true}
                onClick={handleClickCreate}
              >
                Create Linesheet
              </StyledLineSheetsButton>
            </RightContainer>
          </Col>
        </Row>
      )}
      <Loader isLoading={linesheetIsLoading} />
    </MainLayout>
  );
};

export default Toolbar;
