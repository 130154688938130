import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Dropdown, message } from 'antd';
import Toolbar from '../Common/Toolbar';
import ListingTable from '../Common/ListingTable';
import dotImage from 'assets/images/3dots.png';
import { MainLayout, StyledModal, ActionImage, DropdownWrapper } from './styles';
import {
  useAssignCategoryMutation,
  useDeleteCategoriesMutation,
  useDuplicateCategoriesMutation,
  useGetCategoriesQuery,
} from 'api/categoriesApi';
import Loader from '../Common/Loader';
import CategoryModal from './CreateEditCategoryModal';
import AssignModal from '../Common/AsignModal';
import { useGetProductsQuery } from 'api/productApi';

const Categories = () => {
  const [searchFilter, setSearchFilter] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isSelectAll, setSelectAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [editCateory, setEditCategory] = useState(false);
  const [category, setCategory] = useState();
  const [isAssignModal, setIsAssignModal] = useState(false);
  const [skip, setSkip] = useState(true);
  const [showCatListing, setIsShowCatListing] = useState(true);
  const [assignModalStates, setAssignModalStates] = useState({
    searchFilter: '',
    currentPage: 1,
    pageSize: 50,
    selectedRows: [],
  });

  // Get Categories
  const {
    data: categoriesData,
    isLoading: categoriesLoading,
    refetch: refetchCategories,
  } = useGetCategoriesQuery({ searchFilter, sortBy, sortOrder, currentPage, pageSize });

  // Assign categories
  const [assignCategory, { isLoading: assignLoading }] = useAssignCategoryMutation();

  // Products that  use in Category assgin Modal
  const {
    data: productsData,
    isLoading: productsLoading,
    refetch: refetchProducts,
  } = useGetProductsQuery(
    {
      searchFilter: assignModalStates.searchFilter,
      currentPage: assignModalStates.currentPage,
      pageSize: assignModalStates.pageSize,
      categoryFilter: category?.id,
      productFilter: true,
    },
    { skip },
  );

  // refetch Categories
  useEffect(() => {
    refetchCategories();
  }, [pageSize, searchFilter, currentPage]);

  useEffect(() => {
    if (!skip) {
      refetchProducts();
    }
  }, [assignModalStates.currentPage, assignModalStates.pageSize]);

  // Search Categories
  const handleSearchChange = (e) => {
    setSearchFilter(e.target.value);
  };

  // sort Categories
  const handleSortChange = (column, order) => {
    setSortBy(column);
    setSortOrder(order);
  };

  // Delete Categories

  const [deleteCategories, { isLoading: deleteLoading }] = useDeleteCategoriesMutation();

  const handleDelete = () => {
    if (isSelectAll) {
      deleteCategories({ categoryIds: selectedRows, selectAll: true });
    } else if (selectedRows.length) {
      deleteCategories({ categoryIds: selectedRows, selectAll: false });
    }
  };

  // duplicate Categories
  const [duplicateCategories, { isLoading: duplicateLoading }] = useDuplicateCategoriesMutation();

  const handleDuplicate = () => {
    if (isSelectAll && categoriesData?.total > 50) {
      setIsModalOpen(true);
    } else if (selectedRows.length) {
      const payload = {
        categoryIds: selectedRows,
      };
      duplicateCategories(payload);
      resetSelectedRows();
    } else {
      message.error('Please select some categories');
    }
  };

  // update Category

  const handleEditCategoy = async (category) => {
    setCategory(category);
    setEditCategory(true);
    setIsCreateModal(true);
  };

  const assignCategoryToProducts = async () => {
    if (!assignModalStates.selectedRows.length) {
      message.error('Products are not selected');
      return;
    }

    if (category) {
      const res = await assignCategory({
        categoryId: category.id,
        productIds: assignModalStates.selectedRows,
      });

      if (res.error) {
        message.error('Oops, something went wrong');
        return;
      }
      setIsAssignModal(false);
      resetModalSelectedRows();
      resetSelectedRows();
    }
  };

  // manage Row selection

  const resetSelectedRows = () => {
    setSelectedRows([]);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    if (isSelectAll) {
      setSelectAll(false);
    }
    if (newSelectedRowKeys.length === 0) {
      resetSelectedRows();
      return;
    }
    setSelectedRows(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRows,
    preserveSelectedRowKeys: false,
    onSelectMultiple: () => {},
    onChange: onSelectChange,
  };

  // manage Assign Modal row selection

  const resetModalSelectedRows = () => {
    setAssignModalStates({
      ...assignModalStates,
      selectedRows: [],
    });
  };

  const onModalSelectChange = (newSelectedRowKeys) => {
    if (newSelectedRowKeys.length === 0) {
      resetModalSelectedRows();
      return;
    }

    setAssignModalStates({
      ...assignModalStates,
      selectedRows: newSelectedRowKeys,
    });
  };

  const assignModalrowSelection = {
    selectedRows: assignModalStates.selectedRows,
    preserveSelectedRowKeys: false,
    onChange: onModalSelectChange,
  };

  const handleAssign = (data) => {
    setCategory(data);

    setSkip(false);
    setIsAssignModal(true);
  };

  // actions list
  const items = [
    {
      label: 'Duplicate',
      key: 'duplicate',
    },
    {
      label: 'Delete',
      key: 'delete',
    },
    {
      label: 'Edit',
      key: 'edit',
    },
    {
      label: 'Assign',
      key: 'assign',
    },
  ];

  // columns
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <span>{text}</span>,
      sorter: true,
    },
    {
      title: 'Products',
      key: 'products',
      dataIndex: 'products',
      render: (products) => <span>{products?.length}</span>,
      sorter: true,
    },

    {
      title: 'Last Modified',
      dataIndex: 'lastModified',
      key: 'lastModified',
      render: (text) => <span>{moment(text).fromNow()}</span>,
      sorter: true,
    },
    {
      title: '',
      key: 'action',
      width: '40px',
      render: (record) => (
        <Dropdown
          menu={{
            items,
            onClick: ({ key }) => {
              switch (key) {
                case 'delete':
                  deleteCategories({ categoryIds: [record?.id], selectAll: false });
                  break;
                case 'duplicate':
                  duplicateCategories({ categoryIds: [record?.id] });
                  break;
                case 'assign':
                  handleAssign(record);
                  break;
                case 'edit':
                  handleEditCategoy(record);
                  break;
                default:
                  break;
              }
            },
          }}
          trigger={['click']}
        >
          <DropdownWrapper>
            <ActionImage src={dotImage} alt="icon" />
          </DropdownWrapper>
        </Dropdown>
      ),
    },
  ];

  return (
    <MainLayout className="container">
      <StyledModal
        title="Confirmation"
        open={isModalOpen}
        cancelText="No"
        okText="Yes"
        onOk={() => {
          duplicateCategories({
            categoryIds: selectedRows,
            selectAll: true,
            total: categoriesData.total,
          });
          setIsModalOpen(false);
          resetSelectedRows();
        }}
        onCancel={() => {
          setIsModalOpen(false);
          resetSelectedRows();
        }}
      >
        <p>
          You've selected a large number of categories, so we're processing current action this in
          the background. We'll notify you via email once it's complete. Are you certain about this
          selection?
        </p>
      </StyledModal>
      <Toolbar
        title="Categories"
        searchFilter={searchFilter}
        handleSearchChange={handleSearchChange}
        handleDelete={handleDelete}
        handleDuplicate={handleDuplicate}
        setIsCreateModal={setIsCreateModal}
        setEditCategory={setEditCategory}
        isSelectAll={isSelectAll}
        selectedRows={selectedRows}
        setIsShowCatListing={setIsShowCatListing}
      />
      {showCatListing && (
        <ListingTable
          columns={columns}
          data={categoriesData?.categories}
          pageSize={pageSize}
          setPageSize={setPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          total={categoriesData?.total}
          handleSortChange={handleSortChange}
          rowSelection={rowSelection}
          isSelectAll={isSelectAll}
          selectedRows={selectedRows}
          setSelectAll={setSelectAll}
          setSelectedRows={setSelectedRows}
          type="category"
        />
      )}

      <Loader isLoading={categoriesLoading || deleteLoading || duplicateLoading} />
      {!!isCreateModal && (
        <CategoryModal
          isCreateModal={isCreateModal}
          setIsCreateModal={setIsCreateModal}
          data={category}
          setEditCategory={setEditCategory}
          editCateory={editCateory}
        />
      )}

      {!!isAssignModal && (
        <AssignModal
          isAssignModal={isAssignModal}
          setIsAssignModal={setIsAssignModal}
          selectedProducts={selectedRows}
          selectedCategory={category?.id}
          resetParentRows={resetSelectedRows}
          refetchData={refetchProducts}
          parentStates={assignModalStates}
          setParentStates={setAssignModalStates}
          total={productsData?.total}
          dataSource={productsData?.products}
          loading={productsLoading || assignLoading}
          rowSelection={assignModalrowSelection}
          resetRows={resetModalSelectedRows}
          handleAssign={assignCategoryToProducts}
          localeTxt="Category has already assigned to all products"
          type="category"
        />
      )}
    </MainLayout>
  );
};

export default Categories;
