import React from 'react';
import Toolbar from './Toolbar';
import { MainContainer } from './styles';
const Linesheets = () => {
  return (
    <MainContainer className="container">
      <Toolbar />
    </MainContainer>
  );
};

export default Linesheets;
