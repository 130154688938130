import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Product from './Product';
import Text from './Text';
import Image from './Image';
import {
  setContentIsOverflow,
  setSelectedContentId,
  setContentBodyStyle,
  setSelectedPage,
} from 'slices/editorSlice';
import {
  MainContentContainer,
  StyledContentInner,
  StyledContentContainer,
  HorizontalContentHandlerHidden,
  VerticalContentHandlerHidden,
  ContentHandlerUp,
  ContentHandlerDown,
} from './styles';

const Content = ({
  contentData,
  pageId,
  locked,
  pageIdx,
  contentId,
  pageType,
  pageWidth,
  pageHeight,
}) => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const [isOverflow, setIsOverflow] = useState(false);
  const [width, setWidth] = useState(contentData.settings?.width);
  const [height, setHeight] = useState(contentData.settings?.height);
  const [left, setLeft] = useState(contentData.settings?.left);
  const [top, setTop] = useState(contentData.settings?.top);
  const [zIndex, setZIndex] = useState(contentData.settings?.zIndex);
  const { selectedContentId, isEditing, orientation, inPreview } = useSelector(
    (state) => state.editor,
  );
  const linesheetSetting = useSelector((state) => state.editor.linesheetData.settings);
  const isSelected = contentData.unique_content_id === selectedContentId;
  let isResizing = false;

  const renderInner = () => {
    if (!contentData) return null;

    switch (contentData.type) {
      case 'text':
        return <Text data={contentData.text_content.html} type="html" inPreview={inPreview} />;
      case 'image':
        return <Image contentData={contentData} />;
      case 'product':
        return <Product contentData={contentData} inPreview={inPreview} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    setWidth(contentData.settings?.width);
    setHeight(getContentHeight());
    setLeft(contentData.settings?.left);
    setTop(contentData.settings?.top);
    setZIndex(contentData.settings?.zIndex);
  }, [contentData]);

  const checkContentOverflow = useCallback(() => {
    if (pageType === 'custom') return;

    const container = containerRef.current;
    const content = contentRef.current;
    if (
      content.clientWidth > container.clientWidth ||
      content.clientHeight > container.clientHeight
    ) {
      setIsOverflow(true);
      dispatch(setContentIsOverflow({ pageIdx, contentId, isOverflow: true }));
    } else {
      dispatch(setContentIsOverflow({ pageIdx, contentId, isOverflow: false }));
      setIsOverflow(false);
    }
  }, [
    contentData.text_content,
    orientation,
    linesheetSetting,
    contentData.settings.width,
    contentData.settings.height,
  ]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      checkContentOverflow();
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [checkContentOverflow]);

  const getContentHeight = () => {
    if (contentData.type === 'image') {
      return contentData.image_content?.settings?.height;
    } else {
      return contentData.settings?.height;
    }
  };

  const onResizeStart = (e, direction) => {
    e.preventDefault();
    if (!isSelected) return;

    isResizing = true;
    // handleContentClick();
    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = width;
    const startHeight = height;
    const startTop = top;
    const startLeft = left;
    let newWidth = width;
    let newHeight = height;
    let newTop = top;
    let newLeft = left;
    const onMouseMove = (moveEvent) => {
      const deltaX = moveEvent.clientX - startX;
      const deltaY = moveEvent.clientY - startY;

      switch (direction) {
        case 'right':
          newWidth = startWidth + deltaX;
          setWidth(newWidth);
          break;
        case 'bottom':
          newHeight = startHeight + deltaY;
          setHeight(newHeight);
          break;
        case 'left':
          newWidth = startWidth - deltaX;
          setWidth(newWidth);
          newLeft = startLeft + deltaX;
          setLeft(newLeft);
          break;
        case 'top':
          newHeight = startHeight - deltaY;
          setHeight(newHeight);
          newTop = startTop + deltaY;
          setTop(newTop);
          break;
        case 'top-left':
          newWidth = startWidth - deltaX;
          setWidth(newWidth);
          newHeight = startHeight - deltaY;
          setHeight(newHeight);
          newLeft = startLeft + deltaX;
          setLeft(newLeft);
          newTop = startTop + deltaY;
          setTop(newTop);
          break;
        case 'top-right':
          newWidth = startWidth + deltaX;
          setWidth(newWidth);
          newHeight = startHeight - deltaY;
          setHeight(newHeight);
          newTop = startTop + deltaY;
          setTop(newTop);
          break;
        case 'bottom-left':
          newWidth = startWidth - deltaX;
          setWidth(newWidth);
          newHeight = startHeight + deltaY;
          setHeight(newHeight);
          newLeft = startLeft + deltaX;
          setLeft(newLeft);
          break;
        case 'bottom-right':
          newWidth = startWidth + deltaX;
          setWidth(newWidth);
          newHeight = startHeight + deltaY;
          setHeight(newHeight);
          break;
        default:
          break;
      }
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      dispatch(
        setContentBodyStyle({ width: newWidth, height: newHeight, top: newTop, left: newLeft }),
      );
      isResizing = false;
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const onDragStart = (e) => {
    if (isResizing || isEditing || inPreview) return;

    e.preventDefault();
    handleContentClick();
    const startX = e.clientX;
    const startY = e.clientY;
    const startTop = top;
    const startLeft = left;
    let newTop = top;
    let newLeft = left;

    const onMouseMove = (moveEvent) => {
      const deltaX = moveEvent.clientX - startX;
      const deltaY = moveEvent.clientY - startY;

      newTop = startTop + deltaY;
      newLeft = startLeft + deltaX;

      if (pageType !== 'custom') {
        const maxTop = pageHeight - height;
        const maxLeft = pageWidth - width;

        if (newTop < 0) newTop = 0;
        if (newTop > maxTop) newTop = maxTop;
        if (newLeft < 0) newLeft = 0;
        if (newLeft > maxLeft) newLeft = maxLeft;
      }

      setTop(newTop);
      setLeft(newLeft);
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      dispatch(setContentBodyStyle({ width, height, top: newTop, left: newLeft }));
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const renderResizeHandlers = () => {
    if (locked) return null;

    return (
      <>
        <HorizontalContentHandlerHidden
          show={isSelected ? 'block' : 'none'}
          style={{ width: '100%', height: '8px', left: '0', top: '-4px', cursor: 'n-resize' }}
          onMouseDown={(e) => onResizeStart(e, 'top')}
        />
        <VerticalContentHandlerHidden
          show={isSelected ? 'block' : 'none'}
          style={{ width: '8px', height: '100%', right: '-4px', top: '0', cursor: 'e-resize' }}
          onMouseDown={(e) => onResizeStart(e, 'right')}
        />
        <HorizontalContentHandlerHidden
          show={isSelected ? 'block' : 'none'}
          style={{ width: '100%', height: '8px', left: '0', bottom: '-4px', cursor: 's-resize' }}
          onMouseDown={(e) => onResizeStart(e, 'bottom')}
        />
        <VerticalContentHandlerHidden
          show={isSelected ? 'block' : 'none'}
          style={{ width: '8px', height: '100%', left: '-4px', top: '0', cursor: 'w-resize' }}
          onMouseDown={(e) => onResizeStart(e, 'left')}
        />
        <ContentHandlerUp
          show={isSelected ? 'block' : 'none'}
          style={{ left: '-4px', top: '-4px', cursor: 'nw-resize' }}
          onMouseDown={(e) => onResizeStart(e, 'top-left')}
        />
        <ContentHandlerDown
          show={isSelected ? 'block' : 'none'}
          style={{ right: '-4px', top: '-4px', cursor: 'ne-resize' }}
          onMouseDown={(e) => onResizeStart(e, 'top-right')}
        />
        <ContentHandlerUp
          show={isSelected ? 'block' : 'none'}
          style={{ right: '-4px', bottom: '-4px', cursor: 'se-resize' }}
          onMouseDown={(e) => onResizeStart(e, 'bottom-right')}
        />
        <ContentHandlerDown
          show={isSelected ? 'block' : 'none'}
          style={{ left: '-4px', bottom: '-4px', cursor: 'sw-resize' }}
          onMouseDown={(e) => onResizeStart(e, 'bottom-left')}
        />
      </>
    );
  };

  const handleContentClick = () => {
    if (inPreview) return;

    dispatch(setSelectedContentId(contentData.unique_content_id));
    dispatch(setSelectedPage({ pageId, pageIdx }));
  };

  let contentStyles = {
    width: `${width}px`,
    height: `${height}px`,
    left: `${left}px`,
    top: `${top}px`,
    overflow: 'hidden',
    border: '1px solid transparent',
    position: 'absolute',
    backgroundColor: contentData.settings?.backgroundColor,
    zIndex: zIndex,
  };

  if (isOverflow) {
    contentStyles.border = '1px solid #ec0c0c';
  }

  if (contentData.unique_content_id === selectedContentId) {
    contentStyles.border = '1px solid #02b183';
  }

  let containerId = `playground-content-${contentData.type}`;
  if (contentData.type === 'product') {
    if (!contentData.hasOwnProperty('text_content') || contentData.text_content === null) {
      containerId = 'playground-content-image';
    }
    if (!contentData.hasOwnProperty('image_content') || contentData.image_content === null) {
      containerId = 'playground-content-text';
    }
  }

  return (
    <MainContentContainer
      ref={containerRef}
      onClick={handleContentClick}
      onMouseDown={onDragStart}
      id={containerId}
      style={contentStyles}
    >
      <StyledContentInner ref={contentRef}>
        <StyledContentContainer>{renderInner()}</StyledContentContainer>
      </StyledContentInner>
      {renderResizeHandlers()}
    </MainContentContainer>
  );
};

export default React.memo(Content);
