import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import {
  useGetSourceProductsMutation,
  useImportSelectedProductsMutation,
  useCancelImportSourcesMutation,
} from 'api/externalSourceApi';
import Loader from 'components/Common/Loader';
import { columns } from './productColumn';
import globals from 'websiteGlobals';
import CustomInfiniteScroll from 'components/Common/CustomInfiniteScroll';
import Calculator from '../Calculator';
import SearchBar from 'components/Product/ProductListing/components/SearchBar';
import {
  ExternalApiContainer,
  StyledTable,
  ExternalApiHeader,
  MainTitleContainer,
  ExternalApiProductsApiLogo,
  ExternalApiProductsTitle,
  ApiButtonHolder,
  ImportButton,
  CancelButton,
  StyledRow,
  StyledProductCount,
} from './styles';

const ExternalAPIProducts = ({ method }) => {
  const navigate = useNavigate();
  const [getSourceProducts, { isLoading: productsLoading }] = useGetSourceProductsMutation();
  const [importSelectedProducts] = useImportSelectedProductsMutation();
  const [cancelImportSources] = useCancelImportSourcesMutation();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [nextPageParams, setNextPageParams] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [externalAPI, setExternalAPI] = useState(null);
  const [priceChangeParam, setPriceChangeParam] = useState(2.5);
  const [formulaType, setFormulaType] = useState('wholesale');
  const [page, setPage] = useState(1);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const hasFetchedProducts = useRef(false);
  const [searchFilter, setSearchFilter] = useState('');

  useEffect(() => {
    const fetchProducts = async () => {
      const payload = getRequestPayload();
      const res = await getSourceProducts({ source: method, payload: payload, loadMore: false });
      if (res.data) {
        setProducts(res.data.products);
        setNextPageParams(res.data.params);
        setHasMore(res.data.hasNextPage);
      }
      if (res.error) {
        message.error('Oops! Something went wrong. Please try again later');
      }
      const currExternalAPI = globals.externalAPIs.find((e) => e.id === method);
      setExternalAPI(currExternalAPI);
    };

    if (!hasFetchedProducts.current) {
      fetchProducts();
      hasFetchedProducts.current = true;
    }
  }, [method]);

  const getRequestPayload = () => {
    switch (method) {
      case 'shopify':
        return Object.keys(nextPageParams).length > 0 ? nextPageParams : {};
      case '3dcart':
        return { page: page, limit: 50 };
      case 'squarespace':
        return Object.keys(nextPageParams).length > 0
          ? nextPageParams
          : {
              nextPageUrl: null,
              nextPageCursor: null,
              hasNextPage: false,
            };
      case 'woocommerce':
        return { page: page, limit: 50 };

      case 'amazon':
        return { limit: 20, page_info: nextPageParams.page_info ? nextPageParams.page_info : '' };

      case 'bigcartel':
        return {
          limit: 100,
          page,
          page_info: nextPageParams.page_info ? nextPageParams.page_info : '',
        };
      default:
        return {};
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    if (newSelectedRowKeys.length === 0) {
      setSelectedRows([]);
      return;
    }

    setSelectedRows(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: onSelectChange,
  };

  const importBtnClickHandler = async () => {
    const payload = {
      all: false,
      ids: selectedRows,
      retailPriceMultiplier: priceChangeParam,
      formulaType: formulaType,
    };
    const res = await importSelectedProducts({ source: method, payload: payload });
    if (res.data) {
      message.success(res.data.message);
      setProducts([]);
      setNextPageParams({});
      setHasMore(false);
      navigate('/products');
    }
    if (res.error) {
      message.error('Oops! Something went wrong. Please try again later');
    }
  };

  const cancelBtnClickHandler = async () => {
    await cancelImportSources({ source: method });
    navigate('/sync');
  };

  const onLoadMore = async () => {
    if (!hasMore) return;

    setPage((prevPage) => prevPage + 1);
    setLoadMore(true);
    const payload = getRequestPayload();
    const res = await getSourceProducts({
      source: method,
      payload: payload,
      loadMore: true,
    });
    if (res.data) {
      setProducts((prevProducts) => [...prevProducts, ...res.data.products]);
      setNextPageParams(res.data.params);
      setHasMore(res.data.hasNextPage);
      setLoadMore(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchFilter(e.target.value);
    const lowercasedFilter = e.target.value.toLowerCase();
    const filtered = products.filter((product) =>
      product.name.toLowerCase().includes(lowercasedFilter),
    );
    setFilteredProducts(filtered);
  };

  return (
    <ExternalApiContainer>
      <ExternalApiHeader>
        <StyledRow marginbottom="20px">
          <MainTitleContainer>
            <ExternalApiProductsApiLogo src={externalAPI ? `${externalAPI.logo}` : ''} />
            <ExternalApiProductsTitle>Select Products from Import</ExternalApiProductsTitle>
          </MainTitleContainer>
          <ApiButtonHolder>
            <CancelButton onClick={() => cancelBtnClickHandler()}>Cancel</CancelButton>
            <ImportButton disabled={!selectedRows.length} onClick={() => importBtnClickHandler()}>
              Import
            </ImportButton>
          </ApiButtonHolder>
        </StyledRow>
        <StyledRow>
          <Calculator
            formulaType={formulaType}
            setFormulaType={setFormulaType}
            priceChangeParam={priceChangeParam}
            setPriceChangeParam={setPriceChangeParam}
          />
          <SearchBar
            placeholder="Search"
            handleSearchChange={handleSearchChange}
            setSearchFilter={setSearchFilter}
            searchFilter={searchFilter}
          />
        </StyledRow>
        <StyledRow>
          <StyledProductCount>
            {selectedRows.length > 0
              ? selectedRows.length === 1
                ? selectedRows.length + ' product selected'
                : selectedRows.length + ' products selected'
              : ''}
          </StyledProductCount>
        </StyledRow>
      </ExternalApiHeader>
      <CustomInfiniteScroll
        showSpin={loadMore}
        hasMore={hasMore}
        loadMore={() => onLoadMore()}
        limit={20}
      >
        <StyledTable
          rowKey="_id"
          rowSelection={{
            ...rowSelection,
            selectedRowKeys: selectedRows,
          }}
          columns={columns}
          locale={{ emptyText: 'No Data Found' }}
          dataSource={searchFilter !== '' ? filteredProducts : products}
          pagination={false}
          hideSelectAll={true}
        />
      </CustomInfiniteScroll>
      <Loader isLoading={productsLoading && !loadMore} />
    </ExternalApiContainer>
  );
};

export default ExternalAPIProducts;
