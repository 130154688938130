import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Select, Tooltip, Button } from 'antd';
import AntColorPicker from 'uiKits/ColorPicker';
const { Option } = Select;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: #fff;
  padding: 5px;
  &.active {
    background: lightgray;
    color: #000;
  }
`;

export const StyledRow = styled(Row)`
  align-items: center;
  color: #fff !important;
  margin-bottom: 14px;
  justify-content: space-between;
  width: 100%;
`;

export const StyledSelect = styled(Select)`
  color: #fff !important;
  width: 100%;
  .ant-select-selector {
    border-radius: 3px;
    border: 2px solid #6b757c !important;
    background-color: #242424 !important;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    color: #fff !important;
    line-height: 30px;
  }
  .ant-select-arrow,
  .ant-select-selection-item {
    color: #fff !important;
  }
  .ant-select-selection-placeholder {
    font-family: Muli, Helvetica, Arial, sans-serif;
    color: #fff !important;
  }
`;

export const StyledOption = styled(Option)``;

export const StyledColorPicker = styled(AntColorPicker)`
  width: 50px !important;
`;

export const StyledTooltip = styled(Tooltip)``;

export const StyledLabel = styled.p`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #fff;
  margin: 0 !important;
`;

export const EditorContainer = styled.div`
  position: relative;
  width: 100%;
  .ck-editor__editable,
  .ck-toolbar {
    background-color: #242424 !important;
    color: white;
  }

  .ck-toolbar button {
    color: white !important;
    &:hover,
    &:active {
      color: #000 !important;
    }
  }

  .ck-focused {
    border-color: #ccced1 !important;
  }
`;

export const UpdateButton = styled(Button)`
  position: absolute;
  top: 7px;
  right: 10px;
  font-size: 12px;
  color: #6c757d;
  text-align: center;
  vertical-align: middle;
  padding: 5px;
  font-weight: bold;
  height: 26px;
  &:hover {
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
  }
`;
