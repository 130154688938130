const globals = {
  stripe: {
    stripeKey: process.env.REACT_APP_STRIPE_KEY,
  },
  paypal: {
    monthly_plan_id: 'P-9AL37975GB069461TL25JYYI',
    yearly_plan_id: 'P-4PK02428MC888383RL3PY4KI',
    PAYPAL_CLIENT_ID: process.env.PAYPAL_CLIENT_ID,
  },
  ONE_TIME_PAY: 99.99,
  API: {
    baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000',
    users: {
      loginWithGoogle: { url: '/users/loginWithGoogle', method: 'get' },
    },
  },
  constants: {
    acceptedImageTypes: 'image/*',
    maxImageSizeInBytes: 5 * 1024 * 1024, // 5 MB
    maxImageSizeInMegaBytes: 5,
    debounceDelay: 250,
    debounceDelayShort: 100,
    d3: {
      minScale: 0.01,
      maxScale: 10,
    },
    pageSize: [1123.2, 793.92],
    pageDistanceBetween: 75,
    pageGridCount: 4,
    pageGridSize: 792 + 75, // Math.max(pageSize[0], pageSize[1]) + pageDistanceBetween
    pageNamePaddingTop: 5,
    fonts: [
      {
        name: 'Akzidenz Grotesk',
        key: 'akzidenz-grotesk',
        url: '/fonts/Akzidenz Grotesk/',
      },
      { name: 'Arial', key: 'arial', url: '/fonts/Arial/' },
      {
        name: 'Arial Narrow',
        key: 'arial-narrow',
        url: '/fonts/Arial Narrow/',
      },
      {
        name: 'Arial Black',
        key: 'arial-black',
        url: '/fonts/Arial Black/',
        skipBold: true, //means font weight
        skipBoldItalic: true,
      },
      { name: 'Baskerville', key: 'baskerville', url: '/fonts/Baskerville/' },
      {
        name: 'Bodoni',
        key: 'bodoni',
        url: '/fonts/Bodoni/',
        skipItalic: true,
        skipBold: true,
        skipBoldItalic: true,
      },
      {
        name: 'Century Gothic',
        key: 'CenturyGothic',
        url: '/fonts/Century Gothic/',
        skipBold: true, //means font weight
        skipBoldItalic: true,
      },
      {
        name: 'Times New Roman',
        key: 'TimesNewRoman',
        url: '/fonts/Times New Roman/',
        skipBold: true, //means font weight
        skipBoldItalic: true,
      },
      {
        name: 'Gotham',
        key: 'Gotham',
        url: '/fonts/Gotham/',
        skipBold: true, //means font weight
        skipBoldItalic: true,
      },
      {
        name: 'Georgia',
        key: 'Georgia',
        url: '/fonts/Georgia/',
        skipBold: true, //means font weight
        skipBoldItalic: true,
      },
      {
        name: 'Helvetica',
        key: 'Helvetica',
        url: '/fonts/Helvetica/',
        skipBold: true, //means font weight
        skipBoldItalic: true,
      },
    ],
    fontSizes: [
      '8px',
      '9px',
      '10px',
      '11px',
      '12px',
      '14px',
      '18px',
      '24px',
      '30px',
      '36px',
      '48px',
      '60px',
      '72px',
      '96px',
    ],
  },
  pageTemplates: {
    blanktemplate: {
      templateId: 'blanktemplate',
      name: 'Blank / Custom Template',
      gridSize: [1, 1],
    },
    X2OJD5S05oUvKbOpPfCW42: {
      templateId: 'X2OJD5S05oUvKbOpPfCW42',
      name: '1x1',
      gridSize: [1, 1],
    },
    '2FwCGvCQ9vmyVJxy8bfAgQ8': {
      templateId: '2FwCGvCQ9vmyVJxy8bfAgQ8',
      name: '2x1',
      gridSize: [2, 1],
    },
    rNprQ6jPbCuS7bekVD7Sk6: {
      templateId: 'rNprQ6jPbCuS7bekVD7Sk6',
      name: '3x1',
      gridSize: [3, 1],
    },
    CurNSrQ6jpPb7D7bekVSk6: {
      templateId: 'CurNSrQ6jpPb7D7bekVSk6',
      name: '3x2',
      gridSize: [3, 2],
    },
    pPbCurNSrQ6j7bekVD7Sk6: {
      templateId: 'pPbCurNSrQ6j7bekVD7Sk6',
      name: '3x3',
      gridSize: [3, 3],
    },
    '7bekVDrNprQ6jPbCuS7Sk6': {
      templateId: '7bekVDrNprQ6jPbCuS7Sk6',
      name: '4x1',
      gridSize: [4, 1],
    },
    '7beDrNprQ6jkVPbCuS7Sk6': {
      templateId: '7beDrNprQ6jkVPbCuS7Sk6',
      name: '4x2',
      gridSize: [4, 2],
    },
    bCuS7Sk67beDrNprQ6jkVP: {
      templateId: 'bCuS7Sk67beDrNprQ6jkVP',
      name: '4x3',
      gridSize: [4, 3],
    },
    kVDrNp7berQ6jPbCuS7Sk6: {
      templateId: 'kVDrNp7berQ6jPbCuS7Sk6',
      name: '5x1',
      gridSize: [5, 1],
    },
    '7beCuS7kVDrNpQ6jPrbSk6': {
      templateId: '7beCuS7kVDrNpQ6jPrbSk6',
      name: '5x2',
      gridSize: [5, 2],
    },
    DrNpQ6jPrb7beCuS7kVSk6: {
      templateId: 'DrNpQ6jPrb7beCuS7kVSk6',
      name: '5x3',
      gridSize: [5, 3],
    },
  },
  externalAPIs: [
    {
      id: 'shopify',
      name: 'Shopify',
      logo: require('./assets/images/shopify_logo.png'),
      requirements: [
        { name: 'URL of Store', key: 'endpoint', type: 'text', value: '' },
        { name: 'Password', key: 'token', type: 'text', value: '' },
      ],
    },
    {
      id: 'amazon',
      name: 'Amazon',
      logo: require('./assets/images/amazon_logo.png'),
      requirements: [
        { name: 'Store Name', key: 'endpoint', type: 'text', value: '' },
        { name: 'Client Id', key: 'client_id', type: 'text', value: '' },
        { name: 'Client Secret', key: 'client_secret', type: 'text', value: '' },
        { name: 'Market Place Id', key: 'aws_market_place_id', type: 'text', value: '' },
        { name: 'AWS Authorzation Token', key: 'token', type: 'text', value: '' },
        // { name: 'Seller/Merchant Id', key: 'seller_id', type: 'text', value: '' },
      ],
    },
    // {
    //   id: "rakuten",
    //   name: "Rakuten",
    //   logo: "/images/rakuten_logo.png",
    //   requirements: [{ name: "Store Name", key: "endpoint", type: "text" }],
    // },
    {
      id: 'ebay',
      name: 'Ebay',
      logo: require('./assets/images/ebay_logo.png'),
      requirements: [
        { name: 'Store Name', key: 'endpoint', type: 'text', value: '' },
        { name: 'Access Token', key: 'token', type: 'text', value: '' },
      ],
    },
    {
      id: 'bigcartel',
      name: 'Bigcartel',
      logo: require('./assets/images/bigcartel_logo.png'),
      requirements: [
        { name: 'Store Name', key: 'endpoint', type: 'text', value: '' },
        { name: 'Password', key: 'token', type: 'text', value: '' },
      ],
    },
    {
      id: 'etsy',
      name: 'Etsy',
      logo: require('./assets/images/etsy_logo.png'),
      requirements: [
        { name: 'Store Name', key: 'endpoint', type: 'text', value: '' },
        { name: 'Api Key', key: 'api_key', type: 'text', value: '' },
      ],
    },
    {
      id: 'pinnaclecart',
      name: 'Pinnaclecart',
      logo: require('./assets/images/pinnaclart_logo.png'),
      requirements: [
        { name: 'URL of Store', key: 'endpoint', type: 'text', value: '' },
        { name: 'Username', key: 'username', type: 'text', value: '' },
        { name: 'Password', key: 'password', type: 'password', value: '' },
        { name: 'Token', key: 'token', type: 'text', value: '' },
      ],
    },
    {
      id: 'squarespace',
      name: 'Squarespace',
      logo: require('./assets/images/Squarespace_logo.png'),
      requirements: [{ name: 'Token', key: 'token', type: 'text', value: '' }],
    },
    {
      id: 'magento',
      name: 'Magento',
      logo: require('./assets/images/magento_logo.png'),
      requirements: [
        { name: 'Store URL', key: 'endpoint', type: 'text', value: '' },
        { name: 'Access Token', key: 'token', type: 'text', value: '' },
      ],
    },
    {
      id: '3dcart',
      name: '3dcart',
      logo: require('./assets/images/3DCart_logo.png'),
      requirements: [
        { name: '3dcart URL', key: 'endpoint', type: 'text', value: '' },
        { name: 'PrivateKey', key: 'private_key', type: 'text', value: '' },
        { name: 'Token', key: 'token', type: 'text', value: '' },
      ],
    },
    // {
    //   id: "volusion",
    //   name: "Volusion",
    //   logo: "/images/Volusion_logo.png",
    //   requirements: [
    //     { name: "Store Name", key: "endpoint", type: "text" },
    //     { name: "Token", key: "token", type: "text" },
    //   ],
    // },
    // {
    //   id: "weebly",
    //   name: "Weebly",
    //   logo: "/images/apis/weebly.png",
    //   requirements: [{ name: "Store ID", key: "endpoint", type: "text" }],
    // },
    {
      id: 'woocommerce',
      name: 'Woo Commerce',
      logo: require('./assets/images/woocommerce_logo.png'),
      requirements: [
        { name: 'Store Url', key: 'endpoint', type: 'text', value: '' },
        { name: 'Consumer Key', key: 'username', type: 'text', value: '' },
        { name: 'Consumer Secret', key: 'password', type: 'password', value: '' },
      ],
    },
  ],
  videos: [
    {
      videoCategory: 'getstarted',
      name: 'Getting started with Linesheet',
      description: 'A quick overview of Linesheet.',
      videoData: [
        {
          title: 'How to edit a Linesheet?',
          videoLink: 'pDl98bsAF1c',
        },
        {
          title: 'How to add products manually?',
          videoLink: 'tguOwdcjTWE',
        },
        {
          title: 'How to add products by CSV?',
          videoLink: 'hlLkbiRqtYw',
        },
        {
          title: 'How to Sync Various E-commerce Stores with LInesheet?',
          videoLink: 'Wx_AUfdRW3k',
        },
        {
          title: 'How to sync contacts manually?',
          videoLink: 'UnsPKGRN5pI',
        },
        {
          title: 'How to sync contacts from Gmail?',
          videoLink: 'gm1y81geFQ8',
        },
      ],
    },
    {
      videoCategory: 'designing',
      name: 'Designing a Linesheet',
      description: 'Creative tips to supercharge your sales',
      videoData: [
        {
          title: 'How to make a Linesheet?',
          videoLink: 'c1E7j8l00v4',
        },
        {
          title: 'How to make a Linesheet with the custom template?',
          videoLink: 'EhJzaOeNe-w',
        },
      ],
    },
    {
      videoCategory: 'sharing',
      name: 'Sharing a Linesheet',
      description: 'Stay organised and boost your productivity.',
      videoData: [
        {
          title: 'How to Email a Linesheet?',
          videoLink: 'SjN3SZuW4lU',
        },
      ],
    },
    {
      videoCategory: 'csv',
      name: 'How to Import Products Via CSV Import?',
      description: 'Utilize our manual product uploader tool to create Linesheet',
      videoData: [
        {
          title: 'How to add products by CSV?',
          videoLink: 'hlLkbiRqtYw',
        },
      ],
    },
    {
      videoCategory: 'manually',
      name: 'How to Import Products Manually?',
      description: 'No website? Upload product pics straight from your smartphone or computer',
      videoData: [
        {
          title: 'How to add products manually?',
          videoLink: 'tguOwdcjTWE',
        },
      ],
    },
    {
      videoCategory: 'sync',
      name: 'How to Add Products Through API Sync?',
      description: 'Sync products directly from a selection of E-commerce stores.',
      videoData: [
        {
          title: 'How to add products with API via 3D Cart?',
          videoLink: 'im_3j2wvlKA',
          related: '3dcart',
        },
        {
          title: 'How to add products with API via Pinnacle Cart?',
          videoLink: 'G6stzbfflyc',
          related: 'pinnaclecart',
        },
        {
          title: 'How to add products with API via Woocommerce?',
          videoLink: 'Hrg-d7RWpiI',
          related: 'woo commerce',
        },
        {
          title: 'How to add products with API via Shopify?',
          videoLink: 'dJBte9wpbPg',
          related: 'shopify',
        },
        {
          title: 'How to add products with API via big Cartel?',
          videoLink: 'xZfVwOK55rk',
          related: 'bigcartel',
        },
        {
          title: 'How to add products with API via Magento?',
          videoLink: 'RvCxmkX9tzg',
          related: 'magento',
        },
        {
          title: 'How to add products with API via Squarespace?',
          videoLink: 'fSgYErRnlX0',
          related: 'squarespace',
        },
      ],
    },
  ],
  storeUrls: {
    bigcartelUrl: 'https://bigcartel.wufoo.com/forms/big-cartel-api-application/',
    shpifyUrl: 'https://mystore.shopify.com/',
    amazonDeveloperUrl:
      'https://docs.developer.amazonservices.com/en_UK/dev_guide/DG_Endpoints.html',
    EtsyDev: 'https://www.etsy.com/developers/register',
    EtsyApp: 'https://www.etsy.com/developers/your-apps',
  },
};

export const ObjectToArray = (obj) => {
  if (!obj || typeof obj !== 'object') return null;
  else if (obj instanceof Array) return obj;

  return Object.values(obj);
};

export default globals;
