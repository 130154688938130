import styled from 'styled-components';
import { AntInput } from 'uiKits/Input';
import AntButton from 'uiKits/Button';
export const MainLayout = styled.div`
  position: relative;
  // padding: 48px 55px;
  // margin-top: 10px;
`;

export const HeaderWraper = styled.div`
  display: flex;
  h3 {
    margin: 0;
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 1.2rem;
    margin-bottom: 0.5rem;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-left: auto;
`;

export const StyledCancelButton = styled.button`
  width: 100px;
  font-family: Muli;
  font-size: 1rem;
  font-weight: 600;
  color: #6c757d;
  border: none;
  border-radius: 10000px;
  background-color: inherit; /* or any desired background color */
  margin-right: 0.5rem !important;

  &:hover {
    background-color: #6c757d;
    color: #ffffff;
  }
`;

export const StyledNextButton = styled(AntButton)`
  font-family: Muli;
  line-height: 1.92;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 17.5px;
  border: 2px solid #07af7c;
  background-color: #07af7c;
  color: #ffffff;
  width: 100px;
  height: 35px;
`;

export const Label = styled.p`
  margin: 0;
  margin-bottom: 5px;
  margin-top: 15px;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: 0.28px;
`;

export const StyledInput = styled(AntInput)`
  width: 254px;
  height: 50px;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 24px;
  border-radius: 8px;
`;
