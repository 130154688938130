import { Form } from 'antd';
import styled from 'styled-components';

export const AntForm = styled(Form)`
  .ant-form-item-label > label {
    font-family: Muli;
    line-height: 20px;
    height: auto;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.28px;
    color: #000;
  }

  .ant-form-item {
    margin-bottom: 0.5rem !important;
    padding: 4px !important;
    .ant-form-item-explain-error {
      margin-top: 0.3rem;
    }
  }

  .ant-form-item-label {
    padding: 0px 0 5px;
  }

  @media all and (max-width: 1023px) {
    .ant-form-item-label > label {
      font-size: 12px;
    }
  }

  @media all and (max-width: 768px) {
    .ant-form-item-label {
      margin-bottom: 0;
    }
  }
`;
