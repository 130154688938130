import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CountryDropdown } from 'react-country-region-selector';
import { Col, message } from 'antd';
import Loader from 'components/Common/Loader';
import { setUser } from 'slices/authSlice';
import cache from 'cache';
import { useSubscribeMonthlyMutation } from 'api/authApi';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import {
  ModalHeader,
  StyledInput,
  StyledForm,
  StyledRow,
  StyledButton,
  StyledError,
  FooterWrapper,
  StyledStripeInput,
} from './styles';

const CheckoutForm = ({ subscriptionPlan, handleCancel, billingInfo }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [subscribeUser] = useSubscribeMonthlyMutation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [country, setCountry] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [postal, setPostal] = useState('');
  const [cardExpError, setCardExpError] = useState('');
  const [cardError, setCardError] = useState('');
  const [cvcError, setCvcError] = useState('');
  const [countryError, setCountryError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const ELEMENT_OPTIONS = {
    style: {
      base: {
        maxWidth: '100%',
        backgroundColor: '#ffffff',
        fontFamily: 'Muli',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '1.07',
        letterSpacing: '0.28px',
        border: '2px solid #d7d7d7',
        borderRadius: '10px',
        padding: '15px 10px',
        color: '#000000',
        '::placeholder': {
          color: '#d7d7d7',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  const handleSubscribe = async () => {
    try {
      let cardData = {
        name: firstName + ' ' + lastName,
        address_zip: postal,
        address_country: country,
      };
      const card = await stripe.createToken(elements.getElement(CardNumberElement), cardData);

      let data = {
        subscriptionPlan,
        source: card.token.id,
        switchFlag: null,
        postal: postal,
      };
      //   data.nextBilling = futureBillingEnable ? nextBilling : null;
      const userSubscribed = await subscribeUser(data);

      if (userSubscribed?.data) {
        message.success('User subscribed successfully');
        setIsLoading(false);
        dispatch(setUser(userSubscribed.data));
        cache.setItem('user', userSubscribed.data);
        handleCancel();
      }
      if (userSubscribed?.error) {
        message.error(userSubscribed?.error?.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log('err --->', err);
      setIsLoading(false);
      setErrorMessage('Invalid card info');
      setCardError(true);
      setCvcError(true);
      setCardExpError(true);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setErrorMessage('');
    setCardError('');
    setCvcError('');
    setCardExpError('');
    handleSubscribe();
  };

  const handleCardChange = (event) => {
    switch (event.elementType) {
      case 'cardNumber':
        setCardError(event.empty ? 'Please add card number' : '');
        break;
      case 'cardExpiry':
        setCardExpError(event.empty ? 'Please add Expiry Date' : '');
        break;

      case 'cardCvc':
        setCvcError(event.empty ? 'Please add cvc' : '');
        break;
      default:
    }
  };

  return (
    <>
      <ModalHeader>Checkout</ModalHeader>
      <StyledForm
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          firstNameCard: billingInfo?.firstName,
          lastName: billingInfo?.lastName,
          country: billingInfo?.country,
          postal: billingInfo?.postal,
        }}
      >
        <StyledRow style={{ marginBottom: '20px' }} gutter={[16, 32]}>
          <Col span={12}>
            <StyledForm.Item
              name="firstNameCard"
              label="First"
              rules={[{ required: true, message: 'Please input your first name!' }]}
            >
              <StyledInput
                placeholder="First Name"
                size="large"
                onChange={(val) => setFirstName(val.target.value)}
              />
            </StyledForm.Item>
          </Col>
          <Col span={12}>
            <StyledForm.Item
              name="lastName"
              label="Last"
              rules={[{ required: true, message: 'Please input your last name!' }]}
            >
              <StyledInput
                placeholder="Last Name"
                size="large"
                onChange={(val) => setLastName(val.target.value)}
              />
            </StyledForm.Item>
          </Col>
        </StyledRow>
        <StyledRow gutter={[16, 16]}>
          <Col span={12}>
            <StyledForm.Item
              name="creditCard"
              label="Credit Card Number"
              // rules={[{ required: true, message: 'Please provide card number!' }]}
            >
              <StyledStripeInput error={cardError}>
                <CardNumberElement
                  id="cardNumber"
                  options={ELEMENT_OPTIONS}
                  onChange={handleCardChange}
                />
              </StyledStripeInput>
            </StyledForm.Item>
            {cardError && <StyledError>{cardError}</StyledError>}
          </Col>
          <Col span={6}>
            <StyledForm.Item
              name="ExpDate"
              label="Expiration Date"
              // rules={[{ required: true, message: 'Please provide expiry date!' }]}
            >
              <StyledStripeInput error={cardExpError}>
                <CardExpiryElement
                  id="expiry"
                  className="for-border"
                  onChange={handleCardChange}
                  options={ELEMENT_OPTIONS}
                />
              </StyledStripeInput>
            </StyledForm.Item>
            {cardExpError && <StyledError>{cardExpError}</StyledError>}
          </Col>
          <Col span={6}>
            <StyledForm.Item
              name="code"
              label="Security Code"
              // rules={[{ required: true, message: 'Please provide security code!' }]}
            >
              <StyledStripeInput error={cvcError}>
                <CardCvcElement
                  id="cvc"
                  className="for-border"
                  options={ELEMENT_OPTIONS}
                  onChange={handleCardChange}
                />
              </StyledStripeInput>
            </StyledForm.Item>
            {cvcError && <StyledError>{cvcError}</StyledError>}
          </Col>
        </StyledRow>
        <StyledRow gutter={[16, 16]}>
          <Col span={12}>
            <StyledForm.Item
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: 'Plese select country',
                },
              ]}
            >
              <CountryDropdown
                style={{
                  fontFamily: 'Muli',
                  fontSize: '16px',
                  fontWeight: '400',
                  width: '100%',
                  border: !countryError ? '2px solid #d7d7d7' : '1px solid #ff4d4f',
                  borderRadius: '10px',
                  padding: '15px 10px',
                  background: '#fff',
                }}
                placeholder="Country"
                value={country}
                classes="custom-country-dropdown"
                onChange={(val) => {
                  if (val === '') {
                    setCountryError(true);
                  } else {
                    setCountryError(false);
                  }
                  setCountry(val);
                }}
              />
            </StyledForm.Item>
          </Col>
          <Col span={12}>
            <StyledForm.Item
              name="postal"
              label="Postal Code "
              rules={[{ required: true, message: 'Please enter postal code' }]}
            >
              <StyledInput
                placeholder="Billing Postal Code"
                size="large"
                onChange={(val) => setPostal(val.target.value)}
              />
            </StyledForm.Item>
          </Col>
        </StyledRow>
        {errorMessage && (
          <StyledError style={{ textAlign: 'center' }}>
            <small>{errorMessage}</small>
          </StyledError>
        )}
        <FooterWrapper>
          <StyledButton type="primary" htmlType="submit">
            Confirm Payment
          </StyledButton>
        </FooterWrapper>
      </StyledForm>
      <Loader isLoading={isLoading} />
    </>
  );
};

export default CheckoutForm;
