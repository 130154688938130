import React from 'react';
import {
  StyledRow,
  StyledCol,
  StyledLabel,
  StyleSlider,
  StyledInputNumber,
  StyledTooltip,
} from './styles';

const SliderLayout = ({ tooltipTitle, label, value, onChange }) => {
  return (
    <StyledRow justify="space-between" gap={5}>
      <StyledCol sm={24} xl={4}>
        <StyledTooltip title={tooltipTitle} placement="left">
          <StyledLabel textalign={'left'}>{label}</StyledLabel>
        </StyledTooltip>
      </StyledCol>
      <StyledCol sm={24} xl={19}>
        <StyledRow justify="space-between" gap={5} nomargin={'true'}>
          <StyledCol sm={11} xl={13}>
            <StyleSlider
              name={label}
              min={0}
              max={100}
              onChange={onChange}
              value={value}
              step={1}
            />
          </StyledCol>
          <StyledCol sm={11} xl={10}>
            <StyledInputNumber
              name={label}
              min={0}
              step={1}
              max={100}
              value={value}
              onChange={onChange}
            />
          </StyledCol>
        </StyledRow>
      </StyledCol>
    </StyledRow>
  );
};

export default SliderLayout;
