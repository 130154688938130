import styled from 'styled-components';
import { Spin } from 'antd';

export const InfiniteContainer = styled.div`
  height: 70vh;
  overflow: auto;
`;

export const StyledSpin = styled(Spin)`
  bottom: 40px;
  position: absolute;
  margin: 0 auto;
  width: auto;
  left: 55%;
  transform: translateX(-50%);
`;
