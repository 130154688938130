import { ColorPicker } from 'antd';
import styled from 'styled-components';

export const AntColorPicker = styled(ColorPicker)`
  position: relative;
  width: 100%;
  border-radius: 0;
  border: 2px solid #6b757c !important;
  background-color: #242424 !important;
  .ant-color-picker-color-block {
    width: 100%;
    .ant-color-picker-color-block-inner {
      border-radius: 0;
    }
  }
  .ant-color-picker-trigger-text {
    position: absolute;
    right: 0;
  }
`;
