import React from 'react';
import { Link } from 'react-router-dom';
import apiImg from '../../assets/images/a-pi.png';
import csvImg from '../../assets/images/a-csv.png';
import manualImg from '../../assets/images/a-manual.png';
import {
  SyncContainer,
  SyncDivHolder,
  SyncContentHolder,
  SyncBoxPost,
  SyncCustomButton,
} from './styles';

const Sync = () => {
  return (
    <SyncContainer>
      <SyncDivHolder>
        <h2>Sync</h2>
        <SyncContentHolder>
          <h3>What’s a linesheet without products?</h3>
          <p>
            When you’re using Linesheets.com to create a linesheet for wholesale use, you’ll need to
            get all your goodies into the app. Luckily, there are three ways to do it:
          </p>
          <SyncBoxPost>
            <div className="ImageHolder">
              <img src={apiImg} alt="" />
            </div>
            <div>
              <h4>API</h4>
              <p>
                Using API, you can connect to your existing store and directly import the products
                you’ve already uploaded and listed.
              </p>
            </div>
            <Link to={'/syncApi'}>
              <SyncCustomButton>Connect</SyncCustomButton>
            </Link>
          </SyncBoxPost>

          <SyncBoxPost>
            <div className="ImageHolder">
              <img src={csvImg} alt="" />
            </div>
            <div>
              <h4>CSV</h4>
              <p>
                Upload your products in bulk using our CSV template. Either download our sample
                template, input the necessary info, and upload it back to the app, or use your own
                product export file sourced from your existing store.
              </p>
            </div>
            <Link to={'/products/new?method=csv'}>
              <SyncCustomButton>Upload</SyncCustomButton>
            </Link>
          </SyncBoxPost>

          <SyncBoxPost>
            <div className="ImageHolder">
              <img src={manualImg} alt="" />
            </div>
            <div>
              <h4>Manual</h4>
              <p>
                If you don’t have an existing store and don’t want or need to upload in bulk via
                CSV, you can create your products manually. Use our form to add information like
                Product Name, SKU, Wholesale Price, Retail Price, Variants, and Images.
              </p>
            </div>

            <Link to={'/products/new?method=manual'}>
              <SyncCustomButton>Create</SyncCustomButton>
            </Link>
          </SyncBoxPost>
        </SyncContentHolder>
      </SyncDivHolder>
    </SyncContainer>
  );
};

export default Sync;
