import dotImage from 'assets/images/3dots.png';
import { ActionImage, StyledDropdown, DropdownWrapper } from './styles';

const items = [
  {
    label: 'Duplicate',
    key: 'duplicate',
  },
  {
    label: 'Delete',
    key: 'delete',
  },
  {
    label: 'Edit',
    key: 'edit',
  },
];

const Columns = (
  deleteContacts,
  duplicateContacts,
  totalContacts,
  handleOpenEditModal,
  setSelectedRows,
) => [
  {
    title: 'First Name',
    key: 'firstName',
    dataIndex: 'firstName',
    sorter: true,
    width: '15%',
  },
  {
    title: 'Last Name',
    key: 'lastName',
    dataIndex: 'lastName',
    sorter: true,
    width: '15%',
  },
  {
    title: 'Email',
    key: 'email',
    dataIndex: 'email',
    sorter: true,
    width: '15%',
  },
  {
    title: 'Company Name',
    key: 'companyName',
    dataIndex: 'companyName',
    sorter: true,
    width: '15%',
  },
  {
    title: 'Address',
    key: 'address',
    dataIndex: 'address',
    sorter: true,
    width: '15%',
  },
  {
    title: 'Notes',
    key: 'notes',
    dataIndex: 'notes',
    width: '20%',
    sorter: true,
  },
  {
    title: '',
    key: 'action',
    width: '5%',
    render: (record) => (
      <StyledDropdown
        menu={{
          items,
          onClick: ({ key }) => {
            switch (key) {
              case 'delete':
                deleteContacts({
                  selectedContacts: [record.id],
                  isSelectAll: false,
                  totalContacts,
                });
                setSelectedRows([]);
                break;
              case 'duplicate':
                duplicateContacts({
                  selectedContacts: [record.id],
                  isSelectAll: false,
                  totalContacts,
                });
                break;
              case 'edit':
                handleOpenEditModal(record);
                break;
              default:
                break;
            }
          },
        }}
        placement="bottomRight"
        trigger={['click']}
      >
        <DropdownWrapper>
          <ActionImage src={dotImage} alt="icon" />
        </DropdownWrapper>
      </StyledDropdown>
    ),
  },
];

export default Columns;
