import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from '../../layout';

const PublicRoutes = () => {
  const authState = useSelector(state => state.auth);
  return !authState.isAuthenticated ? (
    <Layout isPrivate={false}>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to='/' />
  );
};

export default PublicRoutes;
