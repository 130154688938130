import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_BASE_URL;

export const handle401Error = async (api, action, { getState }) => {
  console.log('handle401Error called!');
  // Rest of your middleware logic
};

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }),
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(handle401Error),
  endpoints: builder => ({
    updateProfile: builder.mutation({
      query: profileData => ({
        url: 'users/update',
        method: 'POST',
        body: profileData,
      }),
    }),
    getUserInfo: builder.query({
      query: () => '/users/getInfo',
    }),
    updateBilling: builder.mutation({
      query: billingData => ({
        url: 'users/updateBilling',
        method: 'POST',
        body: billingData,
      }),
    }),
    updatePassword: builder.mutation({
      query: passwordData => ({
        url: 'users/updatePassword',
        method: 'POST',
        body: passwordData,
      }),
    }),
  }),
});

export const {
  useUpdateProfileMutation,
  useGetUserInfoQuery,
  useUpdateBillingMutation,
  useUpdatePasswordMutation,
} = profileApi;
