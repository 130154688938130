import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Tooltip, Row } from 'antd';
import {
  StyledColLeft,
  ChecklistLeft,
  ChecklistIcon,
  ChecklistDescription,
  StyledButton,
} from './styles';
import {
  ChecklistRight,
  ChecklistStepContainer,
  ChecklistStep,
  ChecklistStepOption,
  ChecklistOptionText,
  ChecklistButtonContainer,
} from '../styles';

const AddProducts = () => {
  return (
    <Row gutter={24}>
      <StyledColLeft span={15}>
        <ChecklistLeft>
          <ChecklistIcon />
          <h4>Add Your Products</h4>
          <ChecklistDescription>
            Sync up with your current online store and import your product info and pictures.Choose
            from the three options on the right and you’re one step closer to making magic happen!
          </ChecklistDescription>
        </ChecklistLeft>
      </StyledColLeft>
      <Col span={9}>
        <ChecklistRight>
          <ChecklistStepContainer>
            <ChecklistStep>
              <ChecklistStepOption>API</ChecklistStepOption>
              <ChecklistOptionText>
                Connect directly to your existing store and import products with a few quick clicks.
              </ChecklistOptionText>
              <ChecklistButtonContainer>
                <Tooltip
                  title="Select to import products by connecting to your store"
                  placement="bottom"
                >
                  <Link to={'/syncApi'}>
                    <StyledButton>Sync</StyledButton>
                  </Link>
                </Tooltip>
              </ChecklistButtonContainer>
            </ChecklistStep>
            <ChecklistStep>
              <ChecklistStepOption>CSV</ChecklistStepOption>
              <ChecklistOptionText>
                Use our spreadsheet template or upload your own exported file from an existing store
                to bulk import your entire product catalog or bring a new collection on board.
              </ChecklistOptionText>
              <ChecklistButtonContainer>
                <Tooltip title="Upload CSV file" placement="bottom">
                  <Link to={'/products/new?method=csv'}>
                    <StyledButton>Import</StyledButton>
                  </Link>
                </Tooltip>
              </ChecklistButtonContainer>
            </ChecklistStep>
            <ChecklistStep>
              <ChecklistStepOption>Manual</ChecklistStepOption>
              <ChecklistOptionText>
                Just need to upload a single superstar product or add one to an existing collection?
                Use our manual upload and put in the details by hand
              </ChecklistOptionText>
              <ChecklistButtonContainer>
                <Tooltip title="Add new product manually" placement="bottom">
                  <Link to={'/products/new?method=manual'}>
                    <StyledButton>Create</StyledButton>
                  </Link>
                </Tooltip>
              </ChecklistButtonContainer>
            </ChecklistStep>
          </ChecklistStepContainer>
        </ChecklistRight>
      </Col>
    </Row>
  );
};

export default AddProducts;
