import React, { useState } from 'react';
import { StyledTooltip, IconWrapper, StyledImage, StyledFontAwesomeIcon } from './styles';

const ImageWithTooltip = ({ title, placement, onClick, src, icon, alt, className, disabled = false }) => {
  const [visible, setVisible] = useState(false);

  const handleMouseEnter = () => {
    if (!isMobile()) {
      setVisible(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile()) {
      setVisible(false);
    }
  };

  const handleTouchStart = () => {
    setVisible(true);
  };

  const handleTouchEnd = () => {
    setVisible(false);
  };

  const handleClick = () => {
    setVisible(false);
    onClick && onClick();
  };

  const isMobile = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  return (
    <StyledTooltip title={title} placement={placement} open={visible} disabled={disabled}>
      <IconWrapper
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onClick={handleClick}
      >
        {src && <StyledImage src={src} alt={alt} className={className} />}
        {icon && <StyledFontAwesomeIcon icon={icon} className={className} />}
      </IconWrapper>
    </StyledTooltip>
  );
};

export default ImageWithTooltip;
