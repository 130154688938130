import { useDispatch } from 'react-redux';
import { addPageAt, addPageAfterLast, deletePages } from 'slices/editorSlice';

const PreviewMenuOptions = () => {
  const dispatch = useDispatch();

  const options = [
    {
      label: 'Linesheets',
      disabled: true,
    },
    {
      label: 'Add Page Before First',
      onClick: () => dispatch(addPageAt(0)),
    },
    {
      label: 'Add Page After Last',
      onClick: () => dispatch(addPageAfterLast()),
    },
    {
      type: 'divider',
    },
    {
      label: 'Delete All Pages',
      onClick: () => dispatch(deletePages()),
    },
  ];

  return options;
};

export default PreviewMenuOptions;
