import React from 'react';
import Help from 'components/Common/NeedHelp';
import SyncProductApi from './components/SyncProductApi';
import globals from 'websiteGlobals';
import {
  SyncContainer,
  SyncTitle,
  SyncSubTitle,
  StyledRow,
  StyledCol,
  StyledProductApiRow,
} from './styles';

const SyncApi = () => {
  const syncVideos = globals.videos.find((video) => video.videoCategory === 'sync');

  const openLink = (url) => {
    window.open(url, '_blank');
  };

  return (
    <SyncContainer>
      <StyledRow>
        <StyledCol span={14}>
          <SyncTitle level={2}>
            Have an Existing Store? Connect & Import Products Using API!
          </SyncTitle>
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <StyledCol span={14}>
          <SyncSubTitle>
            Select the platform that hosts your existing store from the list below to get started.
          </SyncSubTitle>
          <StyledProductApiRow>
            <SyncProductApi syncVideos={syncVideos} openLink={openLink} />
          </StyledProductApiRow>
        </StyledCol>
        <StyledCol span={10}>
          <Help />
        </StyledCol>
      </StyledRow>
    </SyncContainer>
  );
};
export default SyncApi;
