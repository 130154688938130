import styled from 'styled-components';
import { Table } from 'antd';

export const AntTable = styled(Table)`
  .ant-table-cell {
    font-family: Muli;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-weight: 400;
    line-height: 2.5;
  }

  .ant-table-content {
    overflow-x: auto;
  }

  .ant-table-column-sorter-inner {
    .anticon {
      &.active {
        color: #07af7c;
      }
    }
  }

  @media all and (max-width: 1400px) {
    .ant-table {
      width: 100% !important;
      margin-top: 25px;
    }
    .ant-table .ant-table-thead tr th {
      font-weight: normal;
    }

    .ant-spin-container {
      overflow-x: auto;
    }
  }

  @media all and (max-width: 1100px) {
    .ant-table-wrapper.my-table {
      min-width: 850px !important;
    }
  }

  .ant-table-thead > tr > th {
    padding: 16px 5px;
    font-weight: normal;
  }

  .ant-table-thead th.ant-table-cell {
    font-family: Muli;
    font-size: 12px;
    font-weight: 900;
    color: #b8becd;
    vertical-align: top !important;
  }

  .ant-table-column-sorters {
    font-family: Muli;
    font-size: 12px;
    font-weight: 900;
    color: #b8becd;
  }

  .ant-table-column-sorter-inner {
    display: flex !important;
  }

  .ant-table-fixed-header .ant-table-scroll .ant-table-header {
    overflow: hidden; /* instead of overflow:scroll */
  }

  @media all and (max-width: 1400px) {
    .ant-table-column-sorters {
      font-weight: normal;
    }

    th.ant-table-cell {
      font-weight: normal;
    }

    th.product-image {
      font-weight: normal;
    }
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: rgba(2, 177, 131, 0.2) !important;
    border-color: rgba(0, 0, 0, 0.03);
  }

  ant-table-thead.product-image {
    font-weight: normal;
  }

  .ant-pagination .ant-pagination-item-active {
    border-color: #07af7c;
    a {
      color: #07af7c;
    }
  }

  .ant-pagination-options-size-changer {
    .ant-select-selector {
      border-color: #d9d9d9 !important;
      &:hover {
        border-color: #07af7c !important;
      }
    }
  }
`;
