import { React } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';
import PublicRoutes from './routes/publicRoutes';
import PrivateRoutes from './routes/privatRoutes';
import LoginPage from './Pages/LoginPage';
import DashboardPage from './Pages/DashboardPage';
import SignUpPage from './Pages/SignupPage';
import HelpPage from './Pages/HelpPage';
import cache from './cache';
import { clearUser, setUser } from './slices/authSlice';
import { useVerifyUserQuery } from './api/authApi';
import Videos from './components/Help/Videos';
import VideosTypeModel from './components/Help/VideosTypeModel';
import MyProfile from './components/MyProfile/index';
import LogOut from './components/Logout';
import SyncPage from './Pages/sync';
import ForgetPassswordPage from './Pages/ForgetPasswordPage';
import ResetPasswordPage from './Pages/ResetPasswordPage';
import NewProduct from './Pages/Product/NewProduct';
import globals from './websiteGlobals';
import ProductListing from './components/Product/ProductListing';
import Categories from './components/Categories';
import Loader from './components/Common/Loader';
import VerifyEmail from './components/Common/verifyEmail';
import ContactsPage from './Pages/ContactsPage';
import EmailsPage from './Pages/EmailsPage';
import SyncApiPage from './Pages/SyncApiPage';
import LinesheetsPage from 'Pages/LinesheetsPage';
import NewLinesheetsPage from 'Pages/NewLinesheetPage';
import LinesheetEditorPage from 'Pages/LinesheetEditorPage';

const stripePromise = loadStripe(globals.stripe.stripeKey);

function App() {
  const dispatch = useDispatch();
  const { data: user, isLoading } = useVerifyUserQuery();

  if (isLoading) return <Loader isLoading={isLoading} />;

  if (user) {
    cache.setItem('user', user);
    dispatch(setUser(user));
  } else {
    cache.removeItem('user');
    dispatch(clearUser());
  }

  return (
    <Elements stripe={stripePromise}>
      <Router>
        <Routes>
          <Route element={<PublicRoutes />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<SignUpPage />} />
            <Route path="/reset-password" element={<ForgetPassswordPage />} />
            <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/help" element={<HelpPage />} />
            <Route path="/videos" element={<Videos />} />
            <Route path="/videos/:videoCategory" element={<VideosTypeModel />} />
            <Route path="/profile" element={<MyProfile />} />
            <Route path="/sync" element={<SyncPage />} />
            <Route path="/products" element={<ProductListing />} />
            <Route path="/products/new" element={<NewProduct />} />
            <Route path="/products/new/*" element={<Navigate replace to="/products/new" />} />
            <Route path="/contacts" element={<ContactsPage />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/email-outbox" element={<EmailsPage />} />
            <Route path="/syncApi" element={<SyncApiPage />} />
            <Route path="/linesheets" element={<LinesheetsPage />} />
            <Route path="/linesheets/new" element={<NewLinesheetsPage />} />
            <Route path="/linesheets/new/*" element={<Navigate replace to="/linesheets/new" />} />
            <Route path="/linesheet-editor/:linesheetId" element={<LinesheetEditorPage />} />
          </Route>
          <Route path="/logout" element={<LogOut />} />
          <Route path="/verify-email/:token" element={<VerifyEmail />} />
          <Route path="*" element="NOT FOUND" />
        </Routes>
      </Router>
    </Elements>
  );
}

export default App;
