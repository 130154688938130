import { PAGE_SIZE_OPTIONS } from 'utils/constants';

const Pagination = ({
  currentPage,
  pageSize,
  total,
  onShowSizeChange,
  handlePaginationChange,
}) => ({
  current: currentPage,
  pageSize: pageSize,
  total: total,
  onShowSizeChange: onShowSizeChange,
  showSizeChanger: true,
  pageSizeOptions: PAGE_SIZE_OPTIONS,
  onChange: handlePaginationChange,
});

export default Pagination;
