import React from 'react';
import {
  LinesheetThumbnailContainer,
  StyledCheckbox,
  StyledImageContainer,
  StyledImage,
} from './styles';

const LinesheetThumbnail = ({
  linesheet,
  selectedLinesheets,
  setIsSelectedLinesheets,
  showCheckbox,
}) => {
  const handleSelect = (e) => {
    let value = e.target.value;
    let copySheets = [...selectedLinesheets];
    if (copySheets.includes(value)) {
      // remove from array
      copySheets = copySheets.filter((sheet) => sheet !== value);
      setIsSelectedLinesheets(copySheets);
    } else {
      copySheets.push(value);
      setIsSelectedLinesheets(copySheets);
    }
  };

  return (
    <LinesheetThumbnailContainer background={Boolean(linesheet.thumbnail_url) || !showCheckbox}>
      {showCheckbox && (
        <StyledCheckbox
          onChange={handleSelect}
          value={linesheet.id}
          checked={selectedLinesheets.includes(linesheet.id)}
        />
      )}
      <StyledImageContainer height={showCheckbox}>
        <StyledImage src={linesheet.thumbnail_url} />
      </StyledImageContainer>
    </LinesheetThumbnailContainer>
  );
};

export default LinesheetThumbnail;
