import styled from 'styled-components';

export const ProductContainer = styled.div``;

export const StyledProductInner = styled.div``;

export const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;
