import React from 'react';
import { Col, Row } from 'antd';
import {
  StyledForm,
  StyledInput,
  StyledDivider,
  StyledRegionDropdown,
  StyledCountryDropdown,
  StyledPhoneInput,
} from './styles';

const BillingSection = ({ billingInfo, form, onFinish }) => {
  const selectedCountry = StyledForm.useWatch('country', form);

  const handleFinish = values => {
    form
      .validateFields()
      .then(() => {
        onFinish(values);
      })
      .catch(errorInfo => {
        console.log('Validation failed:', errorInfo);
      });
  };
  return (
    <StyledForm
      name='billing'
      layout='vertical'
      autoComplete='off'
      onFinish={handleFinish}
      form={form}
      initialValues={{
        firstName: billingInfo?.firstName,
        lastName: billingInfo?.lastName,
        address: billingInfo?.address,
        city: billingInfo?.city,
        province: billingInfo?.province,
        postal: billingInfo?.postal,
        country: billingInfo?.country,
        phone: billingInfo?.phone,
      }}
    >
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <StyledForm.Item
            label='First Name'
            name='firstName'
            rules={[
              {
                required: true,
                message: 'Please provide first name',
              },
            ]}
          >
            <StyledInput placeholder='First Name' />
          </StyledForm.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <StyledForm.Item
            label='Last Name'
            name='lastName'
            rules={[
              {
                required: true,
                message: 'Please provide last name',
              },
            ]}
          >
            <StyledInput placeholder='Last Name' />
          </StyledForm.Item>
        </Col>
      </Row>
      <StyledDivider />
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <StyledForm.Item label='Address' name='address'>
            <StyledInput placeholder='Address' />
          </StyledForm.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <StyledForm.Item
            label='City'
            name='city'
            rules={[
              {
                required: true,
                message: 'Please provide city',
              },
            ]}
          >
            <StyledInput placeholder='City' />
          </StyledForm.Item>
        </Col>
      </Row>
      <StyledDivider />
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <StyledForm.Item label='State / Province' name='province'>
            <StyledRegionDropdown
              country={selectedCountry}
              name='province'
              defaultOptionLabel='Select Province'
            />
          </StyledForm.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <StyledForm.Item
            label='Postal Code'
            name='postal'
            rules={[
              {
                required: true,
                message: 'Please provide postal',
              },
            ]}
          >
            <StyledInput placeholder='Postal Code' />
          </StyledForm.Item>
        </Col>
      </Row>
      <StyledDivider />
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <StyledForm.Item
            label='Country'
            name='country'
            rules={[
              {
                required: true,
                message: 'Please provide country',
              },
            ]}
          >
            <StyledCountryDropdown name='country' defaultOptionLabel='Select Country' />
          </StyledForm.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <StyledForm.Item label='Phone' name='phone'>
            <StyledPhoneInput />
          </StyledForm.Item>
        </Col>
      </Row>
    </StyledForm>
  );
};

export default BillingSection;
