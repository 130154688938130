import styled from 'styled-components';
import { Row, Col, Select } from 'antd';

export const MainLayout = styled.div`
  width: 100%;
  padding-bottom: 16px;
  .ant-row {
    gap: 10px;
  }
`;

export const StyledRow = styled(Row)`
  justify-content: space-between !important;
`;

export const StyledCol = styled(Col)`
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
`;

export const ProductHeading = styled.div`
  display: inline-flex;
  align-items: center;
  a {
    color: #000 !important;
  }
  .ant-space-item {
    cursor: pointer;
  }
`;

export const SytledTitle = styled.h4`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin: 0;
  cursor: default;
`;

export const Icon = styled.img`
  margin-left: ${(props) => (props.margin ? props.margin : '5px')};
  max-width: ${(props) => (props.width ? props.width : '37px')};
  height: auto;
  cursor: pointer;
  border: none;
`;

export const IconWrapper = styled.span`
  width: 33px;
  height: 32px;
  border: ${(props) => (props.border ? props.border : '2px solid #000000')};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-bottom: 1px;
  &:hover {
    border: 2px solid #07af7c;
  }
`;

export const StyledSelectCategories = styled(Select)`
  width: 150px;
  cursor: pointer;

  .ant-select-selector {
    border-radius: 17.5px !important;
    border: 2px solid #07af7c !important;
    .ant-select-selection-item {
      font-family: Muli;
      font-size: 12px;
      font-weight: bold;
      color: rgb(191, 191, 191);
    }
  }

  .ant-select-arrow {
    color: #07af7c;
  }
`;

export const RightContainer = styled(Col)`
  display: flex;
  align-content: center;
  gap: 10px;
`;

export const StyledLineSheetsButton = styled.button`
  font-family: Muli;
  font-weight: 700;
  font-size: 12px;
  border-radius: 17.5px;
  border: 2px solid #07af7c;
  background-color: #fff;
  color: #07af7c;
  height: 32px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => (props.disabled ? '#ffffff' : '#07af7c')};
    color: ${(props) => (props.disabled ? 'inherit' : '#ffffff')};
  }

  &:disabled {
    background-color: #f5f5f5; /* Optional: Set a different background for disabled state */
    color: #02b183; /* Optional: Set a different color for disabled state */
    cursor: not-allowed;
    opacity: 0.35;
  }
`;
