import AntForm from 'uiKits/Form';
import styled from 'styled-components';
import { AntInput } from 'uiKits/Input';
import { Button, Divider } from 'antd';

export const StyledForm = styled(AntForm)``;

export const StyledInput = styled(AntInput)`
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.28px;
  max-height: 38px;
  padding: 0.5rem 0.75rem !important;
  max-width: 256px;
  height: calc(1.5em + 1.3rem + 4px);
`;

export const StyledButton = styled(Button)`
  color: #02b183;
  border-color: #02b183;
  font-size: 0.875rem;
  border-radius: 10000px;
  font-weight: 600;
  border-width: 2px;
  &:hover {
    color: #ffffff !important;
    border-color: #02b183 !important;
    background-color: #02b183 !important;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 12px 0px;
`;
