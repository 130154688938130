import React from 'react';
import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  BodyWrapper,
  IconHolder,
  MainLayout,
  MostPopularText,
  PriceText,
  StyledSpan,
  Styledlabel,
  StyledIcon,
  StyledUl,
  CheckIconWrapper,
  StyledLi,
  StyledButton,
  SubPlanFooter,
  RestrictText,
} from './styles';

const SubscriptionCard = ({
  icon,
  type,
  title,
  price,
  offer,
  offerDesc,
  showCheckout,
  setSubscriptionPlan,
}) => {
  const GetListItem = (text) => {
    return text.includes('Unlimited downloads —') && type === 'oneTime' ? (
      <StyledLi>
        <CheckIconWrapper>
          <FontAwesomeIcon fontWeight={900} fontSize={14} color="red" icon={faClose} />
        </CheckIconWrapper>
        <RestrictText>{text}</RestrictText>
      </StyledLi>
    ) : (
      <StyledLi>
        <CheckIconWrapper>
          <FontAwesomeIcon fontWeight={900} color="#02b183" fontSize={14} icon={faCheck} />
        </CheckIconWrapper>
        {text}
      </StyledLi>
    );
  };

  return (
    <>
      <MainLayout>
        {type === 'monthly' && <MostPopularText>Most Popular</MostPopularText>}

        <BodyWrapper>
          <IconHolder>
            <StyledIcon src={icon} />
          </IconHolder>
          <Styledlabel>{title}</Styledlabel>
          <PriceText>
            {price} &nbsp;
            <StyledSpan>{offer}</StyledSpan>
          </PriceText>
          <StyledUl>
            {GetListItem('Unlimited access to professionally built line sheet templates')}
            {GetListItem('Unlimited downloads — create and save as many line sheets as you please')}
            {GetListItem('Diverse designs to suit your product types and industry')}
            {GetListItem('A plethora of customization options')}
            {GetListItem('Easy import and upload capabilities')}
            {GetListItem('Click-button functionality to share finished line sheets')}
          </StyledUl>
          <StyledButton
            title="coming soon!"
            onClick={() => {
              showCheckout(true);
              setSubscriptionPlan(type);
            }}
          >
            Purchase Now
          </StyledButton>
        </BodyWrapper>
        <SubPlanFooter>{offerDesc}</SubPlanFooter>
      </MainLayout>
    </>
  );
};

export default SubscriptionCard;
