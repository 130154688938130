import styled from 'styled-components';
import { Row, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ToolsContainer = styled.div``;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  background: rgb(0, 0, 0);
  padding: 0px 15px;
  min-height: 56px;
  color: #ffffff;
  margin: 0;
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 0.5;
  letter-spacing: normal;
  cursor: default;
`;

export const StyledRow = styled(Row)`
  align-items: center;
  color: #fff !important;
`;

export const StyledGroupTitle = styled.p`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
`;

export const StyledTooltip = styled(Tooltip)``;

export const StyledCard = styled.div`
  background-color: #242424;
  padding: 10px;
  cursor: pointer;
  height: calc(90vh - 76px);
  overflow-y: scroll;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;
