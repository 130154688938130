import React, { useState } from 'react';
import Loader from 'components/Common/Loader';
import LinesheetsGrid from 'components/Linesheets/LinesheetsGrid';
import { useGetContactsQuery } from 'api/contactsApi';
import {
  MainContainer,
  LeftContainer,
  LeftHeader,
  StyledDivider,
  RightContainer,
  RightHeader,
  RightSubHeading,
  TextAreaButtonWrapper,
  StyledTextArea,
  StyledButton,
  ButtonWrapper,
  StyledRightDivider,
  AlphabetIndexContainer,
  AlphabetIndex,
  AlphabetLink,
  ContactGroup,
  ContactItem,
  ListWrapper,
  StyledCheckbox,
  StyledNotAvailableText,
  EmailLabelWrapper,
  StyledTag,
  LinesheetContainer,
} from './styles';

const SelectionSection = ({
  selectedEmails,
  setSelectedEmails,
  selectedLinesheetIds,
  setSelectedLinesheetIds,
  handleContinue,
  linesheetsData
}) => {
  const [selectedAlphabet, setSelectedAlphabet] = useState('');
  const { data: contactsList, isLoading } = useGetContactsQuery({ fetchAll: true });

  const manipulatedContacts =
    contactsList?.contacts &&
    contactsList?.contacts?.map((contact) => ({
      label: contact?.firstName,
      email: contact?.email,
      key: contact?.id,
    }));

  // Function to group contacts by first letter
  const groupContactsByFirstLetter = (contacts) => {
    if (contacts) {
      const groupedContacts = {};
      // Initialize groups A to Z and Others
      for (let i = 65; i <= 90; i++) {
        groupedContacts[String.fromCharCode(i)] = [];
      }
      groupedContacts['Others'] = [];

      // Group contacts by first letter
      contacts?.forEach((contact) => {
        const firstLetter = contact?.label[0].toUpperCase();
        if (groupedContacts[firstLetter]) {
          groupedContacts[firstLetter].push(contact);
        } else {
          groupedContacts['Others'].push(contact);
        }
      });
      return groupedContacts;
    }
  };

  // Sort contacts by label
  const sortedContacts = manipulatedContacts?.sort((a, b) => a.label.localeCompare(b.label));

  // Group contacts by first letter
  const groupedContacts = groupContactsByFirstLetter(sortedContacts);

  const handleAlphabetClick = (letter) => {
    setSelectedAlphabet(letter);
    const element = document.getElementById(letter);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleRemoveEmail = (email) => {
    const filteredEmails = selectedEmails.filter((item) => item !== email);
    setSelectedEmails(filteredEmails);
  };

  const handleContactsCheckbox = (e) => {
    if (e.target.checked) {
      setSelectedEmails([...selectedEmails, e.target.value]);
    } else {
      handleRemoveEmail(e.target.value);
    }
  };

  return (
    <MainContainer>
      <LeftContainer>
        <LeftHeader>Select Linesheet to Sent</LeftHeader>
        <LinesheetContainer>
          {linesheetsData && linesheetsData.length > 0 && (
            <LinesheetsGrid
              linesheetsData={linesheetsData}
              selectedLinesheets={selectedLinesheetIds}
              setIsSelectedLinesheets={setSelectedLinesheetIds}
              showActionButtons={false}
            />
          )}
        </LinesheetContainer>
        <StyledDivider />
      </LeftContainer>
      <RightContainer>
        <RightHeader>Email Linesheet</RightHeader>
        <RightSubHeading>Send your Linesheet up to multiple accounts.</RightSubHeading>
        <TextAreaButtonWrapper>
          <StyledTextArea>
            {selectedEmails.map((email, index) => (
              <StyledTag
                key={`${email}-${index}`}
                closeIcon
                onClose={() => handleRemoveEmail(email)}
              >
                {email}
              </StyledTag>
            ))}
          </StyledTextArea>
          <ButtonWrapper>
            <StyledButton onClick={handleContinue}>Continue</StyledButton>
          </ButtonWrapper>
        </TextAreaButtonWrapper>
        <StyledRightDivider />
        <AlphabetIndexContainer>
          <Loader isLoading={isLoading} />
          {/* Render alphabetical index */}
          {!isLoading && (
            <>
              <AlphabetIndex>
                {Object.keys(groupedContacts).map((letter, index) => (
                  <AlphabetLink
                    key={index}
                    href={`#${letter}`}
                    selected={selectedAlphabet === letter}
                    onClick={() => handleAlphabetClick(letter)}
                  >
                    {letter}
                  </AlphabetLink>
                ))}
              </AlphabetIndex>
              <ListWrapper>
                {/* Render grouped contacts */}
                {Object.entries(groupedContacts).map(([letter, contacts], index) => (
                  <ContactGroup key={index} id={letter} selected={selectedAlphabet === letter}>
                    <h2>{letter}</h2>
                    {contacts.length > 0 ? (
                      contacts.map((contact, index) => (
                        <ContactItem key={index}>
                          <StyledCheckbox
                            id={contact.key}
                            key={`${contact.email}-${contact.key}`}
                            value={contact.email}
                            checked={selectedEmails.includes(contact.email)}
                            onChange={handleContactsCheckbox}
                          />
                          <EmailLabelWrapper htmlFor={contact.label}>
                            <span>{contact.label}</span> <span>{contact.email}</span>
                          </EmailLabelWrapper>
                        </ContactItem>
                      ))
                    ) : (
                      <ContactItem>
                        <StyledCheckbox disabled />
                        <StyledNotAvailableText>None available</StyledNotAvailableText>
                      </ContactItem>
                    )}
                  </ContactGroup>
                ))}
              </ListWrapper>
            </>
          )}
        </AlphabetIndexContainer>
      </RightContainer>
    </MainContainer>
  );
};

export default SelectionSection;
