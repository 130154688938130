import { Button, Modal } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-footer {
    margin: 0;
  }
`;

export const FooterWrapper = styled.div`
  background-color: #f3f3f3;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 8px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
`;

export const StyledCancelButton = styled(Button)`
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  height: 50px;
  width: 116px;
  letter-spacing: 0.96px;
  border-radius: 10000px;
  color: #6c757d;
  &:hover {
    color: #ffffff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
  }
`;

export const StyledSaveButton = styled(Button)`
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  height: 50px;
  width: 100px;
  letter-spacing: 0.96px;
  border-radius: 10000px;
  border-width: 2px;
  color: #fff;
  background-color: #02b183;
  border-color: #02b183;
  &:hover {
    color: #ffffff !important;
    background-color: #028b67 !important;
    border-color: #017f5e !important;
  }
`;
