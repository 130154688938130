import React from 'react';
import GlobalFontSettings from '../GlobalFontSettings';
import { faGear, faSquareCaretUp, faSquareCaretDown } from '@fortawesome/free-solid-svg-icons';
import {
  StyledCard,
  ToolsContainer,
  StyledHeader,
  StyledRow,
  StyledGroupTitle,
  StyledTooltip,
  StyledFontAwesomeIcon,
} from './styles';

const GlobalEditorTools = ({
  stylingState,
  updateProductsSettings,
  showSettings,
  handleSettingsVisibility,
}) => {
  const SettingsRow = ({ label, showSetting, styling, type }) => (
    <StyledRow justify="space-between">
      <StyledGroupTitle>{label}</StyledGroupTitle>
      <StyledTooltip title="Show settings" placement="left">
        <StyledFontAwesomeIcon
          icon={showSetting ? faSquareCaretUp : faSquareCaretDown}
          size="lg"
          onClick={() => handleSettingsVisibility(type)}
        />
      </StyledTooltip>
      {showSetting && (
        <GlobalFontSettings
          key={type}
          fontSettings={styling}
          type={type}
          handleSettingsChange={updateProductsSettings}
        />
      )}
    </StyledRow>
  );

  return (
    <>
      <ToolsContainer>
        <StyledHeader>
          <StyledFontAwesomeIcon icon={faGear} />
          &nbsp; Settings
        </StyledHeader>
        <StyledCard>
          <SettingsRow
            label="Title"
            showSetting={showSettings.title}
            styling={stylingState.titleStyling}
            type="title"
          />
          <SettingsRow
            label="Description"
            showSetting={showSettings.description}
            styling={stylingState.descriptionStyling}
            type="description"
          />
          <SettingsRow
            label="SKU"
            showSetting={showSettings.sku}
            styling={stylingState.skuStyling}
            type="sku"
          />
          <SettingsRow
            label="Wholesale Price"
            showSetting={showSettings.wholesalePrice}
            styling={stylingState.wholesalePriceStyling}
            type="wholesalePrice"
          />
          <SettingsRow
            label="Retail Price"
            showSetting={showSettings.retailPrice}
            styling={stylingState.retailPriceStyling}
            type="retailPrice"
          />
        </StyledCard>
      </ToolsContainer>
    </>
  );
};

export default GlobalEditorTools;
