import styled from 'styled-components';
import { Row, Col, Slider, InputNumber, Tooltip } from 'antd';

export const StyledLabel = styled.p`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.08;
  text-align: ${(props) => (props.textalign ? props.textalign : 'center')};
  color: #fff;
  margin: 0 !important;
`;

export const StyleSlider = styled(Slider)`
  .ant-slider-track,
  .ant-slider-step {
    background: #d3d3d3 !important;
    height: 1px !important;
  }
  .ant-slider-handle {
    inset-block-start: 0px !important;
    border-radius: 100% !important;
    &:focus,
    &::after {
      background-color: #cccccc;
      box-shadow: unset !important;
    }
    &:hover {
      &::after {
        background-color: rgb(2, 177, 131);
        box-shadow: unset !important;
      }
    }
  }
`;

export const StyledInputNumber = styled(InputNumber)`
  height: fit-content;
  width: auto;
  border-radius: 3px;
  border: 2px solid #6b757c !important;
  background-color: #242424 !important;
  .ant-input-number-handler-wrap {
    border-radius: 3px;
    border: 2px solid #6b757c !important;
    background-color: #242424 !important;
    svg {
      color: #ffffff;
    }
  }
  .ant-input-number-input-wrap,
  .ant-input-number-input {
    color: #ffffff;
  }
`;

export const StyledTooltip = styled(Tooltip)``;

export const StyledRow = styled(Row)`
  align-items: center;
  color: #fff !important;
  margin-bottom: ${(props) => (props.nomargin === 'true' ? '0' : '14px')};
  text-align: left;
  gap: ${(props) => (props.gap ? `${props.gap}px` : '0')};
`;

export const StyledCol = styled(Col)``;
