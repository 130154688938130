import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import Loader from 'components/Common/Loader';
import { useCreateCategoryMutation, useUpdateCategoryMutation } from 'api/categoriesApi';
import {
  StyledModal,
  StyledTitle,
  StyledLabel,
  LabelInputWrapper,
  StyledInput,
  FooterWrapper,
  StyledOkButton,
  StyledCancelButton,
  ButtonsWrapper,
  StyledError,
} from './styles';

const CategoryModal = ({ isCreateModal, setIsCreateModal, editCateory, data, setEditCategory }) => {
  const [category, setCategory] = useState('');
  const [error, setError] = useState();

  // create Category

  const [createCatgory, { isLoading }] = useCreateCategoryMutation();

  // update Category

  const [updateCategory, { isLoading: updateLoading }] = useUpdateCategoryMutation();

  useEffect(() => {
    if (editCateory) {
      setCategory(data?.name);
    }
  }, [data]);

  const saveCategory = async () => {
    if (!category) {
      setError('Name is required');
      return;
    } else if (editCateory) {
      await updateCategory({ id: data?.id, name: category });

      message.success('Category updated Successfully');
      setIsCreateModal(false);
      setEditCategory(false);
    } else {
      setEditCategory(false);
      await createCatgory(category);
      message.success('Category created Successfully');
      setIsCreateModal(false);
    }
  };

  const handleChange = (e) => {
    if (error) {
      setError('');
    }
    setCategory(e.target.value);
  };

  return (
    <StyledModal
      title={<StyledTitle>{editCateory ? 'Edit' : 'Create'} Category</StyledTitle>}
      open={isCreateModal}
      footer={
        <FooterWrapper>
          <ButtonsWrapper>
            <StyledCancelButton size="large" type="text" onClick={() => setIsCreateModal(false)}>
              Cancel
            </StyledCancelButton>
            <StyledOkButton size="large" onClick={saveCategory}>
              Ok
            </StyledOkButton>
          </ButtonsWrapper>
        </FooterWrapper>
      }
    >
      {error && <StyledError>*&nbsp;{error} </StyledError>}

      <LabelInputWrapper>
        <StyledLabel>Category Name</StyledLabel>
        <StyledInput size="large" value={category} onChange={handleChange} />
      </LabelInputWrapper>
      <Loader isLoading={isLoading || updateLoading} />
    </StyledModal>
  );
};

export default CategoryModal;
