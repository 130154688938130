import styled from 'styled-components';
import AntButton from 'uiKits/Button';
import { Button, Modal } from 'antd';

export const AntModal = styled(Modal)`
  .ant-modal-content {
    height: 90vh !important;
    padding: 0;
  }
`;

export const MainContainer = styled.div`
  display: flex;
`;

export const ProductsContainer = styled.div`
  padding: 20px;
  flex-basis: 100%;
`;

export const SettingsContainer = styled.div`
  max-width: 280px;
  flex: 0 0 280px;
`;

export const ProductListingContainer = styled.div`
  height: calc(90vh - 72px);
  overflow-y: scroll;
`;

export const StyledRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
`;

export const StyledCol = styled.div`
  height: auto;
  position: relative;
  width: 24%;
`;

export const ProductBox = styled.div`
  padding: 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: #cccccc;
  border: 1px solid transparent;
  cursor: pointer;
  &.checked {
    border: 1px solid #02b183;
    background-color: #c0ece0;
    div {
      background-color: #c0ece0;
    }
  }
  p {
    margin: 0;
  }
`;

export const ProductImageContainer = styled.div`
  display: flex;
  align-items: center;
  background: #cccccc;
  width: 100%;
  height: 64px;
  justify-content: center;
`;

export const ProductImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 20px;
`;

export const BackButton = styled(Button)`
  border-radius: 10000px;
  margin-right: 0.5rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #6c757d;
  text-align: center;
  vertical-align: middle;
  &:hover {
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
  }
`;

export const SaveButton = styled(AntButton)`
  border-radius: 10000px;
  font-weight: 600;
  border-width: 2px;
  font-size: 1rem;
  text-align: center;
  vertical-align: middle;
  line-height: 0.5;
`;

export const StyledTitle = styled.h5`
  font-family: Muli;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 1.44px;
  margin: 0;
`;
