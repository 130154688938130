import styled from 'styled-components';

export const StyledCustomModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  display: ${(props) => (props.showing ? 'flex' : 'none')};
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, .5);
`;

export const CustomModalDialog = styled.div`
  position: relative;
  pointer-events: none;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '1169px')};
  width: 100%;
  &.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .modal-content {
      max-height: none;
      overflow: hidden;
    }

    .modal-body {
      overflow-y: auto;
    }
  }
  &.modal-dialog-centered {
    display: flex;
    align-items: center;
  }

  @media (min-width: 576px) {
    margin: 1.75rem auto;
    &.modal-dialog-centered {
      min-height: calc(100% - 3.5rem);
    }
    &.modal-dialog-scrollable {
      max-height: calc(100% - 3.5rem);
    }
  }
`;

export const CustomModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  outline: 0;
  animation: FadeIn 0.25s ease-in-out both;
`;

export const CustomModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  flex-shrink: 0;
  padding: 0px;
  border-bottom: none;
`;

export const CustomModalTitle = styled.h5`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 1.44px;
  margin: 0;
`;

export const CustomModalBody = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 16px 48px 32px;
  max-height: calc(100vh - 150px);
  overflow: auto;
  width: 100%;
`;

export const CustomModalFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom-right-radius: 11px;
  border-bottom-left-radius: 11px;
  justify-content: center;
  flex-shrink: 0;
  border: none;
  padding: 32px;
  background-color: #f3f3f3;
  width: 100%;
  .action-btn-wrapper {
    visibility: visible !important;
  }
`;
