import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Form, message } from 'antd';
import ProfileSection from './ProfileSection';
import BillingSection from './BillingSection';
import SubscriptionSection from './SubscriptionSection';
import Loader from '../Common/Loader';
import { setUser } from 'slices/authSlice';
import cache from 'cache';
import {
  useUpdateProfileMutation,
  useGetUserInfoQuery,
  useUpdateBillingMutation,
  useUpdatePasswordMutation,
} from 'api/profileApi';
import {
  MainLayout,
  InnerLayout,
  TopSection,
  NameTextWrapper,
  ButtonsWrapper,
  SaveButton,
  CancelButton,
  StyledTabs,
} from './styles';

const MyProfile = () => {
  const userData = useSelector((state) => state.auth.user);
  const location = useLocation();
  const state = location.state;

  const [selectedTab, setSelectedTab] = useState('profile');

  const [Profileform] = Form.useForm();
  const [Billingform] = Form.useForm();
  const [Passwordform] = Form.useForm();
  const dispatch = useDispatch();

  const {
    data: userInfo,
    isLoading: isUserInfoLoading,
    refetch: refetchUserInfo,
  } = useGetUserInfoQuery();
  const [updateProfile, { isLoading: isUpdateProfileLoading }] = useUpdateProfileMutation();
  const [updateBilling, { isLoading: isUpdateBillingLoading }] = useUpdateBillingMutation();
  const [updatePassword, { isLoading: isUpdatePasswordLoading }] = useUpdatePasswordMutation();

  const handleTabChange = (activeKey) => {
    setSelectedTab(activeKey);
  };

  const handleProfileFormFinish = async (values) => {
    const res = await updateProfile(values);
    if (res.data) {
      dispatch(setUser(res.data));
      cache.setItem('user', res.data);
      message.success('Your profile information successfully saved please login again');
    } else if (res.error) {
      message.error(res.error.data.message);
    }
  };

  const handleProfileFormReset = () => {
    Profileform.resetFields();
  };

  const handleBillingFormReset = () => {
    Billingform.resetFields();
  };

  const handleBillingFormFinish = async (values) => {
    const res = await updateBilling(values);
    if (res.data) {
      message.success(res.data.message);
      refetchUserInfo();
    } else if (res.error) {
      message.error(res.error.data.message);
    }
  };

  useEffect(() => {
    if (state && state.key) {
      setSelectedTab(state.key);
    }
  }, [state]);

  const items = [
    {
      key: 'profile',
      label: 'Profile',
      children: (
        <ProfileSection
          onFinish={handleProfileFormFinish}
          userData={userData}
          form={Profileform}
          updatePassword={updatePassword}
          passwordForm={Passwordform}
        />
      ),
    },
    {
      key: 'billing',
      label: 'Billing Info',
      children: (
        <BillingSection
          onFinish={handleBillingFormFinish}
          billingInfo={userInfo?.billingInfo}
          form={Billingform}
        />
      ),
    },
    {
      key: 'subscription',
      label: 'Subscription',
      children: <SubscriptionSection billingInfo={userInfo?.billingInfo} />,
    },
  ];

  return (
    <MainLayout className="container">
      <InnerLayout>
        <TopSection>
          <NameTextWrapper>Hi {userData.firstName}</NameTextWrapper>
          {selectedTab !== 'subscription' ? (
            <ButtonsWrapper>
              {selectedTab === 'profile' && (
                <>
                  <CancelButton onClick={handleProfileFormReset} type="text">
                    Cancel
                  </CancelButton>
                  <SaveButton size="large" htmlType="submit" form="profile">
                    Save Changes
                  </SaveButton>
                </>
              )}
              {selectedTab === 'billing' && (
                <>
                  <CancelButton onClick={handleBillingFormReset} type="text">
                    Cancel
                  </CancelButton>
                  <SaveButton size="large" htmlType="submit" form="billing">
                    Save Changes
                  </SaveButton>
                </>
              )}
            </ButtonsWrapper>
          ) : null}
        </TopSection>
        <StyledTabs activeKey={selectedTab} items={items} onChange={handleTabChange} />
      </InnerLayout>
      <Loader
        isLoading={
          isUserInfoLoading ||
          isUpdateBillingLoading ||
          isUpdatePasswordLoading ||
          isUpdateProfileLoading
        }
      />
    </MainLayout>
  );
};

export default MyProfile;
