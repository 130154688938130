import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { Image, Tooltip } from 'antd';
import AntForm from 'uiKits/Form';
import { AntInput } from 'uiKits/Input';
import AntButton from 'uiKits/Button';
import Background from '../../assets/images/signin-bg-croped.png';

export const BackgroundLayout = styled.div`
  background-image: url(${Background});
  background-size: auto;
  background-position: right bottom;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
`;

export const MainLayout = styled.div`
  display: flex;
  justify-content: center;
  max-width: 29.270833333333336vw;
  margin: 1.75rem auto;

  @media all and (max-width: 600px) {
    max-width: 300px;
    margin: 0 auto;
  }
`;

const slideAndFadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(60px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const OuterLayout = styled.div`
  animation: ${slideAndFadeIn} 750ms ease-in-out both;
  background-color: #fafafa;
  border-radius: 0;
  border: 0;
  width: 100%;
`;

export const InnerLayout = styled.div`
  background-color: #ffffff;
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 1rem;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledLogo = styled(Image)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

export const FormContainer = styled.div`
  max-width: 315px;
  margin: 0 auto;
  margin-top: 2.5rem;
`;

export const ForgetPassword = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
`;

export const ForgetPasswordLink = styled(Link)`
  font-family: Muli;
  font-size: 0.7291666666666666vw;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: 0.28px;
  text-align: left;

  @media all and (max-width: 1023px) {
    font-size: 12px;
  }
`;

export const StyledSmall = styled.small``;

export const StyledForm = styled(AntForm)`
  .ant-form-item {
    margin-bottom: 0.4166666666666667vw;
  }
`;

export const StyledInput = styled(AntInput)``;

export const StyledButton = styled(AntButton)`
  width: 100%;
  font-size: 0.8333333333333334vw;
  border-radius: 20px;
  line-height: 16px;
  letter-spacing: 0.96px;

  @media all and (max-width: 1023px) {
    font-size: 12px;
  }
  @media all and (max-width: 600px) {
    width: 100%;
  }
`;

export const StyledSpan = styled.span`
  display: flex;
  justify-content: center;
  font-family: Muli;
  font-size: 14px;
  margin-top: 0.5rem;
`;

export const StyledOrText = styled.span`
  display: flex;
  justify-content: center;
  font-family: Muli;
  font-size: 14px;
`;

export const GoogleButtonContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border: 2px solid #d7d7d7;
  border-radius: 20px;
  background-color: #fff;
  margin: 8px 0;
  width: 100%;
  height: 40px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;

  @media all and (max-width: 1023px) {
    padding: 6px;
  }
  @media all and (max-width: 600px) {
    width: 100%;
  }
`;

export const GoogleButtonIcon = styled.img`
  max-width: 1.3541666666666667vw;

  @media all and (max-width: 1023px) {
    max-width: 18px;
  }
`;

export const GoogleButtonText = styled.span`
  color: #000;
  font-family: Muli;
  font-size: 0.7291666666666666vw;
  font-weight: 600;
  line-height: 1.5;

  @media all and (max-width: 1023px) {
    font-size: 12px;
  }
`;

export const TextCenterContainer = styled.div`
  text-align: center;
`;

export const DontHaveAccountParagraph = styled.p`
  font-family: Muli;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  font-size: 15.5px;
  font-stretch: normal;
  line-height: 1.1;
  color: #000;
  margin-bottom: 1rem;
`;

export const CreateAccountLink = styled(Link)`
  font-family: Muli;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  font-size: 1.2239583333333333vw;
  text-decoration: underline;
  color: #07af7c;
  line-height: 1.9;
  &:hover {
    color: #07af7c;
  }

  @media all and (max-width: 1023px) {
    font-size: 15px;
  }
`;

export const RightsContainer = styled.div`
  font-size: 0.625vw;
  font-family: Muli;
  line-height: 2.46;
  color: #000;
  text-align: center;

  @media all and (max-width: 1023px) {
    font-size: 10px;
  }
`;

export const RightsLink = styled.a`
  color: #000;
  margin: 0 0.78125vw;
  &:hover {
    color: #000;
  }

  @media all and (max-width: 600px) {
    margin: 0 5px;
  }
`;

export const StyledTooltip = styled(Tooltip)``;
