import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Space, message } from 'antd';
import SearchBar from 'components/Product/ProductListing/components/SearchBar';
import LinesheetsGrid from '../LinesheetsGrid';
import ImageWithTooltip from 'components/Common/Tooltip';
import Loader from 'components/Common/Loader';
import SendEmailModal from 'components/Common/SendEmailModal';
import { useGetLinesheetsQuery, useDeleteLinesheetsMutation } from 'api/linesheetApi';
import { faEnvelope, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  ToolbarWrapper,
  Wrapper,
  MainLayout,
  StyledLineSheetsButton,
  StyledSortBy,
  RightContainer,
  SytledTitle,
  StyledRow,
  StyledCol,
} from './styles';

const SORT_BY_OPTIONS = [
  { value: 'name', label: 'Name' },
  { value: 'number_of_pages', label: 'Number of Pages' },
  { value: 'created', label: 'Created' },
  { value: 'last_modified', label: 'Last Modified' },
];

const getSortByOptionNameFromKey = (key) => {
  const option = SORT_BY_OPTIONS.find(({ value: optionKey }) => optionKey === key);
  return option?.label || '';
};

const Toolbar = () => {
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState('last_modified');
  const [searchFilter, setSearchFilter] = useState('');
  const [currentPage] = useState(1);
  const [pageSize] = useState(25);
  const [selectedLinesheets, setIsSelectedLinesheets] = useState([]);
  const [isOpenSendEmailModal, setIsOpenSendEmailModal] = useState(false);
  const userData = useSelector((state) => state.auth.user);
  const isSubscribed = userData.subscription.status === 'active';
  const isOneTimeSubscription = userData.subscription.planType === 'oneTime';
  const [deleteLinesheets, { isLoading: deleteLinesheetsLoading }] = useDeleteLinesheetsMutation();

  // Get Linesheets Query
  const {
    data: linesheetsData,
    isLoading: linesheetsLoading,
    refetch: refetchLinesheets,
  } = useGetLinesheetsQuery({
    searchFilter,
    sortBy,
    currentPage,
    pageSize,
  });

  useEffect(() => {
    refetchLinesheets();
  }, []);

  const handleSortByOptions = (value) => {
    setSortBy(value);
  };
  const handleSearchChange = (e) => {
    setSearchFilter(e.target.value);
  };

  const deleteClickHandler = async () => {
    if (!selectedLinesheets.length) {
      message.error('Please select linesheet(s) to proceed!');
      return;
    }
    const res = await deleteLinesheets({ linesheetIds: selectedLinesheets });

    message.success(res?.data?.message);
    refetchLinesheets();
    setIsSelectedLinesheets([]);
  };

  return (
    <MainLayout>
      <StyledRow justify="space-between">
        <StyledCol>
          <ToolbarWrapper>
            <Wrapper>
              <Space>
                <SytledTitle>Linesheets</SytledTitle>
                <Link to="/linesheets/new">
                  <ImageWithTooltip icon={faPlus} title="Create new linesheet" placement="bottom" />
                </Link>

                <ImageWithTooltip
                  icon={faTrash}
                  title="Delete linesheets"
                  placement="bottom"
                  onClick={deleteClickHandler}
                />
                <ImageWithTooltip
                  icon={faEnvelope}
                  title="Send Email"
                  placement="bottom"
                  disabled={isOneTimeSubscription || !isSubscribed}
                  onClick={() => setIsOpenSendEmailModal(!isOpenSendEmailModal)}
                />
              </Space>
            </Wrapper>
          </ToolbarWrapper>
        </StyledCol>
        <StyledCol>
          <RightContainer>
            <StyledSortBy
              placeholder="Sortby"
              onChange={handleSortByOptions}
              options={SORT_BY_OPTIONS}
              value={getSortByOptionNameFromKey(sortBy)}
            />
            <SearchBar
              placeholder="Search"
              handleSearchChange={handleSearchChange}
              searchFilter={searchFilter}
            />
            <StyledLineSheetsButton onClick={() => navigate('/linesheets/new')}>
              Create Linesheet
            </StyledLineSheetsButton>
          </RightContainer>
        </StyledCol>
      </StyledRow>
      {linesheetsData && linesheetsData.length > 0 && (
        <LinesheetsGrid
          linesheetsData={linesheetsData}
          selectedLinesheets={selectedLinesheets}
          setIsSelectedLinesheets={setIsSelectedLinesheets}
          showActionButtons={true}
        />
      )}
      {!!isOpenSendEmailModal && (
        <SendEmailModal
          isOpenModal={isOpenSendEmailModal}
          toggleModal={() => setIsOpenSendEmailModal(!isOpenSendEmailModal)}
          selectedLinesheets={selectedLinesheets}
        />
      )}
      <Loader isLoading={linesheetsLoading || deleteLinesheetsLoading} />
    </MainLayout>
  );
};

export default Toolbar;
