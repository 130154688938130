import AntTabs from 'uiKits/Tabs';
import { Button } from 'antd';
import styled from 'styled-components';
import AntButton from 'uiKits/Button';

export const MainLayout = styled.div`
  width: 100%;
  padding: 48px 32px;
`;

export const InnerLayout = styled.div`
  padding: 0;
  font-family: Muli;
`;

export const TopSection = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const NameTextWrapper = styled.h2`
  font-size: 25px;
  font-weight: 600;
  font-family: Muli;
  letter-spacing: 0.6px;
  margin: 0;
  line-height: 35px;
`;

export const ButtonsWrapper = styled.div``;

export const CancelButton = styled(Button)`
  border-radius: 10000px;
  margin-right: 0.5rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #6c757d;
  text-align: center;
  vertical-align: middle;
  &:hover {
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
  }
`;

export const SaveButton = styled(AntButton)`
  border-radius: 10000px;
  font-weight: 600;
  border-width: 2px;
  font-size: 1rem;
  text-align: center;
  vertical-align: middle;
  line-height: 0.5;
`;

export const StyledTabs = styled(AntTabs)``;
