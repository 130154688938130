import dotImage from 'assets/images/3dots.png';
import { ActionImage, StyledDropdown, EmailWrapper, DropdownWrapper } from './styles';

const items = [
  {
    label: 'Delete',
    key: 'delete',
  },
];

const Columns = (deleteOutbox, setSelectedRows, openDetailsModal) => [
  {
    title: 'First Name',
    key: 'first_name',
    dataIndex: 'first_name',
    sorter: true,
    width: '20%',
  },
  {
    title: 'Email',
    key: 'email',
    sorter: true,
    width: '25%',
    render: (record) => (
      <EmailWrapper onClick={() => openDetailsModal(record)}>{record.email}</EmailWrapper>
    ),
  },
  {
    title: 'Subject',
    key: 'subject',
    dataIndex: 'subject',
    sorter: true,
    width: '25%',
  },
  {
    title: 'Time',
    key: 'time',
    dataIndex: 'time',
    sorter: true,
    width: '25%',
  },
  {
    title: '',
    key: 'action',
    width: '5%',
    render: (record) => (
      <StyledDropdown
        menu={{
          items,
          onClick: ({ key }) => {
            switch (key) {
              case 'delete':
                deleteOutbox({
                  outboxIds: [record.id],
                  isSelectAll: false,
                });
                setSelectedRows([]);
                break;
              default:
                break;
            }
          },
        }}
        placement="bottomRight"
        trigger={['click']}
      >
        <DropdownWrapper>
          <ActionImage src={dotImage} alt="icon" />
        </DropdownWrapper>
      </StyledDropdown>
    ),
  },
];

export default Columns;
