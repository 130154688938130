import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import xlsIcon from 'assets/images/xls-icon.png';
import { MainContainer, IconBox, ImageIcon, DropText } from './styles';
import { message } from 'antd';

const UploadCSVFileDropzone = ({ csvType, formulaType, priceChangeParam, importFromCSV }) => {
  const navigate = useNavigate();
  const acceptFileTypes = {
    'text/csv': ['.csv'],
    'text/plain': [],
    'application/csv': ['.csv'],
    'text/comma-separated-values': [],
    'application/excel': ['.xls', '.xlsx'],
    'application/vnd.ms-excel': ['.xls', '.xlsx'],
    'application/vnd.msexcel': ['.xls', '.xlsx'],
    'text/anytext': [],
    'application/octet-stream': [],
    'application/txt': ['.txt'],
  };
  const maxFileSize = 5242880;

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) {
        return;
      }

      const formData = new FormData();
      formData.append('csv', file);
      formData.append('formulaType', formulaType);
      formData.append('priceChangeParam', priceChangeParam);
      formData.append('csvType', csvType);

      try {
        const res = await importFromCSV(formData);
        if (res.data) {
          message.success(res.data.message);
          navigate('/products');
        }
        if(res.error){
          message.error(res.error.data.error);
        }
      } catch (err) {
        if (err && err.error.status === 400) {
          const err_message = err.error?.data?.message
            ? err.error.data.message
            : 'you have uploaded a wrong CSV';
          message.error(err_message);
        } else {
          message.error('Oops! Something went wrong. Please try again later');
        }
      }
    },
    [csvType, formulaType, priceChangeParam],
  );

  const onDropRejected = useCallback((rejectedFiles) => {
    console.log('rejectedFiles = ', rejectedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: acceptFileTypes,
    maxSize: maxFileSize,
  });

  return (
    <MainContainer>
      <div {...getRootProps({ className: 'csv-products-dropzone' })}>
        <input {...getInputProps({ multiple: false })} />
        <IconBox>
          <ImageIcon src={xlsIcon} alt="" />
        </IconBox>
        <DropText className="drop-text">
          Drop CSV file to upload <br /> <br />
          <span>
            {' '}
            or <span className="underline-image-text">browse</span> to choose file
          </span>
        </DropText>
      </div>
    </MainContainer>
  );
};

export default UploadCSVFileDropzone;
