import React from 'react';
import { useDispatch } from 'react-redux';
import { addProductImageIndex } from 'slices/editorSlice';
import { MainContainer, StyledButton, StyledTooltip, StyledInputValue } from './styles';

const ChangeProductImage = ({ productImageIndex, productImages, setProductImageIndex }) => {
  const dispatch = useDispatch();

  const productImageChangeHandler = (newProductImageValue) => {
    setProductImageIndex(newProductImageValue);
    dispatch(addProductImageIndex(newProductImageValue));
  };

  return (
    <MainContainer>
      <StyledTooltip title="Use Previous Image" placement="left">
        <StyledButton
          disabled={productImageIndex <= 1}
          onClick={() => {
            productImageChangeHandler(productImageIndex - 1);
          }}
        >
          &lt;
        </StyledButton>
      </StyledTooltip>

      <StyledTooltip title="Image Index" placement="left">
        <StyledInputValue>{productImageIndex}</StyledInputValue>
      </StyledTooltip>

      <StyledTooltip title="Use Next Image" placement="left">
        <StyledButton
          disabled={productImageIndex >= productImages}
          onClick={() => {
            productImageChangeHandler(productImageIndex + 1);
          }}
        >
          &gt;
        </StyledButton>
      </StyledTooltip>
    </MainContainer>
  );
};

export default React.memo(ChangeProductImage);
