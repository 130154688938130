import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const MainContainer = styled.div`
  padding-top: 36px;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.05);
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  background: #000;
  justify-content: space-between;
  padding: 0 15px;
  min-height: 36px;
`;

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #ffffff;
  margin: 0;
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 0.5;
  letter-spacing: normal;
  cursor: default;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: ${(props) => props.cursor};
`;

export const StyledBody = styled.div`
  height: ${(props) => props.height};
  background-color: #242424;
  overflow: hidden;
  transition: height 250ms ease-in-out;
`;

export const StyledEditorToolBody = styled.div`
  height: calc(100% - 62px);
  overflow: auto;
`;

export const StyledCollapsedButton = styled.button`
  background: none;
  border: none;
  color: #ffffff;
`;
