import styled from 'styled-components';

export const MainContainer = styled.div`
  position: relative;
  width: 300px;
  .csv-products-dropzone {
    position: relative;
    width: 100%;
    height: 325px;
    max-width: 325px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 10px;
    background-color: #dbdbdb;
    text-align: center;
    outline: none !important;
    cursor: pointer;
  }
`;

export const IconBox = styled.div`
  min-height: 170px;
`;

export const ImageIcon = styled.img`
  width: 118px;
  height: 110px;
  margin-top: 60px;
  object-fit: contain;
`;

export const DropText = styled.p`
  font-size: 20px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.4px;
  text-align: center;
  color: #495057;
  margin-top: 35px;
  span {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: 0.32px;
    text-align: left;
    color: #495057;
    .underline-image-text {
      font-weight: bold;
      text-decoration: underline;
    }
  }
`;
