import styled, { keyframes } from 'styled-components';
import Background from '../../assets/images/signin-bg-croped.png';
import AntForm from 'uiKits/Form';
import { AntInput } from 'uiKits/Input';
import AntButton from 'uiKits/Button';
import { Link } from 'react-router-dom';

export const BackgroundLayout = styled.div`
  background-image: url(${Background});
  background-size: auto;
  background-position: right bottom;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
`;

export const OuterLayout = styled.div`
  max-width: 29.270833333333336vw;
  margin: 1.75rem auto;
`;

const slideAndFadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(60px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const InnerLayout = styled.div`
  animation: ${slideAndFadeIn} 750ms ease-in-out both;
  background-color: #fafafa;
  border-radius: 0;
  border: 0;
  width: 100%;
`;

export const StyledBody = styled.div`
  background-color: #fff;
  border-radius: 15px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  padding: 1rem;
`;

export const StyledText = styled.h2`
  font-family: Muli;
  font-size: 36px;
  font-weight: 600;
  line-height: 0.83;
  text-align: center;
  color: #000;
  margin-top: 20px;
`;

export const ContentWrapper = styled.div`
  max-width: 315px;
  margin: 0 auto;
  margin-top: 3.5rem;
`;

export const StyledForm = styled(AntForm)``;

export const StyledInput = styled(AntInput)``;

export const ButtonWrapper = styled.div`
  margin-top: 1.5rem;
`;

export const StyledButton = styled(AntButton)`
  width: 100%;
  font-size: 0.8333333333333334vw;
  border-radius: 20px;
  font-family: Muli;
  font-weight: 700;
`;

export const LinkWrapper = styled.div`
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

export const StyledLink = styled(Link)`
  font-size: 1.2239583333333333vw;
  text-decoration: underline !important;
  color: #07af7c;
  line-height: 1.9;
  text-align: center;
`;
