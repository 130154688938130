import linesheetLogo from 'assets/images/linesheet_logo.png';
import shopifyLogo from 'assets/images/shopify_logo.png';
import amazonLogo from 'assets/images/amazon_logo.png';
import etsyLogo from 'assets/images/etsy_logo.png';
import squarespaceLogo from 'assets/images/Squarespace_logo.png';
import threeDCartLogo from 'assets/images/3DCart_logo.png';
import pinnaclartLogo from 'assets/images/pinnaclart_logo.png';
import volusionLogo from 'assets/images/Volusion_logo.png';
import magentoLogo from 'assets/images/magento_logo.png';
import woocommerceLogo from 'assets/images/woocommerce_logo.png';

export const csvOptions = [
  {
    value: 1,
    src: linesheetLogo,
    alt: 'Linesheet',
    name: 'Linesheets',
    tooltip: 'Select for uploading Linesheet csv',
  },
  {
    value: 2,
    src: shopifyLogo,
    alt: 'Shopify',
    name: 'shopify',
    tooltip: 'Select for uploading Shopify csv',
  },
  {
    value: 3,
    src: amazonLogo,
    alt: 'Amazon',
    name: 'Amazon',
    tooltip: 'Select for uploading Amazon csv',
  },
  { value: 4, src: etsyLogo, alt: 'Etsy', name: 'Etsy', tooltip: 'Select for uploading Etsy csv' },
  {
    value: 5,
    src: squarespaceLogo,
    alt: 'Squarespace',
    name: 'Squarespace',
    tooltip: 'Select for uploading Squarespace csv',
  },
  {
    value: 6,
    src: threeDCartLogo,
    alt: '3dcart',
    name: '3dcart',
    tooltip: 'Select for uploading 3DCart csv',
  },
  {
    value: 7,
    src: pinnaclartLogo,
    alt: 'PinnacleCart',
    name: 'PinnacleCart',
    tooltip: 'Select for uploading Pinnacle Cart csv',
  },
  {
    value: 8,
    src: volusionLogo,
    alt: 'Volusion',
    name: 'Volusion',
    tooltip: 'Select for uploading Volusion csv',
  },
  {
    value: 9,
    src: magentoLogo,
    alt: 'Magento',
    name: 'Magento',
    tooltip: 'Select for uploading Magento csv',
  },
  {
    value: 10,
    src: woocommerceLogo,
    alt: 'WooCommerce',
    name: 'WooCommerce',
    tooltip: 'Select for uploading WooCommerce csv',
  },
];
