import styled from 'styled-components';

export const MainContainer = styled.div`
  height: calc(100% - 50px);
  overflow: auto;
`;

export const StyledEditorPlayground = styled.div`
  width: 100%;
  min-height: 100%;
  padding: 25px 0;
  cursor: ${(props) => props.cursor};
`;
