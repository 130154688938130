import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_BASE_URL;

export const handle401Error = async (api, action, { getState }) => {
  console.log('handle401Error called!');
  // Rest of your middleware logic
};

export const categoriesApi = createApi({
  reducerPath: 'categoriesApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(handle401Error),
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: (opitons) => {
        // { searchFilter, sortBy, sortOrder, currentPage, pageSize }
        let url = `/categories`;
        if (opitons && opitons?.pageSize) {
          url = url + `?limit=${opitons?.pageSize}`;
        }

        if (opitons && opitons?.searchFilter) {
          url += `&searchFilter=${encodeURIComponent(opitons.searchFilter)}`;
        }
        if (opitons && opitons?.sortBy && opitons?.sortOrder) {
          url += `&sortBy=${opitons.sortBy}&sortOrder=${opitons.sortOrder}`;
        }
        if (opitons && opitons?.currentPage) {
          url += `&page=${opitons?.currentPage}`;
        }
        return { url };
      },
      providesTags: ['Categories'],
    }),
    deleteCategories: builder.mutation({
      query: (payload) => ({
        url: '/categories',
        method: 'DELETE',
        body: payload,
      }),
      invalidatesTags: ['Categories'],
    }),
    duplicateCategories: builder.mutation({
      query: (payload) => ({
        url: '/categories/duplicate',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Categories'],
    }),
    createCategory: builder.mutation({
      query: (name) => ({
        url: 'categories/create',
        method: 'POST',
        body: { name },
      }),
      invalidatesTags: ['Categories'],
    }),
    updateCategory: builder.mutation({
      query: ({ id, name }) => ({
        url: `categories/edit?categoryId=${id}`,
        method: 'POST',
        body: { name },
      }),
      invalidatesTags: ['Categories'],
    }),
    assignCategory: builder.mutation({
      query: (payload) => ({
        url: `categories/assign`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Categories'],
    }),
    getSingleCategory: builder.query({
      query: (id) => `/categories/${id}`,
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useDeleteCategoriesMutation,
  useDuplicateCategoriesMutation,
  useCreateCategoryMutation,
  useGetSingleCategoryQuery,
  useUpdateCategoryMutation,
  useAssignCategoryMutation,
} = categoriesApi;
