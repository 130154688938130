import styled from 'styled-components';

export const SyncContainer = styled.div`
  padding-right: 32px;
  padding-bottom: 40px;

  @media all and (max-width: 1300px) {
    padding-right: 16px;
  }
`;

export const SyncDivHolder = styled.div`
  position: relative;
  padding: 35px 55px;

  h2 {
    font-family: Muli;
    font-size: 30px;
    font-weight: 600;
    line-height: 1;
    color: #000000;
    margin: 0 0 35px;
  }
`;

export const SyncContentHolder = styled.div`
  border-top: 1px solid #f1f1f1;
  padding: 25px 0;

  h3 {
    font-family: Muli;
    font-size: 24px;
    font-weight: 600;
    line-height: 0.63;
    letter-spacing: 0.48px;
    margin: 0 0 20px;
  }
  p {
    font-size: 16px;
    letter-spacing: normal;
    margin-bottom: 1rem;
    font-family: Muli;
    font-weight: 600;
    line-height: 1;
    color: #000;
  }
`;

export const SyncBoxPost = styled.div`
  position: relative;
  border-radius: 15px;
  background-color: #f7f7f7;
  padding: 30px 15px;
  margin: 35px 0 0;
  display: grid;
  grid-template-columns: 88px auto 88px;
  grid-column-gap: 25px;
  align-items: center;
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  color: #000000;

  h4 {
    font-family: Muli;
    font-size: 16px;
    font-weight: 900;
    line-height: 1;
    margin: 0;
  }

  p {
    margin: 0;
  }

  .ImageHolder {
    position: relative;

    img {
      max-width: 100%;
      display: block;
      height: auto;
      margin: 0 auto;
    }
  }
`;

export const SyncCustomButton = styled.button`
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.92;
  letter-spacing: 0.72px;
  border-radius: 17.5px;
  border: 2px solid #07af7c;
  background-color: #f7f7f7;
  color: #07af7c;
  height: 35px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #07af7c;
    color: #ffffff;
  }
`;
