import { Button, Modal } from 'antd';
import styled from 'styled-components';
import AntForm from 'uiKits/Form';
import { AntInput } from 'uiKits/Input';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-header {
    padding: 30px 0 0 30px;
    margin-bottom: 0;
  }

  .ant-modal-body {
    padding: 30px;
    overflow-y: auto;
    height: 30rem;
  }

  .ant-modal-footer {
    margin: 0;
  }
`;

export const ModalTitle = styled.h5`
  font-family: Muli, Helvetica, Arial, sans-serif;
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 1.44px;
`;

export const StyledForm = styled(AntForm)`
  .ant-form-item-label {
    display: flex;
    justify-content: flex-start;
    align-items: start;
  }
`;

export const StyledRegionDropdown = styled(RegionDropdown)`
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.28px;
  max-height: 45px;
  padding: 0.5rem 0.75rem !important;
  max-width: 256px;
  width: 100%;
  height: calc(1.5em + 1.3rem + 4px);
  border: 1px solid #ced4da;
  border-radius: 10px;
  border-width: 2px;
`;

export const StyledCountryDropdown = styled(CountryDropdown)`
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.28px;
  max-height: 45px;
  padding: 0.5rem 0.75rem !important;
  max-width: 256px;
  width: 100%;
  height: calc(1.5em + 1.3rem + 4px);
  border: 1px solid #ced4da;
  border-radius: 10px;
  border-width: 2px;
`;

export const StyledInput = styled(AntInput)``;

export const FooterWrapper = styled.div`
  background-color: #f3f3f3;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 30px 0;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
`;

export const StyledCancelButton = styled(Button)`
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  height: 50px;
  width: 116px;
  letter-spacing: 0.96px;
  border-radius: 10000px;
  color: #6c757d;
  &:hover {
    color: #ffffff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
  }
`;

export const StyledSaveButton = styled(Button)`
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  height: 50px;
  width: 100px;
  letter-spacing: 0.96px;
  border-radius: 10000px;
  border-width: 2px;
  color: #fff;
  background-color: #02b183;
  border-color: #02b183;
  &:hover {
    color: #ffffff !important;
    background-color: #028b67 !important;
    border-color: #017f5e !important;
  }
`;
