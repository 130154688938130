import React from 'react';
import { Col, Row, message } from 'antd';
import { StyledForm, StyledInput, StyledDivider, StyledButton } from './styles';

const ProfileSection = ({ onFinish, userData, form, updatePassword, passwordForm }) => {
  const showPassSection = userData && userData.usingPassAuth;

  const handleFinish = values => {
    form
      .validateFields()
      .then(() => {
        onFinish(values);
      })
      .catch(errorInfo => {
        console.log('Validation failed:', errorInfo);
      });
  };

  const handlePasswordChange = async values => {
    const res = await updatePassword(values);
    if (res.data) {
      passwordForm.resetFields();
      message.success(res.data.message);
    } else if (res.error) {
      message.error(res.error.data.message);
    }
  };

  return (
    <>
      <StyledForm
        name='profile'
        layout='vertical'
        form={form}
        autoComplete='off'
        onFinish={handleFinish}
        initialValues={{
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          email: userData?.email,
          industry: userData?.industry,
        }}
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <StyledForm.Item
              label='First Name'
              name='firstName'
              rules={[
                {
                  required: true,
                  message: 'Please provide first name!',
                },
              ]}
            >
              <StyledInput placeholder='First Name' />
            </StyledForm.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <StyledForm.Item
              label='Last Name'
              name='lastName'
              rules={[
                {
                  required: true,
                  message: 'Please provide last name!',
                },
              ]}
            >
              <StyledInput placeholder='Last Name' />
            </StyledForm.Item>
          </Col>
        </Row>
        <StyledDivider />
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <StyledForm.Item label='Email Address' name='email'>
              <StyledInput placeholder='Email Address' readOnly disabled />
            </StyledForm.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <StyledForm.Item
              label='Industry'
              name='industry'
              rules={[
                {
                  required: true,
                  message: 'Please provide an industry!',
                },
              ]}
            >
              <StyledInput placeholder='Industry' />
            </StyledForm.Item>
          </Col>
        </Row>
      </StyledForm>
      {showPassSection ? (
        <StyledForm
          name='passwordSection'
          form={passwordForm}
          layout='vertical'
          autoComplete='off'
          onFinish={handlePasswordChange}
          initialValues={{
            currPassword: '',
            newPassword: '',
            passwordVerify: '',
          }}
        >
          <>
            <StyledDivider />
            <Row>
              <Col span={24}>
                <StyledForm.Item
                  label='Password'
                  name='currPassword'
                  rules={[
                    {
                      required: true,
                      message: 'Please provide current password!',
                    },
                  ]}
                >
                  <StyledInput placeholder='Current Password' type='password' />
                </StyledForm.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <StyledForm.Item
                  label='New Password'
                  name='newPassword'
                  validateFirst
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please provide password!',
                    },
                    { min: 8, message: 'Password must have a minimum length of 8' },
                    {
                      pattern: new RegExp(
                        '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$',
                      ),
                      message: 'Password must be strong!',
                    },
                  ]}
                >
                  <StyledInput placeholder='New Password' type='password' />
                </StyledForm.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <StyledForm.Item
                  label='Verify Password'
                  name='passwordVerify'
                  dependencies={['newPassword']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: ' Please provide confirm password!',
                    },
                    ({ getFieldValue }) => ({
                      validator (_, value) {
                        if (!value || getFieldValue('newPassword') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Passwords do not match!'));
                      },
                    }),
                  ]}
                >
                  <StyledInput placeholder='Verify New Password' type='password' />
                </StyledForm.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <StyledButton htmlType='submit'>Change Password</StyledButton>
              </Col>
            </Row>
          </>
        </StyledForm>
      ) : null}
    </>
  );
};

export default ProfileSection;
