import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Globals from '../../../websiteGlobals';
import SelectImageIcon from '../../../assets/images/image-icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import {
  Container,
  IconBox,
  SelectedImage,
  ImageIcon,
  DropText,
  ThumbnailsSection,
  Thumbnails,
  ThumbnailsBackground,
  MessageContainer,
  ImageUploadInstruction,
  ThumbnailImage,
} from './styles';

const ImageUploader = ({ handleImages, imagesArray, deleteImage, defaultImage, thumbnailNum }) => {
  const [message, setMessage] = useState('');
  const [clicked, setClicked] = useState(1);
  const [selectedImage, setSelectedImage] = useState('');
  const [image, setImage] = useState('');
  const [showText, setShowText] = useState(false);
  const acceptFileTypes = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg'],
    'image/jpg': ['.jpg'],
  };

  useEffect(() => {
    setClicked(thumbnailNum);
    setImage(checkImageUrl(defaultImage));
    setSelectedImage('');
  }, [thumbnailNum, defaultImage]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) {
        setMessage('Oops! Looks like you chose an unsupported file type. Please only use images');
        return;
      }

      if (file.size > Globals.constants.maxImageSizeInBytes) {
        setMessage(
          `Image size exceeds maximum allowed size. Please choose an image smaller than ${Globals.constants.maxImageSizeInMegaBytes}MB`,
        );
        return;
      }
      setMessage('');
      handleImages(file, 0, selectedImage);
    },
    [handleImages, selectedImage],
  );

  const checkImageBlob = (image) => {
    if (image) {
      if (image.name) {
        return image;
      } else {
        return image.url;
      }
    }
  };

  const checkImageUrl = (image) => {
    if (image) {
      if (image.name) {
        return URL.createObjectURL(image);
      } else {
        return image.url;
      }
    }
  };

  const selectImage = (image, box) => {
    if (image) {
      setSelectedImage(checkImageBlob(image));
      setImage(checkImageUrl(image));
    } else {
      setSelectedImage('');
      setImage('');
    }
    setClicked(box);
  };

  const onImageDelete = (index) => {
    deleteImage(index);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptFileTypes,
    maxSize: Globals.constants.maxImageSizeInBytes,
  });

  return (
    <Container>
      <div {...getRootProps({ className: 'drop-area' })}>
        <input {...getInputProps({ multiple: false })} name="images" />
        <div>
          {image ? (
            <SelectedImage src={image} alt="" />
          ) : (
            <>
              <IconBox>
                <ImageIcon src={SelectImageIcon} alt="" />
              </IconBox>
              <DropText>
                Drop image file to upload <br /> <br />
                <span>
                  {' '}
                  or <span className="underline-image-text">browse</span> to choose file
                </span>
              </DropText>
            </>
          )}
        </div>
      </div>
      <ThumbnailsSection>
        {[1, 2, 3, 4, 5].map((box) => (
          <Thumbnails
            key={box}
            className={clicked === box ? 'thumbnails-click' : ''}
            onClick={() => selectImage(imagesArray[box - 1], box)}
            onMouseEnter={() => setShowText(true)}
            onMouseLeave={() => setShowText(false)}
          >
            {imagesArray[box - 1] ? (
              <>
                <ThumbnailImage src={checkImageUrl(imagesArray[box - 1])} alt="" />
                {clicked === box ? (
                  <FontAwesomeIcon
                    icon={faX}
                    onClick={() => onImageDelete(box - 1)}
                    className="delete-image-icon"
                  />
                ) : null}
              </>
            ) : (
              <ThumbnailsBackground></ThumbnailsBackground>
            )}
          </Thumbnails>
        ))}
      </ThumbnailsSection>
      <MessageContainer>
        <small>{message}</small>
      </MessageContainer>
      {showText ? (
        <ImageUploadInstruction>
          <p>
            Upload one or more images for your product here. You can also upload your variant images
            in this section <br /> <br /> <br /> if you have photos representing additional color,
            style, fabric, and/or texture options. All images for this product should be uploaded
            here
          </p>
        </ImageUploadInstruction>
      ) : null}
    </Container>
  );
};

export default ImageUploader;
