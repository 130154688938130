import styled from 'styled-components';
import { Tabs } from 'antd';

export const AntTabs = styled(Tabs)`
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #07af7c;
    .ant-tabs-tab-btn {
      color: #07af7c;
    }
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #07af7c;
  }
  .ant-tabs-ink-bar {
    background-color: #07af7c;
  }

  .ant-tabs-tab-btn:focus,
  .ant-tabs-tab-btn:active {
    color: #07af7c !important;
  }
`;
