import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowConfirmTemplateModal, changeTemplateWithAction } from 'slices/editorSlice';
import {
  StyledModal,
  StyledTitle,
  FooterWrapper,
  StyledButton,
  ButtonsWrapper,
  ModalBody,
  StyledActionsButton,
} from './styles';

const ConfirmTemplateModal = () => {
  const dispatch = useDispatch();
  const { showConfirmTemplateModal } = useSelector((state) => state.editor);

  const onClose = () => {
    dispatch(setShowConfirmTemplateModal());
  };

  const submitClickHandler = (action) => {
    dispatch(changeTemplateWithAction({ tempAction: action }));
  };

  return (
    <StyledModal
      title={<StyledTitle>Confirm Realign</StyledTitle>}
      open={showConfirmTemplateModal}
      footer={
        <FooterWrapper>
          <ButtonsWrapper>
            <StyledButton size="large" type="text" onClick={() => onClose()}>
              Cancel
            </StyledButton>
            <StyledActionsButton
              size="large"
              type="text"
              onClick={() => submitClickHandler('add-pages')}
            >
              Add Pages
            </StyledActionsButton>
            <StyledActionsButton
              size="large"
              type="text"
              onClick={() => submitClickHandler('remove-elements')}
            >
              Remove Elements
            </StyledActionsButton>
            <StyledActionsButton
              size="large"
              type="text"
              onClick={() => submitClickHandler('change-template')}
            >
              Change Template
            </StyledActionsButton>
          </ButtonsWrapper>
        </FooterWrapper>
      }
      onCancel={onClose}
      centered={true}
      width={800}
    >
      <ModalBody>
        <p>There are too many elements in this page for the current template.</p>
        <ul>
          <li>You can add new pages with the same template for the extra elements</li>
          <li>Or choose another template</li>
        </ul>
      </ModalBody>
    </StyledModal>
  );
};

export default ConfirmTemplateModal;
