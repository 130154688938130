import { styled } from 'styled-components';
import { Col } from 'antd';
import AntButton from 'uiKits/Button';

export const ColWrapper = styled(Col)`
  padding-right: 15px;
  padding-left: 15px;
  a {
    display: block;
    width: fit-content;
  }
`;

export const StyledTitle = styled.h4`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 1.44px;
`;

export const StyledSubTitle = styled.p`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.44;
  letter-spacing: normal;
  font-weight: 400;
`;

export const StyledButton = styled(AntButton)`
  display: flex;
  align-items: center;
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.96px;
  border-width: 2px;
  font-weight: 600;
  border-radius: 10000px;
  color: #02b183;
  border-color: #02b183;
  background: none;
  &:hover {
    color: #fff !important;
    background-color: #02b183 !important;
    border-color: #02b183 !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(2, 177, 131, 0.5) !important;
  }
`;
