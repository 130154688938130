import React from 'react';
import {
  StyledCustomModal,
  CustomModalDialog,
  CustomModalContent,
  CustomModalHeader,
  CustomModalTitle,
  CustomModalBody,
  CustomModalFooter,
} from './styles';

const CustomModal = ({
  children,
  showing,
  title,
  size,
  onClose,
  optionalClass,
  footer,
  maxWidth,
}) => {
  const closeClickHandler = () => {
    if (onClose) {
      onClose();
    }
  };

  const innerClickHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <StyledCustomModal showing={showing} onClick={closeClickHandler}>
      <CustomModalDialog onClick={innerClickHandler} maxWidth={maxWidth}>
        <CustomModalContent>
          <CustomModalHeader>
            <CustomModalTitle>{title}</CustomModalTitle>
          </CustomModalHeader>
          <CustomModalBody>{children}</CustomModalBody>
          <CustomModalFooter>{footer}</CustomModalFooter>
        </CustomModalContent>
      </CustomModalDialog>
    </StyledCustomModal>
  );
};

export default CustomModal;
