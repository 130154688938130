import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MainContainer,
  BackgroundImage,
  StyledTooltip,
  NeedHelpText,
  StyledLink,
  BackgroundImageOverlay,
  HelpIcon,
} from './styles';

const Help = () => {
  const navigate = useNavigate();
  return (
    <MainContainer>
      <BackgroundImage>
        <BackgroundImageOverlay />
        <StyledTooltip title="Press to see Help pages" placement="right">
          <HelpIcon onClick={() => navigate('/help')} />
        </StyledTooltip>
      </BackgroundImage>
      <NeedHelpText>Need Help</NeedHelpText>
      <StyledTooltip title="Get Started" placement="bottom">
        <StyledLink to="/videos/getstarted">What is a linesheet?</StyledLink>
      </StyledTooltip>
      <StyledTooltip title="Designing" placement="bottom">
        <StyledLink to="/videos/designing">Your first linesheet</StyledLink>
      </StyledTooltip>
      <StyledTooltip title="videos" placement="bottom">
        <StyledLink to="/videos">View videos</StyledLink>
      </StyledTooltip>
    </MainContainer>
  );
};

export default Help;
