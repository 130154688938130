import styled from 'styled-components';
import { Row } from 'antd';

export const StyledRow = styled(Row)`
  align-items: center;
  gap: 15px;
  margin-bottom: 14px;
  flex-wrap: nowrap;
  img {
    vertical-align: top;
    width: 28px;
    height: 28px;
    border-radius: 3px;
    background-color: #35383a;
    padding: 6px;
  }
  p {
    margin: 0;
    color: #ffffff;
  }
  cursor: pointer;
`;
