import styled from 'styled-components';
import { Button, Flex, Modal, Typography, Divider } from 'antd';
const { Text } = Typography;

export const StyledModal = styled(Modal)`
  //   .ant-modal-close-x {
  //     font-size: 36px;
  //     color: #ebebeb;
  //     opacity: 1;
  //     padding: 0.5rem 1rem;
  //   }
`;

export const MainLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  text-align: center;
  padding: 1rem;
`;

export const StyledText = styled(Text)`
  margin-top: 1.5rem;
`;

export const UnsubscribeButton = styled(Button)`
  width: 250px;
  height: 50px;
  border-radius: 38px;
  border: solid 2px #02b183;
  background-color: #ffffff;
  font-family: Muli;
  font-size: 24px;
  font-weight: bold;
  line-height: 0.96;
  letter-spacing: 1.44px;
  color: #02b183;
  margin-bottom: 15px;
  &:hover {
    background-color: #02b183 !important;
    border-color: #02b183 !important;
    color: #ffffff !important;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 0;
`;

export const CancelButton = styled(Button)`
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.96px;
  border-radius: 10000px;
  margin-right: 0.5rem !important;
  color: #6c757d;
  border: none;
  &:hover {
    background-color: #6c757d !important;
    color: #ffffff !important;
  }
`;

export const CancelLayout = styled(Flex)`
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;
