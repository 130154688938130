import { styled } from 'styled-components';
import { Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Container = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 47px 32px;
  box-shadow: 0.8px -0.6px 16px 0 rgba(0, 0, 0, 0.02);
  margin-top: 20px;
  justify-content: flex-end;
`;

export const Detail = styled.p`
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: normal;
`;
export const StlyedSendButton = styled.button`
  background-color: #fff;
  border-radius: 24.5px;
  border: 2px solid #07af7c;
  text-align: center;
  cursor: pointer;
  color: #07af7c;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.96px;
  padding: 15px 30px;
  &:hover {
    background-color: #07af7c;
    color: #ffffff;
  }
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  font-size: 55px;
`;

export const StyledRow = styled(Row)`
  justify-content: flex-end;
`;
