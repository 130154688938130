import React from 'react';
import { Modal } from 'antd';
import PopoverContent from '../Playground/Content/EditablePopover';

const SetDescriptionModal = ({ open, html, handleModalClose, handleSave }) => {
  return (
    <Modal open={open} onCancel={handleModalClose} width={700} footer={null}>
      <PopoverContent html={html} onSave={handleSave} onCancel={handleModalClose} />
    </Modal>
  );
};

export default SetDescriptionModal;
