import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledModal = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  .ant-modal-header {
    padding: 32px 48px;
    margin: 0;
  }
  .ant-modal-content {
    padding: 0;
    top: -70px;
  }
  .ant-modal-body {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 48px;
    justify-content: center;
    align-items: center;
    height: 77vh;
    overflow-y: auto;
  }
`;

export const FooterWrapper = styled.div`
  padding: 38px;
  background-color: #f3f3f3;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
`;

export const StyledButton = styled(Button)`
  border: none;
  color: #6c757d;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.96px;
  border-radius: 10000px;
  background: none;
  &:hover {
    color: #ffffff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
  }
`;

export const StyledTitle = styled.h5`
  font-family: Muli;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 1.44px;
  margin: 0;
`;

export const CustomTemplateContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  padding: 0 15px;
`;

export const TemplateGridCell = styled.div`
  margin-bottom: 16px;
  cursor: pointer;
`;

export const TemplateGridCellInfo = styled.div`
  padding: 8px 16px;
  text-align: center;
`;

export const TemplateGridCellName = styled.div`
  font-size: 16px;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TemplateGridCellThumbnail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f3f5;
  height: 157px !important;
  width: 204px !important;
  border-radius: unset !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.19);
  border: 2px solid transparent;
  padding: 16px 31px;
  &:hover {
    border-color: #02b183;
  }
  &.template-active {
    border-color: #02b183;
  }
`;

export const ThumbnailContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f1f3f5;
  text-align: center;
  flex-direction: column;
  gap: 5px;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  font-size: 80px;
`;

export const ThumbnailRow = styled.div`
  display: flex;
  width: 100%;
  height: ${({ height }) => height}%;
  gap: 5px;
`;

export const ThumbnailCol = styled.div`
  flex: 0 0 ${({ width }) => `${width - 2}%`};
  max-width: ${({ width }) => `${width - 2}%`};
`;

export const ThumbnailBg = styled.div`
  width: 100%;
  height: 100%;
  background-color: #000;
  border-radius: 5px;
`;
