import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Col } from 'antd';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { Container, Detail, StlyedSendButton, StyledFontAwesomeIcon, StyledRow } from './styles';

const EmailLinesheets = () => {
  const navigate = useNavigate();

  return (
    <StyledRow gutter={24}>
      <Col xs={24} sm={24} md={8} offset={1}>
        <Container>
          <StyledFontAwesomeIcon icon={faEnvelope} />
          <h3>Email</h3>
          <Detail>Linesheets are ready to be sent to your contacts</Detail>
          <StlyedSendButton onClick={() => navigate('/linesheets')}>Send</StlyedSendButton>
        </Container>
      </Col>
    </StyledRow>
  );
};

export default EmailLinesheets;
