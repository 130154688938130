import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

export const QuestionIcon = styled(StyledFontAwesomeIcon)`
  font-size: 16px;
  margin-left: 5px;
`;