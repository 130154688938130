import styled from 'styled-components';
import { Layout, Space } from 'antd';
import AntDropdown from 'uiKits/Dropdown';
const { Header, Sider, Content } = Layout;

export const AntSider = styled(Sider)`
  position: fixed;
  top: 80px;
  bottom: 0;
  left: 0;
  background: #f1f3f5;
  z-index: 1;
  overflow: auto;
`;

export const StyledLayout = styled(Layout)``;

export const StyledHeader = styled(Header)`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2 !important;
  height: 80px;
  padding: 16px 27px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1) !important;
  background: #f8f9fa;
`;

export const StyledSider = styled(Sider)`
  position: fixed !important;
  top: 80px;
  width: ${(props) => (props.collapsed ? '120px !important' : '288px !important')};
  max-width: ${(props) => (props.collapsed ? '120px !important' : '288px !important')};
  bottom: 0;
  left: 0;
  background: #f1f3f5 !important;
  z-index: 1;
  overflow: auto;
`;

export const StyledContent = styled(Content)`
  margin-left: ${(props) => (props.collapsed ? '120px' : '288px')};
  height: calc(100vh - 80px);
  overflow: auto;
  margin-top: 80px;
  background-color: #f8f9fa;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 30px;
  @media (max-width: 576px) {
    gap: 10px;
  }
`;

export const StyledImage = styled.img`
  width: 170px;
  margin-top: 26px;
`;

export const BarIconWrapper = styled.div`
  cursor: pointer;
  font-size: 18px;
  color: #6c757d;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  gap: 30px;
`;

export const StyledHeading = styled.span`
  line-height: 80px;
  color: #d7d7d7;
  font-size: 26px;
  @media (max-width: 576px) {
    display: none;
  }
`;

export const DropdownWrapper = styled.div``;

export const StyledDropdown = styled(AntDropdown)`
  cursor: pointer;
`;

export const StyledSpace = styled(Space)`
  font-size: 16px;
  color: #343a40;
  margin-right: 20px;
  line-height: 30px;

  &:after {
    color: $gray-500;
  }

  .anticon-down {
    font-size: 12px;
  }
`;
