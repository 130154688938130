import { styled } from 'styled-components';
import { Col } from 'antd';
import AddProductBackground from 'assets/images/dashboard-recent-background.png';
import SyncImage from 'assets/images/sync-image.png';
import AntButton from 'uiKits/Button';

export const StyledColLeft = styled(Col)`
  position: relative;
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url(${AddProductBackground});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    transform-origin: center;
    transform: scale(1, -1);
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }
`;

export const ChecklistLeft = styled.div`
  padding: 48px 32px;
  border-radius: 0;
  h4 {
    font-size: 24px;
    font-family: Muli;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: 1.44px;
  }
  p {
    font-size: 16px;
    line-height: 1.44;
    font-family: Muli;
    letter-spacing: normal;
    font-weight: normal;
  }
`;

export const ChecklistIcon = styled.div`
  width: 123px;
  height: 105px;
  background-image: url(${SyncImage});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 16px;
`;

export const ChecklistDescription = styled.p`
  font-size: 16px;
  line-height: 1.19;
`;

export const StyledButton = styled(AntButton)`
  display: flex;
  align-items: center;
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.96px;
  border-width: 2px;
  font-weight: 600;
  border-radius: 10000px;
  color: #02b183;
  border-color: #02b183;
  background: none;
  &:hover {
    color: #fff !important;
    background-color: #02b183 !important;
    border-color: #02b183 !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(2, 177, 131, 0.5) !important;
  }
`;
