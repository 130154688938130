import React from 'react';
import styled from 'styled-components';

const HelpSub = styled.div`
  position: relative;
`;

const HelpFrame = styled.div`
  position: relative;
  max-width: 1086px;
  margin: 0 auto;

  &:after {
    display: block;
    clear: both;
    content: '';
  }
`;

const PageNavi = styled.ul`
  padding: 0 20px;
  margin: 0 0 35px;
  list-style: none;
  overflow: hidden;

  li {
    display: inline-block;
    vertical-align: top;
    position: relative;
    color: #52c78d;
    margin: 0 10px 0 0;

    a {
      font-family: Muli;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.32px;
      text-align: left;
      color: #52c78d;
    }
  }
`;

const Cursor = styled.span`
  cursor: pointer;
`;

const WelcomeHolder = styled.div`
  position: relative;
  font-family: Muli;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: left;
  color: #000000;
  padding: 0 20px;

  h2 {
    font-family: Muli;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    text-align: left;
    color: #000000;
    margin: 0 0 35px;
  }

  p {
    margin: 0 0 35px;
  }
`;

const Author = styled.div`
  font-size: 16px;

  .txt-gray {
    color: #999;
  }
`;

const WaysContentHolder = styled.div`
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
  color: #000000;
  position: relative;

  .num-list {
    padding: 0 0 0 50px;
    margin: 0 0 25px;
    list-style: none;
    overflow: hidden;

    li {
      margin: 0 0 15px;
    }
  }

  h3 {
    font-size: 24px;
    letter-spacing: 0.48px;
    font-family: Muli;
    font-weight: 600;
    line-height: normal;
    color: #000000;
  }

  h4 {
    font-family: Muli;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.4px;
    margin: 0;
  }

  .dotted-list {
    padding: 0 0 0 70px;
    margin: 30px 0 25px;
    list-style: none;
    overflow: hidden;
    position: relative;

    li {
      position: relative;
      margin: 0 0 10px;

      &:before {
        position: absolute;
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #000;
        left: -15px;
        top: 5px;
      }
    }
  }
`;

const LinesheetEditorHelp = ({ onClick }) => {
  return (
    <div className="linesheet-editor-help">
      <HelpSub>
        <HelpFrame>
          <PageNavi>
            <li>
              <Cursor onClick={() => onClick('')}>Help</Cursor>
            </li>
            <li>/</li>
            <li>
              <Cursor>Linesheet Editor</Cursor>
            </li>
          </PageNavi>
          <WelcomeHolder>
            <h2>Welcome to the Editor</h2>
            <p>
              Linesheet templates make life a lot easier, but the Linesheets Editor is where all the
              fun happens. Why? Because it’s customization time!
            </p>
            <Author>
              <span className="txt-gray">Written by</span> Alexis Kelly{' '}
              <span className="txt-gray">and</span> Ryan Kelly
            </Author>
            <p>
              We’ve got all the information you need to understand what the Linesheets Editor does
              and how can use each tool to create a linesheet that shows off your product in the
              best possible light.
            </p>
            <WaysContentHolder>
              <p>
                There are several ways to navigate to the editor, but the editing phase itself is
                always the same:
              </p>
              <ul className="num-list">
                <li>
                  1. Add your products to Linesheets.com via the Sync section of our linesheets web
                  app (Just FYI, there are three ways to add those products — API, CSV, or Manual —
                  and you can learn more about that in our Sync Your Products with Line sheets.com
                  section).
                </li>
                <li>2. Select the products you want to add to your linesheet.</li>
                <li>
                  3. Once the products are selected, click the green button in the upper right-hand
                  corner of the screen that says Create Linesheet.
                </li>
                <li>4. Pause to admire your handiwork.</li>
                <li>
                  5. Okay, back to business. Next, select some or all of your products to be added
                  to the workspace. You’ll use the panel on the left-hand side where your products
                  are already loaded.
                </li>
                <li>
                  6. Decide whether to use the check box in the upper right-hand corner of the panel
                  to select and all products you’ve up loaded or check individual boxes to choose
                  specific products and then click the Add to Linesheet button. Tip: You can also
                  drag and drop your product into place if you prefer.
                </li>
                <li>
                  7. Next, a popup will appear with options for either a blank canvas (a more DIY
                  route in which you’ll have to manually place each product into place) or a
                  linesheet template.
                </li>
                <li>
                  8. If you choose to go the template route, you’ll be able to choose the layout you
                  prefer, and the products will be added to the linesheet workspace according to the
                  template you selected. Tip: Templates aren’t fixed; you can change them at any
                  time, altering the overall linesheet or even the page template itself. Remember,
                  we’re all about flexibility and catering to your personal design needs!)
                </li>
                <li>
                  9. Once added to the workspace, some pages may need minor adjustments to
                  accommodate longer text blocks. To make tweaks, go to the Product Size Tools and
                  shift things as needed using the sliders to keep each element in proportion and
                  avoid accidental distortion.
                </li>
              </ul>
              <h3>Editing Tools</h3>
              <p>
                On the right-hand side of the Linesheet Editor you’ll see a panel that contains all
                your editing tools. You can use any or all of the following options to help shape
                the look and feel of your linesheet. Here’s a list of the tools/actions available,
                which elements the tool can be applied to, and, in some cases (look for the
                brackets), details about what the tool does.
              </p>
              <h4>Text</h4>
              <p>
                Tweak your text to highlight products, emphasize features, or match your branding.
              </p>
              <ul className="dotted-list">
                <li>Font</li>
                <li>Font Size</li>
                <li>Text Color</li>
                <li>Text Styles (bold, italic, underline)</li>
                <li>Text Justification (left, center, right)</li>
              </ul>
              <h4>Image/Object</h4>
              <ul className="dotted-list">
                <li>Background Color (text, image, product)</li>
                <li>Opacity (text, image, product)</li>
                <li>Bring Forward (text, image, product)</li>
                <li>Send Backward (text, image, product)</li>
                <li>Image Size (fill, fit, stretch) (image, product)</li>
                <li>Flip (vertical, horizontal) (text, image, product)</li>
                <li>Enlarge (text, image product) [Makes the object the same size as the page]</li>
                <li>
                  Ungroup product (product) [Ungroups text and image so you can manipulate each
                  element separately]
                </li>
              </ul>
              <h4>Product Image (product)</h4>
              <p>
                Here’s where you can input an entirely new image for your product or scroll through
                the existing images you have uploaded during the “add products” phase.
              </p>
              <h4>Product Arrangement (product)</h4>
              <ul className="dotted-list">
                <li>
                  Image on top of text [Moves the image on top of the text and sends text to the
                  bottom]
                </li>
                <li>
                  Image on bottom of text [Moves the text to the top of the image and sends the
                  image to the bottom]
                </li>
              </ul>
              <h4>Product Size (product)</h4>
              <p>
                Each button is a slider to ensure elements are properly sized without distortion.
              </p>
              <ul className="dotted-list">
                <li>Width [Sets product width to % of page width]</li>
                <li>Height [Sets product height to % of page height]</li>
                <li>Image [Sets product’s image height to % of product’s height]</li>
                <li>
                  Space [Set the space between the product’s image and text to % of product’s
                  height]
                </li>
              </ul>
              <h4>Page (page)</h4>
              <ul className="dotted-list">
                <li>Realign [Reverts Product alignment to original template layout]</li>
                <li>
                  Choose Template [Changes the template used by the page and realigns content
                  accordingly]
                </li>
                <li>Lock Pages [Prevents changes]</li>
              </ul>
              <h4>Linesheet Editing Tools</h4>
              <p>
                [Changes made int his section will be applied to the entirety of your linesheet]
              </p>
              <ul className="dotted-list">
                <li>Font</li>
                <li>Font Size</li>
                <li>Text Color</li>
                <li>Text Styles (bold, italic, underline)</li>
                <li>Text Justification (left, center, right)</li>
                <li>Unlock ALL Pages [Unlocks all pages to allow changes]</li>
                <li>Lock ALL Pages [Unlocks all pages to allow changes]</li>
                <li>
                  Template Select (you can set or change the template for the entire linesheet here)
                </li>
              </ul>
            </WaysContentHolder>
          </WelcomeHolder>
        </HelpFrame>
      </HelpSub>
    </div>
  );
};

export default LinesheetEditorHelp;
