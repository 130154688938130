import React from 'react';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import coverIcon from 'assets/images/cover-icon.png';
import { ColWrapper, StyledTitle, StyledSubTitle, StyledButton } from './styles';

const FirstLinesheet = () => {
  return (
    <>
      <ColWrapper span={11}>
        <img src={coverIcon} alt="Cover Icon" preview={false} />
      </ColWrapper>

      <ColWrapper span={13}>
        <StyledTitle>
          Create Your <br /> First Linesheet
        </StyledTitle>
        <StyledSubTitle>
          The path to bigger sales and better things starts here, with your first professional
          linesheet.
        </StyledSubTitle>
        <Tooltip title={'Create new Linesheet'} placement="right">
          <Link to={'linesheets/new'}>
            <StyledButton size="large">Make a Linesheet</StyledButton>
          </Link>
        </Tooltip>
      </ColWrapper>
    </>
  );
};

export default FirstLinesheet;
