import React from 'react';
import YoutubeVideoModel from './YoutubeVideoModel';
import globals from 'websiteGlobals';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Col, Row, Typography } from 'antd';
const { Text } = Typography;

const Container = styled.div`
  padding: 48px 40px;
`;

const CardTitle = styled.h5`
  margin-bottom: 0.75rem;
  padding: 25px 0;
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
`;

const VideoDivHolder = styled.div`
  h2 {
    font-family: Muli;
    font-size: 30px;
    font-weight: 600;
    line-height: 1;
    color: #000000;
    margin: 0 0 35px;
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: 2rem;
`;

const StyledText = styled(Text)`
  font-size: 0.875rem;
  font-weight: 600;
`;

const VideosTypeModel = () => {
  const location = useLocation();
  const videoCategory = location.pathname.split('/')[2];

  const videoList = globals.videos;

  const videoCategoryList = videoList.find((video) => video.videoCategory === videoCategory);

  const renderVideos = () =>
    videoCategoryList.videoData.map((videoEle) => (
      <Col md={8}>
        <YoutubeVideoModel embedId={videoEle.videoLink} />
        <CardTitle>{videoEle.title}</CardTitle>
      </Col>
    ));

  return videoCategoryList ? (
    <Container>
      <StyledRow>
        <VideoDivHolder className="mb-5">
          <h2>{videoCategoryList.name}</h2>
          <StyledText>{videoCategoryList.description}</StyledText>
        </VideoDivHolder>
      </StyledRow>
      <Row gutter={24}>{renderVideos()}</Row>
    </Container>
  ) : (
    <Row justify="center">
      <p>Video Category not found</p>
    </Row>
  );
};

export default VideosTypeModel;
