import styled from 'styled-components';
import { Checkbox, Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const SelectedCount = styled.p`
  margin: 0;
  font-weight: bold;
`;

export const StyleListBody = styled.div`
  height: calc(100% - 30px);
  overflow: auto;
`;

export const CategoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eeeff0;
  margin-bottom: 15px;
  padding-bottom: 10px;
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      color: #fff;
      border: #02b183;
      background-color: #02b183;

      &::after {
        background-color: #02b183;
      }
    }
    &:hover {
      .ant-checkbox-inner {
        background-color: #02b183 !important;
      }
    }
  }
  .ant-checkbox-indeterminate {
    .ant-checkbox-inner::after {
      border: #02b183;
      background-color: #02b183;
    }
  }
`;

export const StyledRow = styled(Row)`
  margin-bottom: 10px;
  column-gap: 1rem;
`;

export const StyledCol = styled(Col)`
  cursor: pointer;
  text-align: center;
`;

export const ProductBox = styled.div`
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e9ecef;
  border: 1px solid transparent;
  &.checked {
    border: 1px solid #02b183;
    background-color: #c0ece0;
  }
`;

export const ProductImageContainer = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  width: 100%;
  height: 64px;
  justify-content: center;
`;

export const ProductImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

export const StyledProductCheckbox = styled(StyledCheckbox)`
  position: absolute;
  top: 2px;
  left: 6px;
  pointer-events: none;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  cursor: pointer;
`;

export const EllipsisTooltip = styled.span`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  max-height: 3em;
  max-width: 95%;
  white-space: normal;
  line-height: 1.2;
  margin-bottom: 1rem;
  font-size: 12px;
`;

export const StyledActionButtons = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
