import styled from 'styled-components';
import { Tooltip, Slider, InputNumber } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CalculatorContainer = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 1rem;
  @media all and (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const WholeSaleCon = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const StyledLabel = styled.label`
  font-family: Muli;
  font-size: 12px;
  font-weight: 800;
  line-height: 2.5;
  letter-spacing: normal;
  color: #000;
`;

export const StyledOptions = styled.div`
  display: flex;
`;

export const StyledOptionLabel = styled.label`
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: 0.28px;
  margin-right: 4px;
  cursor: pointer;

  &.selected {
    border-top: 1px solid #02b183;
    margin-right: 5px;
    color: #28a745 !important;
  }
`;

export const StyledTooltip = styled(Tooltip)``;

export const SliderContainer = styled.div`
  display: flex;
  @media all and (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const StyleSlider = styled(Slider)`
  width: 150px;
  .ant-slider-track {
    background: rgb(124, 179, 66) !important;
  }
  .ant-slider-handle {
    border-radius: 100% !important;
    &:focus,
    &::after {
      background-color: rgb(2, 177, 131);
      box-shadow: unset !important;
    }
  }
`;

export const StyledInputNumber = styled(InputNumber)`
  margin: 0 16px;
  width: 56px;
  height: fit-content;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
  cursor: pointer;
`;
