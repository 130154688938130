import styled from 'styled-components';
import { Tooltip, Dropdown } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const MainContainer = styled.div`
  padding-top: 36px;
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  background-color: #fff;
  gap: 4px;
  flex-wrap: wrap;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

export const StyledButton = styled.button`
  border-color: #343a40;
  border-radius: 18px;
  background: ${(props) => (props.isactive === 'true' ? '#343a40' : '#ffffff')};
  min-width: 32px;
  min-height: 25px;
  color: ${(props) => (props.isactive === 'true' ? '#07af7c !important' : '')};
  cursor: pointer;
  &:hover {
    background-color: #343a40;
    border-color: #343a40;
    color: #ffffff;
  }
`;

export const StyledCollapsedButton = styled.button`
  background: none;
  border: none;
  color: #ffffff;
`;

export const StyledTooltip = styled(Tooltip)``;

export const StyledDropdown = styled(Dropdown)``;

export const PlaygroundSection = styled.div`
  height: 100vh;
`;
