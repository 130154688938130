import styled from 'styled-components';
import { Popover } from 'antd';

export const StyledContentContainer = styled.div`
  ul,
  ol,
  dl {
    list-style-position: inside;
    padding: 0;
  }
`;

export const StyledPopover = styled(Popover)``;

export const StyledContentEditable = styled.div``;
