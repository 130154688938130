import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHouseChimney,
  faFileInvoice,
  faSlidersH,
  faUser,
  faSignOutAlt,
  faLayerGroup,
  faBoxOpen,
} from '@fortawesome/free-solid-svg-icons';
import { faAddressBook, faEnvelope, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { MenuLayout, StyledMenu } from './styles';

const SideBar = ({ selectedKey, setSelectedKey }) => {
  const navigate = useNavigate();

  const handleNavigation = (route, key) => {
    navigate(route);
    setSelectedKey(key);
  };

  const onMenuClick = (key) => {
    switch (key) {
      case '1':
        handleNavigation('/', key);
        break;
      case '2':
        handleNavigation('/products', key);
        break;
      case '3':
        handleNavigation('/linesheets', key);
        break;
      case '4':
        handleNavigation('/contacts');
        break;
      case '5':
        handleNavigation('/email-outbox');
        break;
      case '6':
        handleNavigation('/sync', key);
        break;
      case '7':
        handleNavigation('/profile', key);
        break;
      case '8':
        handleNavigation('/help', key);
        break;
      case '9':
        handleNavigation('/logout', key);
        break;
      case '10':
        handleNavigation('/categories', key);
        break;
      default:
        break;
    }
  };
  const getItem = (label, key, icon, children, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  };

  const items = [
    getItem('Dashboard', '1', <FontAwesomeIcon icon={faHouseChimney} />),
    getItem('Products', '2', <FontAwesomeIcon icon={faBoxOpen} />),
    getItem('Categories', '10', <FontAwesomeIcon icon={faLayerGroup} />),
    getItem('Linesheets', '3', <FontAwesomeIcon icon={faFileInvoice} />),
    getItem('Contacts', '4', <FontAwesomeIcon icon={faAddressBook} />),
    getItem('Emails', '5', <FontAwesomeIcon icon={faEnvelope} />),
    {
      type: 'divider',
      style: { margin: '1rem 0' },
    },
    getItem('Sync', '6', <FontAwesomeIcon icon={faSlidersH} />),
    getItem('My Profile', '7', <FontAwesomeIcon icon={faUser} />),
    getItem('Help', '8', <FontAwesomeIcon icon={faQuestionCircle} />),
    {
      type: 'divider',
      style: { margin: '1rem 0' },
    },
    getItem('Log Out', '9', <FontAwesomeIcon icon={faSignOutAlt} />),
  ];

  return (
    <MenuLayout>
      <StyledMenu
        onClick={({ key }) => onMenuClick(key)}
        mode="inline"
        defaultSelectedKeys={[selectedKey]}
        selectedKeys={[selectedKey]}
        items={items}
      />
    </MenuLayout>
  );
};

export default SideBar;
