import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setHeaderFooterPosition } from 'slices/editorSlice';

const Image = ({ data, pageWidth, isHeader }) => {
  const dispatch = useDispatch();
  const imageDivRef = useRef(null);
  const [left, setLeft] = useState(data.settings?.left || 0);
  const [right, setRight] = useState(data.settings?.right || 0);
  const [top, setTop] = useState(data.settings?.top || 0);
  const [imageSelected, setImageSelected] = useState(false);

  useEffect(() => {
    if (data && data.settings) {
      setRight(data.settings.right);
      setTop(data.settings.top);
      setLeft(data.settings.left);
    }
  }, [data]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (imageDivRef.current && !imageDivRef.current.contains(event.target)) {
        setImageSelected(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const onDragStart = (e) => {
    e.preventDefault();
    setImageSelected(true);
    const { clientHeight: height, clientWidth: width } = e.target;
    const startX = e.clientX;
    const startY = e.clientY;
    const startTop = top;
    const startLeft = left;
    let newTop = top;
    let newLeft = left;

    const onMouseMove = (moveEvent) => {
      const deltaX = moveEvent.clientX - startX;
      const deltaY = moveEvent.clientY - startY;

      newTop = startTop + deltaY;
      newLeft = startLeft + deltaX;

      const maxTop = 40 - height;
      const maxLeft = pageWidth - width;

      if (newTop < 0) newTop = 0;
      if (newTop > maxTop) newTop = maxTop;
      if (newLeft < 0) newLeft = 0;
      if (newLeft > maxLeft) newLeft = maxLeft;

      setTop(newTop);
      setLeft(newLeft);
      dispatch(setHeaderFooterPosition({ left: newLeft, top: newTop, isHeader, isImage: true }));
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const style = {
    left: left === 'unset' ? left : `${left}px`,
    right: right === 'unset' ? right : `${right}px`,
    top: `${top}px`,
    border: `${imageSelected ? '1px solid #02b183' : 'unset'}`,
  };

  return (
    <div ref={imageDivRef} className="image-con" style={style} onMouseDown={onDragStart}>
      <img src={data.value} alt="logo" />
    </div>
  );
};

export default Image;
