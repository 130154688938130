import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetNonLinesheetProductsMutation } from 'api/productApi';
import {
  useAddLinesheetProductsMutation,
  useGetLinesheetProductsByIdMutation,
} from 'api/linesheetApi';
import {
  faClipboardList,
  faObjectGroup,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import AssignModal from 'components/Common/AsignModal';
import ListCategoryProducts from './ListCategoryProducts';
import { setLinesheetProducts } from 'slices/editorSlice';
import { message } from 'antd';
import {
  MainContainer,
  StyledHeader,
  StyledTitle,
  StyledFontAwesomeIcon,
  ActionButton,
  StyledButton,
  StyledCollapsedButton,
  StyledTooltip,
  StyledBody,
  StyledEditorToolBody,
} from './styles';
import Loader from 'components/Common/Loader';

const LeftPanel = () => {
  const dispatch = useDispatch();
  const { id: linesheetId } = useSelector((state) => state.editor.linesheetData);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [addMoreProductsModal, setAddMoreProductsModal] = useState(false);
  const [assignModalStates, setAssignModalStates] = useState({
    searchFilter: '',
    currentPage: 1,
    pageSize: 50,
    selectedRows: [],
  });

  const [addLinesheetProducts, { isLoading: addingLinesheetProducts }] =
    useAddLinesheetProductsMutation();
  const [
    getNonLinesheetProducts,
    { data: productsData, error: productsDataError, isLoading: productsLoading },
  ] = useGetNonLinesheetProductsMutation();
  const [getLinesheetProductsById, { isLoading: fetchingLinesheetProducts }] =
    useGetLinesheetProductsByIdMutation();

  // Memoize functions to prevent unnecessary re-renders
  const getProductsData = useCallback(async () => {
    await getNonLinesheetProducts({
      searchFilter: assignModalStates.searchFilter,
      currentPage: assignModalStates.currentPage,
      pageSize: assignModalStates.pageSize,
      linesheetFilter: linesheetId,
    }).unwrap();
    if (productsDataError) {
      console.log('productsDataError');
      return;
    }
  }, [getNonLinesheetProducts, assignModalStates, linesheetId, productsDataError]);

  useEffect(() => {
    if (addMoreProductsModal) {
      getProductsData();
    }
  }, [assignModalStates.currentPage, assignModalStates.pageSize]);

  const toggleCollapse = useCallback(() => {
    setIsCollapsed((prevState) => !prevState);
  }, []);

  const resetSelectedRows = useCallback(() => {
    setSelectedRows([]);
  }, []);

  const resetModalSelectedRows = useCallback(() => {
    setAssignModalStates((prevState) => ({
      ...prevState,
      selectedRows: [],
    }));
  }, []);

  const onModalSelectChange = useCallback(
    (newSelectedRowKeys) => {
      if (newSelectedRowKeys.length === 0) {
        resetModalSelectedRows();
        return;
      }
      setAssignModalStates((prevState) => ({
        ...prevState,
        selectedRows: newSelectedRowKeys,
      }));
    },
    [resetModalSelectedRows],
  );

  const assignModalrowSelection = {
    selectedRows: assignModalStates.selectedRows,
    preserveSelectedRowKeys: false,
    onChange: onModalSelectChange,
  };

  const handleAssign = useCallback(
    async (selectAll) => {
      if (assignModalStates.selectedRows.length === 0) {
        message.error('Please select product first');
        return;
      }
      const payload = {
        linesheetId,
        productIds: assignModalStates.selectedRows,
        selectAll,
      };
      try {
        const res = await addLinesheetProducts(payload);
        if (res?.data?.message) {
          setAddMoreProductsModal(false);
          const response = await getLinesheetProductsById(linesheetId);
          if (response.data) {
            dispatch(setLinesheetProducts(response.data.categories));
          }
          resetModalSelectedRows();
        } else {
          message.error('Something went wrong. Try again');
        }
      } catch (error) {
        message.error('Failed to assign products');
      }
    },
    [
      assignModalStates.selectedRows,
      linesheetId,
      addLinesheetProducts,
      getLinesheetProductsById,
      dispatch,
      resetModalSelectedRows,
    ],
  );

  const handleAddProducts = useCallback(() => {
    setAddMoreProductsModal(true);
    getProductsData();
  }, [getProductsData]);

  return (
    <MainContainer>
      <StyledHeader>
        <StyledTitle>
          <StyledFontAwesomeIcon icon={faClipboardList} cursor={'default'} size="sm" />
          Products
        </StyledTitle>
        <ActionButton>
          <StyledTooltip title="Add New Product" placement="bottom">
            <StyledButton onClick={handleAddProducts}>
              <StyledFontAwesomeIcon icon={faObjectGroup} />
            </StyledButton>
          </StyledTooltip>
          <StyledCollapsedButton onClick={toggleCollapse}>
            <StyledFontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} />
          </StyledCollapsedButton>
        </ActionButton>
      </StyledHeader>
      <StyledBody height={isCollapsed ? '0' : 'calc(100vh - 62px)'}>
        {fetchingLinesheetProducts ? (
          <Loader isLoading={fetchingLinesheetProducts} />
        ) : (
          <StyledEditorToolBody>
            <ListCategoryProducts />
          </StyledEditorToolBody>
        )}
      </StyledBody>
      {!!addMoreProductsModal && (
        <AssignModal
          isAssignModal={addMoreProductsModal}
          setIsAssignModal={setAddMoreProductsModal}
          selectedProducts={selectedRows}
          resetParentRows={resetSelectedRows}
          refetchData={getProductsData}
          parentStates={assignModalStates}
          setParentStates={setAssignModalStates}
          total={productsData?.total}
          dataSource={productsData?.products}
          loading={productsLoading || addingLinesheetProducts}
          rowSelection={assignModalrowSelection}
          resetRows={resetModalSelectedRows}
          handleAssign={handleAssign}
          localeTxt="No Data Found"
          type="linesheetProducts"
        />
      )}
    </MainContainer>
  );
};

export default React.memo(LeftPanel);
