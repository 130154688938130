import React from 'react';
import { StyledModal, StyledDivider, StyledButton } from './styles';

const RightClickMenu = ({ visible, x, y, transform, options, onClose }) => {
  const renderOption = (option, index) => {
    if (option.type === 'divider') {
      return <StyledDivider key={index} />;
    }
    return (
      <StyledButton
        key={index}
        type="text"
        block
        disabled={option.disabled}
        onClick={() => {
          if (option.onClick) {
            option.onClick();
          }
          onClose();
        }}
        style={{
          color: option.disabled ? '#ccc' : '#000',
        }}
        destroyOnClose
      >
        {option.label}
      </StyledButton>
    );
  };

  return (
    <StyledModal
      open={visible}
      onCancel={onClose}
      footer={null}
      closable={false}
      mask={false}
      style={{
        top: y,
        left: x,
        transform: transform,
        position: 'absolute',
        padding: 0,
        margin: 0,
      }}
      width={200}
    >
      <div>{options.map((option, index) => renderOption(option, index))}</div>
    </StyledModal>
  );
};

export default RightClickMenu;
