import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import GuideIcon from 'assets/images/guides-icon.png';
import VideoIcon from 'assets/images/video-icon.png';
import GettingStartedIcon from 'assets/images/getting-started-icon.png';
import ProductImportingIcon from 'assets/images/product-importing-icon.png';
import LinesheetEditorIcon from 'assets/images/linesheet-editor-icon.png';
import { Tooltip } from 'antd';

const TwoColumns = styled.div`
  position: relative;
  overflow: hidden;
  text-align: center;
  margin: 0 0 60px;
`;

const HelpFrame = styled.div`
  position: relative;
  max-width: 1086px;
  margin: 0 auto;

  &:after {
    display: block;
    clear: both;
    content: '';
  }
`;

const HelpBox = styled.div`
  width: 406px;
  border-radius: 10px;
  background-color: #f1f3f5;
  padding: 30px;
  text-align: center;
  margin: 0 30px;
  display: inline-block;
  vertical-align: top;
`;

const PicHolder = styled.div`
  max-width: 57px;
  margin: 0 auto 17px;
  min-height: 57px;
`;

const InfoPicHolder = styled.div`
  max-width: 74px;
  margin-right: 45px;
  float: left;
`;

const Pic = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

const Title = styled.h2`
  font-family: Muli;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #000000;
  margin: 0 0 15px;
`;

const InfoTitle = styled.h2`
  cursor: pointer;
  font-family: Muli;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: left;
  color: #07af7c !important;
  margin: 0 0 15px;
`;

const TextInfo = styled.h2`
  font-family: Muli;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: center;
  color: #000000;
  margin: 0 0 50px;
`;

const StepBox = styled.div`
  border-radius: 10px;
  background-color: #f1f3f5;
  overflow: hidden;
  padding: 45px 70px;
  margin: 0 0 40px;
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.32px;
  text-align: left;
  color: #000000;
`;

const StepContent = styled.div`
  overflow: hidden;
`;

const Cursor = styled.span`
  cursor: pointer;
`;

const StepsHolder = styled.div`
  position: relative;
  overflow: hidden;
  padding: 0 15px;
`;

const StyledSpan = styled.span`
  color: #999;
`;

const LandingHelpPage = ({ onClick }) => {
  return (
    <>
      <TwoColumns>
        <HelpFrame>
          <HelpBox>
            <Cursor>
              <PicHolder>
                <Pic src={GuideIcon} alt="" />
              </PicHolder>
              <Tooltip title="Coming Soon" placement="top">
                <Title>Guides</Title>
              </Tooltip>
            </Cursor>
          </HelpBox>
          <HelpBox>
            <Link to="/videos">
              <Cursor>
                <PicHolder>
                  <Pic src={VideoIcon} alt="" />
                </PicHolder>
                <Title>Videos</Title>
              </Cursor>
            </Link>
          </HelpBox>
        </HelpFrame>
      </TwoColumns>
      <TextInfo>Most Popular Information</TextInfo>
      <StepsHolder>
        <HelpFrame>
          <StepBox>
            <InfoPicHolder>
              <Pic src={GettingStartedIcon} alt="" />
            </InfoPicHolder>
            <StepContent>
              <InfoTitle onClick={() => onClick('gettingStarted')}>Getting Started</InfoTitle>
              <p>
                Introduction articles and videos to give you an overview on how to set your
                Linesheet.
              </p>
              <p>
                <StyledSpan>Written by</StyledSpan> Alexis Kelly <StyledSpan>and</StyledSpan> Ryan
                Kelly
              </p>
            </StepContent>
          </StepBox>
          <StepBox>
            <InfoPicHolder>
              <Pic src={ProductImportingIcon} alt="" />
            </InfoPicHolder>
            <StepContent>
              <InfoTitle onClick={() => onClick('gettingStarted')}>Products & Importing</InfoTitle>
              <p>Products and how they are imported into your Linesheet</p>
              <p>
                <StyledSpan>Written by</StyledSpan> Alexis Kelly <StyledSpan>and</StyledSpan> Ryan
                Kelly
              </p>
            </StepContent>
          </StepBox>
          <StepBox>
            <InfoPicHolder>
              <Pic src={LinesheetEditorIcon} alt="" />
            </InfoPicHolder>
            <StepContent>
              <InfoTitle onClick={() => onClick('editor')}>Linesheet Editor</InfoTitle>
              <p>Add, duplicate, delete, arrange your pages with ease/</p>
              <p>
                <StyledSpan>Written by</StyledSpan> Alexis Kelly <StyledSpan>and</StyledSpan> Ryan
                Kelly
              </p>
            </StepContent>
          </StepBox>
        </HelpFrame>
      </StepsHolder>
    </>
  );
};

export default LandingHelpPage;
