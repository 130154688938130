import React from 'react';
import YoutubeVideoModel from 'components/Help/YoutubeVideoModel';
import { HelpingHeader, HelpingContentContainer } from './styles';

const HelpingContent = ({ syncVideos, name, openLink }) => {
  const bigcartelUrl = 'https://bigcartel.wufoo.com/forms/big-cartel-api-application/';
  const shpifyUrl = 'https://mystore.shopify.com/';
  const amazonDeveloperUrl =
    'https://docs.developer.amazonservices.com/en_UK/dev_guide/DG_Endpoints.html';
  const EtsyDev = 'https://www.etsy.com/developers/register';
  const EtsyApp = 'https://www.etsy.com/developers/your-apps';

  const DisplayModelVideo = (name) => {
    const videoData = syncVideos.videoData.find((video) => video.related === name.toLowerCase());
    if (videoData) {
      return (
        <>
          <hr />
          <div className="col-12">
            <h5 className="card-title video-card-title">{videoData.title}</h5>
            <YoutubeVideoModel embedId={videoData.videoLink} />
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  switch (name.toLowerCase()) {
    case 'shopify':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <p>
              Endpoint: Your store’s URL, for example
              <a
                href={shpifyUrl}
                style={{ paddingLeft: '4px' }}
                onClick={() => openLink(shpifyUrl)}
              >
                {shpifyUrl}
              </a>
            </p>
            <p>Password : shppa_88e7563fe96hh6a272d52ggc0a38c20e0</p>

            <p>To get your token:</p>
            <ul>
              <li>
                Go to your Shopify admin page and click <span>Apps</span>
              </li>
              <li>
                Click <span>Manage Private Apps</span> near the very bottom of the page
              </li>
              <li>
                Click <span>Create a New Private App</span>
              </li>
              <li>
                Fill out the form, entering “Linesheets” in the private app section and adding a
                contact email address (the email address is so Shopify can contact the developer if
                there’s an issue with the connection or performance of the Linesheets app or in case
                of an API change)
              </li>
              <li>
                In the <span>Admin API</span> section, select the areas of your store that you want
                Linesheets to be able to access (If you want to use the Storefront API, then you’ll
                need to put a checkmark where it says Allow this app to access your storefront data
                using the Storefront API”)
              </li>
              <li>
                If you click to allow Storefront API permissions, you’ll have the option to select
                which types of data you want to share with the Linesheets app
              </li>
              <li>
                Click <span>Save</span>
              </li>
              <li>
                API Password will appear right after you save the app then copy{' '}
                <span>password</span> from the admin api.
              </li>
              <strong>Note: </strong>
              <li>
                select <strong>2021-07</strong> api versions of the app.
              </li>
              <li>
                Please select <strong>smart collections</strong> while creating categories in
                shopify store.
              </li>
            </ul>
          </HelpingContentContainer>
          {DisplayModelVideo(name)}
        </>
      );
    case 'amazon':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <p>
              <strong>Store Name:</strong> Enter the name of your store
            </p>
            <p>
              <strong>Market place Id:</strong>
            </p>
            <ul>
              <li>Click on the below attach link</li>
              <li>Copy Market Place id according to your store region</li>
              <a
                style={{ wordBreak: 'break-all' }}
                href={amazonDeveloperUrl}
                onClick={() => openLink(amazonDeveloperUrl)}
              >
                {amazonDeveloperUrl}
              </a>
            </ul>
            <p>
              <strong>Seller/Merchant Id:</strong>
            </p>
            <ul>
              <li>
                Go to Selling Account information Dashboard <strong>settings > Account info</strong>
              </li>
              <li>
                In the <strong>“Business Information”</strong> section you should see a link titled{' '}
                <strong>“Your Merchant Token”</strong>
              </li>
              <li>
                Click that & you’ll see your <strong>Seller/Merchant ID</strong>
              </li>
            </ul>
            <p>
              <strong>AWS Access key Id and Client Secret Access key:</strong>
            </p>
            <ul>
              <li>Open the IAM console</li>
              <li>
                From the navigation menu, click <strong>Users</strong>
              </li>
              <li>Select your IAM user name</li>
              <li>
                Click <strong>User Actions</strong>, and then click{' '}
                <strong>Manage Access Keys</strong>
              </li>
              <li>
                Click <strong>Create Access Key</strong>
              </li>
            </ul>
            <p>
              <strong>Your keys will look something like this:</strong>
            </p>
            <div>
              <strong>Access key ID example:</strong> AKIAIOSFODNN7EXAMPLE
            </div>
            <div>
              <strong>Secret access key example:</strong> wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY
            </div>
            <ul>
              <li>
                Click <strong>Download Credentials,</strong> and store the keys in a secure location
              </li>
            </ul>
          </HelpingContentContainer>
          {DisplayModelVideo(name)}
        </>
      );
    case 'rakuten':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <p>
              <strong>Endpoint:</strong> Enter the name of your store
            </p>
          </HelpingContentContainer>
          {DisplayModelVideo(name)}
        </>
      );

    case 'etsy':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <p>
              <strong>Endpoint:</strong> Enter the name of your store
            </p>
            <div>
              <strong>Api Key: </strong>
            </div>

            <ul>
              <li>
                Create a New App so that you can get an API key. Go to
                <a
                  style={{ wordBreak: 'break-all', paddingLeft: '4px' }}
                  href={EtsyDev}
                  onClick={() => openLink(EtsyDev)}
                >
                  {EtsyDev}
                </a>
                . Fill in the details as in the example below:
              </li>
              <ul>
                <li>
                  <strong>Application Name:</strong> WP Auto Content WordPress Plugin
                </li>
                <li>
                  <strong>Describe your Application:</strong> Automatically imports content from
                  Etsy to my website via the WP Auto Content WordPress plugin
                </li>
                <li>
                  <strong>Application Website:</strong> Your website’s URL, for example,
                  https://wpautocontent.com
                </li>
                <li>
                  <strong>What type of application are you building?</strong> Buyer Tools
                </li>
                <li>
                  <strong>Who will be the users of this application?</strong> The general public
                </li>
                <li>
                  <strong>Is your application commercial?</strong> No
                </li>
                <li>
                  <strong>Will your app do any of the following?</strong> No check/Check
                  none/Blank/Empty
                </li>
                <li>
                  <strong>Inventory Management compatibility:</strong> Tick Compatible with the new
                  Inventory Management API
                </li>
                <li>Solve the Captcha</li>
                <li>
                  Click on <strong>Read Terms and Create App</strong>
                </li>
              </ul>
              <li>
                Tick M<strong>I have read and agree to the Etsy API Terms of Use</strong> and then
                click on <strong>Create App</strong>
              </li>
              <li>
                Once the App creation is successful, you will be sent to a page containing the App
                Name as well as your <strong>Keystring</strong> and <strong>Shared Secret</strong>.
                Copy these keys
              </li>
              <li>
                Paste them into the appropriate fields in your WP Auto Content settings. Click on
                Save All Changes once done
              </li>
              <li>
                Your keys are saved to your developer account for future reference. While logged in,
                go to{' '}
                <a
                  style={{ wordBreak: 'break-all' }}
                  href={EtsyApp}
                  onClick={() => openLink(EtsyApp)}
                >
                  {EtsyApp}
                </a>{' '}
                to revisit your keys.
              </li>
            </ul>
          </HelpingContentContainer>
          {DisplayModelVideo(name)}
        </>
      );
    case 'bigcartel':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <ul>
              <li>
                {' '}
                <strong>Endpoint:</strong> Account id
              </li>
              <li>
                <strong>e.g.</strong> (winterclothing.bigcartel.com) <strong>winterclothing</strong>{' '}
                is account id
              </li>
            </ul>
            <p>
              Sign up for their API here:{' '}
              <a href={bigcartelUrl} onClick={() => openLink(bigcartelUrl)}>
                {bigcartelUrl}
              </a>{' '}
              and Big Cartel will grant you access.
            </p>
          </HelpingContentContainer>
          {DisplayModelVideo(name)}
        </>
      );
    case 'ebay':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <p>
              <strong>Endpoint:</strong> Enter the name of your store
            </p>
          </HelpingContentContainer>
        </>
      );
    case 'pinnaclecart':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <p>
              <strong>Endpoint:</strong> The URL of your store
            </p>
            <p>
              <strong>Username:</strong> Username of your account
            </p>
            <p>
              <strong>Password:</strong> Password of your account
            </p>
            <p>
              <strong>Token:</strong>
              <ul>
                <li>
                  Type <strong>API</strong> in the search tab of your account
                </li>
                <li>
                  Click <strong>API</strong> access in the search results
                </li>
                <li>
                  Check the box to <strong>enable API</strong>
                </li>
                <li>
                  You will be prompted to create a <strong>Username and Password</strong>
                </li>
                <li>
                  To get a security token, <strong>click Generate</strong>
                </li>
              </ul>
            </p>
          </HelpingContentContainer>
          {DisplayModelVideo(name)}
        </>
      );
    case 'squarespace':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <strong>Location for the API key:</strong>
            <ul>
              <li>
                Go to the Home Menu, click <span>Settings</span>, then click <span>Advanced</span>
              </li>
              <li>
                Click <span>Developer API Keys</span>
              </li>
              <li>Click Generate Key</li>
              <li>
                Under Permissions, check <span>Orders, Forms, Inventory, or Transactions.</span>
                <span>
                  Forms are available for Business-level plans or higher. Orders, Inventory, and
                  Transactions are only available if you have the Commerce Advanced plan.
                </span>
              </li>
              <li>Select the permissions for the API key</li>
              <li>
                Click <span>Next</span>
              </li>
              <li>Copy your unique API key</li>
            </ul>
          </HelpingContentContainer>
          {DisplayModelVideo(name)}
        </>
      );
    case 'magento':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <p>
              <ul>
                <li>
                  Go to the <strong>system</strong>>>
                  <strong>integrations</strong>>>click <strong>Add new </strong>
                  integrations Button
                </li>
                <li>Fill the required fields,Name and password(of your store)</li>
                <li>
                  Go to <strong>Api section</strong> from left menu
                </li>
                <li>Give Resource access (Custom/All)</li>
                <li>
                  click on <strong>save</strong> button
                </li>
                <li>
                  From the listing click Activate link >>
                  <strong>Allow</strong>
                </li>
              </ul>
            </p>
          </HelpingContentContainer>
          {DisplayModelVideo(name)}
        </>
      );
    case '3dcart':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <ul>
              <li>
                <strong>Log in/sign-up</strong> to 3d-cart's dev portal
              </li>
              <li>
                Click on <strong>Add New</strong> button
              </li>
              <li>
                Enter
                <strong>Application name</strong> Press <strong>create app</strong> button
              </li>

              <li>
                Check the permissions for required <strong>modules</strong> to read and write
              </li>
              <li>
                Copy <strong>Public key</strong>
              </li>
              <li>
                Go to your store's <strong>admin panel</strong> Then go to <strong>module</strong>{' '}
                section Search for <strong>Rest API</strong>
              </li>
              <li>
                Click <strong>setting</strong> button click <strong>Add</strong> button from top
                right corner of the page
              </li>
              <li>
                Paste <strong>public key</strong> here (copied from the dev portal)
              </li>
              <li>
                Click <strong>authorize</strong> button from the bottom of pop-up <li></li>
              </li>
              <li>
                Go to <strong>dev portal</strong> where you can see the token in the bottom Copy{' '}
                <strong>Token</strong> and <strong>Private key</strong>
              </li>
            </ul>
          </HelpingContentContainer>
          {DisplayModelVideo(name)}
        </>
      );
    case 'woo commerce':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <ul>
              <li>
                Go to
                <strong>WooCommerce > Settings > Advanced > REST API</strong>
              </li>
              <li>
                Select <strong>Add Key</strong> You are taken to the <strong>Key Details</strong>{' '}
                screen.
              </li>
              <li>
                Add a <strong>Description</strong>
              </li>
              <li>
                Select the <strong>User</strong> you would like to generate a key for in the drop
                down.
              </li>
              <li>
                Select a level of access for this API key — <strong>Read</strong> access,{' '}
                <strong>Write</strong>
                access or <strong>Read/Write </strong> access.
              </li>
              <li>
                Select <strong>Generate API Key</strong>, and WooCommerce creates{' '}
                <strong>API keys</strong> for that user.
              </li>
            </ul>
          </HelpingContentContainer>
          {DisplayModelVideo(name)}
        </>
      );
    case 'volusion':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <p>
              <strong>Endpoint:</strong> Enter the name of your store
            </p>
            <p>
              <strong>Token:</strong> To find your API token (Volusion update their administrator
              account passwords every 90 days; you’ll need to update your API access then as well):
            </p>
            <ul>
              <li>Sign in to your Volusion store</li>
              <li>
                Find the <span>Integration Help</span> page, then scroll to the Import or Export
                sections (either one works)
              </li>
              <li>
                Use the drop-down menu to choose the username/email address associated with the
                right administrator account — this will be your username for your API connection
              </li>
              <li>
                Cope your encrypted password string from the longer string in the information field
                — the password is the text located between <span>“&EncryptedPassword=”</span> and{' '}
                <span>“&”</span>
              </li>
            </ul>
          </HelpingContentContainer>
          {DisplayModelVideo(name)}
        </>
      );
    case 'weebly':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <p>Endpoint:</p>
            <p>
              Token: Weebly gives store owners limited access to API info and even their store ID.
              Try the instructions below, but if you run into trouble contact Weebly support
              directly to get your store ID. You should be able to proceed on your own from there.
            </p>
            <ul>
              <li>Sign in to your Weebly store</li>
              <li>
                Click <span>Edit Site</span> in the upper right-hand corner
              </li>
              <li>
                Click on <span>Settings</span> on the right-hand side of the header menu and then
                <span>General</span> from the pull-down menu
              </li>
              <li>
                Scroll down the page until you find the Platform API token, then copy your{' '}
                <span>Store ID</span>
              </li>
              <li>Copy your Store ID into the matching field in the Linesheets app</li>
            </ul>
          </HelpingContentContainer>
          {DisplayModelVideo(name)}
        </>
      );
    default:
      return null;
  }
};

export default HelpingContent;
