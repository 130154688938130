import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import ManualProductVariant from '../ManualProductVariant';
import {
  useGetProductMutation,
  useCreateProductMutation,
  useUpdateProductMutation,
} from 'api/productApi';
import { useGetCategoriesQuery } from 'api/categoriesApi';
import Loader from 'components/Common/Loader';
import ProductFormBody from '../ProductFormBody';
import CustomModal from './../../Common/CustomModal';
import {
  FormContainer,
  ActionButtonContainer,
  StyledCancelButton,
  StyledSubmitButton,
  StyledViewButton,
  HeaderContainer,
  StyledHeading,
  StyledForm,
  HorizontalLine,
  EditProductHeading,
  EditProductFooter,
} from './styles';

const ManualForm = ({ editProduct, productId, hideEditModal, onSubmit }) => {
  const navigate = useNavigate();
  const [form] = StyledForm.useForm();
  const [images, setImages] = useState([]);
  const [defaultImage, setdefaultImage] = useState('');
  const [thumbnailNum, setThumbnailNum] = useState(null);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [variants, setVariants] = useState([]);
  const [barcodeImage, setBarcodeImage] = useState(null);
  const [error, setError] = useState({
    variants: '',
    image: '',
    retailPrice: '',
    barcode: '',
    wholesalePrice: '',
  });
  const [isSaveAndCreateNew, setIsSaveAndCreateNew] = useState(false);
  const [removedImages, setRemovedImages] = useState([]);
  const { data: categoriesData } = useGetCategoriesQuery();
  const [createProduct, { isLoading: productIsLoading }] = useCreateProductMutation();
  const [getProduct, { isLoading: productLoading }] = useGetProductMutation();
  const [updateProduct, { isLoading: updateProductLoading }] = useUpdateProductMutation();

  useEffect(() => {
    if (editProduct) {
      const asyncProductDetails = async () => {
        try {
          const res = await getProduct(productId);
          if (res.data) {
            const productData = res.data;
            form.setFieldsValue({
              name: productData.name,
              description: productData.description?.replace(/<[^>]*>/g, ''),
              productNumber: productData.productNumber,
              wholesalePrice: productData.wholesalePrice,
              retailPrice: productData.retailPrice,
              categories: productData?.categories?.map((category) => category.id) || [],
              barcodeNumber: productData.barcodeNumber,
            });
            setBarcodeImage(productData.barcodeImage);
            setImages(productData.images);
            setdefaultImage(productData.images[0]);
            const productVariants = productData.variants.map((variant) => ({
              id: variant.id,
              data: variant,
            }));
            setVariants(productVariants);
          }
        } catch (error) {
          console.error('Error fetching product details:', error);
        }
      };

      asyncProductDetails();
    }
  }, [editProduct]);

  const handleImages = (file) => {
    setImages((prevImages) => [...prevImages, file]);
    setdefaultImage(file);
  };

  const deleteImage = (index) => {
    if (editProduct) {
      const removedImage = images.find((image, i) => i === index);
      setRemovedImages((prevRemovedImages) => [...prevRemovedImages, removedImage.id]);
    }
    setImages((prevImages) => {
      const newImages = prevImages.filter((_, i) => i !== index);
      const newIndex = Math.min(index, newImages.length - 1); // Determine the new index for defaultImage and thumbnailNum
      setdefaultImage(newImages[newIndex] || ''); // Set defaultImage to the new image at newIndex or an empty string if it doesn't exist
      setThumbnailNum(newIndex + 1); // Increment thumbnailNum by 1
      return newImages;
    });
  };

  const addVariant = () => {
    const newVariant = {
      id: `variant-${Date.now()}`,
      data: {},
    };

    setVariants((prevVariants) => [...prevVariants, newVariant]);
    setSelectedVariants((prevSelectedVariants) => [...prevSelectedVariants, false]);
  };

  const variantChangeHandler = (variantId, newVariantData) => {
    setVariants((prevVariants) => {
      return prevVariants.map((variant) => {
        if (variant.id === variantId) {
          return { ...variant, data: newVariantData };
        }
        return variant;
      });
    });
  };

  const renderVariants = () => {
    return variants.map((variant, index) => {
      return (
        <div key={variant.id}>
          <hr />
          <ManualProductVariant variant={variant} onChange={variantChangeHandler} />
        </div>
      );
    });
  };

  const goToProducts = () => {
    navigate('/products');
  };

  const isPriceValid = (retailPrice, wholesalePrice) => {
    return retailPrice && wholesalePrice && parseFloat(retailPrice) < parseFloat(wholesalePrice);
  };

  const areVariantsValid = (variants) => {
    const isPriceValid = (price) => parseFloat(price) >= -999999 && parseFloat(price) <= 999999;

    return !variants.some((variant) => {
      const { retailPrice, wholesalePrice } = variant.data;
      return (
        !isPriceValid(retailPrice) ||
        !isPriceValid(wholesalePrice) ||
        parseFloat(retailPrice) < parseFloat(wholesalePrice)
      );
    });
  };

  const onFinish = async (values) => {
    const {
      categories,
      description,
      name,
      productNumber,
      retailPrice,
      wholesalePrice,
      barcodeNumber,
    } = values;
    const isValidPrice = !isPriceValid(retailPrice, wholesalePrice);
    const validVariants = areVariantsValid(variants);
    setError((prevError) => ({
      ...prevError,
      retailPrice: isValidPrice
        ? ''
        : 'Please enter retail price greater or equal to wholesale price',
      variants: validVariants ? '' : 'Some variants has wrong retail price',
    }));
    const isErrorPresent = Object.keys(error)
      .filter((key) => key !== 'retailPrice' && key !== 'variants') // Exclude retailPrice and variants keys
      .some((key) => error[key] !== '');
    if (isErrorPresent || !isValidPrice || !validVariants) {
      return;
    }

    const formData = new FormData();
    // Append scalar fields
    formData.append('name', name);
    formData.append('description', description);
    formData.append('productNumber', productNumber);
    formData.append('wholesalePrice', wholesalePrice ? parseFloat(wholesalePrice) : 0);
    formData.append('retailPrice', retailPrice ? parseFloat(retailPrice) : 0);
    formData.append('barcodeImage', barcodeImage);
    formData.append('numberOfVariants', variants.length ?? 0);
    formData.append('barcodeNumber', barcodeNumber);

    categories?.length > 0 &&
      categories?.forEach((category) => formData.append('categories', category));

    images.forEach((image) => formData.append('images', image));

    // Append variants
    variants.forEach((variant, idx) => {
      const variantKeyPrefix = `variant-${idx}-`;
      const variantData = variant.data;
      formData.append(`${variantKeyPrefix}id`, variant.id ?? 'new');
      formData.append(`${variantKeyPrefix}name`, variantData.name);
      formData.append(
        `${variantKeyPrefix}productNumber`,
        variantData.productNumber.length > 0 ? variantData.productNumber : '',
      );
      formData.append(
        `${variantKeyPrefix}wholesalePrice`,
        variantData.wholesalePrice ? parseFloat(variantData.wholesalePrice) : 0,
      );
      formData.append(
        `${variantKeyPrefix}retailPrice`,
        variantData.retailPrice ? parseFloat(variantData.retailPrice) : 0,
      );
      formData.append(`${variantKeyPrefix}selected`, selectedVariants[idx] ? true : false);
    });

    let res;
    if (editProduct) {
      removedImages.forEach((imageId) => formData.append('removedImages', imageId));
      res = await updateProduct({ productId, formData });
    } else {
      res = await createProduct(formData);
    }
    if (res.data) {
      if (editProduct) {
        message.success('Product updated Successfully');
        onSubmit();
      } else if (isSaveAndCreateNew) {
        message.success('Product created Successfully');
        resetState();
      } else {
        message.success('Product created Successfully');
        navigate('/products');
      }
    } else {
      message.error('Oops! Something went wrong. Please try again');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const resetState = () => {
    form.setFieldsValue({
      name: '',
      description: '',
      productNumber: '',
      wholesalePrice: '',
      retailPrice: '',
    });
    setBarcodeImage(null);
    setImages([]);
    setVariants([]);
    setdefaultImage('');
    setThumbnailNum(null);
    setError({});
  };

  const renderProductForm = () => {
    return (
      <FormContainer removePadding={editProduct}>
        <StyledForm
          id="productForm"
          name="newProduct"
          layout="vertical"
          autoComplete="off"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          {editProduct ? (
            <EditProductHeading>Edit Product</EditProductHeading>
          ) : (
            <>
              <HeaderContainer>
                <StyledHeading>
                  New Product
                </StyledHeading>
                <ActionButtonContainer>
                  <StyledCancelButton size="large" onClick={goToProducts}>
                    Cancel
                  </StyledCancelButton>
                  <StyledSubmitButton htmlType="submit" size="large">
                    Save
                  </StyledSubmitButton>
                  <StyledSubmitButton
                    htmlType="submit"
                    size="large"
                    onClick={() => setIsSaveAndCreateNew(true)}
                  >
                    Save and Create New
                  </StyledSubmitButton>
                  <StyledViewButton size="large" onClick={goToProducts}>
                    View
                  </StyledViewButton>
                </ActionButtonContainer>
              </HeaderContainer>
              <HorizontalLine className="top-line" />
            </>
          )}
          <ProductFormBody
            categories={categoriesData?.categories}
            error={error}
            images={images}
            handleImages={handleImages}
            deleteImage={deleteImage}
            defaultImage={defaultImage}
            thumbnailNum={thumbnailNum}
            addVariant={addVariant}
            renderVariants={renderVariants}
            barcodeImage={barcodeImage}
            setBarcodeImage={setBarcodeImage}
            setError={setError}
          />
        </StyledForm>
      </FormContainer>
    );
  };
  return (
    <>
      {editProduct ? (
        <CustomModal
          showing={editProduct}
          onClose={() => hideEditModal()}
          footer={
            <EditProductFooter key="footer" className="action-btn-wrapper">
              <StyledCancelButton size="large" onClick={() => hideEditModal()}>
                Cancel
              </StyledCancelButton>
              <StyledSubmitButton size="large" className="ml-2" onClick={() => form.submit()}>
                Save
              </StyledSubmitButton>
            </EditProductFooter>
          }
        >
          {renderProductForm()}
          <Loader isLoading={productIsLoading || productLoading || updateProductLoading} />
        </CustomModal>
      ) : (
        <>
          {renderProductForm()}
          <Loader isLoading={productIsLoading || productLoading || updateProductLoading} />
        </>
      )}
    </>
  );
};

export default ManualForm;
