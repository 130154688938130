import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { message } from 'antd';
import Loader from '../Common/Loader';
import { useResetPasswordMutation } from 'api/authApi';
import {
  BackgroundLayout,
  ContentWrapper,
  InnerLayout,
  OuterLayout,
  StyledBody,
  StyledText,
  StyledForm,
  StyledInput,
  StyledButton,
  ButtonWrapper,
  StyledLink,
  LinkWrapper,
} from './styles';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [passwordReset, { isLoading }] = useResetPasswordMutation();

  const onFinish = async (values) => {
    const payload = {
      ...values,
      token,
    };
    const res = await passwordReset(payload);
    if (res.data) {
      message.success('Password reset Successfully');
    }
    navigate('/login');
  };
  return (
    <BackgroundLayout>
      <OuterLayout>
        <InnerLayout>
          <StyledBody>
            <StyledText>Reset Password</StyledText>
            <ContentWrapper>
              <StyledForm
                name="resetPassword"
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
              >
                <StyledForm.Item
                  label="Password"
                  name="password"
                  validateFirst
                  rules={[
                    {
                      required: true,
                      message: 'Please provide password!',
                    },
                    { min: 8, message: 'Password must have a minimum length of 8' },
                    {
                      pattern: new RegExp(
                        '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$',
                      ),
                      message: 'Password must be strong!',
                    },
                  ]}
                >
                  <StyledInput type="password" placeholder="Enter password" />
                </StyledForm.Item>
                <StyledForm.Item
                  label="Confirm Password"
                  name="confirmPassword"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('The two passwords do not match!'));
                      },
                    }),
                  ]}
                >
                  <StyledInput type="password" placeholder="Enter confirm password" />
                </StyledForm.Item>
                <ButtonWrapper>
                  <StyledButton htmlType="submit" size="large">
                    Reset
                  </StyledButton>
                </ButtonWrapper>
                <LinkWrapper>
                  <StyledLink to="/login">Login</StyledLink>
                </LinkWrapper>
              </StyledForm>
            </ContentWrapper>
          </StyledBody>
        </InnerLayout>
      </OuterLayout>
      <Loader isLoading={isLoading} />
    </BackgroundLayout>
  );
};

export default ResetPassword;
