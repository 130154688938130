import { Button } from 'antd';
import styled from 'styled-components';

export const AntButton = styled(Button)`
  background-color: #07af7c;
  border-color: #02b183;
  border-width: 2px;
  color: #fff;
  font-family: Muli;
  font-size: 12px;
  font-weight: 700;
  &:hover {
    background: #07af7c !important;
    color: #fff !important;
    border-color: #017f5e !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 189, 150, 0.5);
    border-color: #017f5e !important;
  }
`;
