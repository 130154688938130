import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { faQuestionCircle as faQuestionCircleRegular } from '@fortawesome/free-regular-svg-icons';
import Loader from 'components/Common/Loader';
import Help from 'components/Common/NeedHelp';
import UploadCSVFileDropzone from './components/UploadCSVFileDropzone';
import { csvData } from './sampleCsvData';
import { useImportFromCSVMutation } from 'api/productApi';
import { csvOptions } from './csvTypeList';
import CustomModal from 'components/Common/CustomModal';
import HelpingContent from './components/HelpingContent';
import LineSheetsLogo from 'assets/images/logo-only.svg';
import Calculator from '../Calculator';
import {
  MainContainer,
  PageHeaderContainer,
  StyledTitle,
  BackButton,
  StyledRow,
  StyledCol,
  StyledTooltip,
  StyledCSVLink,
  StyledRadioGroup,
  RadioContainer,
  StyledRadioButton,
  StyledImage,
  StyleLogoCon,
  StyledLogoImage,
  StyledFontAwesomeIcon,
} from './styles';
import { StyledCancelButton } from '../ManualForm/styles';

const CSVProducts = () => {
  const [priceChangeParam, setPriceChangeParam] = useState(2.5);
  const [csvType, setCsvType] = useState(1);
  const [formulaType, setFormulaType] = useState('wholesale');
  const [importFromCSV, { isLoading }] = useImportFromCSVMutation();
  const [helpingPopup, setHelpingPopup] = useState(false);
  const [helpingContentFor, setHelpingContentFor] = useState('');

  const csvTypeChangeHandler = (e) => {
    setCsvType(e.target.value);
  };

  const showHelpingPopupModal = (name) => {
    setHelpingPopup(true);
    setHelpingContentFor(name);
  };

  return (
    <>
      <MainContainer className="container">
        <PageHeaderContainer>
          <StyledTitle>Upload a CSV File</StyledTitle>
          <Link to="/products/new">
            <BackButton>Back</BackButton>
          </Link>
        </PageHeaderContainer>
        <StyledRow>
          <Calculator
            formulaType={formulaType}
            setFormulaType={setFormulaType}
            priceChangeParam={priceChangeParam}
            setPriceChangeParam={setPriceChangeParam}
          />
          <StyledCol xs={{ flex: '100%' }} sm={{ flex: '100%' }} xl={{ flex: '70%' }}>
            <StyledRow>
              <StyledCol xs={{ flex: '100%' }} sm={{ flex: '40%' }}>
                <UploadCSVFileDropzone
                  csvType={csvType}
                  formulaType={formulaType}
                  priceChangeParam={priceChangeParam}
                  importFromCSV={importFromCSV}
                />
                <StyledCSVLink
                  id="csvLink"
                  className={csvType === 1 ? '' : 'disable-sample-csv'}
                  data={csvData}
                  filename={'linesheets-csv-sample.csv'}
                  target="_blank"
                >
                  Sample to Linesheet
                </StyledCSVLink>
              </StyledCol>
              <StyledCol offset={1} xs={{ flex: '100%' }} sm={{ flex: '40%' }}>
                <StyledRadioGroup onChange={csvTypeChangeHandler} value={csvType}>
                  {csvOptions.map((option) => (
                    <RadioContainer>
                      <StyledRadioButton key={option.value} value={option.value}>
                        <StyledTooltip title={option.tooltip} placement="top">
                          <StyledImage src={option.src} alt={option.alt} />
                        </StyledTooltip>
                      </StyledRadioButton>
                      <StyledFontAwesomeIcon
                        icon={faQuestionCircleRegular}
                        onClick={() => showHelpingPopupModal(option.name)}
                      />
                    </RadioContainer>
                  ))}
                </StyledRadioGroup>
              </StyledCol>
            </StyledRow>
          </StyledCol>
          <StyledCol xs={{ flex: '100%' }} sm={{ flex: '100%' }} xl={{ flex: '30%' }}>
            <Help />
          </StyledCol>
        </StyledRow>
      </MainContainer>
      {helpingPopup && (
        <CustomModal
          showing={helpingPopup}
          onClose={() => setHelpingPopup(false)}
          footer={
            <StyledCancelButton size="large" onClick={() => setHelpingPopup(false)}>
              Close
            </StyledCancelButton>
          }
          maxWidth="640"
        >
          <StyleLogoCon>
            <StyledLogoImage src={LineSheetsLogo} alt="LineSheetsLogo" />
          </StyleLogoCon>
          <HelpingContent name={helpingContentFor} />
        </CustomModal>
      )}
      <Loader isLoading={isLoading} />
    </>
  );
};

export default CSVProducts;
