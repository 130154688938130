import { styled } from 'styled-components';
import { Button, Divider, Tooltip, Row, Modal } from 'antd';
import AddProductBackground from 'assets/images/dashboard-recent-background.png';
import SyncImage from 'assets/images/sync-image.png';
import AntButton from 'uiKits/Button';

export const DashboardContainer = styled.div`
  padding: 48px 35px;
  .dashboard-col {
    padding-right: 15px;
    padding-left: 15px;
  }
`;

export const MainContainer = styled.div`
  width: 100%;
  padding: 48px 32px;
`;

export const CreateLinesheetWrapper = styled.div`
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 16px 32px;
  box-shadow: 0.8px -0.6px 16px 0 rgba(0, 0, 0, 0.02);
  position: relative;
  margin-bottom: 30px;
`;

export const CreateLinesheetButton = styled(Button)`
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  font-family: Muli;
  line-height: 16px;
  letter-spacing: 0.96px;
  padding: 0 30px;
  height: 50px;
  border-radius: 10000px;
  border-width: 2px;
  margin-right: 1rem !important;
  color: #02b183;
  border-color: #02b183;
  &:hover {
    color: #ffffff !important;
    background-color: #02b183 !important;
    border-color: #02b183 !important;
  }
`;

export const AddProductWrapper = styled.div`
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 16px 32px;
  box-shadow: 0.8px -0.6px 16px 0 rgba(0, 0, 0, 0.02);
  position: relative;
  padding: 0;
`;

export const AddProductLeftContainer = styled.div`
  position: relative;
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url(${AddProductBackground});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 125%;
    background-position: bottom right;
    transform-origin: center;
    transform: scale(1, -1);
  }
`;

export const ChecklistLeft = styled.div`
  padding: 48px 32px;
  border-radius: 0;
  h4 {
    font-size: 24px;
    font-family: Muli;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: 1.44px;
  }
  p {
    font-size: 16px;
    line-height: 1.44;
    font-family: Muli;
    letter-spacing: normal;
    font-weight: normal;
  }
`;

export const ChecklistIcon = styled.div`
  width: 123px;
  height: 105px;
  background-image: url(${SyncImage});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 16px;
`;

export const AddProductRightContainer = styled.div``;

export const ChecklistRight = styled.div`
  padding: 0px 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #f1f3f5;
`;

export const ChecklistStepContainer = styled.div`
  position: relative;
  z-index: 0;
`;

export const ChecklistStep = styled.div`
  padding: 10px 20px;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

export const ChecklistStepOption = styled.p`
  font-size: 16px !important;
  font-weight: 900 !important;
  line-height: 1.75 !important;
  margin: 0;
`;

export const ChecklistOptionText = styled.p`
  font-size: 12px !important;
  font-weight: normal !important;
  line-height: 1.17 !important;
  margin: 0;
  margin-bottom: 17px;
`;

export const ChecklistButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`;

export const StyledDivider = styled(Divider)`
  margin: 0;
`;

export const SyncButton = styled(Button)`
  color: #07af7c !important;
  border-color: #07af7c !important;
  border-radius: 10000px;
  font-weight: 600;
  border-width: 2px;
  line-height: 20px;
  font-size: 14px;
  &:hover {
    background-color: #07af7c !important;
    border-color: #07af7c !important;
    color: #ffffff !important;
  }
`;

export const StyledTooltip = styled(Tooltip)``;
export const StyledRow = styled(Row)``;

/** Dashboard Intro Modal Styles */

export const FooterBox = styled.div`
  width: 100%;
  align-items: center;
  background-color: #f3f3f3;
  padding: 25px 0px;
  margin: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const StyledCloseButton = styled(AntButton)`
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.96px;
  border-radius: 10000px;
  border-width: 2px;
  height: 50px;
  width: 100px;
  background: inherit;
  color: #07af7c;
`;

export const ModalHeading = styled.h3`
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 1.44px;
  text-align: left;
  margin: 0;
  margin-bottom: 31px;
  margin-top: 31px;
  padding-left: 24px;
`;

export const ModalDescriptionText = styled.p`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  padding-left: 24px;
`;

export const ModalOptionsRow = styled(Row)`
  margin-top: 20px;
  padding: 10px;
  p {
    font-size: 16px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: 0.96px;
    text-align: center;
    color: #000000;
    margin-top: 10px;
  }
`;

export const ModalApiBox = styled.div`
  background-color: #f3f3f3;
  height: 124px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DashboardModalRightCol = styled.div`
  background-color: #f3f3f3;
  min-height: 100%;
  padding: 10px;
  border-top-right-radius: 8px;
`;

export const ModalRightHeading = styled.h2`
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
`;

export const ModalRightContent = styled.div`
  margin-top: 20px;
`;

export const ModalRightContentHeading = styled.h4`
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin: 0;
  margin-bottom: 10px;
  img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
`;

export const ModalRightContentText = styled.p`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin: 0;
`;

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 0;
  }
`;
