export const assignTableColumns = (type) => {
  switch (type) {
    case 'category':
      return productColumns;
    case 'product':
      return categoryColumns;
    default:
      return productColumns;
  }
};

export const productColumns = [
  {
    title: 'Image',
    dataIndex: 'product_image',
    class: 'product-image',
    key: 'product_image',
    render: (product_image) => {
      return (
        <div className="table-image-wrapper">
          {product_image ? <img src={product_image} alt="" /> : null}
        </div>
      );
    },
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <span>{text}</span>,
  },
  {
    title: 'Product Number',
    key: 'productNumber',
    dataIndex: 'productNumber',
    render: (text) => <span>{text}</span>,
  },
];

export const categoryColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <span>{text}</span>,
  },
];
