import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateImageSettings,
  addPageAt,
  realign,
  setTemplateModalVisibility,
  lockPage,
  unlockPage,
  setShowConfirmTemplateModal,
} from 'slices/editorSlice';
import { setAlert } from 'slices/alertMessageSlice';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import FontSettings from '../FontSettings';
import ChangeProductImage from '../ChangeProductImage';
import ImagePosition from '../ImagePosition';
import SliderLayout from '../SliderLayout';
import ProductSizing from '../ProductSizing';
import BackgroundColor from '../BackgroundColor';
import EditorImageSizingToolsComponent from '../EditorImageSizingTools';
import EditorImagePositioningToolsComponent from '../EditorImagePostioningTools';
import {
  ProductEditorToolsContainer,
  StyledFontAwesomeIcon,
  StyledRow,
  StyledCol,
  StyledGroupTitle,
  StyledTooltip,
  StyledAddButton,
  StyledButton,
  StyledSwitch,
  StyledImage,
} from './styles';

const ProductEditorTools = () => {
  const [opacity, setOpacity] = useState(100);
  const [background, setBackground] = useState('rgb(255,255,255)');
  const [pageOpacity, setPageOpacity] = useState(100);
  const [pageBackground, setPageBackground] = useState('rgb(255,255,255)');
  const [productSizeWidth, setProductSizeWidth] = useState(100);
  const [productSizeHeight, setProductSizeHeight] = useState(100);
  const [productSizeImage, setProductSizeImage] = useState(100);
  const [productSizeSpace, setProductSizeSpace] = useState(10);
  const [pageLocked, setPageLocked] = useState(false);
  const [productImageIndex, setProductImageIndex] = useState(1);
  const [productImages, setProductImages] = useState(1);
  const [fontSettings, setFontSettings] = useState({
    font: 'Arial',
    size: '12px',
    color: '#000',
  });
  const EditorImageSizingTools = EditorImageSizingToolsComponent();
  const EditorImagePositioningTools = EditorImagePositioningToolsComponent();
  const [page, setPage] = useState(null);
  const { selectedContentId, selectedPageIndex, linesheetData } = useSelector(
    (state) => state.editor,
  );
  useEffect(() => {
    if (selectedPageIndex || selectedPageIndex === 0) {
      const pageData = linesheetData?.pages[selectedPageIndex];
      if (pageData) {
        setPage(pageData);
        setPageLocked(pageData.settings.locked || false);
        setPageOpacity(pageData.settings.opacity);
        setPageBackground(pageData.settings.background);
        const contentData = pageData.content?.find(
          (c) => c.unique_content_id === selectedContentId,
        );
        if (contentData) {
          const contentSettings = contentData.settings;
          setBackground(contentSettings?.backgroundColor || 'rgb(255,255,255)');
          setOpacity(contentSettings?.opacity || 100);
          if (contentData.type === 'product') {
            if (contentSettings) {
              setProductSizeWidth(contentSettings.widthPercentage);
              setProductSizeHeight(contentSettings.heightPercentage);
              setProductSizeSpace(contentSettings.spaceBetween);
            }
            if (contentData.text_content) {
              const { font, size, color } = contentData.text_content.description.settings;
              setFontSettings({ font, size, color });
            }
            if (contentData.image_content) {
              setProductSizeImage(contentData.image_content.settings?.imageSizePercentage || 100);
              setProductImageIndex(contentData.image_content.image_index);
              setProductImages(contentData.image_content.number_of_images);
            }
          } else if (contentData.type === 'text') {
            const { font, size, color } = contentData.text_content.html.settings;
            setFontSettings({ font, size, color });
          }
        }
      }
    }
  }, [selectedPageIndex, selectedContentId, linesheetData]);

  const dispatch = useDispatch();

  const opacityChangeHandler = (type, value) => {
    if (isNaN(value)) {
      return;
    }
    if (type === 'image') {
      setOpacity(value);
      dispatch(updateImageSettings({ key: 'opacity', value }));
    } else {
      setPageOpacity(value);
      dispatch(updateImageSettings({ key: 'pageOpacity', value }));
    }
  };

  const addPageClickHandler = () => {
    const indexToAdd = selectedPageIndex !== null ? selectedPageIndex + 1 : 0;
    dispatch(addPageAt(indexToAdd));
  };

  const realignPageContentClickHandler = () => {
    if (page) {
      const selectedTemplate = page.settings?.template;
      if (selectedTemplate) {
        const size = selectedTemplate.gridSize;
        const totalContentSize = size[0] * size[1];
        const currentPageContentSize = page.content?.length;
        if (currentPageContentSize > totalContentSize) {
          dispatch(setShowConfirmTemplateModal());
        } else {
          dispatch(realign());
        }
      }
    }
  };

  const chooseTemplateClickHandler = () => {
    if (!page) {
      dispatch(setAlert({ message: 'Select Page First', type: 'info' }));
      return;
    }
    if (page.settings.locked) {
      dispatch(setAlert({ message: "Can't modify a locked page", type: 'info' }));
      return;
    }
    dispatch(setTemplateModalVisibility({ show: true, isChangeTemplate: true }));
  };

  const pageLockedChangeHandler = () => {
    if (!page) {
      dispatch(setAlert({ message: 'Select Page First', type: 'info' }));
      return;
    }
    if (page.settings.locked) {
      dispatch(unlockPage());
    } else {
      dispatch(lockPage());
    }
  };

  return (
    <ProductEditorToolsContainer>
      <StyledGroupTitle notopmargin="true">Text</StyledGroupTitle>
      <FontSettings isGlobal={false} fontSettings={fontSettings} />
      <StyledGroupTitle>Page</StyledGroupTitle>
      <BackgroundColor title="Page Background Color" initialColor={pageBackground} />
      <SliderLayout
        tooltipTitle="page Opacity"
        label="Page Opacity"
        value={pageOpacity}
        onChange={(value) => opacityChangeHandler('page', value)}
      />
      <StyledGroupTitle>Product/Object</StyledGroupTitle>
      <BackgroundColor title="Background Color" initialColor={background} />
      <SliderLayout
        tooltipTitle="Object's Opacity"
        label="Opacity"
        value={opacity}
        onChange={(value) => opacityChangeHandler('image', value)}
      />
      <StyledGroupTitle>Image</StyledGroupTitle>
      <StyledRow justify="space-between" nomargin={'true'}>
        <StyledCol sm={24} xl={8}>
          <StyledRow textalign={'center'} justify="space-between">
            {EditorImagePositioningTools.map((tool, index) => (
              <StyledCol sm={6} xl={12} key={index}>
                <StyledTooltip title={tool.tooltip} placement="left">
                  {tool.image ? (
                    <StyledImage onClick={() => tool.action(tool.name)} src={tool.image} alt="" />
                  ) : (
                    <StyledFontAwesomeIcon
                      icon={tool.icon}
                      onClick={() => tool.action(tool.name)}
                    />
                  )}
                </StyledTooltip>
              </StyledCol>
            ))}
          </StyledRow>
        </StyledCol>
        <StyledCol sm={24} xl={12}>
          <StyledRow textalign={'center'} justify="space-between">
            {EditorImageSizingTools.map((tool, index) => (
              <StyledCol sm={4} xl={8} key={index}>
                <StyledTooltip title={tool.tooltip} placement="left">
                  {tool.image ? (
                    <StyledImage onClick={() => tool.action(tool.name)} src={tool.image} alt="" />
                  ) : (
                    <StyledFontAwesomeIcon
                      icon={tool.icon}
                      onClick={() => tool.action(tool.name)}
                    />
                  )}
                </StyledTooltip>
              </StyledCol>
            ))}
          </StyledRow>
        </StyledCol>
      </StyledRow>
      <StyledGroupTitle>Product Image</StyledGroupTitle>
      <ChangeProductImage
        productImageIndex={productImageIndex}
        productImages={productImages}
        setProductImageIndex={setProductImageIndex}
      />
      <StyledGroupTitle>Product Arrangement</StyledGroupTitle>
      <ImagePosition />
      <StyledGroupTitle>Product Size</StyledGroupTitle>
      <ProductSizing
        productSizeWidth={productSizeWidth}
        productSizeHeight={productSizeHeight}
        productSizeImage={productSizeImage}
        productSizeSpace={productSizeSpace}
      />
      <StyledRow justify="space-between" wrap={false}>
        <StyledGroupTitle>Page</StyledGroupTitle>
        <StyledTooltip title="Add Page" placement="left">
          <StyledAddButton onClick={addPageClickHandler}>
            {' '}
            <StyledFontAwesomeIcon icon={faPlus} /> Add
          </StyledAddButton>
        </StyledTooltip>
      </StyledRow>
      <StyledGroupTitle>Page Template</StyledGroupTitle>
      <StyledRow justify="space-between">
        <StyledTooltip title="Realign Page's Content" placement="left">
          <StyledButton onClick={realignPageContentClickHandler}>Realign</StyledButton>
        </StyledTooltip>
        <StyledTooltip title="Choose Template for Page" placement="left">
          <StyledButton onClick={chooseTemplateClickHandler}>Change Template</StyledButton>
        </StyledTooltip>
      </StyledRow>
      <StyledRow justify="space-between" wrap={false}>
        <StyledGroupTitle>Lock Page</StyledGroupTitle>
        <StyledTooltip title="Lock page to ignore global changes" placement="left">
          <StyledSwitch value={pageLocked} onChange={pageLockedChangeHandler} />
        </StyledTooltip>
      </StyledRow>
    </ProductEditorToolsContainer>
  );
};

export default React.memo(ProductEditorTools);
