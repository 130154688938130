import React, { useState } from 'react';
import globals from 'websiteGlobals';
import { DownOutlined } from '@ant-design/icons';
import EditorTextDecorationToolsComponent from '../EditorTextDecorationTools';
import EditorTextDirectionToolsComponent from '../EditorTextDirectionTools';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  StyledFontAwesomeIcon,
  StyledRow,
  StyledSelect,
  StyledOption,
  StyledColorPicker,
  StyledTooltip,
  StyledLabel,
  EditorContainer,
  UpdateButton,
} from './styles';

const GlobalFontSettings = ({ fontSettings, type = '', handleSettingsChange }) => {
  const [open, setOpen] = useState(false);
  const [updatedText, setUpdatedText] = useState(fontSettings.value || '');
  const fonts = globals.constants.fonts;
  const fontSizes = globals.constants.fontSizes;
  const TextDecorationTools = EditorTextDecorationToolsComponent();
  const TextAlignmentTools = EditorTextDirectionToolsComponent();

  const handleFontChange = (value) => handleSettingsChange(type, 'font', value);
  const handleFontSizeChange = (value) => handleSettingsChange(type, 'size', value);
  const handleColorChange = (value) => handleSettingsChange(type, 'color', `#${value.toHex()}`);
  const handleTextChange = () => {
    if (updatedText === null || updatedText === '') {
      return;
    }
    handleSettingsChange(type, 'value', updatedText);
  };

  const customTitle = (type) => {
    switch (type) {
      case 'sku':
        return 'SKU';
      case 'wholesalePrice':
        return 'WS price';
      case 'retailPrice':
        return 'MSRP price';
      default:
        return type;
    }
  };

  return (
    <>
      <StyledRow>
        <StyledTooltip title={`Show Products ${type}`} placement="left">
          <StyledSelect
            onChange={(value) => handleSettingsChange(type, 'show', value)}
            placeholder="Action"
            value={fontSettings.show}
            popupClassName="editor-font-select"
            options={[
              { value: 'show', label: <span>Show</span> },
              { value: 'hide', label: <span>Hide</span> },
              { value: 'none', label: <span>Keep Unchanged</span> },
            ]}
          />
        </StyledTooltip>
      </StyledRow>
      <StyledRow>
        <StyledSelect
          onChange={handleFontChange}
          placeholder="Select Font Family"
          value={fontSettings.font}
          popupClassName="editor-font-select"
        >
          {fonts.map((font) => (
            <StyledOption key={font.key} value={font.name} style={{ fontFamily: font.name }}>
              {font.name}
            </StyledOption>
          ))}
        </StyledSelect>
      </StyledRow>
      <StyledRow>
        <StyledSelect
          onChange={handleFontSizeChange}
          placeholder={'Select Font Size'}
          value={fontSettings.size}
          popupClassName="editor-font-select"
        >
          {fontSizes.map((size) => (
            <StyledOption key={size} value={size}>
              {size}
            </StyledOption>
          ))}
        </StyledSelect>
      </StyledRow>
      <StyledRow>
        {TextDecorationTools.map((tool, index) => (
          <StyledTooltip title={tool.tooltip} placement="left">
            <StyledFontAwesomeIcon
              className={fontSettings[tool.name] === true ? 'active' : ''}
              key={index}
              icon={tool.icon}
              onClick={() => handleSettingsChange(type, tool.name, !fontSettings[tool.name])}
            />
          </StyledTooltip>
        ))}
        {TextAlignmentTools.map((tool, index) => (
          <StyledTooltip title={tool.tooltip} placement="left">
            <StyledFontAwesomeIcon
              className={fontSettings.align === tool.name ? 'active' : ''}
              key={index}
              icon={tool.icon}
              onClick={() => handleSettingsChange(type, 'align', tool.name)}
            />
          </StyledTooltip>
        ))}
      </StyledRow>
      <StyledRow justify="space-between">
        <StyledLabel>Text Color</StyledLabel>
        <StyledColorPicker
          onChange={handleColorChange}
          value={fontSettings.color}
          open={open}
          onOpenChange={setOpen}
          showText={() => (
            <DownOutlined
              rotate={open ? 180 : 0}
              style={{
                color: `${fontSettings.color}`,
                filter: 'invert(1)',
              }}
            />
          )}
        />
      </StyledRow>
      <StyledRow justify="space-between">
        <StyledLabel>Set custom {customTitle(type)}</StyledLabel>
      </StyledRow>
      <StyledRow>
        <EditorContainer>
          <CKEditor
            editor={ClassicEditor}
            data={updatedText}
            onChange={(event, editor) => {
              const data = editor.getData();
              setUpdatedText(data);
            }}
            config={{
              toolbar: ['numberedList', 'bulletedList'],
            }}
          />
          <UpdateButton onClick={() => handleTextChange()}>Update</UpdateButton>
        </EditorContainer>
      </StyledRow>
    </>
  );
};

export default GlobalFontSettings;
