import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useGoogleLogin } from '@react-oauth/google';
import { message } from 'antd';
import { setUser } from '../../slices/authSlice';
import Loader from '../Common/Loader';
import cache from '../../cache';
import { handleSuccessfulLogin, handleLoginError } from '../../utils/helper';
import logo from '../../assets/images/LinesheetsLogo.png';
import googleIcon from '../../assets/images/google-hangouts.png';
import {
  useLoginMutation,
  useLoginWithGoogleMutation,
  useResendEmailMutation,
} from '../../api/authApi';
import {
  BackgroundLayout,
  OuterLayout,
  InnerLayout,
  MainLayout,
  StyledLogo,
  LogoContainer,
  FormContainer,
  StyledForm,
  StyledInput,
  ForgetPassword,
  StyledSmall,
  StyledButton,
  StyledSpan,
  GoogleButtonContainer,
  GoogleButtonIcon,
  GoogleButtonText,
  ForgetPasswordLink,
  TextCenterContainer,
  DontHaveAccountParagraph,
  CreateAccountLink,
  RightsContainer,
  RightsLink,
  StyledTooltip,
  StyledOrText,
} from './styles';

const Login = () => {
  const [showResend, setShowResend] = useState(false);
  const [formData, setFormData] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, { isLoading: loginIsLoading }] = useLoginMutation();
  const [resendEmail, { isLoading: resendIsLoading }] = useResendEmailMutation();
  const [loginWithGoogle, { isLoading: loginWithGoogleIsLoading }] = useLoginWithGoogleMutation();

  const onFinish = async (values) => {
    setFormData(values);
    const res = await login(values);
    if (res.data) {
      dispatch(setUser(res.data));
      cache.setItem('user', res.data);
      navigate('/');
    }
    if (res.error && res.error?.data?.message) {
      message.error(res.error?.data?.message);
      if (res.error?.data?.verified === false) {
        setShowResend(true);
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) =>
      handleSuccessfulLogin(codeResponse, dispatch, cache, navigate, loginWithGoogle),
    onError: handleLoginError,
  });

  const handleResendEmail = async () => {
    if (!formData?.email) return message.error('Please enter email address');
    const res = await resendEmail({ email: formData?.email });
    if (res?.data) {
      setShowResend(false);
      message.success(res?.data?.message);
    }
    if (res.error && res.error?.data?.message) {
      message.error(res.error?.data?.message);
    }
  };

  return (
    <BackgroundLayout>
      <MainLayout>
        <OuterLayout>
          <InnerLayout>
            <LogoContainer>
              <StyledLogo preview={false} src={logo} width={200} />
            </LogoContainer>
            <FormContainer>
              <StyledForm
                name="login"
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <StyledForm.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    { required: true, message: 'Please input your email!' },
                    { type: 'email', message: 'Please enter a valid email address!' },
                  ]}
                >
                  <StyledInput placeholder="Enter email address" />
                </StyledForm.Item>
                <StyledForm.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please provide a password!',
                    },
                  ]}
                >
                  <StyledInput placeholder="Enter password" type="password" />
                </StyledForm.Item>
                <ForgetPassword>
                  <StyledSmall>
                    <ForgetPasswordLink to="/reset-password">Forgot Password?</ForgetPasswordLink>
                  </StyledSmall>
                </ForgetPassword>
                <StyledButton htmlType="submit" size="large">
                  Login
                </StyledButton>
                {!!showResend && (
                  <>
                    <StyledOrText>Or</StyledOrText>
                    <StyledButton onClick={handleResendEmail} size="large">
                      Resend Email
                    </StyledButton>
                  </>
                )}
                <StyledSpan>Or Sign Up Using</StyledSpan>
                <GoogleButtonContainer onClick={googleLogin}>
                  <GoogleButtonIcon src={googleIcon} alt="Google Icon" />
                  <GoogleButtonText>Continue with Google</GoogleButtonText>
                </GoogleButtonContainer>
                <TextCenterContainer>
                  <DontHaveAccountParagraph>
                    Don't have an account?
                    <br />
                    <CreateAccountLink to="/register">Create account</CreateAccountLink>
                  </DontHaveAccountParagraph>
                </TextCenterContainer>
              </StyledForm>
            </FormContainer>
          </InnerLayout>
          <RightsContainer>
            <StyledTooltip placement="bottom" title="Coming Soon">
              <RightsLink href="">Terms of Service</RightsLink>
            </StyledTooltip>
            {' | '}
            <StyledTooltip placement="bottom" title="Coming Soon">
              <RightsLink href="">Privacy Policy</RightsLink>
            </StyledTooltip>
            {' | '}
            <StyledTooltip placement="bottom" title="Coming Soon">
              <RightsLink href="">Copyright 2020 Linesheet All rights reserved</RightsLink>
            </StyledTooltip>
          </RightsContainer>
        </OuterLayout>
      </MainLayout>
      <Loader isLoading={loginIsLoading || loginWithGoogleIsLoading || resendIsLoading} />
    </BackgroundLayout>
  );
};

export default Login;
