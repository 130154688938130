import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import globals from 'websiteGlobals';
import { faQuestionCircle as faQuestionCircleRegular } from '@fortawesome/free-regular-svg-icons';
import CustomModal from 'components/Common/CustomModal';
import LineSheetsLogo from 'assets/images/logo-only.svg';
import HelpingContent from '../HelpingContent';
import ExternalApiForm from '../ExternalApiForm';
import Loader from 'components/Common/Loader';
import {
  useGetSourcesQuery,
  useAddSourceMutation,
  useRemoveSourceMutation,
} from 'api/externalSourceApi';
import { message } from 'antd';
import {
  StyledCol,
  StyledApiImageContainer,
  StyledLogo,
  StyledFontAwesomeIcon,
  StyledButtonsContainer,
  StyledImportButton,
  StyledConnectButton,
  StyledDisconnectButton,
  StyledEditButton,
  StyledWatchButton,
} from './styles';
import { StyledCancelButton } from 'components/Product/ManualForm/styles';
import { StyleLogoCon, StyledLogoImage } from 'components/Product/CSVProducts/styles';

const SyncProductApi = ({ syncVideos, openLink }) => {
  const {
    data: userExternalAPIs,
    isLoading: isLoadingUserExternalAPIs,
    refetch: refetchUserExternalAPIs,
  } = useGetSourcesQuery();
  const [helpingPopup, setHelpingPopup] = useState(false);
  const [helpingContentFor, setHelpingContentFor] = useState('');
  const [apiForm, setApiForm] = useState({
    show: false,
    id: '',
    title: '',
    inputs: [],
  });
  const [addSource, { isLoading: isAddSourceLoading }] = useAddSourceMutation();
  const [removeSource, { isLoading: isRemoveSourceLoading }] = useRemoveSourceMutation();

  const showHelpingPopupModal = (name) => {
    setHelpingPopup(true);
    setHelpingContentFor(name);
  };

  const showWatchButton = (name) => {
    const videoData = syncVideos.videoData.find((video) => video.related === name.toLowerCase());
    if (videoData) {
      return (
        <StyledWatchButton
          onClick={() => openLink(`https://www.youtube.com/watch?v=${videoData.videoLink}`)}
        >
          Watch Video
        </StyledWatchButton>
      );
    } else {
      return null;
    }
  };

  const connectBtnClickHandler = (apiId) => {
    const apiInfo = globals.externalAPIs.find((e) => e.id === apiId);
    if (!apiInfo) {
      console.warn("Shouldn't be here");
      return;
    }

    setApiForm({
      show: true,
      id: apiId,
      title: apiInfo.name,
      inputs: apiInfo.requirements,
    });
  };

  const editBtnClickHandler = (apiId) => {
    const apiInfo = globals.externalAPIs.find((e) => e.id === apiId);
    if (!apiInfo) {
      console.warn("Shouldn't be here");
      return;
    }

    let newModalInputs = [];
    const userExternalAPI = userExternalAPIs.find((e) => e.type === apiId);
    if (userExternalAPI) {
      newModalInputs = apiInfo.requirements.map((req) => {
        return Object.assign({}, req, { value: userExternalAPI[req.key] || '' });
      });
    } else {
      newModalInputs = apiInfo.requirements.filter(() => true);
    }

    setApiForm({
      show: true,
      id: apiId,
      title: apiInfo.name,
      inputs: newModalInputs,
    });
  };

  const disconnectBtnClickHandler = async (apiId, apiName) => {
    const res = await removeSource({ apiId });
    if (res.data) {
      refetchUserExternalAPIs();
      resetApiForm();
      message.success(`Successfully disconnected from ${apiName} API`);
    } else if (res.error) {
      message.error(res.error.data.message);
    }
  };

  const resetApiForm = () => {
    setApiForm({ show: false, id: '', title: '', inputs: [] });
  };

  const handleApiFormSubmit = async (formData) => {
    const res = await addSource({ type: apiForm.id, value: formData });
    if (res.data) {
      refetchUserExternalAPIs();
      resetApiForm();
      message.success(res.data.message);
    } else if (res.error) {
      message.error(res.error.data.message);
    }
  };

  const renderAPIGrid = () => {
    return globals.externalAPIs.map((api, idx) => {
      const userExternalAPI = userExternalAPIs?.find((e) => e.type === api.id);
      const isConnected = !!userExternalAPI;
      return (
        <StyledCol
          className={isConnected ? 'connected' : ''}
          key={idx}
          xs={24}
          sm={24}
          md={24}
          lg={11}
        >
          <StyledApiImageContainer>
            <StyledLogo src={api.logo} />
            <StyledFontAwesomeIcon
              icon={faQuestionCircleRegular}
              onClick={() => showHelpingPopupModal(api.name)}
            />
          </StyledApiImageContainer>
          {isConnected ? (
            <StyledButtonsContainer>
              <StyledDisconnectButton
                onClick={() => disconnectBtnClickHandler(userExternalAPI.id, userExternalAPI.type)}
              >
                Disconnect
              </StyledDisconnectButton>
              <Link to={`/products/new?method=${api.id}`}>
                <StyledImportButton>Import</StyledImportButton>
              </Link>
              <StyledEditButton onClick={() => editBtnClickHandler(api.id)}>Edit</StyledEditButton>
            </StyledButtonsContainer>
          ) : (
            <StyledConnectButton onClick={() => connectBtnClickHandler(api.id)}>
              Connect
            </StyledConnectButton>
          )}
        </StyledCol>
      );
    });
  };

  return (
    <>
      {renderAPIGrid()}
      {apiForm.show && (
        <ExternalApiForm
          showing={apiForm.show}
          title={apiForm.title}
          inputs={apiForm.inputs}
          onClose={resetApiForm}
          onSubmit={handleApiFormSubmit}
        />
      )}
      {helpingPopup && (
        <CustomModal
          showing={helpingPopup}
          onClose={() => setHelpingPopup(false)}
          footer={
            <>
              {showWatchButton(helpingContentFor)}
              <StyledCancelButton onClick={() => setHelpingPopup(false)}>Close</StyledCancelButton>
            </>
          }
          maxWidth="640"
        >
          <StyleLogoCon>
            <StyledLogoImage src={LineSheetsLogo} alt="LineSheetsLogo" />
          </StyleLogoCon>
          <HelpingContent name={helpingContentFor} syncVideos={syncVideos} openLink={openLink} />
        </CustomModal>
      )}
      <Loader
        isLoading={isLoadingUserExternalAPIs || isAddSourceLoading || isRemoveSourceLoading}
      />
    </>
  );
};

export default SyncProductApi;
