import styled from 'styled-components';

export const MainContentContainer = styled.div`
  border: ${(props) => (props.isoverflow ? `1px solid #ec0c0c` : 'unset')};
`;

export const StyledContentInner = styled.div``;

export const StyledContentContainer = styled.div`
  p {
    margin: 0 !important;
    line-height: 1;
  }
`;

export const ContentHandlerHidden = styled.div`
  display: ${(props) => props.show};
  position: absolute;
  background-color: transparent;
  pointer-events: all;
`;

export const HorizontalContentHandlerHidden = styled(ContentHandlerHidden)`
  cursor: ns-resize;
`;

export const VerticalContentHandlerHidden = styled(ContentHandlerHidden)`
  cursor: ew-resize;
`;

export const ContentHandler = styled.div`
  display: ${(props) => props.show};
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  border: 2px solid #02b183;
  background-color: #fff;
  pointer-events: all;
`;

export const ContentHandlerUp = styled(ContentHandler)`
  cursor: nw-resize;
`;

export const ContentHandlerDown = styled(ContentHandler)`
  cursor: ne-resize;
`;
