import React from 'react';
import {
  StyledCancelButton,
  StyledLineSheetsButton,
  Wrapper,
  AntdModal,
  TitleWrapper,
} from './styles';

const BackModal = ({ isBack, setIsBack, handleDntSave, handleSave }) => {
  return (
    <AntdModal
      open={isBack}
      closable={false}
      centered={true}
      onClose={() => setIsBack(false)}
      title={<TitleWrapper>Want to save your changes?</TitleWrapper>}
      footer={
        <Wrapper>
          <StyledCancelButton onClick={() => setIsBack(false)}>Cancel</StyledCancelButton>
          <StyledLineSheetsButton onClick={handleDntSave}>Don't Save</StyledLineSheetsButton>
          <StyledLineSheetsButton onClick={handleSave}>Save</StyledLineSheetsButton>
        </Wrapper>
      }
      maxWidth="575"
    />
  );
};

export default BackModal;
