import React, { useState, createRef, useEffect } from 'react';
import 'cropperjs/dist/cropper.css';
import { useDispatch, useSelector } from 'react-redux';
import { setImageUrl, contentMenuActionHandler } from 'slices/editorSlice';
import {
  faCropSimple,
  faRotateLeft,
  faRotateRight,
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { useUploadFileMutation } from 'api/linesheetApi';
import {
  StyledCropper,
  StyledFontAwesomeIcon,
  StyledImageContainer,
  StyledImage,
  ImageActions,
  StyledTooltip,
  StyledSpin,
} from './styles';

const Image = ({ contentData }) => {
  const dispatch = useDispatch();
  const [image] = useState(contentData.image_content.url);
  const [cropData, setCropData] = useState(null);
  const cropperRef = createRef();
  const [isCropping, setIsCropping] = useState(
    contentData.image_content.settings.cropImage || false,
  );
  const [history, setHistory] = useState([
    { imageUrl: contentData.image_content.url || contentData.image_content.fileUrl },
  ]);
  const [future, setFuture] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadFile] = useUploadFileMutation();
  const selectedContentId = useSelector((state) => state.editor.selectedContentId);
  const isSelected = contentData.unique_content_id === selectedContentId;

  useEffect(() => {
    setIsCropping(contentData.image_content.settings.cropImage);
  }, [contentData.image_content]);

  useEffect(() => {
    if (!isCropping && contentData.image_content.settings.cropImage)
      dispatch(contentMenuActionHandler('crop'));
  }, [isCropping, dispatch]);

  const handleCropClick = () => {
    setIsLoading(true);
    setIsCropping(true);
  };

  const getCropData = () => {
    if (cropperRef.current?.cropper !== 'undefined') {
      setIsCropping(false);
      setIsLoading(true);
      const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas();
      // Convert URL to File and handle upload
      croppedCanvas.toBlob(async (blob) => {
        const file = new File([blob], 'cropped-image.png', { type: 'image/png' });

        try {
          const response = await uploadFile(file).unwrap();
          if (response.imageUrl) {
            const croppedUrl = response.imageUrl;
            setCropData(croppedUrl); // Update cropData with new image URL if needed
            dispatch(setImageUrl(croppedUrl));
            setHistory([...history, { imageUrl: croppedUrl }]);
            setFuture([]);
          }
        } catch (error) {
          console.error('Error uploading file:', error);
        }
        setIsLoading(false);
      });
    }
  };

  const handleUndo = () => {
    if (history.length > 1) {
      const lastState = history[history.length - 1];
      const prevState = history[history.length - 2];
      setCropData(prevState?.imageUrl);
      dispatch(setImageUrl(prevState?.imageUrl));
      setFuture([lastState, ...future]);
      setHistory(history.slice(0, -1));
    }
  };

  const handleRedo = () => {
    if (future.length > 0) {
      const nextState = future[0];
      setCropData(nextState.imageUrl);
      dispatch(setImageUrl(nextState.imageUrl));
      setHistory([...history, nextState]);
      setFuture(future.slice(1));
    }
  };

  const handleCancel = () => {
    setIsCropping(false);
  };

  const onCropperInitialized = () => {
    setIsLoading(false);
    setIsCropping(true);
  };

  const imageStyle = {
    transform: contentData.image_content?.settings?.transform,
    WebkitTransform: contentData.image_content?.settings?.WebkitTransform,
    MozTransform: contentData.image_content?.settings?.MozTransform,
    height: `${contentData.image_content.settings?.height}px`,
  };

  const imgStyles = {
    objectFit: contentData.image_content?.settings?.size,
    height: contentData.image_content.settings?.height,
    width: contentData.settings?.width,
  };

  const iconActions = [
    { icon: faCropSimple, title: 'Crop', action: handleCropClick },
    { icon: faRotateLeft, title: 'Undo', action: handleUndo },
    { icon: faRotateRight, title: 'Redo', action: handleRedo },
    { icon: faCircleCheck, title: 'Submit', action: getCropData },
    { icon: faCircleXmark, title: 'Close', action: handleCancel },
  ];

  const renderImage = () => (
    <StyledImage
      src={cropData || contentData.image_content.url || contentData.image_content.fileUrl}
      alt="Invalid url"
      style={imgStyles}
    />
  );

  return (
    <StyledImageContainer style={imageStyle}>
      {isLoading && <StyledSpin />}
      {isCropping ? (
        <StyledCropper
          ref={cropperRef}
          style={imgStyles}
          zoomTo={0.5}
          initialAspectRatio={1}
          src={image}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          guides={true}
          onInitialized={() => onCropperInitialized()}
        />
      ) : (
        renderImage()
      )}
      {isSelected && (
        <ImageActions>
          {iconActions?.map(({ icon, title, action }, index) => (
            <StyledTooltip key={index} title={title} placement="left">
              <StyledFontAwesomeIcon onClick={action} icon={icon} />
            </StyledTooltip>
          ))}
        </ImageActions>
      )}
    </StyledImageContainer>
  );
};

export default Image;
