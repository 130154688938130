import styled from 'styled-components';
import { Row, Col, Tooltip } from 'antd';
import AntColorPicker from 'uiKits/ColorPicker';

export const StyledRow = styled(Row)`
  align-items: center;
  color: #fff !important;
  margin-bottom: 14px;
  gap: ${(props) => (props.gap ? `${props.gap}px` : '0')};
`;

export const StyledCol = styled(Col)``;

export const StyledLabel = styled.p`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.08;
  text-align: 'left';
  color: #fff;
  margin: 0 !important;
`;

export const StyledColorPicker = styled(AntColorPicker)``;

export const StyledTooltip = styled(Tooltip)``;
