import React, { useState } from 'react';
import ManualForm from 'components/Product/ManualForm';
import Pagination from '../Pagination';
import { StyledTable, SelectAllContainer, SelectAllButton, SelectedRowsText } from './styles';

const ListingTable = ({
  data,
  total,
  columns,
  rowSelection,
  refetchProducts,
  handleSortChange,
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  selectedRows,
  isSelectAll,
  setSelectAll,
  setSelectedRows,
  type,
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedEditProductId, setSelectedEditProductId] = useState(null);

  const onEditProductSubmit = () => {
    refetchProducts();
    setShowEditModal(false);
  };

  const handlePaginationChange = (page) => {
    // resetSelectedRows();
    setCurrentPage(page);
  };

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };

  const handleSelectAll = () => {
    setSelectAll(true);
    setSelectedRows(data?.map(({ id }) => id));
  };

  const handleUnselectAll = () => {
    setSelectAll(false);
    setSelectedRows([]);
  };

  return (
    <>
      <SelectAllContainer>
        {selectedRows?.length > 0 && (
          <>
            {isSelectAll || selectedRows?.length === total ? (
              <SelectAllButton onClick={handleUnselectAll}>Unselect all</SelectAllButton>
            ) : (
              <SelectAllButton onClick={handleSelectAll}>Select all {total}</SelectAllButton>
            )}
            <SelectedRowsText>
              Selected {isSelectAll ? total : selectedRows?.length}&nbsp;
              {type === 'category' ? 'categories' : 'products'}
            </SelectedRowsText>
          </>
        )}
      </SelectAllContainer>
      <StyledTable
        rowKey="id"
        rowSelection={{
          ...rowSelection,
          selectedRowKeys: selectedRows,
        }}
        columns={columns}
        // expandable={{ rowExpand }}
        locale={{ emptyText: 'No Data Found' }}
        dataSource={data}
        onChange={(pagination, filters, sorter) => {
          // Handle column sorting change
          if (sorter && sorter.column && sorter.order) {
            handleSortChange(sorter.column.key, sorter.order === 'ascend' ? 'ASC' : 'DESC');
          }
        }}
        pagination={{
          ...Pagination({
            currentPage,
            pageSize,
            total,
            onShowSizeChange,
            handlePaginationChange,
          }),
        }}
      />
      {showEditModal && (
        <ManualForm
          editProduct={true}
          productId={selectedEditProductId}
          hideEditModal={() => setShowEditModal(false)}
          onSubmit={onEditProductSubmit}
        />
      )}
    </>
  );
};

export default ListingTable;
