import React, { useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { StyledInput } from './styles';

const SearchBar = ({ handleSearchChange = null, searchFilter = '' }) => {
  const [debounce, setDebounce] = useState();

  useEffect(() => {
    return () => {
      if (debounce) clearTimeout(debounce);
    };
  }, [debounce]);

  return (
    <StyledInput
      value={searchFilter}
      onChange={handleSearchChange}
      placeholder="Search"
      prefix={<SearchOutlined />}
    />
  );
};

export default SearchBar;
