import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_BASE_URL;

export const handle401Error = async (api, action, { getState }) => {
  console.log('handle401Error called!');
  // Rest of your middleware logic
};

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }),
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(handle401Error),
  endpoints: builder => ({
    login: builder.mutation({
      query: credentials => ({
        url: 'auth/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    loginWithGoogle: builder.mutation({
      query: credentials => ({
        url: 'auth/loginWithGoogle',
        method: 'POST',
        body: credentials,
      }),
    }),
    register: builder.mutation({
      query: userData => ({
        url: 'auth/register',
        method: 'POST',
        body: userData,
      }),
    }),
    verifyUser: builder.query({
      query: () => 'auth',
    }),
    logOut: builder.mutation({
      query: () => ({
        url: 'auth/logout',
        method: 'GET',
      }),
    }),
    resetPassword: builder.mutation({
      query: resetPassPayload => ({
        url: 'users/resetPassword',
        method: 'POST',
        body: resetPassPayload,
      }),
    }),
    resetPasswordReq: builder.mutation({
      query: email => ({
        url: 'users/passResetReq',
        method: 'POST',
        body: email,
      }),
    }),
    showIntroModal: builder.query({
      query: () => 'users/showIntroPopup',
    }),
    subscribeMonthly: builder.mutation({
      query: body => ({
        url: 'users/subscribe',
        method: 'POST',
        body,
      }),
    }),
    unSubscribeMonthly: builder.mutation({
      query: body => ({
        url: 'users/unsubscribeMonthly',
        method: 'POST',
        body,
      }),
    }),
    unSubscribe: builder.mutation({
      query: body => ({
        url: 'users/unsubscribe',
        method: 'POST',
        body,
      }),
    }),
    reSubscribe: builder.mutation({
      query: body => ({
        url: 'users/resubscribe',
        method: 'POST',
        body,
      }),
    }),
    verifyEmail: builder.mutation({
      query: body => ({
        url: 'auth/verify-email',
        method: 'POST',
        body,
      }),
    }),
    resendEmail: builder.mutation({
      query: body => ({
        url: 'auth/resend-email',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useVerifyUserQuery,
  useLogOutMutation,
  useLoginWithGoogleMutation,
  useResetPasswordMutation,
  useResetPasswordReqMutation,
  useShowIntroModalQuery,
  useSubscribeMonthlyMutation,
  useUnSubscribeMonthlyMutation,
  useUnSubscribeMutation,
  useReSubscribeMutation,
  useVerifyEmailMutation,
  useResendEmailMutation,
} = authApi;
