import { isRejectedWithValue } from '@reduxjs/toolkit';
import { message } from 'antd';

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    console.warn('We got a rejected action!');
    if(action.error && action.error.message && action.error.message !== 'Rejected'){
      message.error(action.error && action.error.message);
    }
  }

  return next(action);
};
