import styled from 'styled-components';
import { AntInput } from 'uiKits/Input';
export const MainLayout = styled.div`
  position: relative;
  padding: 48px 55px;
  margin-top: 10px;
`;

export const HeaderWraper = styled.div`
  display: flex;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-left: auto;
`;

export const StyledCancelButton = styled.button`
  width: 100px;
  font-family: Muli;
  font-size: 1rem;
  font-weight: 600;
  color: #6c757d;
  border: none;
  border-radius: 10000px;
  background-color: inherit; /* or any desired background color */
  margin-right: 0.5rem !important;
  cursor: pointer;
  &:hover {
    background-color: #6c757d;
    color: #ffffff;
  }
`;

export const StyledNextButton = styled.button`
  font-family: Muli;
  line-height: 1.92;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.72px;
  border-radius: 17.5px;
  border: 2px solid #07af7c;
  background-color: #f7f7f7;
  color: #07af7c;
  width: 100px;
  height: 35px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => (props.disabled ? '#ffffff' : '#07af7c')};
    color: ${(props) => (props.disabled ? 'inherit' : '#ffffff')};
  }

  &:disabled {
    background-color: #f5f5f5; /* Optional: Set a different background for disabled state */
    color: #02b183; /* Optional: Set a different color for disabled state */
    cursor: not-allowed;
    opacity: 0.35;
  }
`;
export const SearchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

export const Label = styled.p`
  margin-top: 15px;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: 0.28px;
`;

export const StyledInput = styled(AntInput)`
  width: 254px;
  height: 50px;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 24px;
  border-radius: 8px;
`;

export const SytledTitle = styled.h4`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin: 0;
  cursor: default;
`;
