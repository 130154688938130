import React, { useState } from 'react';
import { faTools, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import ProductEditorTools from './ProductEditorTools';
import LinesheetsEditorTools from './LinesheetsEditorTools';
import {
  MainContainer,
  StyledHeader,
  StyledTitle,
  StyledCollapsedButton,
  StyledFontAwesomeIcon,
  StyledBody,
  StyledEditorToolBody,
} from './styles';

const RightPanel = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <MainContainer>
      <StyledHeader>
        <StyledTitle>
          <StyledFontAwesomeIcon icon={faTools} cursor={'default'} size="sm" />
          Editing Tools
        </StyledTitle>
        <StyledCollapsedButton onClick={() => toggleCollapse()}>
          <StyledFontAwesomeIcon
            icon={isCollapsed ? faChevronDown : faChevronUp}
            cursor={'pointer'}
          />
        </StyledCollapsedButton>
      </StyledHeader>
      <StyledBody height={isCollapsed ? '0' : '100vh'}>
        <StyledEditorToolBody>
          <ProductEditorTools />
          <LinesheetsEditorTools />
        </StyledEditorToolBody>
      </StyledBody>
    </MainContainer>
  );
};

export default RightPanel;
