import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Loader from './Loader';
import cache from 'cache';
import { setUser } from 'slices/authSlice';
import { useVerifyEmailMutation } from 'api/authApi';

const VerifyEmail = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [verifyEmail, { isLoading }] = useVerifyEmailMutation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await verifyEmail({ token });
        if (res.data) {
          dispatch(setUser(res.data));
          cache.setItem('user', res.data);
          navigate('/');
        }
      } catch (error) {
        console.error('Error: ', error);
      }
    };
    fetchData();
  }, []);

  return <Loader isLoading={isLoading} />;
};

export default VerifyEmail;
