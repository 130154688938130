import React from 'react';
import globals from 'websiteGlobals';
import Content from '../Content';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedPage } from 'slices/editorSlice';
import HeaderFooter from './HeaderFooter';
import {
  StyledPlaygroundPage,
  StyledPageContent,
  StyledPageName,
  StyledPageNote,
  StyledFontAwesomeIcon,
} from './styles';
import { faLock } from '@fortawesome/free-solid-svg-icons';

const Page = ({ pageData, pageIdx, onPageClick }) => {
  const dispatch = useDispatch();
  const zoomLevel = useSelector((state) => state.editor.linesheetData.settings.zoomLevel);
  const { header_content, footer_content } = useSelector((state) => state.editor.linesheetData);
  const selectedPageId = useSelector((state) => state.editor.selectedPageId);

  const pageWidth =
    globals.constants.pageSize[pageData.settings?.orientation === 'portrait' ? 1 : 0];

  const pageHeight =
    globals.constants.pageSize[pageData.settings?.orientation === 'portrait' ? 0 : 1];

  let pageStyles = {
    backgroundColor: pageData.settings?.background,
    width: pageWidth + 'px',
    height: pageHeight + 'px',
    overflow: 'hidden',
    border: '2px solid transparent',
    zoom: zoomLevel,
  };

  const handlePageClick = (event) => {
    // Prevent the event from propagating to parent components
    event.stopPropagation();
    if (!event.target.closest('#linesheet-editor-playground-content')) {
      dispatch(setSelectedPage({ pageId: pageData.unique_page_id, pageIdx: pageIdx }));
    }
    onPageClick(event, pageData.unique_page_id);
  };

  const renderContent = () => {
    if (!pageData || !pageData.content) return null;

    return pageData.content.map((content, idx) => (
      <Content
        key={content.unique_content_id}
        contentData={content}
        locked={pageData.settings.locked || false}
        pageId={pageData.unique_page_id}
        pageIdx={pageIdx}
        contentId={content.unique_content_id}
        pageType={pageData.type}
        pageWidth={pageWidth}
        pageHeight={pageHeight}
      />
    ));
  };

  if (pageData.settings.isNotFormatted) {
    pageStyles.border = '2px solid red';
  }

  if (pageData.unique_page_id === selectedPageId) {
    pageStyles.border = '2px solid #02b183';
  }

  return (
    <StyledPlaygroundPage
      id={`linesheet-editor-playground-page-${pageIdx}`}
      className="linesheet-editor-playground-page"
      style={pageStyles}
      onClick={handlePageClick}
    >
      {pageData.settings.locked && <StyledFontAwesomeIcon icon={faLock} />}
      {pageData.settings.isNotFormatted && (
        <StyledPageNote>
          Oops! Please double-check your alignment. Some of your text is out of bounds.
        </StyledPageNote>
      )}
      {header_content && pageData.type !== 'custom' && (
        <HeaderFooter content={header_content} pageWidth={pageWidth} isHeader={true} />
      )}
      <StyledPageContent className={pageData.settings.locked ? 'locked' : ''}>
        {renderContent()}
      </StyledPageContent>
      {footer_content && pageData.type !== 'custom' && (
        <HeaderFooter content={footer_content} pageWidth={pageWidth} />
      )}
      <StyledPageName>{(pageIdx | 0) + 1}</StyledPageName>
    </StyledPlaygroundPage>
  );
};

export default Page;
