import styled from 'styled-components';
import { AntInput } from 'uiKits/Input';

export const StyledInput = styled(AntInput)`
  width: 254px;
  max-width: 300px;
  height: 32px;
  border-radius: 10px !important;
  border: solid 2px #d7d7d7;
  background-color: #ffffff;
`;
