import { Button, Checkbox, Divider, Tag } from 'antd';
import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  height: 100%;
`;

export const LeftContainer = styled.div`
  width: 70%;
  padding: 25px 0 0 25px;
`;

export const LeftHeader = styled.h2`
  font-family: Muli;
  font-size: 24px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 1.44px;
  text-align: left;
  color: #000;
  margin-bottom: 32px;
`;

export const StyledDivider = styled(Divider)``;

export const RightContainer = styled.div`
  width: 30%;
  background-color: #000;
  border-top-right-radius: 8px;
  color: #fff;
  padding: 15px 0 0;
`;

export const RightHeader = styled.h2`
  font-family: Muli;
  font-size: 24px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 1.44px;
  text-align: left;
  color: #fff;
  margin-bottom: 0;
  padding: 0 20px;
`;

export const RightSubHeading = styled.p`
  padding: 0 20px;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: Muli;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
`;

export const TextAreaButtonWrapper = styled.div`
  padding: 0 20px;
`;

export const StyledTextArea = styled.div`
  height: 68px !important;
  display: block;
  width: 98%;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 15px;
  color: #000;
  padding: 5px;
  overflow-y: auto;
`;

export const ButtonWrapper = styled.div`
  text-align: right;
  margin-bottom: 15px;
`;

export const StyledButton = styled(Button)`
  display: inline-block;
  width: 123px;
  height: 37px;
  border-radius: 18.2px;
  border: 2px solid #fff;
  background-color: initial;
  color: #fff;
  font-family: Muli;
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 0.96px;

  &:hover {
    border: 2px solid #fff !important;
    background-color: initial !important;
    color: #fff !important;
  }
`;

export const StyledRightDivider = styled(Divider)`
  height: 1px;
  width: 100%;
  opacity: 0.24;
  background-color: #fff;
  margin: 0;
`;

export const AlphabetIndexContainer = styled.div`
  position: relative;
`;

export const AlphabetIndex = styled.div`
  overflow: hidden;
  margin: 0;
  top: 4px;
  position: absolute;
  right: 0;
  height: auto;
  z-index: 3;
`;

export const AlphabetLink = styled.a`
  display: block;
  margin-bottom: 5px;
  text-decoration: none;
  color: ${(props) => (props.selected ? '#07af7c' : '#ffffff')};
  font-size: 8px;
  line-height: 9px;
  padding-right: 15px;
  font-family: Muli;
  cursor: pointer;
  margin: 0;

  &:hover {
    color: #07af7c;
  }
`;

export const ContactGroup = styled.div`
  padding: 0 0 0 20px;

  h2 {
    font-size: 12px;
    margin-bottom: 10px;
    color: ${(props) => (props.selected ? '#07af7c' : '#ffffff')};
  }

  p {
    color: #777;
  }
`;

export const ContactItem = styled.div`
  display: flex;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 250px;
  overflow-y: auto;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-right: 10px;
`;

export const StyledNotAvailableText = styled.p`
  margin: 0;
`;

export const EmailLabelWrapper = styled.div`
  display: inherit;
  flex-direction: column;
  width: 70%;
`;

export const StyledTag = styled(Tag)`
  border: 2px solid #02b183;
  color: #02b183;
  background-color: rgba(2, 177, 131, 0.5);
  font-family: Muli;
  font-size: 8px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;

  .ant-tag-close-icon {
    font-size: 7px;
    color: #02b183;
    &:hover {
      color: #02b183;
    }
  }
`;

export const LinesheetContainer = styled.div`
  height: 400px;
  overflow-y: scroll;
`;