import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_BASE_URL;

export const handle401Error = async (api, action, { getState }) => {
  console.log('handle401Error called!');
};

export const externalSourceApi = createApi({
  reducerPath: 'externalSourceApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }),
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(handle401Error),
  endpoints: (builder) => ({
    getSources: builder.query({
      query: () => '/users/get-external-sources',
    }),
    addSource: builder.mutation({
      query: (apiPayload) => ({
        url: 'users/add-external-source',
        method: 'POST',
        body: apiPayload,
      }),
    }),
    removeSource: builder.mutation({
      query: (apiPayload) => ({
        url: 'users/remove-external-source',
        method: 'POST',
        body: apiPayload,
      }),
    }),
    getSourceProducts: builder.mutation({
      query: ({ source, payload, loadMore }) => ({
        url: `products/external-source-data/${source}?load-more=${loadMore}`,
        method: 'POST',
        body: payload ? payload : {},
      }),
    }),
    importSelectedProducts: builder.mutation({
      query: ({ source, payload }) => ({
        url: `products/import-selected-data/${source}`,
        method: 'POST',
        body: payload,
      }),
    }),
    cancelImportSources: builder.mutation({
      query: ({ source }) => `products/cancel/${source}`,
    }),
  }),
});

export const {
  useGetSourcesQuery,
  useAddSourceMutation,
  useRemoveSourceMutation,
  useGetSourceProductsMutation,
  useImportSelectedProductsMutation,
  useCancelImportSourcesMutation
} = externalSourceApi;
