import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ChooseAddProductType from 'components/Product/ChooseAddProductType';
import ManualForm from 'components/Product/ManualForm';
import CSVProducts from 'components/Product/CSVProducts';
import ExternalAPIProducts from 'components/Product/ExternalAPIProducts'

const NewProduct = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  const [method, setMethod] = useState(queryParams.get('method'));

  useEffect(() => {
    const newMethod = queryParams.get('method');
    setMethod(newMethod);
  }, [location.search]);

  const renderInner = () => {
    if (!method) {
      return <ChooseAddProductType />;
    }
    switch (method) {
      case 'manual':
        return <ManualForm editProduct={false} />;
      case 'csv':
        return <CSVProducts />;
      default:
        return <ExternalAPIProducts method={method}/>;
    }
  };

  return (
    <>
      <div className="container new-product">{renderInner()}</div>
    </>
  );
};

export default NewProduct;
