import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowDownloadConfirmationModal } from 'slices/editorSlice';
import {
  StyledModal,
  StyledTitle,
  FooterWrapper,
  StyledButton,
  ButtonsWrapper,
  ModalBody,
  StyledActionsButton,
} from '../ConfirmTemplateModal/styles';

const DownloadConfirmationModal = ({ setShowSubscriptionModal, saveAndDownloadApi }) => {
  const dispatch = useDispatch();
  const { showDownloadConfirmationModal } = useSelector((state) => state.editor);

  const onClose = () => {
    dispatch(setShowDownloadConfirmationModal());
  };

  const showSubscriptionModal = () => {
    onClose();
    setShowSubscriptionModal(true);
  };

  const download = () => {
    onClose();
    saveAndDownloadApi();
  };

  return (
    <StyledModal
      title={<StyledTitle>Confirm Download</StyledTitle>}
      open={showDownloadConfirmationModal}
      footer={
        <FooterWrapper>
          <ButtonsWrapper>
            <StyledButton size="large" type="text" onClick={() => onClose()}>
              Cancel
            </StyledButton>
            <StyledActionsButton size="large" type="text" onClick={download}>
              Download Previous Linesheet
            </StyledActionsButton>
            <StyledActionsButton size="large" type="text" onClick={showSubscriptionModal}>
              Show Pricing
            </StyledActionsButton>
          </ButtonsWrapper>
        </FooterWrapper>
      }
      onCancel={onClose}
      centered={true}
      width={800}
    >
      <ModalBody>
        <p>You have already used your one-time download.</p>
        <ul>
          <li>You can download the same file again.</li>
          <li>Or explore pricing options for additional downloads.</li>
        </ul>
      </ModalBody>
    </StyledModal>
  );
};

export default DownloadConfirmationModal;
