import React from 'react';
import { faQuestionCircle as faQuestionCircleSolid } from '@fortawesome/free-solid-svg-icons';
import {
  CalculatorContainer,
  WholeSaleCon,
  StyledLabel,
  StyledOptions,
  StyledOptionLabel,  
  StyledTooltip,
  SliderContainer,
  StyleSlider,
  StyledInputNumber,
  StyledFontAwesomeIcon
} from './styles';

const Calculator = ({ formulaType, setFormulaType, priceChangeParam, setPriceChangeParam }) => {
  const priceChangeParamChangeHandler = (value) => {
    if (isNaN(value)) {
      return;
    }
    setPriceChangeParam(value);
  };

  return (
    <CalculatorContainer>
      <WholeSaleCon>
        <StyledLabel>Calculator</StyledLabel>
        <StyledOptions>
          <StyledOptionLabel
            className={formulaType === 'wholesale' ? 'selected' : ''}
            onClick={() => setFormulaType('wholesale')}
          >
            Wholesale
          </StyledOptionLabel>
          <StyledTooltip
            title={`Use this Calculator if your Store’s Products are listed in Retail/MSRP.
                For example: Retail/${priceChangeParam} = WHOLESALE`}
            placement="bottom"
            overlayStyle={{ maxWidth: '480px' }}
          >
            <StyledFontAwesomeIcon icon={faQuestionCircleSolid} />
          </StyledTooltip>
        </StyledOptions>{' '}
        /
        <StyledOptions>
          <StyledOptionLabel
            className={formulaType === 'retail' ? 'selected' : ''}
            onClick={() => setFormulaType('retail')}
          >
            Retail
          </StyledOptionLabel>
          <StyledTooltip
            title={`Use this Calculator if your Store’s Products are listed in Wholesale.
               For example Wholesale*${priceChangeParam} = RETAIL`}
            placement="bottom"
            overlayStyle={{ maxWidth: '470px' }}
          >
            <StyledFontAwesomeIcon icon={faQuestionCircleSolid} />
          </StyledTooltip>
        </StyledOptions>
      </WholeSaleCon>
      <SliderContainer>
        <StyleSlider
          min={0}
          max={10}
          onChange={priceChangeParamChangeHandler}
          value={typeof priceChangeParam === 'number' ? priceChangeParam : 0}
          step={0.5}
        />
        <StyledInputNumber
          min={0}
          step={1}
          value={priceChangeParam}
          onChange={priceChangeParamChangeHandler}
          required
        />
      </SliderContainer>
    </CalculatorContainer>
  );
};

export default Calculator;
