import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faTrash, faSearch } from '@fortawesome/free-solid-svg-icons';
import SendEmailModal from '../Common/SendEmailModal';
import Pagination from '../Common/Pagination';
import Loader from '../Common/Loader';
import EmailDetailsModal from './DetailsModal';
import Columns from './column';
import { useGetOutboxQuery, useDeleteOutboxMutation } from 'api/outboxApi';
import ImageWithTooltip from '../Common/Tooltip';
import {
  MainLayout,
  ToolBarWrapper,
  LeftContainer,
  HeadingText,
  StyledInput,
  StyledTable,
  SelectAllContainer,
  SelectAllButton,
  SelectedRowsText,
  StyledSpace,
} from './styles';

const Emails = () => {
  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});
  const userData = useSelector((state) => state.auth.user);
  const isSubscribed = userData.subscription.status === 'active';
  const isOneTimeSubscription = userData.subscription.planType === 'oneTime';
  const { data: outboxList, isLoading: getOutboxLoading } = useGetOutboxQuery(
    {
      pageSize,
      currentPage,
      sortOrder,
      sortBy,
      searchFilter,
    },
    { refetchOnMountOrArgChange: true },
  );

  const [deleteOutbox, { isLoading: deleteOutboxLoading }] = useDeleteOutboxMutation();

  const toggleSendEmailModal = () => {
    setSendEmailModalOpen(!sendEmailModalOpen);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    if (newSelectedRowKeys.length < pageSize) {
      setSelectAll(false);
    }
    setSelectedRows(newSelectedRowKeys);
  };

  const handleDelete = async () => {
    if (!selectedRows.length) return message.error('Please select atleast one row');
    const res = await deleteOutbox({ outboxIds: selectedRows, isSelectAll: selectAll });
    setSelectedRows([]);
    if (res?.data) {
      message.success(res?.data?.message);
    } else if (res?.error) {
      message.error(res?.error?.data?.message);
    }
  };

  const handleSelectAll = () => {
    setSelectAll(true);
    setSelectedRows(outboxList?.emails?.map((contact) => contact.id));
  };

  const handleUnselectAll = () => {
    setSelectAll(false);
    setSelectedRows([]);
  };

  const handleSortChange = (column, order) => {
    setSortBy(column);
    setSortOrder(order);
  };

  const onShowSizeChange = (current, pageSize) => {
    setSelectAll(false);
    setSelectedRows([]);
    setPageSize(pageSize);
  };

  const handlePaginationChange = (page) => {
    setSelectAll(false);
    setSelectedRows([]);
    setCurrentPage(page);
  };

  const handleSearchDebounced = debounce((value) => {
    setSearchFilter(value);
  }, 500);

  const handleSearch = (e) => {
    handleSearchDebounced(e.target.value);
  };

  const openDetailsModal = (record) => {
    setSelectedDetails(record);
    setShowDetailsModal(!showDetailsModal);
  };

  const rowSelection = {
    selectedRows,
    preserveSelectedRowKeys: false,
    onChange: onSelectChange,
  };

  return (
    <MainLayout className="container">
      <ToolBarWrapper>
        <LeftContainer>
          <StyledSpace>
            <HeadingText>Emails</HeadingText>
            <ImageWithTooltip
              icon={faTrash}
              onClick={handleDelete}
              title="Delete Emails"
              placement="bottom"
            />
            <ImageWithTooltip
              icon={faEnvelope}
              onClick={toggleSendEmailModal}
              title="Send Email"
              placement="bottom"
              disabled={isOneTimeSubscription || !isSubscribed}
            />
          </StyledSpace>
        </LeftContainer>
        <StyledInput
          placeholder="Search"
          size="large"
          prefix={<FontAwesomeIcon icon={faSearch} />}
          onChange={handleSearch}
        />
      </ToolBarWrapper>
      <SelectAllContainer>
        {selectedRows?.length > 0 && (
          <>
            {selectAll || selectedRows?.length === outboxList?.total ? (
              <SelectAllButton onClick={handleUnselectAll}>Unselect all</SelectAllButton>
            ) : (
              <SelectAllButton onClick={handleSelectAll}>
                Select all {outboxList?.total}
              </SelectAllButton>
            )}
            <SelectedRowsText>
              Selected {selectAll ? outboxList?.total : selectedRows?.length} emails
            </SelectedRowsText>
          </>
        )}
      </SelectAllContainer>
      <StyledTable
        rowKey="id"
        columns={Columns(deleteOutbox, setSelectedRows, openDetailsModal)}
        dataSource={outboxList?.emails}
        locale={{ emptyText: 'No Data Found' }}
        rowSelection={{
          ...rowSelection,
          selectedRowKeys: selectedRows,
        }}
        onChange={(pagination, filters, sorter) => {
          // Handle column sorting change
          if (sorter && sorter.column && sorter.order) {
            handleSortChange(sorter.column.key, sorter.order === 'ascend' ? 'ASC' : 'DESC');
          }
        }}
        pagination={{
          ...Pagination({
            currentPage,
            pageSize,
            total: outboxList?.total,
            onShowSizeChange,
            handlePaginationChange,
          }),
        }}
      />
      {!!sendEmailModalOpen && (
        <SendEmailModal isOpenModal={sendEmailModalOpen} toggleModal={toggleSendEmailModal} />
      )}
      {!!showDetailsModal && (
        <EmailDetailsModal
          isOpen={showDetailsModal}
          setShowDetailsModal={setShowDetailsModal}
          selectedDetails={selectedDetails}
        />
      )}
      {<Loader isLoading={getOutboxLoading || deleteOutboxLoading} />}
    </MainLayout>
  );
};

export default Emails;
