import styled from 'styled-components';
import { Carousel, Divider, Input } from 'antd';
const { TextArea } = Input;

export const MainContainer = styled.div`
  padding: 10px;
`;

export const InputLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  gap: 20px;

  .react-tagsinput {
    border: unset;
    width: 100%;
    .react-tagsinput-input {
      width: 40%;
    }
    .react-tagsinput-tag {
      background-color: #afe7d8;
      font-size: 10px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: normal;
      text-align: left;
      color: #02b183;
      border: solid 2px #02b183;
    }
  }
`;

export const StyledSpan = styled.span`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
`;

export const StyledDivider = styled(Divider)``;

export const StyledInput = styled(Input)`
  border: none;
`;

export const StyledTextArea = styled(TextArea)`
  border: none;
`;

export const StyledCarousel = styled(Carousel)`
  padding-left: 20px;
  .slick-arrow {
    color: #000 !important;
  }
`;

export const StyledLinesheetContainer = styled.div`
  display: flex !important;
`;

export const StyledImage = styled.div`
  width: 90px;
  height: 60px;
  border-radius: 5px;
  text-align: center;
  img {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }
`;

export const StyledLinesheetInfo = styled.div``;

export const StyledLinesheetName = styled.div`
  font-family: Muli;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: normal;
  text-align: left;
  color: #000;
`;

export const StyledLinesheetDetail = styled.div`
  font-size: 10.5px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.9;
  letter-spacing: normal;
  text-align: left;
  color: #495057;
`;
