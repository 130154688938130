import React from 'react';
import {
  HelpingHeader,
  HelpingContentContainer
} from './styles';

const HelpingContent = ({ name }) => {
  switch (name.toLowerCase()) {
    case 'shopify':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <p>
              <strong>Export from Shopify:</strong>{' '}
            </p>
            <ul>
              <li>
                Go to
                <strong> Products</strong>
              </li>
              <li>
                Click <span>Export</span>
              </li>
              <li>
                Select <strong>Products</strong> from the popup
              </li>
              <li>
                Click <strong>Export Products</strong>
              </li>
            </ul>
            <p>
              <strong>Import to Linesheets:</strong>
            </p>
            <ul>
              <li>
                Select <strong>Shopify</strong> from the given options
              </li>
              <li>
                Browse <strong>CSV</strong> from your computer or drag and drop it in the box
              </li>
            </ul>
          </HelpingContentContainer>
        </>
      );
    case 'amazon':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <p>
              <strong>Export from Amazon:</strong>
            </p>
            <ul>
              <li>Log into your Amazon sellers account.</li>
              <li>
                On the <strong>Inventory</strong> tab, select <strong>Inventory Reports</strong>
              </li>
              <li>
                Select the <strong>Active Listings Report</strong> from the drop-down menu.
              </li>
              <li>
                Click the <strong>Request Report</strong> button.
              </li>
              <li>
                Reports generally take 15 to 45 minutes to generate. You can return to this page
                later to pick up your completed report.
              </li>
              <li>
                Under <strong>Check Report Status & Download</strong>, when the Report Status is
                "Ready," click the <strong>Download</strong> button.
              </li>
              <li>
                <strong>Save</strong> the file to your local computer.
              </li>
            </ul>
            <p>
              <strong>Import to Linesheets:</strong>
            </p>
            <ul>
              <li>
                Select <strong>Amazon</strong> from the given options
              </li>
              <li>
                Browse <strong>CSV</strong> from your computer or drag and drop it in the box
              </li>
            </ul>
          </HelpingContentContainer>
        </>
      );
    case 'rakuten':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <p>
              <strong>Endpoint:</strong> The name of your store
            </p>
          </HelpingContentContainer>
        </>
      );

    case 'ebay':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <p>
              <strong>Export from Ebay:</strong>
            </p>
            <ul>
              <li>
                Go to My eBay, Selling Manager, or Selling Manager Pro, and then go to your{' '}
                <strong>Active</strong>, <strong>Sold</strong>, or <strong>Unsold</strong> listings
                view.
              </li>
              <li>Select the listings you want to export to a file.</li>
              <li>
                Click the <strong>Action</strong> drop-down and then select{' '}
                <strong>Export to file</strong>.
              </li>
              <li>
                Select a file format (CSV or PDF) and then click the <strong>Export</strong>
                button.
              </li>
            </ul>
            <p>
              <strong>Import to Linesheets:</strong>
            </p>
            <ul>
              <li>
                Select <strong>Ebay</strong> from the given options
              </li>
              <li>
                Browse <strong>CSV</strong> from your computer or drag and drop it in the box
              </li>
            </ul>
          </HelpingContentContainer>
        </>
      );
    case 'bigcartel':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <ul>
              <li>
                {' '}
                <strong>Endpoint:</strong> Account id
              </li>
              <li>
                <strong>e.g.</strong> (winterclothing.bigcartel.com) <strong>winterclothing</strong>{' '}
                is account id
              </li>
            </ul>
            <p>Sign up for their API here: and Big Cartel will grant you access.</p>
          </HelpingContentContainer>
        </>
      );
    case 'etsy':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <p>
              <strong>Export from Etsy:</strong>
            </p>
            <ul>
              <li>
                Sign in to your Etsy account and go to Your{' '}
                <strong>
                  Shop {'>'} Shop Settings {'>'} Options.
                </strong>
              </li>
              <li>
                Click the <strong>Download Data</strong> tab. Then, click the{' '}
                <strong>Download CSV </strong>
                button to save the file to your compute
              </li>
            </ul>
            <p>
              <strong>Import to Linesheets:</strong>
            </p>
            <ul>
              <li>
                Select <strong>Etsy</strong> from the given options
              </li>
              <li>
                Browse <strong>CSV</strong> from your computer or drag and drop it in the box
              </li>
            </ul>
          </HelpingContentContainer>
        </>
      );
    case 'pinnaclecart':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <p>
              <strong>Export from PinnacleCart:</strong>
            </p>

            <ul>
              <li>
                Click <strong>Products & Categories {'>'} Browse Products</strong> on the left side
                of the admin area.
              </li>
              <li>
                In the Bulk Actions drop-down menu, select Export. This will present an option to
                <strong> Export only selected products</strong> or
                <strong> Export all products</strong>. Export all products will ignore what you’ve
                selected and push all existing products into a .csv
              </li>
            </ul>
            <p>
              <strong>Import to Linesheets:</strong>
            </p>
            <ul>
              <li>
                Select <strong>PinnacleCart</strong> from the given options
              </li>
              <li>
                Browse <strong>CSV</strong> from your computer or drag and drop it in the box
              </li>
            </ul>
          </HelpingContentContainer>
        </>
      );
    case 'squarespace':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <strong>Export from Squarespace:</strong>
            <ul>
              <li>
                In the Home Menu, click <strong>Commerce</strong>, then click{' '}
                <strong>Inventory</strong>.
              </li>
              <li>
                Click <span>Export All</span>
              </li>
              <li>
                To export specific products, select the products in the Inventory Panel, then click{' '}
                <strong>Export selected</strong> in the bottom-right corner.{' '}
              </li>
            </ul>
            <p>
              <strong>Import to Linesheets:</strong>
            </p>
            <ul>
              <li>
                Select <strong>Squarespace</strong> from the given options
              </li>
              <li>
                Browse <strong>CSV</strong> from your computer or drag and drop it in the box
              </li>
            </ul>
          </HelpingContentContainer>
        </>
      );
    case 'magento':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <p>
              <strong>Export from Magento:</strong>
            </p>
            <p>
              <ul>
                <li>
                  On the Admin sidebar, go to{' '}
                  <strong>
                    System-{'>'}Data Transfer-{'>'}Export
                  </strong>
                </li>
                <li>
                  For <strong>Entity Type menu</strong> choose <strong>Stock Sources</strong>
                </li>

                <li>
                  The export only extracts data for products with a <strong>SKU</strong>
                </li>
                <li>
                  Use the <strong>Entity Attributes</strong> to filter the exported products for a
                  specific source.
                </li>
                <li>
                  click <strong>Continue</strong> button
                </li>
              </ul>
            </p>
            <p>
              <strong>Import to Linesheets:</strong>
            </p>
            <ul>
              <li>
                Select <strong>Magento</strong> from the given options
              </li>
              <li>
                Browse <strong>CSV</strong> from your computer or drag and drop it in the box
              </li>
            </ul>
          </HelpingContentContainer>
        </>
      );
    case '3dcart':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <p>
              <strong>Export from 3DCart:</strong>
            </p>
            <ul>
              <li>
                Go to <strong>Products-{'>'}Product List</strong>
              </li>
              <li>
                Look towards the top right of the page and click on the{' '}
                <strong>Export/Import</strong> button
              </li>
              <li>
                The Export/Import page will contain Export options on the left hand side and Import
                options on the right hand side.
              </li>

              <li>
                Look towards the left hand side of the page and click on the <strong>+Icon</strong>{' '}
                next to "Products"
              </li>
              <li>
                Next, click on the <strong>Export Data</strong> link to generate your store's
                product CSV file.
              </li>
            </ul>
            <p>
              <strong>Import to Linesheets:</strong>
            </p>
            <ul>
              <li>
                Select <strong>3DCart</strong> from the given options
              </li>
              <li>
                Browse <strong>CSV</strong> from your computer or drag and drop it in the box
              </li>
            </ul>
          </HelpingContentContainer>
        </>
      );
    case 'woocommerce':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <p>
              <strong>Export from WooCommerce:</strong>
            </p>
            <ul>
              <li>
                Go to <strong>Products</strong>
              </li>
              <li>
                Select to Export All Columns. Or select which columns to export by using the
                dropdown menu.
              </li>
              <li>
                Select to Export All Products. Or select which product types to export by using the
                dropdown menu.
              </li>
              <li>
                Select to Export All Categories. Or select which categories to export by using the
                dropdown menu.{' '}
              </li>

              <li>Tick the box to Export Custom Meta, if you need this info.</li>
              <li>
                Select <strong>Generate CSV</strong>. Wait for the export to finish.
              </li>
              <li>CSV will download.</li>
            </ul>
            <p>
              <strong>Import to Linesheets:</strong>
            </p>
            <ul>
              <li>
                Select <strong>WooCommerce</strong> from the given options
              </li>
              <li>
                Browse <strong>CSV</strong> from your computer or drag and drop it in the box
              </li>
            </ul>
            <p>
              <strong>Note:</strong> We only supports textual data in discription box of the
              products
            </p>
          </HelpingContentContainer>
        </>
      );
    case 'volusion':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <p>
              <strong>Export from Volusion:</strong>
            </p>
            <ul>
              <li>
                In your Admin Area, go to <strong>Products/Inventory</strong>
              </li>
              <li>
                Click on <strong>Export</strong>
              </li>
              <li>
                Click <strong>Start Export</strong> on the popup
              </li>
              <li>It will email you the CSV file</li>
              <li>
                <strong>Download</strong> file from the email
              </li>
            </ul>
            <p>
              <strong>Import to Linesheets:</strong>
            </p>
            <ul>
              <li>
                Select <strong>Volusion</strong> from the given options
              </li>
              <li>
                Browse <strong>CSV</strong> from your computer or drag and drop it in the box
              </li>
            </ul>
          </HelpingContentContainer>
        </>
      );
    case 'linesheets':
      return (
        <>
          <HelpingHeader>{name}</HelpingHeader>
          <HelpingContentContainer>
            <p>
              <strong>Import to Linesheet:</strong>
            </p>

            <ul>
              <li>
                Download<strong> sample CSV.</strong>
              </li>
              <li>
                <strong>Add</strong> products according to the format
              </li>
              <li>
                Select <strong>Linesheets</strong> from the given options
              </li>
              <li>
                Browse <strong>CSV</strong> from your computer or drag and drop it in the box
              </li>
            </ul>
          </HelpingContentContainer>
        </>
      );
    default:
      return null;
  }
};

export default HelpingContent;
