import { useDispatch } from 'react-redux';
import {
  contentMenuActionHandler,
  moveContentForward,
  moveContentBackward,
  moveContentFront,
  moveContentBack,
} from 'slices/editorSlice';

const TextMenuOptions = () => {
  const dispatch = useDispatch();

  const options = [
    {
      label: 'Text',
      disabled: true,
    },
    {
      label: 'Edit Text',
      onClick: () => dispatch(contentMenuActionHandler('editText')),
      disabled: false,
    },
    {
      label: 'Duplicate',
      onClick: () => dispatch(contentMenuActionHandler('duplicate')),
      disabled: false,
    },
    {
      type: 'divider',
    },
    {
      label: 'Bring Forward',
      onClick: () => dispatch(moveContentForward()),
      disabled: false,
    },
    {
      label: 'Bring to Front',
      onClick: () => dispatch(moveContentFront()),
    },
    {
      label: 'Send Backward',
      onClick: () => dispatch(moveContentBackward()),
    },
    {
      label: 'Send to Back',
      onClick: () => dispatch(moveContentBack()),
    },
    {
      type: 'divider',
    },
    {
      label: 'Delete Text',
      onClick: () => dispatch(contentMenuActionHandler('delete')),
    },
  ];

  return options;
};

export default TextMenuOptions;
