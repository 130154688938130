import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(2px);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Spinner = styled(FontAwesomeIcon)`
  font-size: 48px;
  color: #02b183;
  animation: ${spin} 1s linear infinite;
`;

export const Message = styled.p`
  margin-top: 24px;
  font-size: 18px;
  font-weight: 600;
`;
