import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_BASE_URL;

export const handle401Error = async (api, action, { getState }) => {
  console.log('handle401Error called!');
  // Rest of your middleware logic
};

export const outboxApi = createApi({
  reducerPath: 'outboxApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    credentials: 'include',
  }),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(handle401Error),
  endpoints: (builder) => ({
    getOutbox: builder.query({
      query: ({ searchFilter, sortBy, sortOrder, currentPage, pageSize }) => {
        let url = `/outbox?limit=${pageSize}`;
        if (searchFilter) {
          url += `&searchFilter=${encodeURIComponent(searchFilter)}`;
        }
        if (sortBy && sortOrder) {
          url += `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
        }
        if (currentPage) {
          url += `&page=${currentPage}`;
        }
        return { url };
      },
      providesTags: ['Outbox'],
    }),
    deleteOutbox: builder.mutation({
      query: (deletedIds) => ({
        url: `/outbox`,
        method: 'DELETE',
        body: deletedIds,
      }),
      invalidatesTags: ['Outbox'],
    }),
    createOutbox: builder.mutation({
      query: (outboxData) => ({
        url: `/outbox/send-emails`,
        method: 'POST',
        body: outboxData,
      }),
      invalidatesTags: ['Outbox'],
    }),
  }),
});

export const { useGetOutboxQuery, useDeleteOutboxMutation, useCreateOutboxMutation } = outboxApi;
