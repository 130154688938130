import styled from 'styled-components';

export const HelpingHeader = styled.h3`
  font-family: Muli;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 1.44px;
  text-align: left;
  color: #000000;
`;

export const HelpingContentContainer = styled.div`
  font-family: Muli;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.96px;
  text-align: left;
  color: #000000;
  p {
    margin-bottom: 0px;
  }
  ul li span {
    font-weight: bold;
  }
`;
