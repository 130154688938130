import styled from 'styled-components';
import { Checkbox } from 'antd';

export const LinesheetThumbnailContainer = styled.div`
  background-color: ${({ background }) => (background ? 'white' : '#f8f9fa')};
`;

export const StyledCheckbox = styled(Checkbox)`
  position: absolute;
  padding: 5px;
`;

export const StyledImageContainer = styled.div`
  height: ${({ height }) => (height ? '200px' : '144px')};
  text-align: center;
`;

export const StyledImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;
