import React from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { LoadingContainer, LoadingInner, Spinner, Message } from './styles';

const Loader = ({ isLoading, message }) => {
  return (
    isLoading && (
      <LoadingContainer>
        <LoadingInner>
          <Spinner icon={faSpinner} />
          <Message>{message}</Message>
        </LoadingInner>
      </LoadingContainer>
    )
  );
};

export default Loader;
