import styled from 'styled-components';
import { Row } from 'antd';
import AntTable from 'uiKits/Table';
import { StyledCancelButton, StyledSubmitButton } from '../ManualForm/styles';

export const ExternalApiContainer = styled.div`
  padding: 48px 32px;
`;

export const StyledTable = styled(AntTable)``;

export const ExternalApiHeader = styled.div``;

export const MainTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ExternalApiProductsApiLogo = styled.img`
  width: 20%;
`;

export const ExternalApiProductsTitle = styled.h2`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.03;
  letter-spacing: 0.6px;
  margin: 0;
`;

export const ApiButtonHolder = styled.div``;

export const ImportButton = styled(StyledSubmitButton)`
  height: unset;
`;

export const CancelButton = styled(StyledCancelButton)``;

export const StyledRow = styled(Row)`
  margin-bottom: ${(props) => (props.marginbottom ? `${props.marginbottom}` : '0')};
  justify-content: space-between;
  width: 100%;
`;

export const StyledProductCount = styled.h3``;
