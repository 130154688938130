import React from 'react';
import {
  MainLayout,
  HeaderWraper,
  ButtonWrapper,
  StyledCancelButton,
  StyledNextButton,
  Label,
  StyledInput,
} from './styles';

const LinesheetsName = ({ backClickHandler, handleCreate, nameChangeHandler, selectedName }) => {
  return (
    <MainLayout className="container">
      <HeaderWraper>
        <h3>Choose Name for Linesheet</h3>
        <ButtonWrapper>
          <StyledCancelButton onClick={backClickHandler}>Back</StyledCancelButton>
          <StyledNextButton onClick={handleCreate} disabled={!selectedName}>
            Create
          </StyledNextButton>
        </ButtonWrapper>
      </HeaderWraper>
      <Label>Linesheet Name*</Label>
      <StyledInput onChange={nameChangeHandler} placeholder="Enter Linesheet Name" />
    </MainLayout>
  );
};
export default LinesheetsName;
