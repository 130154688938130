import React, { useCallback } from 'react';
import barcodeImageIcon from 'assets/images/image-icon.svg';
import ImageUploader from 'components/Common/ImageUploader';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { useDropzone } from 'react-dropzone';
import { Row, Col } from 'antd';
import FormTooltip from '../FormTooltip';
import globals from 'websiteGlobals';
import {
  StyledForm,
  StyledInput,
  HorizontalLine,
  StyledTextArea,
  StyledSelect,
  BarcodeContainer,
  BarcodeContent,
  BarcodeImageIcon,
  BarcodeText,
  AddVariantContainer,
  VariantHeading,
  AddIcon,
  CustomError,
  BarcodeImage,
  ProductFormContainer,
} from './../ManualForm/styles';

const ProductFormBody = ({
  categories,
  error,
  images,
  handleImages,
  deleteImage,
  defaultImage,
  thumbnailNum,
  addVariant,
  renderVariants,
  barcodeImage,
  setBarcodeImage,
  setError,
}) => {
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file.size > globals.constants.maxImageSizeInBytes) {
      setError((prevError) => ({
        ...prevError,
        barcode: `Image size exceeds maximum allowed size. Please choose an image smaller than ${globals.constants.maxImageSizeInMegaBytes}MB`,
      }));
      return;
    }
    setBarcodeImage(file);
    // eslint-disable-next-line
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1, noClick: false });

  const categoryOptions = categories?.map((category) => ({
    value: category.id,
    label: category.name,
  }));

  const handlePriceChange = (event) => {
    const { name, value } = event.target;
    let isNotValid;
    switch (name) {
      case 'wholesalePrice':
        const wholesalePrice = parseFloat(value);
        isNotValid = wholesalePrice < -999999 || wholesalePrice > 999999;
        setError((prevError) => ({
          ...prevError,
          wholesalePrice: isNotValid
            ? 'Whole price must be within the range of -999999 to 999999.'
            : '',
        }));
        break;
      case 'retailPrice':
        const retailPrice = parseFloat(value);
        isNotValid = retailPrice < -999999 || retailPrice > 999999;

        setError((prevError) => ({
          ...prevError,
          retailPrice: isNotValid
            ? 'Retail price must be within the range of -999999 to 999999.'
            : '',
        }));
        break;
      default:
        break;
    }
  };

  const handleKeyDown = (e) => {
    const allowedKeys = [
      'Backspace',
      'Tab',
      'Enter',
      'Escape',
      'Delete', // control keys
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown', // arrow keys
      '.', // decimal point
    ];
    if (
      e.key.length === 1 &&
      !/[0-9]/.test(e.key) && // Allow digits
      !allowedKeys.includes(e.key)
    ) {
      e.preventDefault();
    }

    if (e.key === '.' && e.target.value.includes('.')) {
      e.preventDefault();
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      // Allow only valid numeric values
      handlePriceChange(e);
    }
  };

  return (
    <>
      <Row>
        <Col xs={{ flex: '100%' }} lg={{ flex: '40%' }} xl={{ flex: '33%' }}>
          <ImageUploader
            handleImages={handleImages}
            showthumbnails={true}
            imagesArray={images}
            deleteImage={deleteImage}
            defaultImage={defaultImage}
            thumbnailNum={thumbnailNum}
          />
        </Col>
        <Col xs={{ flex: '100%' }} lg={{ flex: '60%' }} xl={{ flex: '67%' }}>
          <ProductFormContainer>
            <StyledForm.Item
              label={
                <>
                  Product Name
                  <FormTooltip title="This is the name of product. Enter it exactly how you want it to appear on your linesheet." />
                </>
              }
              name="name"
              required
              rules={[{ required: true, message: 'Please provide a valid name!' }]}
            >
              <StyledInput placeholder="Name" className="product-name" />
            </StyledForm.Item>
            <HorizontalLine />
            <StyledForm.Item
              label={
                <>
                  Product Number/SKU
                  <FormTooltip
                    title="This is the unique number assigned to the product and it will be
                    visible on the finished linesheet. Please double-check the SKU
                    for accuracy"
                  />
                </>
              }
              name="productNumber"
            >
              <StyledInput placeholder="Product Number" />
            </StyledForm.Item>
            <HorizontalLine />
            <StyledForm.Item label="Description" name="description">
              <StyledTextArea placeholder="Description" rows="3" />
            </StyledForm.Item>
            <HorizontalLine />
            <StyledForm.Item label="Categories" name="categories">
              <StyledSelect
                mode="multiple"
                placeholder="Categories"
                popupClassName="categories-dropdown"
                options={categoryOptions}
              />
            </StyledForm.Item>
            <HorizontalLine />
            <StyledForm.Item
              label={
                <>
                  Wholesale Price
                  <FormTooltip
                    title="This is the price at which you sell your product to retailers.<br/>
                    To calculate your wholesale price, use this formula:<br/><br/><br/>
                    COST * X = WHOLESALE PRICE <br/><br/>
                    X represents your target multiplier and <br/>
                    could be a number like 2 or 2.2, though that depends on <br/>
                    the industry you’re in and your desired profit margins.<br/><br/><br/>
                    Keep in mind that the Wholesale<br/>
                    Price will appear on the linesheet"
                  />
                </>
              }
              name="wholesalePrice"
            >
              <StyledInput
                name="wholesalePrice"
                type="number"
                placeholder="Wholesale Price"
                step="0.1"
                // onChange={(e) => handlePriceChange(e)}
                onKeyDown={handleKeyDown}
                onChange={handleInputChange}
              />
            </StyledForm.Item>
            {error.wholesalePrice && <CustomError>{error.wholesalePrice}</CustomError>}
            <HorizontalLine />
            <StyledForm.Item
              label={
                <>
                  Retail Price
                  <FormTooltip
                    title="Your retail price is the price at which you sell products to <br/>
                      consumers. Retail (aka MSRP —Manufacturer Suggested Retail <br/>
                      Price) is calculated using your wholesale price plus a multiplier <br/>
                      (known as X) like 2.5 or 3 depending on your industry and <br/>
                      what helps you achieve your desired profit margin.<br/><br/><br/>
                      The general formula is WHOLESALE<br/>
                      * X = RETAIL/MSRP<br/><br/>
                      Note that your Retail Price will appear on the finished linesheet"
                  />
                </>
              }
              name="retailPrice"
            >
              <StyledInput
                name="retailPrice"
                type="number"
                placeholder="Retail Price"
                step="0.1"
                // onChange={(e) => handlePriceChange(e)}
                onKeyDown={handleKeyDown}
                onChange={handleInputChange}
              />
            </StyledForm.Item>
            {error.retailPrice && <CustomError>{error.retailPrice}</CustomError>}
            <HorizontalLine />
            <StyledForm.Item
              label={
                <>
                  Barcode Number
                  <FormTooltip title="Enter the 12-digit barcode number" />
                </>
              }
              name="barcodeNumber"
            >
              <StyledInput placeholder="Barcode Number" />
            </StyledForm.Item>
            <HorizontalLine />
            <StyledForm.Item
              label={
                <>
                  Barcode
                  <FormTooltip title="Upload Barcode image" />
                </>
              }
              name="barcodeImage"
            >
              <BarcodeContainer {...getRootProps()}>
                <input {...getInputProps({ multiple: false })} />
                {barcodeImage ? (
                  <BarcodeImage
                    className="mx-auto img-fluid d-block mh-100"
                    src={barcodeImage.path ? URL.createObjectURL(barcodeImage) : barcodeImage.url}
                    alt="Barcode Image"
                  />
                ) : (
                  <BarcodeContent>
                    <BarcodeImageIcon src={barcodeImageIcon} />
                    <BarcodeText>
                      Drop barcode
                      <br />
                      file to upload
                      <span>
                        or <strong className="image-underline-text">browse</strong> to choose file
                      </span>
                    </BarcodeText>
                  </BarcodeContent>
                )}
              </BarcodeContainer>
            </StyledForm.Item>
            {error.barcode && <CustomError>{error.barcode}</CustomError>}
            <HorizontalLine />
            <AddVariantContainer>
              <VariantHeading>
                Variants
                <AddIcon icon={faCirclePlus} onClick={addVariant} />
                <FormTooltip
                  title="Do you have different sizes, color, or style options for
                  your product? If so, add the variants in this section."
                />
              </VariantHeading>
              {error.variants && <CustomError>{error.variants}</CustomError>}
            </AddVariantContainer>
            {renderVariants()}
          </ProductFormContainer>
        </Col>
      </Row>
    </>
  );
};

export default ProductFormBody;
