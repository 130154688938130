import styled from 'styled-components';
import { Button, Tooltip } from 'antd';

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const StyledTooltip = styled(Tooltip)``;

export const StyledInputValue = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 31px;
  border-radius: 3px;
  border: solid 2px #6b757c !important;
  background-color: #242424 !important;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.08;
  text-align: center;
  color: #ffffff !important;
  padding: 0 8px;
  margin: 0;
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 34px;
  border-radius: 3px;
  border: solid 2px #ffffff !important;
  background-color: #242424 !important;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  padding: 0 8px;
  line-height: 1.08;
  span{
    color: #ffffff !important;
  }
`;
