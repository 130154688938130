import React from 'react';
import { faQuestionCircle as faQuestionCircleRegular } from '@fortawesome/free-regular-svg-icons';
import AntTootltip from 'uiKits/Tooltip';
import { QuestionIcon } from './styles';

const FormTooltip = ({ title }) => {
  return (
    <AntTootltip title={<div dangerouslySetInnerHTML={{ __html: title }} />} placement="right">
      <QuestionIcon icon={faQuestionCircleRegular} />
    </AntTootltip>
  );
};

export default FormTooltip;
