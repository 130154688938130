import React from 'react';
import { useDispatch } from 'react-redux';
import { setProductArrangement } from 'slices/editorSlice';
import imageOnTop from 'assets/images/image-on-top-of-text-icon.svg';
import imageOnBottom from 'assets/images/image-on-bottom-of-text-icon.svg';
import { StyledRow } from './styles';

const ImagePosition = () => {
  const dispatch = useDispatch();
  const handleClick = (val) => {
    dispatch(setProductArrangement(val));
  };
  return (
    <>
      <StyledRow onClick={() => handleClick('top')}>
        <img src={imageOnTop} alt="" />
        <p>Image on top of Text</p>
      </StyledRow>
      <StyledRow onClick={() => handleClick('bottom')}>
        <img src={imageOnBottom} alt="" />
        <p>Image on bottom of Text</p>
      </StyledRow>
    </>
  );
};

export default ImagePosition;
