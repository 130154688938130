import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import dashboardBackground from 'assets/images/needHelpBg.png';
import NeedHelpIcon from 'assets/images/needHelpIcon.svg';

export const MainContainer = styled.div`
  position: relative;
  z-index: 0;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  padding: 48px 32px;
  box-shadow: 0.8px -0.6px 16px 0 rgba(0, 0, 0, 0.02);
`;

export const BackgroundImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const BackgroundImageOverlay = styled.div`
  position: absolute;
  top: -48px;
  right: -33px;
  width: 100%;
  height: 100%;
  background-image: url(${dashboardBackground});
  background-size: 150%;
  background-position: 100% 100%;
  transform-origin: center;
  transform: scale(1, -1);
  z-index: -1;
`;

export const HelpIcon = styled.div`
  width: 80px;
  height: 80px;
  background-image: url(${NeedHelpIcon});
  background-size: cover;
  margin-bottom: 16px;
  cursor: pointer;
`;

export const StyledTooltip = styled(Tooltip)``;

export const NeedHelpText = styled.h4`
  padding: 24px 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 1.44px;
`;

export const StyledLink = styled(Link)`
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.44;
  display: block;
  padding: 16px 0;
  transition: color 0.15s ease-in-out;
  text-decoration: none !important;
  cursor: pointer;
  width: max-content;

  &:hover {
    color: #02b183;
  }
`;
